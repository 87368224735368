import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { PhoneInput } from "react-international-phone";
import cx from "classnames";
import { ErrorMessage } from "@hookform/error-message";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";

import {
  ENUMS as ENUMS_EMAIL_OR_PHONE,
  ENUMS,
} from "components/elements/email-or-phone/EmailOrPhone";
import { SIGN_IN_METHOD as SIGN_UP_METHOD } from "pages/common/login/sign-in/SignIn";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import useAsync from "utils/hooks/useAsync";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import { forgotGuestPassword } from "utils/api/services/guest";
import { ROUTE_NAME, QUERY_PARAMS } from "utils/constants/routes";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import EmailInput, {
  ENUMS as ENUMS_EMAIL_INPUT,
} from "components/forms/input/input-email/EmailInput";
import PasswordInput, {
  ENUMS as ENUMS_PASSWORD_INPUT,
} from "components/forms/input/input-password/PasswordInput";

import "./ForgotPasswordNewPassword.scss";

const ForgotPasswordNewPassword = ({
  formData,
  setFormData,
  selectedMethod,
}) => {
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const schemas = useValidationSchema(t);
  const { guestSignInSchema } = schemas;

  const methods = useForm({
    resolver: zodResolver(guestSignInSchema(selectedMethod)),
    criteriaMode: "all",
  });

  const {
    formState: { errors },
    register,
    control,
    handleSubmit,
    setError,
    setValue,
  } = methods;

  useEffect(() => {
    setValue("phoneNumber", formData.phoneNumber);
    setValue("email", formData.email);
  }, [formData.phoneNumber, formData.email]);

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handlePhoneInputChange = (phone, meta, onChange) => {
    const countryDialCode = meta.country.dialCode;
    if (phone && phone !== ENUMS_EMAIL_OR_PHONE.plusSign + countryDialCode) {
      setFormData((prev) => {
        return {
          ...prev,
          phoneNumber: phone,
        };
      });
    }

    onChange(phone);
  };

  const { execute: executeResetGuestPassword, loading: isSignUpLoading } =
    useAsync(forgotGuestPassword, {
      onError: (error) => {
        const errorData = handleAPIErrorMessage(error.response.data);
        if (errorData) {
          const { field, errorMessage } = errorData;
          setError(field, {
            type: "manual",
            message: errorMessage,
          });
        }
      },
      onSuccess: () => {
        const phoneNumber = methods.getValues("phoneNumber");
        const email = methods.getValues("email");

        let targetSearchParams;

        if (phoneNumber) {
          targetSearchParams = `?${QUERY_PARAMS.phoneNumber}=${phoneNumber}`;
        } else if (email) {
          targetSearchParams = `?${QUERY_PARAMS.email}=${email}`;
        }

        navigate({
          pathname: `${ROUTE_NAME.client}${ROUTE_NAME.auth}${ROUTE_NAME.signIn}`,
          ...(targetSearchParams && { search: targetSearchParams }),
        });
      },
    });

  const handleOnSubmit = () => {
    const { otpToken, password } = formData;
    executeResetGuestPassword({
      otpToken,
      body: {
        password,
      },
    });
  };
  const handleKeyDown = ({ target, key }) => {
    if (key === "Enter") {
      switch (target.name) {
        case "password":
          handleSubmit(handleOnSubmit)();
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="ForgotPasswordNewPassword">
      <div className="ForgotPasswordTitle">
        <h1 className="SemiBold title">{t("auth.setNewPassword")}</h1>
        <h6 className="Medium">{t("auth.setNewPasswordDesc")}</h6>
      </div>

      <form
        className="ForgotPasswordSetNewPassword"
        onSubmit={(e) => e.preventDefault()}
      >
        {selectedMethod === SIGN_UP_METHOD.phoneNumber ? (
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <PhoneInput
                  value={value}
                  onChange={(phone, meta) =>
                    handlePhoneInputChange(phone, meta, onChange)
                  }
                  name="phoneNumber"
                  placeholder={t("inputs.phoneNumber")}
                  defaultCountry={ENUMS.defaultCountry}
                  hideDropdown
                  className={cx("phone-input", {
                    hasError: errors.phoneNumber,
                  })}
                  disabled={true}
                  inputProps={{
                    autoComplete: "off",
                  }}
                />
                <div className="EmailOrPhoneSignInMethodError">
                  {errors.phoneNumber && (
                    <ErrorMessage
                      errors={errors}
                      name="phoneNumber"
                      render={({ message }) => (
                        <p className="h7 error-message">{message}</p>
                      )}
                    />
                  )}
                </div>
              </>
            )}
          />
        ) : (
          <EmailInput
            className={"InputEmail"}
            containerClassName={"TypeA"}
            placeholder={t("inputs.email")}
            required
            name="email"
            labelType={ENUMS_EMAIL_INPUT.types.TYPE_B}
            func={{
              ...register("email", {
                onChange: handleInputChange,
              }),
            }}
            readOnly={true}
            hasError={errors.email}
            error={
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
          />
        )}
        <PasswordInput
          name="password"
          containerClassName={"TypeA"}
          placeholder={t("inputs.newPassword")}
          value={formData.password}
          hasError={errors.password}
          required
          func={{
            ...register("password", {
              onChange: handleInputChange,
            }),
          }}
          onKeyDown={handleKeyDown}
          labelType={ENUMS_PASSWORD_INPUT.types.TYPE_B}
          error={
            <ErrorMessage
              errors={errors}
              name="password"
              render={({ message }) => (
                <p className="h7 error-message">{message}</p>
              )}
            />
          }
        />
        <PrimaryButton
          onClick={handleSubmit(handleOnSubmit)}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_P}
          text={t("buttons.continue")}
          isLoading={isSignUpLoading}
        />
      </form>
    </div>
  );
};
ForgotPasswordNewPassword.propTypes = {
  formData: PropTypes.shape({
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    otpToken: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  selectedMethod: PropTypes.oneOf([
    SIGN_UP_METHOD.phoneNumber,
    SIGN_UP_METHOD.email,
  ]).isRequired,
};

export default ForgotPasswordNewPassword;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-cards";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import { createDOBucketName } from "utils/DO-Spaces";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { ROUTE_NAME } from "utils/constants/routes";

import "./AllBusinessesPoker.scss";

const AllBusinessesPoker = ({ allBusiness }) => {
  const navigate = useNavigate();
  return (
    <div className="AllBusinessesPokerWrapper">
      <div className="AllBusinessesPoker">
        <Swiper
          effect={"cards"}
          grabCursor={true}
          modules={[EffectCards]}
          className="mySwiper"
        >
          {allBusiness?.map((business, index) => (
            <SwiperSlide
              key={index}
              onClick={() =>
                navigate(
                  `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business.id}${ROUTE_NAME.menu}`
                )
              }
            >
              {/*<img*/}
              {/*  src="https://images.unsplash.com/photo-1726601057524-069373bb2fdf?q=80&w=1970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"*/}
              {/*  alt="SliderImage"*/}
              {/*/>*/}
              <ImageWithPlaceholder
                imageSource={createDOBucketName(business?.images?.logo)}
                placeholder={IMAGE_ITEM_PLACEHOLDER}
                alt="business-image"
                className="SliderImage"
              />
              <div className="SwiperSlideTitleWrapper">
                <h2 className="Bold SwiperSlideTitle">{business?.name}</h2>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
AllBusinessesPoker.propTypes = {
  allBusiness: PropTypes.array,
};

export default AllBusinessesPoker;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useWindowSize, { BREAKPOINT_NAMES } from "utils/hooks/useWindowSize";
import {
  NAVBAR_ROUTES,
  QUERY_PARAMS,
  ROUTE_NAME,
} from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import {
  initialState,
  logOut,
  setLanguage,
  setUserWithPin,
} from "redux/slices/userStore";
import { setIsAdminDashboardPinLocked } from "redux/slices/appStore";
import useCheckNetwork from "utils/hooks/useCheckNetwork";
import { ReactComponent as LockScreenIcon } from "assets/icons/admin-dashboard/LockScreen.svg";
import { ReactComponent as LogOutIcon } from "assets/icons/admin-dashboard/LogOut.svg";
import ICON_LEFT_ARROW from "assets/icons/arrows/AdminArrowLeft.svg";
import IMAGE_USER from "assets/images/user/User.png";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import useOutsideClick from "utils/hooks/useOutsideClick";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import useGetLoggedBusinessUser from "utils/hooks/useGetLoggedBusinessUser";
import { getBrandMainLogo } from "utils/branding-helper";
import { getUserRoutesByRole } from "utils/helpers";
import { adminRoles, ROLES } from "utils/constants/global";

import "./AdminSidebar.scss";

const MainLogo = getBrandMainLogo();

const AdminSidebar = ({
  isNavbarOpen,
  setIsNavbarOpen,
  onRouteClick,
  activeIndex,
  isMobile,
}) => {
  // const hasUnSavedChanges = useSelector(
  //   (state) => state[STORE_NAMES.app].hasUnSavedChanges
  // );
  const networkStatus = useCheckNetwork();
  let [searchParams] = useSearchParams();
  const [hasUnSavedChanges, setHasUnSavedChanges] = useState(
    Boolean(searchParams.get(QUERY_PARAMS.unsavedChanges))
  );
  const [
    openSlideConfirmUpdateBusiness,
    setOpenSlideConfirmUpdateBusiness,
    mainElementRefConfirmUpdateBusiness,
  ] = useOutsideClick();
  const [
    openSlideConfirmLogOut,
    setOpenSlideConfirmLogOut,
    mainElementRefConfirmLogOut,
  ] = useOutsideClick();

  useEffect(() => {
    const unSavedChanges = Boolean(
      searchParams.get(QUERY_PARAMS.unsavedChanges)
    );
    setHasUnSavedChanges(unSavedChanges);
  }, [searchParams]);

  const { t } = useTranslation();
  const { isScreenSize } = useWindowSize();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useGetLoggedBusinessUser();
  const userRole = user?.roles[0].name;
  const businessStore = useSelector((state) => state[STORE_NAMES.business]);
  const allLanguages = useSelector((state) => state[STORE_NAMES.app].languages);
  const selectedLanguage = useSelector(
    (state) => state[STORE_NAMES.user].userLanguage
  );
  const menuLanguage = useSelector(
    (state) => state[STORE_NAMES.menu].data?.language
  );

  const getUserNavbarRoutesByRole = (role) => {
    const userRouteInfo = getUserRoutesByRole(role);
    const isAdmin = adminRoles.includes(role);
    const businessExists = businessStore?.business?.id;
    const restrictedRoutes = userRouteInfo?.restrictedRoutes || [];
    return NAVBAR_ROUTES.reduce((acc, route) => {
      const isRouteAllowed = isAdmin
        ? businessExists || route.path === ROUTE_NAME.super
        : !restrictedRoutes.includes(route.path);
      if (isRouteAllowed) {
        const subRoutes =
          route?.subRoutes?.filter(
            (sub) => !restrictedRoutes.includes(sub.path)
          ) || [];
        acc.push({
          ...route,
          subRoutes: subRoutes.length > 0 ? subRoutes : route.subRoutes,
        });
      }
      return acc;
    }, []);
  };

  const languageOptions = allLanguages.map((language) => {
    return {
      ...language,
      name: language.code,
      imgSrc: language.imgSrc,
    };
  });
  const [navbarRoutesToDisplay, setNavbarRoutesToDisplay] = useState(
    getUserNavbarRoutesByRole(userRole)
  );

  const [clickedRoute, setClickedRoute] = useState(null);

  useEffect(() => {
    setNavbarRoutesToDisplay(getUserNavbarRoutesByRole(userRole));
  }, [businessStore?.business?.id]);

  const handleOnLanguageClick = async (language) => {
    if (
      !selectedLanguage ||
      (selectedLanguage && selectedLanguage.id !== language.id)
    ) {
      dispatch(setLanguage(language));
    }
  };

  const handleNavbarToggleClick = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const handleOnLockScreen = () => {
    dispatch(setUserWithPin(initialState.userWithPin));
    dispatch(setIsAdminDashboardPinLocked(true));
    navigate(`${ROUTE_NAME.admin}${ROUTE_NAME.lockScreen}`);
  };

  const handleOnRouteClick = (route, index) => {
    if (hasUnSavedChanges) {
      setClickedRoute({ route, index });
      return setOpenSlideConfirmUpdateBusiness(true);
    }
    onRouteClick(route, index);
  };

  const handleOnUpdateBusinessConfirm = () => {
    setHasUnSavedChanges(false);
    setOpenSlideConfirmUpdateBusiness(false);
    onRouteClick(clickedRoute.route, clickedRoute.index);
  };

  const handleOnLogOutConfirm = () => {
    setOpenSlideConfirmLogOut(false);
    dispatch(logOut());
    navigate(`${ROUTE_NAME.signIn}`);
  };
  const handleOnUpdateBusinessCancel = () => {
    setOpenSlideConfirmUpdateBusiness(false);
  };

  const handleOnLogOutCancel = () => {
    setOpenSlideConfirmLogOut(false);
  };

  const isLocationIncludesSubRoutes = (subRoutes) => {
    for (const subRoute of subRoutes) {
      if (location.pathname.includes(subRoute.path)) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    isMobile && setIsNavbarOpen(false);
    isScreenSize(BREAKPOINT_NAMES.desktop) &&
      !isMobile &&
      setIsNavbarOpen(true);
  }, [isMobile]);

  return (
    <div
      className={cx(
        "AdminSidebar",
        { isDesktopBarClosed: !isNavbarOpen && !isMobile },
        { isMobileBarClosed: !isNavbarOpen && isMobile },
        { isMobile: isMobile }
      )}
    >
      {!isMobile && (
        <div className="AdminSidebarToggle" onClick={handleNavbarToggleClick}>
          <img
            src={ICON_LEFT_ARROW}
            alt="toggle sidebar"
            className={cx("AdminSidebarToggleIcon", {
              isClosed: isNavbarOpen && !isMobile,
            })}
          />
        </div>
      )}
      <div className="AdminSidebarLogoLanguageContainer">
        <div className="AdminSidebarLogo">
          <MainLogo />
        </div>
        <div className="AdminSidebarLanguageDropdown">
          {allLanguages?.length > 0 && (selectedLanguage || menuLanguage) && (
            <Dropdown
              value={selectedLanguage ? selectedLanguage : menuLanguage}
              dropdownWithIcon
              hideDescription={true}
              options={languageOptions}
              onChange={(language) => {
                if (language) handleOnLanguageClick(language);
              }}
              showCodeColumnInTitle
            />
          )}
        </div>
      </div>

      <div className="AdminSidebarProfile">
        <div className="AdminSidebarProfileInfo">
          <div className="AdminSidebarProfileImageContainer">
            <img
              src={IMAGE_USER}
              alt="admin"
              className="AdminSidebarProfileImage"
            />
            <div
              className={cx("NetworkStatus", {
                isOnline: networkStatus,
              })}
            ></div>
            <div className="NetworkStatusText">
              <h6 className="Medium">
                {networkStatus
                  ? t("common.connectToWifi")
                  : t("common.disconnectFromWifi")}
              </h6>
            </div>
          </div>
          {user && (
            <h6 className="SemiBold AdminSidebarProfileUsername">
              {user.firstName + " " + user.lastName}
            </h6>
          )}
        </div>
        {/* <button className="AdminSidebarProfileDetails">
          <img src={ICON_THREE_DOTS} alt="details" />
        </button> */}
      </div>
      <div className="AdminSidebarRoutes">
        <div className="AdminSidebarRoutesNavigations">
          {navbarRoutesToDisplay.map((route, index) => {
            const newPathname = location.pathname.replace(/^\/a/, "");
            return (
              <div key={index}>
                <div
                  className={cx("AdminSidebarRoute", {
                    isActive:
                      newPathname === route.path ||
                      route?.subRoutes?.find(
                        (subRoute) => newPathname === subRoute.path
                      ),
                  })}
                  onClick={() => {
                    handleOnRouteClick(route, index);
                  }}
                >
                  <img
                    src={route.icon}
                    className="AdminSidebarRouteIcon"
                    alt={route.name}
                  />
                  <h5 className="Medium RouteName">{t(`${route.name}`)}</h5>
                </div>
                {route.subRoutes && (
                  <div
                    className={cx("AdminSidebarSubRoutes", {
                      isVisible:
                        isNavbarOpen &&
                        (activeIndex === index ||
                          isLocationIncludesSubRoutes(route.subRoutes)),
                    })}
                  >
                    {route.subRoutes.map((subRoute, i) => {
                      const newPathname = location.pathname.replace(/^\/a/, "");
                      return (
                        <h6
                          key={i}
                          className={cx("Medium AdminSidebarSubRoute", {
                            isActive: newPathname === subRoute.path,
                          })}
                          onClick={() => {
                            handleOnRouteClick(subRoute, index);
                          }}
                        >
                          {t(`${subRoute.name}`)}
                        </h6>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="AdminSidebarLockScreenLogOut">
          <div
            className="AdminSidebarRoute AdminSidebarLogOut"
            onClick={() => setOpenSlideConfirmLogOut(true)}
          >
            <LogOutIcon className="AdminSidebarExitIcon" />
            <h5 className="Medium LogoutButtonText">{t("buttons.logOut")}</h5>
          </div>

          {user?.roles[0].name !== "ADMIN" && (
            <div
              className="AdminSidebarRoute AdminSidebarLockScreen"
              onClick={handleOnLockScreen}
            >
              <LockScreenIcon className="AdminSidebarExitIcon" />
              <h5 className="Medium LockScreenButtonText">
                {t("buttons.lockScreen")}
              </h5>
            </div>
          )}
        </div>
      </div>
      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_B}
        title={t("modal.warningModalTitleUnsavedChanges")}
        description={t("modal.warningModalDescription")}
        mainElementRefConfirm={mainElementRefConfirmUpdateBusiness}
        openSlide={openSlideConfirmUpdateBusiness}
        onCancel={handleOnUpdateBusinessCancel}
        onConfirm={handleOnUpdateBusinessConfirm}
      />
      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_A}
        title={t("modal.warningModalTitleLogout")}
        mainElementRefConfirm={mainElementRefConfirmLogOut}
        openSlide={openSlideConfirmLogOut}
        onCancel={handleOnLogOutCancel}
        onConfirm={handleOnLogOutConfirm}
      />
    </div>
  );
};

AdminSidebar.propTypes = {
  /**
   * Check if navbar is open
   */
  isNavbarOpen: PropTypes.bool.isRequired,

  /**
   * Indicates whether it is a mobile device.
   */
  isMobile: PropTypes.bool,

  /**
   * The function for toggle navbar
   */
  setIsNavbarOpen: PropTypes.func.isRequired,

  /**
   * The function called when route clicked
   */
  onRouteClick: PropTypes.func.isRequired,

  /**
   * Active route index
   */
  activeIndex: PropTypes.any,
};

export default AdminSidebar;

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import { ReactComponent as ArrowRight } from "assets/icons/arrows/ArrowRight.svg";
import { RotatingLines } from "react-loader-spinner";

import "./CTAButton.scss";

export const ENUMS = {
  name: "CTAButton",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_C: "TYPE_C",
    TYPE_D: "TYPE_D",
    TYPE_E: "TYPE_E",
    TYPE_F: "TYPE_F",
    TYPE_G: "TYPE_G",
    TYPE_H: "TYPE_H",
    TYPE_L: "TYPE_L",
    TYPE_M: "TYPE_M",
    TYPE_N: "TYPE_N",
    TYPE_R: "TYPE_R",
    TYPE_Z: "TYPE_Z",
  },
};

const CTAButton = ({
  name,
  price,
  type,
  onClick,
  className,
  disabled,
  icon,
  element,
  isLoading,
}) => {
  const hasPrice = price !== undefined && price !== null;
  return (
    <button
      onClick={onClick}
      className={cx(
        "CTAButton",
        { typeA: type === ENUMS.types.TYPE_A },
        { typeB: type === ENUMS.types.TYPE_B },
        { typeC: type === ENUMS.types.TYPE_C },
        { typeD: type === ENUMS.types.TYPE_D },
        { typeE: type === ENUMS.types.TYPE_E },
        { typeF: type === ENUMS.types.TYPE_F },
        { typeG: type === ENUMS.types.TYPE_G },
        { typeH: type === ENUMS.types.TYPE_H },
        { typeL: type === ENUMS.types.TYPE_L },
        { typeM: type === ENUMS.types.TYPE_M },
        { typeN: type === ENUMS.types.TYPE_N },
        { typeZ: type === ENUMS.types.TYPE_Z },
        { typeR: type === ENUMS.types.TYPE_R },
        { isLoading: isLoading },
        className
      )}
      disabled={disabled}
      type="button"
    >
      {isLoading ? (
        <RotatingLines
          strokeColor="#000"
          height={type === ENUMS.types.TYPE_E ? 20 : 30}
          width={
            type === ENUMS.types.TYPE_E || type === ENUMS.types.TYPE_D ? 20 : 30
          }
          strokeWidth="4"
        />
      ) : (
        <>
          {icon && (
            <div
              className={cx("CTAButtonIcon", {
                IconWrapper:
                  type === ENUMS.types.TYPE_G || type === ENUMS.types.TYPE_L,
              })}
            >
              {icon}
            </div>
          )}
          {type !== ENUMS.types.TYPE_F &&
            type !== ENUMS.types.TYPE_G &&
            type !== ENUMS.types.TYPE_L &&
            type !== ENUMS.types.TYPE_M &&
            type !== ENUMS.types.TYPE_N &&
            type !== ENUMS.types.TYPE_Z && (
              <h4
                className={cx("CTAButtonName SemiBold", {
                  h7: type === ENUMS.types.TYPE_C,
                })}
              >
                {name}
              </h4>
            )}

          {element}

          {(type === ENUMS.types.TYPE_F || type === ENUMS.types.TYPE_G) && (
            <h6 className={cx("CTAButtonName SemiBold")}>{name}</h6>
          )}

          {type === ENUMS.types.TYPE_L && (
            <h6 className={cx("CTAButtonName Medium")}>{name}</h6>
          )}
          {type === ENUMS.types.TYPE_N && hasPrice && (
            <h6 className={cx("CTAButtonName SemiBold")}>{name}</h6>
          )}
          {type === ENUMS.types.TYPE_N && !hasPrice && (
            <h6 className={cx("CTAButtonName SemiBold")}>{name}</h6>
          )}
          {type !== ENUMS.types.TYPE_N && hasPrice && (
            <Price type={ENUMS_PRICE.types.PRICE_L} value={price} />
          )}
          {type === ENUMS.types.TYPE_N && hasPrice && (
            <Price type={ENUMS_PRICE.types.PRICE_S} value={price} />
          )}
          {(type === ENUMS.types.TYPE_E || type === ENUMS.types.TYPE_G) && (
            <div className="ArrowIconContainer">
              <ArrowRight />
            </div>
          )}
        </>
      )}
    </button>
  );
};

CTAButton.propTypes = {
  /**
   * The name of the component
   */
  name: PropTypes.string,

  /**
   * The price
   */
  price: PropTypes.number,

  /**
   * The type of the component
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)).isRequired,

  /**
   * The function called when component clicked
   */
  onClick: PropTypes.func.isRequired,
  /**
   * Additional class name(s) for styling the CTA button.
   */
  className: PropTypes.string,
  /**
   * Indicates whether the CTA button is disabled or not.
   */
  disabled: PropTypes.bool,

  /**
   * Icon as component
   */
  icon: PropTypes.node,

  element: PropTypes.element,
  /**
   * Loading state for the button
   */
  isLoading: PropTypes.bool,
};

export default CTAButton;

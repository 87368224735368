import { useEffect, useRef, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import _ from "lodash";

import { STORE_NAMES } from "utils/constants/redux";
import { ROUTE_NAME } from "utils/constants/routes";
import useTimeout from "utils/hooks/useTimeout";
import CallWaiterAudio from "assets/audios/CallWaiter.mp3";
import AskForBillAudio from "assets/audios/askForBill.mp3";
import NewOrderAudio from "assets/audios/CallWaiter.mp3";
import newChatMessageAudio from "assets/audios/newMessage.mp3";

export const NOTIFICATION_SOUND_OPTIONS = {
  hasNewOrder: NewOrderAudio,
  actionBill: AskForBillAudio,
  actionWaiter: CallWaiterAudio,
  chatMessage: newChatMessageAudio,
};

export const activeRoutes = [ROUTE_NAME.adminOrderDashboard];

const findLastOrderItemId = (orders) => {
  return (
    orders
      .flatMap((order) => order?.guests)
      .flatMap((guest) => guest.orderItems)
      .filter((orderItem) => orderItem?.isConfirmed === null)
      .flatMap((orderItem) => orderItem.id)
      .sort((a, b) => b - a)[0] || 0
  );
};
const useOrderNotificationSound = () => {
  const { pathname } = useLocation();
  const isActiveRoute = activeRoutes
    .map((route) => ROUTE_NAME.admin + route)
    .includes(pathname);
  const { orders } = useSelector((state) => state[STORE_NAMES.orders]);
  const user = useSelector((state) => state[STORE_NAMES.user].user);
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const chatLastMessage = useSelector(
    (state) => state[STORE_NAMES.chat].lastMessage
  );

  const askForBillSound = business?.askForBillSound || false;
  const callWaiterSound = business?.callWaiterSound || false;
  const orderSound = business?.orderSound || false;
  const chatSound = business?.chatSound || false;

  const getActionDataFromOrders = useCallback(
    (orders) =>
      orders
        .map((order) => {
          if (callWaiterSound || askForBillSound) {
            return {
              orderId: order.id,
              actions: {
                ...(callWaiterSound
                  ? { actionWaiter: order.actionWaiter }
                  : {}),
                ...(askForBillSound ? { actionBill: order.actionBill } : {}),
              },
            };
          }
          return null;
        })
        .filter(Boolean),
    [askForBillSound, callWaiterSound]
  );

  const findDifference = useCallback(
    (prevData, newData) => {
      if (prevData.lastMessage?.id !== newData.lastMessage?.id) {
        const isThisSomeoneElseMessage = !(
          newData?.lastMessage?.message?.author?.role?.name ===
            user?.roles?.[0]?.name &&
          newData?.lastMessage?.message?.author?.id === user?.id
        );
        if (isThisSomeoneElseMessage && chatSound) {
          return { action: "chatMessage" };
        }
      }
      if (newData.lastOrderItemId > prevData.lastOrderItemId && orderSound) {
        return { action: "hasNewOrder" };
      }

      let change = null;

      const prevMap = _.keyBy(prevData.actions, "orderId");
      const newMap = _.keyBy(newData.actions, "orderId");

      _.forOwn(newMap, (newOrder) => {
        const prevOrder = prevMap[newOrder.orderId];

        if (prevOrder && prevOrder.actions) {
          _.forOwn(newOrder.actions, (newActionValue, actionKey) => {
            const prevActionValue = prevOrder.actions[actionKey];

            if (!prevActionValue || prevActionValue !== newActionValue) {
              if (newActionValue === true) {
                change = {
                  action: actionKey,
                };
              }
            }
          });
        } else {
          _.forOwn(newOrder.actions, (newActionValue, actionKey) => {
            if (newActionValue === true) {
              change = {
                action: actionKey,
              };
            }
          });
        }
      });

      return change;
    },
    [orderSound, chatSound]
  );

  const prevActionsRef = useRef({
    actions: getActionDataFromOrders(orders),
    lastOrderItemId: findLastOrderItemId(orders),
    lastMessage: chatLastMessage,
  });

  const [notification, setNotification] = useState(null);

  useEffect(() => {
    const prevActions = prevActionsRef.current;
    const newActions = {
      actions: getActionDataFromOrders(orders),
      lastOrderItemId: findLastOrderItemId(orders),
      lastMessage: chatLastMessage,
    };

    const newNotification = findDifference(prevActions, newActions);

    if (newNotification) {
      setNotification(newNotification);
    }

    prevActionsRef.current = newActions;
  }, [orders, chatLastMessage]);

  useTimeout({
    callback: () => setNotification(null),
    delay: notification ? 1500 : null,
  });

  useEffect(() => {
    if (notification && isActiveRoute) {
      const audioSrc = NOTIFICATION_SOUND_OPTIONS[notification.action];
      if (audioSrc) {
        const audio = new Audio(audioSrc);
        audio.play();
      }
    }
  }, [notification, isActiveRoute]);
};

export default useOrderNotificationSound;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { registerGuestAsync } from "redux/actions/guestAction";
import cx from "classnames";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { useValidationSchema } from "utils/hooks/useValidationSchema";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import { STORE_NAMES } from "utils/constants/redux";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import useAsync from "utils/hooks/useAsync";
import { createOtpRequest } from "utils/api/services/otp";
import { SIGN_IN_METHOD as SIGN_UP_METHOD } from "pages/common/login/sign-in/SignIn";
import OtpInput from "components/forms/input/input-otp/OtpInput";
import { GUEST_SIGN_UP_STEP_TYPES } from "pages/client/auth/ClientAuthHelper";
import useTransferOrderSourceToTarget from "utils/hooks/useTransferOrderSourceToTarget";
import { replaceGuestId } from "redux/slices/basketStore";
import { verifyGuestOtpCode } from "utils/api/services/guest";

import "./SignUpVerifyOtp.scss";

const SignUpVerifyOtp = ({
  counter,
  setCounter,
  setFormData,
  formData,
  selectedMethod,
  setActiveStep,
}) => {
  const dispatch = useDispatch();
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const { t } = useTranslation();
  const { guestSignUpOtp } = useValidationSchema(t);
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();
  const [otpSending, setOtpSending] = useState(false);
  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
    setValue,
    trigger,
  } = useForm({
    resolver: zodResolver(guestSignUpOtp),
    criteriaMode: "all",
  });
  const handleOtpChange = (value) => {
    setFormData((prev) => {
      return {
        ...prev,
        otpCode: value,
      };
    });
    setValue("otpCode", value);
    if (errors.otpCode) {
      trigger("otpCode");
    }
  };

  const { execute: executeCreateOtpRequest } = useAsync(createOtpRequest, {
    onError: (error) => {
      const errorData = handleAPIErrorMessage(error.response.data);
      if (errorData) {
        const { field, errorMessage } = errorData;
        setError(field, {
          type: "manual",
          message: errorMessage,
        });
      }
    },
    onSuccess: ({ data }) => {
      setFormData((prev) => ({
        ...prev,
        otpToken: data.otpToken,
        otpSendCount: data.otpSendCount,
      }));
      setCounter(60);
    },
  });

  const { execute: executeVerifyOtp } = useAsync(verifyGuestOtpCode, {
    onError: (error) => {
      const errorData = handleAPIErrorMessage(error.response.data);
      if (errorData) {
        const { field, errorMessage } = errorData;
        setError(field, {
          type: "manual",
          message: errorMessage,
        });
      }
    },
    onSuccess: ({ data }) => {
      setFormData((prev) => ({
        ...prev,
        otpToken: data.otpToken,
      }));
    },
  });

  const { transferOrderSourceToTarget } = useTransferOrderSourceToTarget();

  const handleResendCode = () => {
    let otpBody;
    if (selectedMethod === SIGN_UP_METHOD.email) {
      otpBody = { email: formData.email };
    } else if (selectedMethod === SIGN_UP_METHOD.phoneNumber) {
      otpBody = { phoneNumber: formData.phoneNumber.replace(/\+/g, "") };
    }
    executeCreateOtpRequest(otpBody);
  };

  const handleOnSubmit = async () => {
    setOtpSending(true);
    const anonymousGuestId = guestId;
    await executeVerifyOtp({
      otpCode: formData.otpCode,
      otpToken: formData.otpToken,
    });
    const response = await dispatch(
      registerGuestAsync({
        guestId,
        otpToken: formData.otpToken,
        guestBody: {
          firstName: formData.firstName,
          lastName: formData?.lastName,
          password: formData.password,
        },
      })
    );
    setOtpSending(false);
    if (response.error) {
      const errorData = handleAPIErrorMessage(response.payload);
      if (errorData) {
        const { field, errorMessage } = errorData;
        return setError(field, {
          type: "manual",
          message: errorMessage,
        });
      }
    } else {
      await dispatch(
        replaceGuestId({
          oldGuestId: anonymousGuestId,
          newGuestId: response.payload.guest.id,
        })
      );
      if (anonymousGuestId !== response.payload.guest.id) {
        await transferOrderSourceToTarget({
          sourceId: anonymousGuestId,
          targetId: response.payload.guest.id,
        });
      }

      setActiveStep(GUEST_SIGN_UP_STEP_TYPES.detailForm);
      setCounter(0);
    }
  };

  const otpSendingAvailable = counter === 0;

  useEffect(() => {
    if (formData.otpCode && formData.otpCode.length === 6) {
      handleSubmit(handleOnSubmit)();
    }
  }, [formData.otpCode]);

  return (
    <div className="SignUpVerifyOtp">
      <div className="SignUpVerifyOtpHeader">
        <h1 className="SemiBold title">{t("auth.verifyOtp")}</h1>
        <div className="SignUpVerifyOtpHeaderPhone">
          <h6 className="Medium">{`${t("auth.otpSentTo")}`} </h6>{" "}
          <h6 className="Bold">
            {selectedMethod === "email" ? formData.email : formData.phoneNumber}
          </h6>
        </div>
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <OtpInput
          name={"otpCode"}
          value={formData.otpCode}
          hasError={!!errors.otpCode}
          onOtpChange={handleOtpChange}
          func={{
            ...register("otpCode"),
          }}
          error={
            <ErrorMessage
              errors={errors}
              name="otpCode"
              render={({ message }) => (
                <p className="h7 error-message">{t(message)}</p>
              )}
            />
          }
        />
        <div className="SignUpVerifyOtpResend">
          <h6
            className={cx("Medium", {
              isDisabledText: formData.otpSendCount === 0,
            })}
          >
            {formData.otpSendCount === 0
              ? t("errorMessages.input.pinCode.TryAgainLater")
              : otpSendingAvailable
              ? t("auth.resendingIsAvailable")
              : t("auth.resendingCodeWillBeAvailableIn") +
                ` ${Math.floor(counter / 60)}:${(counter % 60)
                  .toString()
                  .padStart(2, "0")}`}
          </h6>
          <button
            className={cx("SignUpVerifyOtpResendButton", {
              isDisabled: !otpSendingAvailable || formData.otpSendCount === 0,
            })}
            disabled={!otpSendingAvailable || formData.otpSendCount === 0}
            onClick={handleResendCode}
          >
            {t("buttons.resend")}
          </button>
        </div>

        <PrimaryButton
          onClick={handleSubmit(handleOnSubmit)}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_P}
          text={t("buttons.continue")}
          isLoading={otpSending}
          className="SignUpFooterButton"
        />
      </form>
    </div>
  );
};
SignUpVerifyOtp.propTypes = {
  counter: PropTypes.number.isRequired,
  setCounter: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    otpCode: PropTypes.string,
    otpToken: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  selectedMethod: PropTypes.oneOf(["email", "phoneNumber"]).isRequired,
  setActiveStep: PropTypes.func.isRequired,
};

export default SignUpVerifyOtp;

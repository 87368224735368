import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { STORE_NAMES } from "utils/constants/redux";
import { getInventoryCategoriesAsync } from "redux/actions/inventoryActions";

const useInventoryUpdater = () => {
  const dispatch = useDispatch();
  const businessId = useSelector(
      (state) => state[STORE_NAMES.business]?.business?.id
  );
  useEffect(() => {
    businessId && dispatch(getInventoryCategoriesAsync(businessId));
  }, [businessId]);
};

export default useInventoryUpdater;

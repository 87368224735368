import React from "react";
import PropTypes from "prop-types";

import Tag, { ENUMS as ENUMS_TAG } from "components/elements/tag/Tag";
import FavoritesIconButton, {
  ENUMS as ENUMS_FAVORITES_ICON_BUTTON,
} from "components/buttons/favorites-icon-button/FavoritesIconButton";
import AddIconButton, {
  ENUMS as ENUMS_ADD_ICON_BUTTON,
} from "components/buttons/add-icon-button/AddIconButton";
import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import {
  calculateItemPriceWithDefaultModificationPrice,
  calculateMenuItemPriceBySchedule,
} from "utils/general";
import useLanguage from "utils/hooks/useLanguage";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import useTags from "utils/hooks/useTags";
import useTagsWIcon from "utils/hooks/useTagsWithIcon";
import TagWithIcon, {
  ENUMS as TAG_ENUMS_WITH_ICON,
} from "components/elements/tag-with-icon/TagWithIcon";
import LazyImage from "components/lazy-image/LazyImage";
import useFlyBasketAnimation from "utils/hooks/useFlyBasketAnimation";

import "./MenuViewScroll.scss";

export const ENUMS = {
  name: "MenuViewScroll",
};

const MenuViewScroll = ({
  menuItem,
  onAdd,
  onFavorite,
  onClick,
  favoriteItems,
  orderItems,
}) => {
  const { menuItemImageRef, flyAnimationHandler } = useFlyBasketAnimation();
  const { displayDataByLanguage } = useLanguage();
  const { allTags } = useTags(menuItem, true);
  const { allTagsWithIcon } = useTagsWIcon(
    menuItem,
    displayDataByLanguage,
    true
  );

  const discountPrice = calculateMenuItemPriceBySchedule(menuItem);
  const menuItemPrice =
    calculateItemPriceWithDefaultModificationPrice(menuItem);

  const menuItemName = displayDataByLanguage(menuItem.name);
  const addBasketHandler = () => {
    onAdd(menuItem);
    flyAnimationHandler();
  };

  return (
    <div onClick={() => onClick(menuItem.id)} className="MenuViewScroll">
      <div className="MenuViewScrollTop">
        <LazyImage
          imgRef={menuItemImageRef}
          className="MenuViewScrollImg"
          src={menuItem?.coverImageSrc}
          placeholder={IMAGE_ITEM_PLACEHOLDER}
          alt={menuItemName}
        />
        <div className="MenuViewScrollFavoritesButton">
          <FavoritesIconButton
            id={menuItem.id}
            type={ENUMS_FAVORITES_ICON_BUTTON.types.TYPE_A}
            onClick={() => onFavorite(menuItem.id)}
            value={favoriteItems.includes(menuItem.id)}
          />
        </div>
      </div>
      <div className="MenuViewScrollMiddle">
        <h5 className="MenuViewScrollName Medium">{menuItemName}</h5>
      </div>
      <div className="MenuViewScrollBottom">
        <div className="MenuViewScrollPriceAndTags">
          <div className="MenuViewScrollPrice">
            <Price
              type={ENUMS_PRICE.types.PRICE_M_BOLD}
              value={
                discountPrice !== null
                  ? menuItemPrice + discountPrice
                  : menuItemPrice
              }
            />
            {discountPrice !== null && (
              <Price
                type={ENUMS_PRICE.types.PRICE_LINE_THROUGH}
                value={menuItemPrice}
              />
            )}
          </div>
          {allTags.length > 0 && (
            <div className="MenuViewScrollTags">
              <Tag items={allTags} type={ENUMS_TAG.types.TYPE_A} />
            </div>
          )}
        </div>
        <div className="MenuViewScrollTagsAndAdd">
          {allTagsWithIcon.length > 0 && (
            <div className="MenuViewScrollTagsWithIcon">
              <TagWithIcon
                type={TAG_ENUMS_WITH_ICON.types.TYPE_A}
                items={allTagsWithIcon}
              />
            </div>
          )}
          <div className="MenuViewScrollAddButton">
            <AddIconButton
              id={menuItem.id}
              onClick={addBasketHandler}
              type={ENUMS_ADD_ICON_BUTTON.types.TYPE_A}
              value={orderItems.reduce(
                (sum, orderItem) =>
                  sum +
                  (orderItem.item.id === menuItem.id ? orderItem.count : 0),
                0
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

MenuViewScroll.propTypes = {
  /**
   * Menu item
   */
  menuItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.array.isRequired,
    priceSell: PropTypes.number.isRequired,
    discountRate: PropTypes.number,
    discountPrice: PropTypes.string,
    coverImageSrc: PropTypes.string,
    tags: PropTypes.array,
    rate: PropTypes.object.isRequired,
    isFixed: PropTypes.bool,
  }).isRequired,

  /**
   * Add action
   */
  onAdd: PropTypes.func.isRequired,

  /**
   * Favorite action
   */
  onFavorite: PropTypes.func.isRequired,

  /**
   * Component click action
   */
  onClick: PropTypes.func.isRequired,

  /**
   * An array of favorite items.
   */
  favoriteItems: PropTypes.array,

  /**
   * An array of order items.
   */
  orderItems: PropTypes.array,
};

export default MenuViewScroll;

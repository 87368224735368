import React from "react";

const FunZoneLeaderBoard = () => {
  return (
    <div>
      <h3>LeaderBoard</h3>
    </div>
  );
};

export default FunZoneLeaderBoard;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { ErrorMessage } from "@hookform/error-message";
import { zodResolver } from "@hookform/resolvers/zod";

import IconButton from "components/buttons/icon-button/IconButton";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import Modal from "components/modal/Modal";
import DeleteButton from "components/buttons/delete-button/DeleteButton";
import QR from "components/admin/cards/QR/QR";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import { QR_TYPES } from "utils/constants/data/base";
import { STORE_NAMES } from "utils/constants/redux";
import { createDOBucketName } from "utils/DO-Spaces";
import isEqual from "lodash/isEqual";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useFormOutsideClickHandler from "utils/hooks/useFormOutsideClickHandler";

import "./AdminQRModal.scss";

export const ENUMS = {
  name: "AddItem",
};

const AdminQRModal = ({
  mainElementRef,
  openSlide,
  setOpenSlide,
  formData,
  setFormData,
  formDataInitial,
  setFormDataInitial,
  selectedQR,
  onSave,
  onDelete,
  setOutsideClickAction,
  isLoading,
  title,
}) => {
  const isEditable = !!selectedQR;
  const { t } = useTranslation();

  const [
    openSlideConfirmCloseModal,
    setOpenSlideConfirmCloseModal,
    mainElementRefConfirmCloseModal,
  ] = useOutsideClick();

  const schemas = useValidationSchema(t);
  const qrModalSchema = schemas.qrModalSchema(formData, QR_TYPES);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm({
    resolver: zodResolver(qrModalSchema),
    criteriaMode: "all",
  });

  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);

  useEffect(() => {
    setFormData({
      name: selectedQR?.name || ``,
      table: selectedQR?.table || null,
      type: QrTypes.find((QrType) => QrType.value === selectedQR?.type) || null,
      wiFiName: selectedQR?.value?.ssid,
      wiFiPassword: selectedQR?.value?.password,
    });
    setFormDataInitial({
      name: selectedQR?.name || ``,
      table: selectedQR?.table || null,
      type: QrTypes.find((QrType) => QrType.value === selectedQR?.type) || null,
      wiFiName: selectedQR?.value?.ssid,
      wiFiPassword: selectedQR?.value?.password,
    });

    reset();
  }, [selectedQR, openSlide]);

  useFormOutsideClickHandler({
    formData,
    formDataInitial,
    setOpenSlide,
    setOpenSlideConfirmCloseModal,
    setOutsideClickAction,
  });

  const selectedQRWithImage = {
    ...selectedQR,
    image: createDOBucketName(selectedQR?.image),
  };
  const dropdownOptions = zones?.map((zone) => ({
    name: zone.name,
    subOptions: zone.tables,
  }));
  const QrTypes = [
    {
      id: 1,
      name: t("qr.types.menu"),
      value: QR_TYPES.URL,
    },
    {
      id: 2,
      name: t("qr.types.wifi"),
      value: QR_TYPES.WiFi,
    },
  ];
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDropdownChange = (option, name) => {
    setFormData({
      ...formData,
      [name]: option,
    });
  };

  const handleOnSubmit = () => {
    if (isEditable) {
      onSave(formData, selectedQR.id);
    } else {
      onSave(formData);
    }
  };

  const handleOnUnlinkQr = () => {
    setFormData({
      ...formData,
      table: null,
    });
  };

  const handleOnCancelCloseModal = () => {
    setOpenSlideConfirmCloseModal(false);
  };
  const associatedZone = zones.find((zone) =>
    zone.tables.some((table) => table.id === formData?.table?.id)
  );
  const handleOnConfirmCloseModal = () => {
    setOpenSlide(false);
    setOpenSlideConfirmCloseModal(false);
  };
  const AdminQRModalHeader = (
    <div className="AdminQRModalTitle">
      <div className="AdminQRModalTitleAndId">
        <h3 className="SemiBold">
          {isEditable
            ? t("buttons.editForModal", { title: title })
            : t("buttons.addForModal", { title: title })}
        </h3>
        {selectedQR && (
          <h6 className="Medium h7">
            {t("modal.id")}:{selectedQR?.id}
          </h6>
        )}
      </div>

      <div className="AdminQRModalTitleRight">
        {isEditable && (
          <DeleteButton
            onClick={() => {
              onDelete(selectedQR.id);
            }}
            setOpenSlide={setOpenSlide}
          />
        )}
        <IconButton
          onClick={() => {
            if (!isEqual(formData, formDataInitial)) {
              return setOpenSlideConfirmCloseModal(true);
            }
            setOpenSlide(false);
          }}
          svgComponent={<IconClose />}
        />
      </div>
    </div>
  );

  const AdminQRModalBody = (
    <div className="AdminQRModalBody">
      <form className="AdminQRModalBodyForm">
        <InputControl
          name="name"
          required
          type="text"
          placeholder={t("inputs.qrName")}
          value={formData?.name}
          hasError={errors.name}
          labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
          func={{
            ...register("name", {
              onChange: handleInputChange,
            }),
          }}
          error={
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => (
                <p className="h7 error-message">{message}</p>
              )}
            />
          }
        />
        <Controller
          name="type"
          control={control}
          defaultValue={formData?.type || {}}
          render={({ field: { onChange, value } }) => (
            <Dropdown
              onChange={(type) => {
                onChange(type);
                handleDropdownChange(type, "type");
              }}
              isOptionRequired
              required
              name="type"
              value={value}
              options={QrTypes}
              className="AdminQRModalBodyFormDropdown"
              placeholder={t("qr.types.qrType")}
              error={
                <ErrorMessage
                  errors={errors}
                  name="type"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
              hasError={errors.type}
            />
          )}
        />
        {formData?.type?.value === QR_TYPES.URL && (
          <Dropdown
            onChange={(table) => {
              handleDropdownChange(table, "table");
            }}
            placeholder={t("table.table")}
            value={formData?.table}
            options={dropdownOptions}
            className="AdminQRModalBodyFormDropdown"
            isGrouped
          />
        )}
        {formData?.type?.value === QR_TYPES.WiFi && (
          <>
            <InputControl
              name="wiFiName"
              required
              type="text"
              placeholder={t("inputs.wifiName")}
              value={formData?.wiFiName}
              hasError={errors.wiFiName}
              labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
              func={{
                ...register("wiFiName", {
                  onChange: handleInputChange,
                }),
              }}
              error={
                <ErrorMessage
                  errors={errors}
                  name="wiFiName"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
            />
            <InputControl
              name="wiFiPassword"
              required
              type="password"
              placeholder={t("inputs.password")}
              value={formData?.wiFiPassword}
              hasError={errors.wiFiPassword}
              labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
              func={{
                ...register("wiFiPassword", {
                  onChange: handleInputChange,
                }),
              }}
              error={
                <ErrorMessage
                  errors={errors}
                  name="wiFiPassword"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
            />
          </>
        )}
      </form>
      {isEditable && (
        <QR
          zone={associatedZone}
          onUnlinkQr={handleOnUnlinkQr}
          QR={selectedQRWithImage}
          table={formData?.table}
        />
      )}
      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_C}
        title={t("modal.warningModalTitleUnsavedChanges")}
        mainElementRefConfirm={mainElementRefConfirmCloseModal}
        onCancel={(e) => handleOnCancelCloseModal(e)}
        onConfirm={(e) => handleOnConfirmCloseModal(e)}
        openSlide={openSlideConfirmCloseModal}
        description={t("modal.warningModalDescription")}
      />
    </div>
  );

  const AdminQRModalFooter = (
    <PrimaryButton
      onClick={handleSubmit(handleOnSubmit)}
      text={t("buttons.save")}
      isLoading={isLoading}
    />
  );

  return (
    <Modal
      header={AdminQRModalHeader}
      body={AdminQRModalBody}
      footer={AdminQRModalFooter}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    />
  );
};

AdminQRModal.propTypes = {
  /**
   * The ref for the main element of the modal
   */
  mainElementRef: PropTypes.object,

  /**
   * Flag to determine if the modal is open
   */
  openSlide: PropTypes.bool,

  /**
   * The function called to set the open slide state
   */
  setOpenSlide: PropTypes.func,

  /**
   * The form data for the QR code
   */
  formData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.object,
    table: PropTypes.object,
    wiFiName: PropTypes.string,
    wiFiPassword: PropTypes.string,
  }),

  /**
   * The initial form data for the QR code
   */
  formDataInitial: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.object,
    table: PropTypes.object,
    wiFiName: PropTypes.string,
    wiFiPassword: PropTypes.string,
  }),

  /**
   * The currently selected QR code
   */
  selectedQR: PropTypes.object,

  /**
   * The function called to update the form data
   */
  setFormData: PropTypes.func.isRequired,

  /**
   * The function called to update the initial form data
   */
  setFormDataInitial: PropTypes.func.isRequired,

  /**
   * The function called when the form is submitted to save the data.
   */
  onSave: PropTypes.func,

  /**
   * The function called to delete the item.
   */
  onDelete: PropTypes.func,
  /**
   * The title to display
   */
  title: PropTypes.string.isRequired,
  /**
   * The function called to set the outside click action
   */
  setOutsideClickAction: PropTypes.func,

  /**
   * Flag to determine if the form is loading
   * */
  isLoading: PropTypes.bool,
};

export default AdminQRModal;

import React from "react";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { PhoneInput } from "react-international-phone";
import cx from "classnames";
import { ErrorMessage } from "@hookform/error-message";

import {
  ENUMS as ENUMS_EMAIL_OR_PHONE,
  ENUMS,
} from "components/elements/email-or-phone/EmailOrPhone";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import {
  SIGN_IN_METHOD,
  SIGN_IN_METHOD as SIGN_UP_METHOD,
} from "pages/common/login/sign-in/SignIn";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import useAsync from "utils/hooks/useAsync";
import { createOtpRequestForGuestReset } from "utils/api/services/otp";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import EmailInput, {
  ENUMS as ENUMS_EMAIL_INPUT,
} from "components/forms/input/input-email/EmailInput";
import SwitchOptions from "components/elements/switch-options/SwitchOptions";
import { GUEST_FORGET_PASSWORD_STEP_TYPES } from "pages/client/auth/ClientAuthHelper";
import PropTypes from "prop-types";

import "./ForgotPasswordMainForm.scss";

const SWITCH_OPTIONS_FOR_FORGOT_PASSWORD = [
  {
    name: "phoneNumber",
    label: "inputs.phoneNumber",
    value: SIGN_UP_METHOD.phoneNumber,
  },
  {
    name: "email",
    label: "inputs.email",
    value: SIGN_UP_METHOD.email,
  },
];

const ForgotPasswordMainForm = ({
  formData,
  setFormData,
  setActiveStep,
  selectedMethod,
  setSelectedMethod,
  setCounter,
}) => {
  const { t } = useTranslation();
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();

  const schemas = useValidationSchema(t);
  const { emailOrPhoneSchema } = schemas;

  const methods = useForm({
    resolver: zodResolver(emailOrPhoneSchema(selectedMethod)),
    criteriaMode: "all",
    defaultValues: {
      phoneNumber: formData?.phoneNumber,
      email: formData?.email,
    },
  });

  const {
    formState: { errors },
    register,
    control,
    handleSubmit,
    setError,
  } = methods;
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePhoneInputChange = (phone, meta, onChange) => {
    const countryDialCode = meta.country.dialCode;
    if (phone && phone !== ENUMS_EMAIL_OR_PHONE.plusSign + countryDialCode) {
      setFormData((prev) => {
        return {
          ...prev,
          phoneNumber: phone,
        };
      });
    }

    onChange(phone);
  };
  const { execute: executeCreateOtpRequestForReset, loading: isSignUpLoading } =
    useAsync(createOtpRequestForGuestReset, {
      onError: (error) => {
        const errorData = handleAPIErrorMessage(error.response.data);
        if (errorData) {
          const { field, errorMessage } = errorData;
          setError(field, {
            type: "manual",
            message: errorMessage,
          });
        }
      },
      onSuccess: ({ data }) => {
        setFormData((prev) => ({
          ...prev,
          otpToken: data.otpToken,
          otpSendCount: data.otpSendCount,
        }));
        setCounter(60);
        setActiveStep(GUEST_FORGET_PASSWORD_STEP_TYPES.verifyOtp);
      },
    });

  const handleOnSubmit = () => {
    let otpBody;
    if (selectedMethod === SIGN_UP_METHOD.email) {
      otpBody = { email: formData.email };
    } else if (selectedMethod === SIGN_UP_METHOD.phoneNumber) {
      otpBody = { phoneNumber: formData.phoneNumber.replace(/\+/g, "") };
    }
    executeCreateOtpRequestForReset(otpBody);
  };

  const handleKeyDown = ({ target, key }) => {
    if (key === "Enter") {
      switch (target.name) {
        case "phoneNumber":
        case "email":
          handleSubmit(handleOnSubmit)();
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="ForgetPasswordMainForm">
      <div className="title">
        <h1 className="SemiBold title">{t("auth.forgotPassword")}</h1>
        <h6>
          {t("auth.forgotPasswordDesc").replace(
            "{{method}}",
            t(`auth.${selectedMethod}`)
          )}
        </h6>
      </div>

      <form
        className="ForgetPasswordCreateAnAccountForm"
        onSubmit={(e) => e.preventDefault()}
      >
        <SwitchOptions
          className={"SignUpPhoneOrEmailSwitch"}
          options={SWITCH_OPTIONS_FOR_FORGOT_PASSWORD}
          selectedMethod={selectedMethod}
          setSelectedMethod={setSelectedMethod}
        />
        {selectedMethod === SIGN_UP_METHOD.phoneNumber ? (
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <PhoneInput
                  value={value}
                  onChange={(phone, meta) =>
                    handlePhoneInputChange(phone, meta, onChange)
                  }
                  name="phoneNumber"
                  placeholder={t("inputs.phoneNumber")}
                  defaultCountry={ENUMS.defaultCountry}
                  hideDropdown
                  className={cx("phone-input", {
                    hasError: errors.phoneNumber,
                  })}
                  inputProps={{
                    autoComplete: "username",
                    onKeyDown: handleKeyDown,
                  }}
                />
                {errors.phoneNumber && (
                  <div className="EmailOrPhoneForgetPasswordMethodError">
                    {errors.phoneNumber && (
                      <ErrorMessage
                        errors={errors}
                        name="phoneNumber"
                        render={({ message }) => (
                          <p className="h7 error-message">{message}</p>
                        )}
                      />
                    )}
                  </div>
                )}
              </>
            )}
          />
        ) : (
          <EmailInput
            className={"InputEmail"}
            containerClassName={"TypeA"}
            placeholder={t("inputs.email")}
            required
            name="email"
            labelType={ENUMS_EMAIL_INPUT.types.TYPE_B}
            func={{
              ...register("email", {
                onChange: handleInputChange,
              }),
            }}
            hasError={errors.email}
            onKeyDown={handleKeyDown}
            error={
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
            autoComplete={"username"}
          />
        )}

        <PrimaryButton
          onClick={handleSubmit(handleOnSubmit)}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_P}
          text={t("buttons.continue")}
          isLoading={isSignUpLoading}
        />
      </form>
    </div>
  );
};

ForgotPasswordMainForm.proptpes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  setActiveStep: PropTypes.func,
  selectedMethod: PropTypes.oneOf([SIGN_IN_METHOD.email, SIGN_IN_METHOD.email])
    .isRequired,
  setSelectedMethod: PropTypes.func,
  setCounter: PropTypes.func,
};

export default ForgotPasswordMainForm;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./AddEmployeeModal.scss";

const AddEmployeeModal = ({ body, mainElementRef, openSlide }) => {
  const [isVisible, setIsVisible] = useState(openSlide);

  useEffect(() => {
    setIsVisible(openSlide);
  }, [openSlide]);

  return (
    isVisible && (
      <div className="AddEmployeeModal">
        <div className="AddEmployeeModalBackground">
          <div className="AddEmployeeModalContainer" ref={mainElementRef}>
            {body}
          </div>
        </div>
      </div>
    )
  );
};

AddEmployeeModal.propTypes = {
  body: PropTypes.node.isRequired,
  mainElementRef: PropTypes.object,
  openSlide: PropTypes.bool,
};

export default AddEmployeeModal;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import SectionHeader from "components/admin/elements/section-header/SectionHeader";
import AddTagButton from "components/admin/buttons/add-tag-button/AddTagButton";
import TagCard from "components/admin/cards/tag-card/TagCard";
import useOutsideClick from "utils/hooks/useOutsideClick";
import AddItemModal from "components/admin/cards/add-item-modal/AddItemModal";
import AddNewButton from "components/admin/buttons/add-new-button/AddNewButton";
import Search, { ENUMS as SEARCH_ENUMS } from "components/forms/search/Search";
import Table from "components/admin/elements/table/Table";
import WarningIcon, {
  ENUMS as WARNING_ICON_ENUMS,
} from "components/admin/elements/warning-icon/WarningIcon";
import AddInventoryItemModal from "pages/admin/admin-pages/admin-inventory/add-inventory-item-modal/AddInventoryItemModal";
import { useDispatch, useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { handleOnAsyncError, handleOnAsyncSuccess } from "utils/helpers";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import { getInventoryCategories } from "utils/api/services/inventoryCategory";
import {
  createInventoryCategoryAsync,
  deleteInventoryCategoryAsync,
  updateInventoryCategoryAsync,
} from "redux/actions/inventoryActions";
import useAsync from "utils/hooks/useAsync";
import {
  createInventoryItem,
  deleteInventoryItem,
  updateInventoryItem,
} from "utils/api/services/inventoryItem";

import "./AdminInventory.scss";

const AdminInventory = () => {
  const { t } = useTranslation();
  const { data: categories } = useSelector(
    (state) => state[STORE_NAMES.inventory]
  );
  const businessId = useSelector(
      (state) => state[STORE_NAMES.business]?.business?.id
  );
  const dispatch = useDispatch();
  const [openSlideCategory, setOpenSlideCategory, mainElementRefCategory] =
    useOutsideClick();
  const [openSlideItem, setOpenSlideItem, mainElementRefItem] =
    useOutsideClick();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (activeCategory) {
      setItems(activeCategory.inventoryItems);
    }
  }, [activeCategory]);

  const handleOnAsyncErrorForInventory = (errorMessage) => {
    handleOnAsyncError(errorMessage || t(commonAsyncErrorMessage));
  };
  const handleOnAsyncSuccessForInventory = (successMessage) => {
    handleOnAsyncSuccess(successMessage, () => {
      setOpenSlideCategory(false);
      //TODO remove reFetch after web sockets
      dispatch(getInventoryCategories(businessId));
    });
  };

  const handleOnAsyncSuccessForInventoryItem = (successMessage) => {
    handleOnAsyncSuccess(successMessage, () => {
      setOpenSlideItem(false);
      //TODO remove reFetch after web sockets
      dispatch(getInventoryCategories(businessId));
    });
  };

  const { execute: executeCreateInventoryItem } = useAsync(
    createInventoryItem,
    {
      onError: () => handleOnAsyncErrorForInventory(),
      onSuccess: () =>
        handleOnAsyncSuccessForInventoryItem(
          t("toastMessages.success.createInventoryItem")
        ),
    }
  );
  const { execute: executeUpdateInventoryItem } = useAsync(
    updateInventoryItem,
    {
      onError: () => handleOnAsyncErrorForInventory(),
      onSuccess: () =>
        handleOnAsyncSuccessForInventoryItem(
          t("toastMessages.success.updateInventoryItem")
        ),
    }
  );
  const { execute: executeDeleteInventoryItem } = useAsync(
    deleteInventoryItem,
    {
      onError: () => handleOnAsyncErrorForInventory(),
      onSuccess: () =>
        handleOnAsyncSuccessForInventoryItem(
          t("toastMessages.success.deleteInventoryItem")
        ),
    }
  );
  const handleOnAddCategory = () => {
    setOpenSlideCategory(true);
    setSelectedCategory(null);
  };

  const handleOnEditCategory = (category) => {
    setSelectedCategory(category);
    setOpenSlideCategory(true);
  };

  const handleOnSaveCategory = async (data, id) => {
    const category = { name: data.name };

    if (id) {
      const response = await dispatch(
        updateInventoryCategoryAsync({ businessId, category, id })
      );
      if (response.error) {
        handleOnAsyncErrorForInventory();
      } else {
        handleOnAsyncSuccessForInventory(
          t("toastMessages.success.updateInventoryCategory")
        );
      }
    } else {
      const response = await dispatch(
        createInventoryCategoryAsync({ businessId, category })
      );
      if (response.error) {
        handleOnAsyncErrorForInventory();
      } else {
        handleOnAsyncSuccessForInventory(
          t("toastMessages.success.createInventoryCategory")
        );
      }
    }
  };

  const handleOnDeleteCategory = async (id) => {
    const response = await dispatch(
      deleteInventoryCategoryAsync({ businessId, id })
    );
    if (response.error) {
      handleOnAsyncErrorForInventory();
    } else {
      if (activeCategory.id === id) setActiveCategory(null);
      handleOnAsyncSuccessForInventory(
        t("toastMessages.success.deleteInventoryCategory")
      );
    }
  };

  const handleOnAddInventoryItem = () => {
    setOpenSlideItem(true);
    setSelectedItem(null);
  };
  const handleOnSaveInventoryItem = async (data, id) => {
    if (id) {
      await executeUpdateInventoryItem(businessId, data, id);
    } else {
      await executeCreateInventoryItem(businessId, data);
    }
  };
  const handleOnDeleteInventoryItem = async (id) => {
    await executeDeleteInventoryItem(businessId, id);
  };

  const handleOnEditInventoryItem = (id) => {
    setOpenSlideItem(true);
    setSelectedItem(items.find((item) => item.id === id));
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    if (value.trim() !== "") {
      const result = activeCategory.inventoryItems.filter((item) =>
        item.name
          .toLowerCase()
          .split(" ")
          .some((word) => word.startsWith(value.toLowerCase()))
      );
      setItems(result);
    }
  };

  useEffect(() => {
    if (activeCategory) {
      setActiveCategory(
        categories.find((category) => category.id === activeCategory.id)
      );
    } else {
      setActiveCategory(categories?.[0]);
    }
  }, [categories]);

  const tableColumns = [
    {
      key: "name",
      name: t("inputs.name"),
      isFilterable: true,
    },
    {
      key: "stock",
      name: t("inventory.stock"),
      isFilterable: true,
    },
  ];

  const tableItems =
    items?.map((item) => ({
      id: item.id,
      name: item.name,
      stock: (
        <div className="AdminInventoryItemsStock">
          <span> {`${item.amount} ${item.unit.name}`}</span>
          {item.amount === 0 ? (
            <WarningIcon type={WARNING_ICON_ENUMS.types.TYPE_B} />
          ) : (
            item.amount <= item.warningAmount && <WarningIcon />
          )}
        </div>
      ),
    })) || [];

  return (
    <div className="AdminInventory">
      <div className="AdminInventoryTitle">
        <h2 className="SemiBold">{t("navbarRoutes.inventory")}</h2>
      </div>
      <div className="AdminInventoryCategories">
        <SectionHeader
          title={t("menu.category.categories")}
          count={categories?.length}
        />
        <div className="AdminInventoryCategoriesList">
          <div className="AdminInventoryCategoriesListAdd">
            <AddTagButton onClick={handleOnAddCategory} />
          </div>
          <div className="AdminInventoryCategoriesListExisting">
            {categories?.map((category) => (
              <div
                key={category.id}
                className="AdminInventoryCategoriesListItem"
              >
                <TagCard
                  tag={category}
                  onClick={() => setActiveCategory(category)}
                  activeTagId={activeCategory?.id}
                  onEdit={() => handleOnEditCategory(category)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="AdminInventoryItems">
        <div className="AdminInventoryItemsTitle">
          <div className="AdminInventoryItemsTitleLeft">
            <h4 className="SemiBold">{t("inventory.productList")}</h4>
            <h6 className="Medium">
              {activeCategory?.inventoryItems?.length} {t("inventory.product")}
            </h6>
          </div>
          <div className="AdminInventoryItemsTitleRight">
            <div className="AdminInventoryItemsTitleRightSearch">
              <Search
                onChange={(value) => {
                  handleSearchChange(value);
                }}
                value={searchValue}
                type={SEARCH_ENUMS.types.TYPE_B}
              />
            </div>
            <AddNewButton
              onClick={handleOnAddInventoryItem}
              label={t("buttons.addNew")}
            />
          </div>
        </div>
        <Table
          columns={tableColumns}
          items={tableItems}
          hasDeleteButton
          onEdit={(id) => handleOnEditInventoryItem(id)}
          onDelete={(id) => handleOnDeleteInventoryItem(id)}
        />
      </div>
      <AddItemModal
        mainElementRef={mainElementRefCategory}
        setOpenSlide={setOpenSlideCategory}
        title={t("menu.category.name")}
        item={selectedCategory}
        openSlide={openSlideCategory}
        onSave={handleOnSaveCategory}
        onDelete={handleOnDeleteCategory}
      />
      <AddInventoryItemModal
        mainElementRef={mainElementRefItem}
        setOpenSlide={setOpenSlideItem}
        title={t("inventory.product")}
        item={selectedItem}
        openSlide={openSlideItem}
        activeCategory={activeCategory}
        onSave={handleOnSaveInventoryItem}
        onDelete={handleOnDeleteInventoryItem}
      />
    </div>
  );
};

export default AdminInventory;

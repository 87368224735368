import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as ExclamationIcon } from "assets/icons/client-dashboard/exclamation.svg";
import { ReactComponent as ICON_WARNING } from "assets/icons/client-dashboard/information.svg";
import { ReactComponent as ICON_WARNING_TRIANGLE } from "assets/icons/other/Triangle.svg";
import GuestModal, {
  MODAL_POSITIONS,
  POSITION_TYPES,
} from "components/guest/guest-modal/GuestModal";
import PrimaryButton, {
  ENUMS as ENUMS_PRIMARY_BUTTON,
} from "components/admin/buttons/primary-button/PrimaryButton";
import {
  calculateMenuItemPriceBySchedule,
  calculateModificationsPrice,
} from "utils/general";
import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import { findMenuItemByIdAndPublished } from "utils/helpers";
import useLanguage from "utils/hooks/useLanguage";
import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";
import { STORE_NAMES } from "utils/constants/redux";

import "./DeleteMenuItemsModal.scss";

const DeleteMenuItemsModal = ({
  mainElementRef,
  openSlide,
  guestId,
  orderItems,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [itemsInOrder, setItemsInOrder] = useState([]);
  const { displayDataByLanguage } = useLanguage();
  const { data: menu } = useSelector((state) => state[STORE_NAMES.menu]);

  useEffect(() => {
    if (orderItems && orderItems.length > 0) {
      const fetchedMenuItems = orderItems.map((item) => {
        const menuItem = findMenuItemByIdAndPublished(
          menu.categories,
          item.item,
          dispatch,
          guestId
        );
        return {
          menuItem,
          count: item.count,
          isConfirmed: item.isConfirmed,
        };
      });

      setItemsInOrder(fetchedMenuItems);
    }
  }, [orderItems, menu.categories, dispatch, guestId]);

  const unconfirmedItems = itemsInOrder.filter(
    (item) => item.isConfirmed === null
  );
  const confirmedItems = itemsInOrder.filter(
    (item) => item.isConfirmed === true
  );

  const DeleteItemModalHeader = (
    <div className="DeleteItemModalHeader">
      <div className="CloseButtonContainer">
        <CloseButton
          onClick={onCancel}
          type={ENUMS_CLOSE_BUTTON.types.TYPE_M}
        />
      </div>
      <div className="DeleteItemModalBodyIconWrapper">
        <div className="DeleteItemModalBodyIcon">
          <ICON_WARNING className="DeleteItemModalBodyIconCircle" />
          <ExclamationIcon className="DeleteItemModalBodyExclamationIcon" />
        </div>
      </div>
    </div>
  );
  const DeleteItemModalBody = (
    <div className="DeleteItemModalBody">
      <h4 className="Medium DeleteItemModalTitle">
        {t("modal.deleteMenuItemTitle")}
      </h4>
      <div className="DeleteItemModalBodyContainer">
        {unconfirmedItems.length > 0 && (
          <div className="DeleteMenuItemsModalBodyContentDetails">
            {unconfirmedItems &&
              unconfirmedItems.map((itemInOrder, index) => {
                const discountPrice = calculateMenuItemPriceBySchedule(
                  itemInOrder.menuItem
                );
                const menuItemFinalPrice =
                  itemInOrder.menuItem.priceSell +
                  calculateModificationsPrice(
                    itemInOrder.menuItem.modifications
                  );
                return (
                  <div
                    key={index}
                    className="DeleteMenuItemsModalBodyContentDetail"
                  >
                    <div className="DeleteMenuItemsModalBodyContentDetailContent">
                      <p className="h7 Medium DeleteMenuItemsModalBodyContentDetailContentTitle">
                        {itemInOrder.count}x{" "}
                        {displayDataByLanguage(itemInOrder.menuItem.name)}
                      </p>
                      <div className="DeleteMenuItemsModalBodyContentDetailContentTotalPriceWrapper">
                        <div className="h7 Medium DeleteMenuItemsModalBodyContentTotalPrice">
                          {discountPrice !== null && (
                            <Price
                              type={ENUMS_PRICE.types.PRICE_LINE_THROUGH_XXS}
                              value={menuItemFinalPrice * itemInOrder.count}
                            />
                          )}
                        </div>
                        <div className="h7 Medium DeleteMenuItemsModalBodyContentTotalPrice">
                          <Price
                            type={ENUMS_PRICE.types.PRICE_XS}
                            value={
                              discountPrice !== null
                                ? itemInOrder.count * menuItemFinalPrice +
                                  discountPrice * itemInOrder.count
                                : menuItemFinalPrice * itemInOrder.count
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {itemInOrder.menuItem.modifications &&
                    itemInOrder.menuItem.modifications.length > 0 ? (
                      <div className="DeleteMenuItemsModalBodyContentDetailContentModifications">
                        {itemInOrder.menuItem.modifications.map(
                          (modification, index) => (
                            <div
                              className="DeleteMenuItemsModalBodyContentDetailContentModificationWrapper"
                              key={index}
                            >
                              <p className="h8 Regular DeleteMenuItemsModalBodyContentDetailContentModification">
                                {displayDataByLanguage(modification.name)}:
                              </p>
                              <div className="h8 Regular DeleteMenuItemsModalBodyContentDetailContentModification">
                                {modification.options
                                  .map((option, i) => (
                                    <React.Fragment key={i}>
                                      {`${
                                        option.count
                                      }x ${displayDataByLanguage(option.name)}`}
                                      <Price
                                        type={ENUMS_PRICE.types.PRICE_XS}
                                        value={option.count * option.priceSell}
                                      />
                                    </React.Fragment>
                                  ))
                                  .reduce((prev, curr) => [prev, ", ", curr])}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ) : null}
                  </div>
                );
              })}
          </div>
        )}
        {unconfirmedItems.length > 0 && confirmedItems.length > 0 && (
          <div className="DeleteMenuItemsModalBodyContentDetailsLine"></div>
        )}

        {confirmedItems.length > 0 && (
          <div className="DeleteMenuItemsModalBodyContentConfirmedDetails ">
            <div className="DeleteMenuItemsModalBodyContentConfirmedDetailsTitle">
              <div className="DeleteMenuItemsModalBodyContentConfirmedDetailsTitleIcon">
                <ICON_WARNING_TRIANGLE className="DeleteItemModalBodyIconTriangle" />
                <ExclamationIcon className="DeleteItemModalBodyExclamationIcon" />
              </div>
              <h6 className="Medium h7">{t("modal.cannotBeRemoved")}</h6>
            </div>
            {confirmedItems &&
              confirmedItems.map((itemInOrder, index) => {
                const discountPrice = calculateMenuItemPriceBySchedule(
                  itemInOrder.menuItem
                );
                const menuItemFinalPrice =
                  itemInOrder.menuItem.priceSell +
                  calculateModificationsPrice(
                    itemInOrder.menuItem.modifications
                  );
                return (
                  <div
                    key={index}
                    className="DeleteMenuItemsModalBodyContentConfirmedDetail"
                  >
                    <div className="DeleteMenuItemsModalBodyContentConfirmedDetailContent">
                      <p className="h7 Medium DeleteMenuItemsModalBodyContentConfirmedDetailContentTitle">
                        {itemInOrder.count}x{" "}
                        {displayDataByLanguage(itemInOrder.menuItem.name)}
                      </p>
                      <div className="DeleteMenuItemsModalBodyContentConfirmedDetailContentTotalPriceWrapper">
                        <div className="h7 Medium DeleteMenuItemsModalBodyContentConfirmedTotalPrice">
                          {discountPrice !== null && (
                            <Price
                              type={ENUMS_PRICE.types.PRICE_LINE_THROUGH_XXS}
                              value={menuItemFinalPrice * itemInOrder.count}
                            />
                          )}
                        </div>
                        <div className="h7 Medium DeleteMenuItemsModalBodyContentConfirmedTotalPrice">
                          <Price
                            type={ENUMS_PRICE.types.PRICE_XS}
                            value={
                              discountPrice !== null
                                ? itemInOrder.count * menuItemFinalPrice +
                                  discountPrice * itemInOrder.count
                                : menuItemFinalPrice * itemInOrder.count
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {itemInOrder.menuItem.modifications &&
                    itemInOrder.menuItem.modifications.length > 0 ? (
                      <div className="DeleteMenuItemsModalBodyContentConfirmedDetailContentModifications">
                        {itemInOrder.menuItem.modifications.map(
                          (modification, index) => (
                            <div
                              className="DeleteMenuItemsModalBodyContentConfirmedDetailContentModificationWrapper"
                              key={index}
                            >
                              <p className="h8 Regular DeleteMenuItemsModalBodyContentConfirmedDetailContentModification">
                                {displayDataByLanguage(modification.name)}:
                              </p>
                              <div className="h8 Regular DeleteMenuItemsModalBodyContentConfirmedDetailContentModification">
                                {modification.options
                                  .map((option, i) => (
                                    <React.Fragment key={i}>
                                      {`${
                                        option.count
                                      }x ${displayDataByLanguage(option.name)}`}
                                      <Price
                                        type={ENUMS_PRICE.types.PRICE_XS}
                                        value={option.count * option.priceSell}
                                      />
                                    </React.Fragment>
                                  ))
                                  .reduce((prev, curr) => [prev, ", ", curr])}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ) : null}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );

  const DeleteItemModalFooter = (
    <div className="DeleteItemModalFooter">
      <PrimaryButton
        onClick={onConfirm}
        text={t("buttons.delete")}
        type={ENUMS_PRIMARY_BUTTON.types.TYPE_R}
        isDisabled={confirmedItems.length > 0}
      />
    </div>
  );

  return (
    <GuestModal
      header={DeleteItemModalHeader}
      body={DeleteItemModalBody}
      footer={DeleteItemModalFooter}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
      className={"ModalClassname"}
      containerClassname={"ContainerClassname"}
      modalClassname={"ModalClassname"}
      slideDirection={POSITION_TYPES.DOWN}
      modalPosition={MODAL_POSITIONS.BOTTOM}
    />
  );
};
DeleteMenuItemsModal.propTypes = {
  mainElementRef: PropTypes.object,
  openSlide: PropTypes.bool.isRequired,
  guestId: PropTypes.number.isRequired,
  orderItems: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DeleteMenuItemsModal;

import { createSlice } from "@reduxjs/toolkit";

import { STORE_NAMES } from "utils/constants/redux";

// const sampleTopic = {
//   id: 9,
//   orderId: 9,
//   businessId: 1,
//   messages: [
//     {
//       authorGuest: {
//         id: '8g',
//         originalId: 8,
//       },
//       authorAdmin: {
//         id: 1,
//         name: "owner",
//         role: { name: "OWNER" },
//       },
//       text: "",
//       dateTime: "",
//     },
//   ],
// };

const initialState = {
  isLoading: false,
  error: null,
  topics: [],
  lastMessage: null,
};

const isExistTopic = (allTopics, orderId) => {
  return allTopics.findIndex((topic) => {
    return topic.id === orderId;
  });
};

export const chatStore = createSlice({
  name: STORE_NAMES.chat,
  initialState: initialState,
  reducers: {
    addMessageToOrder: (
      state,
      { payload: { id, orderId, businessId, message } }
    ) => {
      const existingTopicIndex = isExistTopic(state.topics, orderId);
      if (existingTopicIndex >= 0) {
        const updatedTopics = [...state.topics];
        const existingTopic = { ...updatedTopics[existingTopicIndex] };
        existingTopic.messages = [...existingTopic.messages, message];
        updatedTopics[existingTopicIndex] = existingTopic;
        return {
          ...state,
          topics: updatedTopics,
          lastMessage: { id, message },
        };
      } else {
        return {
          ...state,
          topics: [
            ...state.topics,
            {
              id: orderId,
              orderId,
              businessId,
              messages: [message],
              usersLastReadTime: [],
            },
          ],
          lastMessage: { id, message },
        };
      }
    },
    addMessagesToOrder: (state, { payload: topic }) => {
      return { ...state, topics: [...topic] };
    },
    updateUserLastReadTime: (state, { payload: { orderId, userId, time } }) => {
      const isTopicExist = state.topics.find((topic) => topic.id === orderId);
      if (isTopicExist) {
        state.topics?.map((topic) => {
          if (topic.id === orderId) {
            const currenUserData = topic.usersLastReadTime.find(
              (item) => item.userId === userId
            );
            if (currenUserData) {
              currenUserData.dateTime = time;
            } else {
              topic.usersLastReadTime = [
                ...topic.usersLastReadTime,
                {
                  userId: userId,
                  dateTime: time,
                },
              ];
            }
          }
          return topic;
        });
      } else {
        return state;
      }
    },
    deleteTopicForOrder: (state, { payload: { orderId } }) => {
      const updatedTopics = state.topics?.filter(
        (topic) => topic.id !== orderId
      );
      return {
        ...state,
        topics: updatedTopics,
      };
    },
    resetChatStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  addMessageToOrder,
  addMessagesToOrder,
  updateUserLastReadTime,
  resetChatStore,
  deleteTopicForOrder,
} = chatStore.actions;

export default chatStore.reducer;

import React, { useEffect } from "react";

import useLogoutGuest from "utils/hooks/useGuestLogout";
import ForgotPasswordStepper from "pages/client/auth/forget-password/forget-password-stepper/ForgotPasswordStepper";

import "./ForgotPassword.scss";

const ForgotPassword = () => {
  const { logoutGuest } = useLogoutGuest();
  useEffect(() => {
    logoutGuest();
  }, []);

  return (
    <div className="GuestAuthForgetPassword">
      <ForgotPasswordStepper />
    </div>
  );
};

export default ForgotPassword;

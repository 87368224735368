import mixpanel from "mixpanel-browser";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";

const useMixPanelEvents = () => {
  const businessId = useSelector(
      (state) => state[STORE_NAMES.business]?.business?.id
  );
  const { user } = useSelector((state) => state[STORE_NAMES.user]);
  const saveEvent = (type) =>
    mixpanel.track("Save", { type, userId: user.id, businessId });
  return {
    saveEvent,
  };
};

export default useMixPanelEvents;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import Modal from "components/modal/Modal";
import IconButton from "components/buttons/icon-button/IconButton";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import RadioSelection from "components/admin/forms/radio-selection/RadioSelection";
import Rectangle from "assets/icons/map/Rectangle.svg";
import Circle from "assets/icons/map/Circle.svg";
import BackButton, {
  ENUMS as BACK_BUTTON_ENUMS,
} from "components/buttons/back-button/BackButton";
import ArrowIcon from "assets/icons/arrows/ArrowRight.svg";
import { SHAPE_ENUMS } from "utils/constants/data/base";

import "./AddToMapModal.scss";

const INITIAL_FORM_DATA = {
  shape: SHAPE_ENUMS.RECTANGLE,
};

const AddToMapModal = ({
  mainElementRef,
  setOpenSlide,
  openSlide,
  onSave,
  isLoading,
  tables,
}) => {
  const { t } = useTranslation();
  const [selectedTables, setSelectedTables] = useState([]);
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [step, setStep] = useState(1);

  const handleGoBack = () => {
    setStep(1);
  };
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: parseInt(value),
      };
    });
  };
  const handleOnSubmit = () => {
    if (step === 1) {
      setStep(2);
    } else {
      const editedData = selectedTables.map((table) => {
        return { ...table, shape: formData.shape };
      });
      onSave(editedData);
      setSelectedTables([]);
      setStep(1);
      setOpenSlide(false);
    }
  };
  const handleClickTable = (table) => {
    setSelectedTables((prev) => {
      if (prev.flatMap((value) => value.id).includes(table.id)) {
        return prev.filter((value) => value.id !== table.id);
      } else {
        return [...prev, table];
      }
    });
  };

  const AddToMapModalHeaderStep1 = (
    <div className="AddToMapModalTitle">
      <h3 className="SemiBold">
        {t("map.addToMap")} ({tables.length})
      </h3>
      <IconButton
        onClick={() => {
          setOpenSlide(false);
          setStep(1);
          setSelectedTables([]);
        }}
        svgComponent={<IconClose />}
      />
    </div>
  );

  const AddToMapModalHeaderStep2 = (
    <div className="AddToMapModalTitle">
      <BackButton
        type={BACK_BUTTON_ENUMS.types.TYPE_C}
        onClick={handleGoBack}
      />
      <h3 className="SemiBold">{t("common.shape")}</h3>
      <IconButton
        onClick={() => {
          setOpenSlide(false);
          setStep(1);
        }}
        svgComponent={<IconClose />}
      />
    </div>
  );

  const AddToMapModalHeader =
    step === 1 ? AddToMapModalHeaderStep1 : AddToMapModalHeaderStep2;

  const AddToMapModalBodyStep1 = (
    <div className="AddToMapModalBodyTables">
      {tables.map((table) => {
        const { id, name } = table;
        return (
          <div
            className={cx("AddToMapModalBodyTable", {
              active: selectedTables.flatMap((value) => value.id).includes(id),
            })}
            key={id}
            onClick={() => handleClickTable(table)}
          >
            <h6 className=" h7 Medium">{name}</h6>
          </div>
        );
      })}
    </div>
  );

  const AddToMapModalBodyStep2 = (
    <div className="AddToMapModalBodyTables">
      <div className="ShapeSelectionOptions">
        <RadioSelection
          value={SHAPE_ENUMS.RECTANGLE}
          label={t("map.shapes.rectangle")}
          name="shape"
          imgSrc={Rectangle}
          isChecked={formData.shape === SHAPE_ENUMS.RECTANGLE}
          onChange={handleInputChange}
        />
        <RadioSelection
          value={SHAPE_ENUMS.CIRCLE}
          label={t("map.shapes.circle")}
          name="shape"
          imgSrc={Circle}
          isChecked={formData.shape === SHAPE_ENUMS.CIRCLE}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
  const AddToMapModalBody =
    step === 1 ? AddToMapModalBodyStep1 : AddToMapModalBodyStep2;

  const AddToMapModalFooter = (
    <div className="AddToMapModalFooter">
      <PrimaryButton
        onClick={handleOnSubmit}
        text={step === 1 ? t("buttons.continue") : t("map.addToMap")}
        isLoading={isLoading}
        isDisabled={selectedTables.length === 0}
        type={
          step === 1
            ? PRIMARY_BUTTON_ENUMS.types.TYPE_A
            : PRIMARY_BUTTON_ENUMS.types.TYPE_E
        }
        icon={step === 1 && ArrowIcon}
      />
    </div>
  );
  return (
    <Modal
      header={AddToMapModalHeader}
      body={AddToMapModalBody}
      footer={AddToMapModalFooter}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    />
  );
};

export default AddToMapModal;

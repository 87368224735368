import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./Spinner.scss";

export const ENUMS = {
  name: "Spinner",
};

const Spinner = ({ className }) => (
  <div className={cx(`SpinnerContainer`, className)}>
    <div className="Spinner"></div>
  </div>
);

Spinner.propTypes = {
  /**
   * The classes of button
   */
  className: PropTypes.string,
};

export default Spinner;

import React from "react";
import { useNavigate } from "react-router-dom";

import { ROUTE_NAME } from "utils/constants/routes";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { createDOBucketName } from "utils/DO-Spaces";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";

import "./AllBusinessesGrid.scss";
import PropTypes from "prop-types";

const AllBusinessesGrid = ({ allBusinesses }) => {
  const navigate = useNavigate();
  return (
    <div className="AllBusinessesCategoriesGridView">
      <h3 className="Bold AllBusinessesCategoriesGridTitle">Seaside</h3>
      <div className="AllBusinessesCategoriesGridWrapper">
        {allBusinesses?.map((business, index) => (
          <div
            key={index}
            className="AllBusinessesCategoryGridView"
            onClick={() =>
              navigate(
                `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business.id}${ROUTE_NAME.menu}`
              )
            }
          >
            <ImageWithPlaceholder
              className="AllBusinessesCategoryGridImg"
              imageSource={createDOBucketName(business?.images?.logo)}
              placeholder={IMAGE_ITEM_PLACEHOLDER}
              alt="business-image"
            />
            <div className="GridTitleWrapper">
              <h5 className="Medium GridTitle">{business?.name}</h5>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

AllBusinessesGrid.propTypes = {
  allBusinesses: PropTypes.array,
};

export default AllBusinessesGrid;

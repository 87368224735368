import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { ReactComponent as QrNameIcon } from "assets/icons/qr/QrNameIcon.svg";
import { ReactComponent as TableNameIcon } from "assets/icons/qr/SmallTableIcon.svg";

import "./Table.scss";

export const ENUMS = {
  name: "Table",
};

const Table = ({ table, onClick }) => {
  const tableQR = table.qrLinks?.[0];

  return (
    <div className="Table" onClick={() => onClick(table)}>
      <div className="TableName">
        <TableNameIcon />
        <h5 className="SemiBold TableTitleName">{table.name}</h5>
      </div>

      <div
        className={cx("TableQR", {
          isLinked: tableQR,
        })}
      >
        {tableQR ? (
          <div className="TableQRNameContainer">
            <QrNameIcon />
            <h6 className="Medium Qr h7">QR:</h6>
            <h6 className="Medium TableQRName h7">{tableQR.name}</h6>
          </div>
        ) : (
          <div className="TableUnlinkQRNameContainer">
            <h6 className="Medium TableQRNameUnlinked h7">Unlinked</h6>
          </div>
        )}
      </div>
    </div>
  );
};

Table.propTypes = {
  /**
   * The table object
   */
  table: PropTypes.shape({
    id: PropTypes.number.isRequired,
    qrLinks: PropTypes.array,
    name: PropTypes.string.isRequired,
  }).isRequired,

  /**
   * The function called when the component is clicked
   */
  onClick: PropTypes.func.isRequired,
};

export default Table;

import React from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import { EffectCards } from "swiper/modules";
import cx from "classnames";

import Discount from "components/elements/discount/Discount";
import FavoritesIconButton, {
  ENUMS as ENUMS_FAVORITES_ICON_BUTTON,
} from "components/buttons/favorites-icon-button/FavoritesIconButton";
import AddIconButton, {
  ENUMS as ENUMS_ADD_ICON_BUTTON,
} from "components/buttons/add-icon-button/AddIconButton";
import LazyImage from "components/lazy-image/LazyImage";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import useFlyBasketAnimation from "utils/hooks/useFlyBasketAnimation";

import "./MenuViewPokerSlider.scss";

export const ENUMS = {
  name: "MenuViewPokerSlider",
};

const MenuViewPokerCard = ({
  index,
  activeCardIndex,
  menuItem,
  currentItem,
  favoriteItems,
  orderItems,
  prevItemId,
  onFavorite,
  nextItemId,
  onAdd,
}) => {
  const { menuItemImageRef, flyAnimationHandler } = useFlyBasketAnimation();

  const addBasketHandler = () => {
    onAdd(currentItem);
    flyAnimationHandler();
  };

  return (
    <>
      <LazyImage
        imgRef={menuItemImageRef}
        src={menuItem?.imgSrc}
        placeholder={IMAGE_ITEM_PLACEHOLDER}
        className="SliderImage"
        alt=""
      />
      {menuItem.rate && (
        <div className="SliderDiscountRate">
          <Discount rate={menuItem.rate.amount} />
        </div>
      )}
      <div className="MenuViewPokerFavoritesButton">
        <FavoritesIconButton
          id={currentItem.id}
          type={ENUMS_FAVORITES_ICON_BUTTON.types.TYPE_B}
          onClick={() => onFavorite(currentItem.id)}
          value={
            (index === activeCardIndex &&
              favoriteItems.includes(currentItem.id)) ||
            (index === activeCardIndex + 1 &&
              favoriteItems.includes(nextItemId)) ||
            (index === activeCardIndex - 1 &&
              favoriteItems.includes(prevItemId))
          }
        />
      </div>
      <div className="MenuViewPokerAddButton">
        <AddIconButton
          id={currentItem.id}
          onClick={addBasketHandler}
          type={ENUMS_ADD_ICON_BUTTON.types.TYPE_A}
          value={orderItems.reduce(
            (sum, orderItem) =>
              sum +
                (index === activeCardIndex &&
                orderItem.item.id === currentItem.id
                  ? orderItem.count
                  : 0) ||
              (index === activeCardIndex + 1 && orderItem.item.id === nextItemId
                ? orderItem.count
                : 0) ||
              (index === activeCardIndex - 1 && orderItem.item.id === prevItemId
                ? orderItem.count
                : 0),
            0
          )}
        />
      </div>
    </>
  );
};

const MenuViewPokerSlider = ({
  menuItems,
  setActiveCardIndex,
  favoriteItems,
  orderItems,
  onFavorite,
  onAdd,
  currentItem,
  activeCardIndex,
  nextItemId,
  prevItemId,
}) => {
  const handleSlideChange = (swiper) => {
    setActiveCardIndex(swiper.activeIndex);
  };

  return (
    <Swiper
      effect={"cards"}
      grabCursor={true}
      modules={[EffectCards]}
      onSlideChange={handleSlideChange}
      className="MenuViewPokerSlider"
    >
      {menuItems.map((menuItem, index) => {
        return (
          <SwiperSlide
            key={index}
            className={cx({ isActive: index === activeCardIndex })}
          >
            <MenuViewPokerCard
              index={index}
              activeCardIndex={activeCardIndex}
              menuItem={menuItem}
              currentItem={currentItem}
              favoriteItems={favoriteItems}
              orderItems={orderItems}
              prevItemId={prevItemId}
              onFavorite={onFavorite}
              nextItemId={nextItemId}
              onAdd={onAdd}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

MenuViewPokerSlider.propTypes = {
  /**
   * Menu items of slider
   */
  menuItems: PropTypes.array.isRequired,

  /**
   * Function for changing active card index
   */
  setActiveCardIndex: PropTypes.func.isRequired,

  /**
   * An array of favorite items
   */
  favoriteItems: PropTypes.array,

  /**
   * An array of order items
   */
  orderItems: PropTypes.array,

  /**
   * Add action
   */
  onAdd: PropTypes.func.isRequired,

  /**
   * Favorite action
   */
  onFavorite: PropTypes.func.isRequired,

  /**
   * The current item of slider
   */
  currentItem: PropTypes.object.isRequired,

  /**
   * Index of the currently active card
   */
  activeCardIndex: PropTypes.number,

  nextItemId: PropTypes.number,
  prevItemId: PropTypes.number,
};

export default MenuViewPokerSlider;

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import ICON_CHECK from "assets/icons/form/Check.svg";

import "./RadioButton.scss";

export const ENUMS = {
  name: "RadioButton",
};

const RadioButton = ({ checked }) => {
  return (
    <h4
      className={cx("RadioButtonContainer Bold", {
        isChecked: checked,
      })}
    >
      <div className="RadioButton">
        {checked && <img src={ICON_CHECK} alt="check" />}
      </div>
    </h4>
  );
};

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
};

export default RadioButton;

import { AXIOS_USER } from "utils/api/axios";
import { API_PATH_PARAMS } from "utils/constants/api";

export const getMenuById = async (businessId, menuId) =>
  AXIOS_USER.get(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.menus}/${menuId}`
  );

export const updateMenu = async (businessId, menuId, menuData) => {
  return AXIOS_USER.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.menus}/${menuId}`,
    menuData
  );
};

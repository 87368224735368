import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";

import BackButton, {
  ENUMS as BACK_BUTTON_ENUMS,
} from "components/buttons/back-button/BackButton";
import GuestProfileWithIcon from "components/elements/guest-profile-with-icon/GuestProfileWithIcon";
import { STORE_NAMES } from "utils/constants/redux";
import { ROUTE_NAME } from "utils/constants/routes";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import PhoneNumberFormatter from "components/elements/formatted-phone-number/FormattedPhoneNumber";
import EditButton, {
  ENUMS as ENUMS_EDIT_BUTTON,
} from "components/admin/buttons/edit-button/EditButton";
import { DO_FILE_TYPES, IMAGE_FILE } from "utils/constants/DOSpaces";
import { uploadImageToDO } from "utils/DO-Spaces";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import TextInput, {
  ENUMS as ENUMS_TEXT_INPUT,
} from "components/forms/input/input-text/TextInput";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import isEqual from "lodash/isEqual";
import Spinner from "components/elements/spinner/Spinner";
import { updateGuestInfoAsync } from "redux/actions/guestAction";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import AddTagButton from "components/admin/buttons/add-tag-button/AddTagButton";

import "./MyProfileEdit.scss";

const MyProfileEdit = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const guest = useSelector((state) => state[STORE_NAMES.guest]);
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);

  const initialData = {
    profilePic: guest.profilePic,
    firstName: guest.firstName,
    lastName: guest.lastName,
    gender: guest.gender,
    dateOfBirth: guest.dateOfBirth,
  };
  const [formData, setFormData] = useState(initialData);
  const isEqualForm = isEqual(initialData, formData);
  const { editMyProfileSchema } = useValidationSchema(t);

  const handleGoBack = () => {
    navigate(`${ROUTE_NAME.client}${ROUTE_NAME.myProfile}`);
  };

  const handleGoResetPassword = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.myProfile}${ROUTE_NAME.resetPassword}`
    );
  };
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );
  const genderTypes = useSelector(
    (state) => state[STORE_NAMES.app].enums.genderTypes
  );
  const fileInputRef = useRef(null);
  const [profileImageLoading, setProfileImageLoading] = useState(false);
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };
  const handleOnSelectSingleImage = async (selectedImage) => {
    const imageType = DO_FILE_TYPES.GUEST_PROFILE_PHOTO;
    setProfileImageLoading(true);
    try {
      await uploadImageToDO({
        image: selectedImage,
        maxSizeMB: IMAGE_FILE.guestAvatar.maxSizeMB,
        maxWidthOrHeight: IMAGE_FILE.guestAvatar.maxWidthOrHeight,
        fileType: imageType,
        businessId,
        onSuccess: (location) => {
          setFormData((prev) => {
            return {
              ...prev,
              profilePic: location,
            };
          });
          setProfileImageLoading(false);
        },
      });
    } catch (error) {
      throw new Error("Something went wrong...", error);
    }
  };

  const handleFileSelect = async (e) => {
    const selectedFiles = e.target.files[0];
    if (selectedFiles) {
      try {
        await handleOnSelectSingleImage(selectedFiles);
      } catch (error) {
        toast.error(t("errorMessages.image"));
      }
      e.target.value = "";
    }
  };

  const methods = useForm({
    criteriaMode: "all",
    resolver: zodResolver(editMyProfileSchema),
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = methods;

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDate = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      dateOfBirth: value,
    });
  };
  const handleGenderChange = (option) => {
    setFormData({
      ...formData,
      gender: option.id,
    });
  };

  const handleOnSubmit = async () => {
    const { profilePic, dateOfBirth, gender, firstName, lastName } = formData;

    try {
      const guestBody = {
        ...(profilePic ? { profilePic } : {}),
        ...(dateOfBirth ? { dateOfBirth } : {}),
        ...(gender ? { gender } : {}),
        ...(firstName ? { firstName } : {}),
        ...(lastName ? { lastName } : {}),
      };
      setIsUpdatingProfile(true);
      await dispatch(
        updateGuestInfoAsync({
          guestId,
          guestBody,
        })
      );
      setIsUpdatingProfile(false);
      navigate(
        businessId
          ? `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
          : `${ROUTE_NAME.client}${ROUTE_NAME.allBusinesses}`
      );
    } catch (error) {
      toast.error(t("errorMessages.updateFailed"));
    }
  };

  return (
    <div className="MyProfileEdit">
      <div className="MyProfileEditHeader">
        <BackButton
          onClick={handleGoBack}
          type={BACK_BUTTON_ENUMS.types.TYPE_D}
        />
        <h2 className="SemiBold">{t("myProfile.editProfile")}</h2>
      </div>
      <div className="MyProfileEditInfo">
        <div>
          {profileImageLoading ? (
            <Spinner className="MyProfileGuestImageContainerLoading" />
          ) : (
            <GuestProfileWithIcon
              image={formData?.profilePic}
              hasImage={!!formData?.profilePic}
              className="MyProfileEditGuestImageContainer"
              handleOnClick={handleButtonClick}
              actionButton={
                formData?.profilePic ? (
                  <EditButton
                    className="MyProfileGuestEditButton"
                    type={ENUMS_EDIT_BUTTON.types.TYPE_B}
                    onClick={handleButtonClick}
                  />
                ) : (
                  <AddTagButton
                    className="GuestAccountInfoModalProfileButton"
                    onClick={() => {}}
                  />
                )
              }
            />
          )}
          <input
            type="file"
            accept="image/*"
            className="AddPhotoButtonFileInput"
            ref={fileInputRef}
            onChange={handleFileSelect}
          />
        </div>
        <h4 className="SemiBold GuestName"> {formData.lastName ? `${formData.firstName} ${formData.lastName}` : formData.firstName}</h4>
        {guest.phoneNumber && (
          <PhoneNumberFormatter
            phoneNumber={guest.phoneNumber}
            className="MyProfileEditGuestPhoneNumber"
          />
        )}
        {guest.email && <h5>{guest.email}</h5>}
        <PrimaryButton
          onClick={handleGoResetPassword}
          text={t("myProfile.resetPassword.resetPassword")}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_M}
        />
        <form
          className="MyProfileEditGuestForm"
          onSubmit={(e) => e.preventDefault()}
        >
          <TextInput
            placeholder={t("inputs.firstName")}
            required
            name="firstName"
            labelType={ENUMS_TEXT_INPUT.types.TYPE_B}
            func={{
              ...register("firstName", {
                onChange: handleInputChange,
              }),
            }}
            hasError={errors.firstName}
            value={formData?.firstName}
            error={
              <ErrorMessage
                errors={errors}
                name="firstName"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
            containerClassName={"TypeA"}
          />
          <TextInput
            placeholder={t("inputs.lastName")}
            name="lastName"
            labelType={ENUMS_TEXT_INPUT.types.TYPE_B}
            func={{
              ...register("lastName", {
                onChange: handleInputChange,
              }),
            }}
            hasError={errors.lastName}
            value={formData?.lastName}
            error={
              <ErrorMessage
                errors={errors}
                name="lastName"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
            containerClassName={"TypeA"}
          />
          <Dropdown
            onChange={handleGenderChange}
            placeholder={t("auth.gender")}
            options={Object.values(genderTypes).map((type) => {
              return {
                name: t(`auth.genders.${type}`),
                id: type,
              };
            })}
            value={{
              name: t(`auth.genders.${formData.gender}`),
              id: formData.gender,
            }}
            name="guestGender"
          />
          <InputControl
            type="date"
            placeholder={t("inputs.dateOfBirth")}
            name="dateOfBirth"
            labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
            func={{
              ...register("dateOfBirth", {
                onChange: handleDate,
              }),
            }}
            defaultValue={formData.dateOfBirth}
          />
        </form>
        <PrimaryButton
          onClick={handleSubmit(handleOnSubmit)}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_P}
          className="MyProfileEditEditButton"
          isDisabled={isEqualForm}
          isLoading={isUpdatingProfile}
        />
      </div>
    </div>
  );
};

export default MyProfileEdit;

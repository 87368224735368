import React from "react";
import PropTypes from "prop-types";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import HamburgerButton from "components/buttons/hamburger-button/HamburgerButton";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import BackButton, {
  ENUMS as BACK_BUTTON_ENUMS,
} from "components/buttons/back-button/BackButton";
import { STORE_NAMES } from "utils/constants/redux";

import "./BusinessHeroSection.scss";

const BusinessHeroSection = ({ backgroundImage, name }) => {
  const navigate = useNavigate();
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );
  // const handleShare = async () => {
  //   // Check if the Web Share API is available
  //   if (navigator.share) {
  //     navigator
  //       .share({
  //         title: `Share ${name}'s menu!`,
  //         url: window.location.href,
  //       })
  //       .then(() => console.log("Shared successfully"))
  //       .catch((error) => console.error("Error sharing:", error));
  //   } else {
  //     // Copy URL to clipboard for unsupported browsers or devices
  //     await navigator.clipboard.writeText(window.location.href);
  //   }
  // };

  let [searchParams, setSearchParams] = useSearchParams();
  const hamburgerClickHandler = () => {
    setSearchParams({
      ...searchParams,
      [QUERY_PARAMS.showHamburgerMenu]: true,
    });
  };
  const handleGoBack = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
    );
  };

  return (
    <section className="BusinessHeroSection">
      <div className="BusinessBackgroundImageContainer">
        <ImageWithPlaceholder
          imageSource={backgroundImage}
          placeholder={IMAGE_ITEM_PLACEHOLDER}
          alt="background"
        />
        <div className="BusinessHeroButtons">
          <BackButton
            onClick={handleGoBack}
            type={BACK_BUTTON_ENUMS.types.TYPE_C}
          />
          <HamburgerButton onClick={hamburgerClickHandler} hasBackground />
        </div>
      </div>
    </section>
  );
};

BusinessHeroSection.propTypes = {
  backgroundImage: PropTypes.string,
  name: PropTypes.string,
};

export default BusinessHeroSection;

import { createSlice } from "@reduxjs/toolkit";

import { STORE_NAMES } from "utils/constants/redux";
import {
  getAllPendingReservationsAsync,
  getAllReservationsAsync,
} from "redux/actions/reservationAction";

const initialState = {
  isLoading: false,
  reservations: [],
  pending: [],
  error: null,
};

export const reservationStore = createSlice({
  name: STORE_NAMES.reservation,
  initialState: initialState,

  reducers: {
    resetReservationStore: () => {
      return {
        ...initialState,
      };
    },
    setOrReplaceReservations: (state, { payload }) => {
      let copyState = { ...state };
      payload.forEach((reservation) => {
        const { id, status } = reservation;
        const key = status === "PENDING" ? "pending" : "reservations";
        const list = copyState[key];
        const index = list.findIndex((res) => res.id === id);

        if (index === -1) {
          copyState = {
            ...copyState,
            [key]: [...list, reservation],
          };
        } else {
          copyState = {
            ...copyState,
            [key]: list.map((res, i) => (i === index ? reservation : res)),
          };
        }

        if (status === "PENDING") {
          copyState.reservations = copyState.reservations.filter(
            (res) => res.id !== id
          );
        } else {
          copyState.pending = copyState.pending.filter((res) => res.id !== id);
        }
      });
      return copyState;
    },
    deleteReservationRedux: (state, { payload }) => {
      const { id } = payload;
      let key;
      if (state["pending"].find((res) => res.id === id)) {
        key = "pending";
      } else {
        key = "reservations";
      }
      return {
        ...state,
        [key]: state[key].filter((res) => res.id !== id),
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllReservationsAsync.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    });
    builder.addCase(getAllReservationsAsync.rejected, (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    });
    builder.addCase(getAllReservationsAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        reservations: payload,
        isLoading: false,
        error: null,
      };
    });
    builder.addCase(getAllPendingReservationsAsync.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    });
    builder.addCase(
      getAllPendingReservationsAsync.rejected,
      (state, { payload }) => {
        return {
          ...state,
          isLoading: false,
          error: payload,
        };
      }
    );
    builder.addCase(
      getAllPendingReservationsAsync.fulfilled,
      (state, { payload }) => {
        return {
          ...state,
          pending: payload,
          isLoading: false,
          error: null,
        };
      }
    );
  },
});

export const {
  setOrReplaceReservations,
  deleteReservationRedux,
  resetReservationStore,
} = reservationStore.actions;

export default reservationStore.reducer;

import axios from "axios";
import { HEADERS } from "utils/constants/api";
import { ROUTE_NAME } from "utils/constants/routes";
import { LOCAL_STORAGE_VARIABLES } from "utils/constants/global";

export const BACKEND_BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_BACKEND_URL_VERSION}`;

const apiDefaults = {
  baseURL: BACKEND_BASE_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
  },
};

const authRequestInterceptor = (config) => {
  const user = JSON.parse(localStorage.getItem("redux"))?.User;

  if (user) {
    config.headers[
      HEADERS.keys.auth
    ] = `${HEADERS.values.authType} ${user.token}`;
  }

  return config;
};

const guestAuthRequestInterceptor = (config) => {
  const guestAccessToken = localStorage.getItem(
    LOCAL_STORAGE_VARIABLES.accessTokenForGuest
  );

  if (guestAccessToken) {
    config.headers[
      HEADERS.keys.auth
    ] = `${HEADERS.values.authType} ${guestAccessToken}`;
  }

  return config;
};

const responseInterceptor = (response) => {
  return response;
};

const responseErrorInterceptor = (response) => {
  const statusCode = response.response.status;
  const statusText = response.response.statusText;
  const message = response.response.data.message;

  if (statusCode === 401) {
    window.location.href = ROUTE_NAME.signIn;
  }

  console.log(
    "Generic axios error intercept. Code: ",
    statusCode,
    ", statusText: ",
    statusText,
    ", message: ",
    message
  );
  return Promise.reject(response);
};

const responseErrorInterceptorForGuest = (response) => {
  const statusCode = response.response.status;
  const statusText = response.response.statusText;
  const message = response.response.data.message;

  if (statusCode === 401) {
    window.location.href = `${ROUTE_NAME.client}${ROUTE_NAME.auth}${ROUTE_NAME.signUp}`;
  }

  console.log(
    "Generic axios error intercept. Code: ",
    statusCode,
    ", statusText: ",
    statusText,
    ", message: ",
    message
  );
  return Promise.reject(response);
};

export const AXIOS_GUEST_NO_AUTH = axios.create(apiDefaults);
AXIOS_GUEST_NO_AUTH.interceptors.response.use(
  responseInterceptor
  // responseErrorInterceptorForGuest
);

export const AXIOS_USER_NO_AUTH = axios.create(apiDefaults);
AXIOS_GUEST_NO_AUTH.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptor
);

export const AXIOS_USER = axios.create(apiDefaults);
AXIOS_USER.interceptors.request.use(authRequestInterceptor);
AXIOS_USER.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptor
);

export const AXIOS_GUEST = axios.create(apiDefaults);
AXIOS_GUEST.interceptors.request.use(guestAuthRequestInterceptor);
AXIOS_GUEST.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptorForGuest
);

export default AXIOS_USER;

import React, { useMemo, useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";

import { createDOBucketName } from "utils/DO-Spaces";
import EmptyState from "components/admin/empty-state/EmptyState";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import SearchIcon from "assets/icons/other/Search.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check/Select.svg";
import NoReservation from "assets/icons/reserve/NoReservation.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrows/ArrowRight.svg";
import CTAButton, {
  ENUMS as ENUMS_CTA_BUTTON,
} from "components/buttons/cta-button/CTAButton";
import { QUERY_PARAMS } from "utils/constants/routes";
import Search, { ENUMS as SEARCH_ENUMS } from "components/forms/search/Search";
import { advanceSearch } from "utils/algorithms";

import "./ReservationBusinessSelection.scss";

const BusinessCard = ({ business, searchParams, onClick }) => {
  const { id, name, images } = business;

  return (
    <div
      onClick={onClick}
      className={cx("ReservationBusinessSelectionOneBusiness", {
        SelectedBusiness:
          searchParams.get(QUERY_PARAMS.reservation.businessId) === String(id),
      })}
    >
      <div className="ReservationBusinessSelectionLogoOuter">
        <div className="ReservationBusinessSelectionLogo">
          <ImageWithPlaceholder
            imageSource={createDOBucketName(images.logo)}
            placeholder={IMAGE_ITEM_PLACEHOLDER}
            alt="logo"
            className="ReservationBusinessSelectionLogo"
          />
        </div>
      </div>

      <div className="ReservationBusinessSelectionTitle">
        <h6 className="h7 Medium BusinessName">{name}</h6>
      </div>

      {searchParams.get(QUERY_PARAMS.reservation.businessId) === String(id) && (
        <div className="BusinessSelectCheckIcon">
          <CheckIcon />
        </div>
      )}
    </div>
  );
};

const ReservationBusinessSelection = ({ allBusinesses, handleNextStep }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchBusinesses, setSearchBusinesses] = useState("");

  const { t } = useTranslation();

  const handleSelectBusiness = (businessId) => {
    if (
      searchParams.get(QUERY_PARAMS.reservation.businessId) ===
      String(businessId)
    ) {
      searchParams.delete(QUERY_PARAMS.reservation.businessId);
      setSearchParams(searchParams);
    } else {
      setSearchParams({
        ...searchParams,
        [QUERY_PARAMS.reservation.businessId]: businessId,
      });
    }
  };
  const handleSearchBusinesses = (value) => {
    setSearchBusinesses(value);
  };

  const displayedBusinesses = useMemo(() => {
    if (allBusinesses) {
      const searchValue = searchBusinesses.trim().toLowerCase();
      const filteredBusinesses = allBusinesses.filter((business) => {
        return business.reservation && !business.isArchived;
      });
      //No search value
      if (!searchValue) {
        return filteredBusinesses;
      } else {
        return filteredBusinesses.filter((business) => {
          const businessName = business?.name;
          if (businessName) {
            return advanceSearch({
              string: businessName,
              searchString: searchValue,
            });
          } else {
            return false;
          }
        });
      }
    } else {
      return [];
    }
  }, [allBusinesses, searchBusinesses]);

  return (
    <div className="ReservationBusinessSelection">
      <Search
        onChange={(value) => {
          handleSearchBusinesses(value);
        }}
        value={searchBusinesses}
        placeholder={t("reservation.searchRestaurant") + "..."}
        type={SEARCH_ENUMS.types.TYPE_B}
        classname="ReservationBusinessSelectionSearch"
      />
      <div className="ReservationBusinessSelectionAllBusiness">
        {displayedBusinesses.length > 0 &&
          displayedBusinesses.map((business) => {
            return (
              <BusinessCard
                key={business.id}
                business={business}
                searchParams={searchParams}
                onClick={() => handleSelectBusiness(business.id)}
              />
            );
          })}
        {/*No Search Result*/}
        {displayedBusinesses.length === 0 && searchBusinesses.length > 0 && (
          <EmptyState
            description={t("emptyStates.noSearchResults")}
            icon={SearchIcon}
            isAdmin
            className="ReservationEmptyState"
          />
        )}

        {/*  No Business*/}
        {displayedBusinesses.length === 0 && searchBusinesses.length === 0 && (
          <EmptyState
            icon={NoReservation}
            description={t("emptyStates.noBusinessDescription")}
            isAdmin={false}
            title={t("emptyStates.noBusiness")}
            className="ReservationEmptyState"
          />
        )}
      </div>
      {searchParams.get(QUERY_PARAMS.reservation.businessId) && (
        <CTAButton
          onClick={handleNextStep}
          name={t("buttons.continue")}
          type={ENUMS_CTA_BUTTON.types.TYPE_R}
          className="ReservationBusinessSelectionButton"
          icon={<ArrowIcon />}
        />
      )}
    </div>
  );
};

ReservationBusinessSelection.propTypes = {
  allBusinesses: PropTypes.array,
  handleNextStep: PropTypes.func.isRequired,
};

export default ReservationBusinessSelection;

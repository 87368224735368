import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { PhoneInput } from "react-international-phone";
import InputControl from "components/admin/forms/input-control/InputControl";
import "react-international-phone/style.css";
import { Controller } from "react-hook-form";
import { ENUMS as ENUMS_EMAIL_OR_PHONE } from "components/elements/email-or-phone/EmailOrPhone";

import ICON_INSTAGRAM from "assets/icons/sosial-media/Instagram.svg";
import ICON_PHONE from "assets/icons/sosial-media/Phone.svg";
import ICON_WHATSAPP from "assets/icons/sosial-media/Whatsapp.svg";
import ICON_MAIL from "assets/icons/sosial-media/Mail.svg";

import "./Contact.scss";

export const ENUMS = {
  name: "Contact",
  phoneInputs: {
    phone: "phone",
    whatsapp: "whatsapp",
  },
};

const Contact = ({ contact, contactValue, func, control, error, hasError }) => {
  const { t } = useTranslation();

  const SOCIAL_MEDIA = {
    instagram: {
      name: "socials.instagram",
      icon: ICON_INSTAGRAM,
      placeholder: "inputs.instagram",
      link: contactValue,
    },
    phone: {
      name: "inputs.phoneNumber",
      icon: ICON_PHONE,
      placeholder: "inputs.contactPhone",
      link: `tel:${contactValue}`,
    },
    whatsapp: {
      name: "socials.whatsapp",
      icon: ICON_WHATSAPP,
      placeholder: "inputs.whatsapp",
      link: `https://wa.me/${contactValue}`,
    },
    gmail: {
      name: "socials.email",
      icon: ICON_MAIL,
      placeholder: "inputs.contactMail",
      link: `mailto:${contactValue}`,
    },
  };

  const handlePhoneInputChange = (phone, meta, onChange) => {
    const countryDialCode = meta.country.dialCode;

    if (
      phone &&
      phone !== ENUMS_EMAIL_OR_PHONE.plusSign + meta.country.dialCode
    ) {
      onChange(phone);
    }

    if (
      !phone ||
      (contactValue &&
        !contactValue.startsWith(countryDialCode) &&
        phone === ENUMS_EMAIL_OR_PHONE.plusSign + countryDialCode)
    ) {
      onChange("");
    }
  };

  const { name, icon, placeholder, link } = SOCIAL_MEDIA[contact] || {};
  const arePhoneInputs =
    contact === ENUMS.phoneInputs.phone ||
    contact === ENUMS.phoneInputs.whatsapp;

  return (
    <div className="ContactContainer">
      <h6 className="Medium ContactName">{t(name)}</h6>
      <div
        className={cx("Contact", {
          hasError: hasError,
          isDefaultInput: !arePhoneInputs,
        })}
      >
        {arePhoneInputs ? (
          <div className="ContactInput">
            <Controller
              name={`contact.${contact}`}
              control={control}
              defaultValue={contactValue || ""}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  value={value}
                  onChange={(phone, meta) =>
                    handlePhoneInputChange(phone, meta, onChange)
                  }
                  defaultCountry={ENUMS_EMAIL_OR_PHONE.defaultCountry}
                  placeholder={t(placeholder)}
                  className={cx("phone-input", { hasError: hasError })}
                  inputProps={{
                    autoComplete: "off",
                  }}
                />
              )}
            />
          </div>
        ) : (
          <>
            <a
              href={link}
              target="_self"
              rel="noopener noreferrer"
              className="ContactIcon"
            >
              <img src={icon} alt={contact} />
            </a>
            <div className="ContactInput">
              <InputControl
                placeholder={t(placeholder)}
                isBorderless
                value={contactValue}
                noLabelFloating
                name={`contact.${contact}`}
                func={func}
              />
            </div>
          </>
        )}
      </div>
      {error}
    </div>
  );
};

Contact.propTypes = {
  /**
   * The type of contact
   */
  contact: PropTypes.string.isRequired,

  /**
   * The URL or path for the Link component
   */
  contactValue: PropTypes.string.isRequired,

  /**
   * Indicates whether the input has an error
   */
  hasError: PropTypes.object,

  /**
   * Additional function to pass to the input
   * (e.g., onKeyDown, onClick)
   */
  func: PropTypes.object,

  /**
   * Error message to display
   * (e.g., for form validation)
   */
  error: PropTypes.object,

  /**
   * React Hook Form control object
   */
  control: PropTypes.object,
};

export default Contact;

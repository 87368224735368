import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import cx from "classnames";

import { useValidationSchema } from "utils/hooks/useValidationSchema";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import useAsync from "utils/hooks/useAsync";
import { createOtpRequestForGuestReset } from "utils/api/services/otp";
import {
  SIGN_IN_METHOD,
  SIGN_IN_METHOD as SIGN_UP_METHOD,
} from "pages/common/login/sign-in/SignIn";
import OtpInput from "components/forms/input/input-otp/OtpInput";
import { GUEST_FORGET_PASSWORD_STEP_TYPES } from "pages/client/auth/ClientAuthHelper";
import { verifyGuestOtpCode } from "utils/api/services/guest";

import "./ForgotPasswordVerifyOtp.scss";

const ForgotPasswordVerifyOtp = ({
  setFormData,
  formData,
  setActiveStep,
  selectedMethod,
  counter,
  setCounter,
}) => {
  const { t } = useTranslation();
  const { guestSignUpOtp } = useValidationSchema(t);
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();
  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
    setValue,
    trigger,
  } = useForm({
    resolver: zodResolver(guestSignUpOtp),
    criteriaMode: "all",
  });

  const { execute: executeVerifyOtpRequestForReset } = useAsync(
    verifyGuestOtpCode,
    {
      onError: (error) => {
        const errorData = handleAPIErrorMessage(error.response.data);
        if (errorData) {
          const { field, errorMessage } = errorData;
          setError(field, {
            type: "manual",
            message: errorMessage,
          });
        }
      },
      onSuccess: ({ data }) => {
        setFormData((prev) => ({
          ...prev,
          otpToken: data.otpToken,
        }));
        setActiveStep(GUEST_FORGET_PASSWORD_STEP_TYPES.newPassword);
        setCounter(0);
      },
    }
  );
  const {
    execute: executeCreateOtpRequestForReset,
    loading: executeCreateOtpRequestForResetLoading,
  } = useAsync(createOtpRequestForGuestReset, {
    onError: (error) => {
      const errorData = handleAPIErrorMessage(error.response.data);
      if (errorData) {
        const { field, errorMessage } = errorData;
        setError(field, {
          type: "manual",
          message: errorMessage,
        });
      }
    },
    onSuccess: ({ data }) => {
      setFormData((prev) => ({
        ...prev,
        otpSendCount: data.otpSendCount,
      }));
      setCounter(60);
    },
  });

  const handleResendCode = () => {
    let otpBody;
    if (selectedMethod === SIGN_UP_METHOD.email) {
      otpBody = { email: formData.email };
    } else if (selectedMethod === SIGN_UP_METHOD.phoneNumber) {
      otpBody = { phoneNumber: formData.phoneNumber.replace(/\+/g, "") };
    }
    executeCreateOtpRequestForReset(otpBody);
  };

  const handleOnSubmit = async () => {
    executeVerifyOtpRequestForReset({
      otpCode: formData.otpCode,
      otpToken: formData.otpToken,
    });
  };
  const handleOtpChange = async (value) => {
    setFormData((prev) => {
      return {
        ...prev,
        otpCode: value,
      };
    });
    setValue("otpCode", value);
    if (errors.otpCode) {
      await trigger("otpCode");
    }
  };
  const otpSendingAvailable = counter === 0;

  useEffect(() => {
    if (formData.otpCode && formData.otpCode.length === 6) {
      handleSubmit(handleOnSubmit)();
    }
  }, [formData.otpCode]);

  return (
    <div className="ForgetPasswordVerifyOtp">
      <div className="ForgetPasswordVerifyOtpHeader">
        <h1 className="SemiBold title">{t("auth.verifyOtp")}</h1>
        <div className="ForgetPasswordOtpHeaderPhone">
          <h6 className="Medium">{`${t("auth.otpSentTo")}`} </h6>
          <h6 className="Bold">
            {selectedMethod === "email" ? formData.email : formData.phoneNumber}
          </h6>
        </div>
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <OtpInput
          name={"otpCode"}
          value={formData.otpCode}
          hasError={errors.otpCode}
          onOtpChange={handleOtpChange}
          func={{
            ...register("otpCode", {}),
          }}
          error={
            <ErrorMessage
              errors={errors}
              name="otpCode"
              render={({ message }) => (
                <p className="h7 error-message">{t(message)}</p>
              )}
            />
          }
        />
        <div className="ForgetPasswordVerifyOtpResend">
          <h6
            className={cx("Medium", {
              isDisabledText: formData.otpSendCount === 0,
            })}
          >
            {formData.otpSendCount === 0
              ? t("errorMessages.input.pinCode.TryAgainLater")
              : otpSendingAvailable
              ? t("auth.resendingIsAvailable")
              : t("auth.resendingCodeWillBeAvailableIn") +
                ` ${Math.floor(counter / 60)}:${(counter % 60)
                  .toString()
                  .padStart(2, "0")}`}
          </h6>
          <button
            className={cx("ForgetPasswordVerifyOtpResendButton", {
              isDisabled: !otpSendingAvailable || formData.otpSendCount === 0,
            })}
            disabled={!otpSendingAvailable || formData.otpSendCount === 0}
            onClick={handleResendCode}
          >
            {t("buttons.resend")}
          </button>
        </div>
        <PrimaryButton
          onClick={handleSubmit(handleOnSubmit)}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_P}
          text={t("buttons.continue")}
          isLoading={executeCreateOtpRequestForResetLoading}
        />
      </form>
    </div>
  );
};
ForgotPasswordVerifyOtp.propTypes = {
  setFormData: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    otpCode: PropTypes.string.isRequired,
    otpSendCount: PropTypes.number.isRequired,
    otpToken: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
  }).isRequired,
  setActiveStep: PropTypes.func.isRequired,
  selectedMethod: PropTypes.oneOf([SIGN_IN_METHOD.email, SIGN_IN_METHOD.email])
    .isRequired,
  counter: PropTypes.number.isRequired,
  setCounter: PropTypes.func.isRequired,
};

export default ForgotPasswordVerifyOtp;

import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import BackButton, {
  ENUMS as BACK_BUTTON_ENUMS,
} from "components/buttons/back-button/BackButton";
import GuestProfileWithIcon from "components/elements/guest-profile-with-icon/GuestProfileWithIcon";
import { ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import { ReactComponent as LogOutIcon } from "assets/icons/admin-dashboard/LogOut.svg";
import useGuestLogout from "utils/hooks/useGuestLogout";
import PhoneNumberFormatter from "components/elements/formatted-phone-number/FormattedPhoneNumber";
import useOutsideClick from "utils/hooks/useOutsideClick";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import useTransferOrderSourceToTarget from "utils/hooks/useTransferOrderSourceToTarget";

import "./MyProfile.scss";

const MyProfile = () => {
  const { logoutGuest } = useGuestLogout();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );
  const guest = useSelector((state) => state[STORE_NAMES.guest]);
  const [
    openSlideConfirmLogOutGuest,
    setOpenSlideConfirmLogOutGuest,
    mainElementRefConfirmLogOutGuest,
  ] = useOutsideClick();

  const { transferOrderSourceToTarget } = useTransferOrderSourceToTarget();

  const handleGoMenu = () => {
    navigate(
      businessId
        ? `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
        : `${ROUTE_NAME.client}${ROUTE_NAME.allBusinesses}`
    );
  };

  const handleGoEditProfile = () => {
    navigate(`${ROUTE_NAME.client}${ROUTE_NAME.myProfile}${ROUTE_NAME.edit}`);
  };

  const handleLogOutGuest = async () => {
    setOpenSlideConfirmLogOutGuest(true);
  };
  const handleOnCancelLogOutGuest = (e) => {
    e.stopPropagation();
    setOpenSlideConfirmLogOutGuest(false);
  };
  const handleOnConfirmLogOutGuest = async (e) => {
    e.stopPropagation();
    const authenticatedGuestId = guest.id;
    const response = await logoutGuest();

    await transferOrderSourceToTarget({
      sourceId: authenticatedGuestId,
      targetId: response.payload.id,
    });

    navigate(
      businessId
        ? `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
        : `${ROUTE_NAME.client}${ROUTE_NAME.allBusinesses}`
    );
  };

  return (
    <div className="MyProfile">
      <div className="MyProfileHeader">
        <BackButton
          onClick={handleGoMenu}
          type={BACK_BUTTON_ENUMS.types.TYPE_D}
        />
        <h2 className="SemiBold">{t("myProfile.title")}</h2>
      </div>
      <div className="MyProfileInfo">
        <GuestProfileWithIcon
          image={guest.profilePic}
          hasImage={!!guest.profilePic}
          className="MyProfileGuestImageContainer"
        />
        <div className="MyProfileInfoDetails">
          <h4 className="SemiBold"> {guest.firstName ? `${guest.firstName} ${guest.lastName || ""}` : ""}</h4>
          {guest.phoneNumber && (
            <PhoneNumberFormatter
              phoneNumber={guest.phoneNumber}
              className="MyProfileGuestPhoneNumber"
            />
          )}
          {guest.email && <h5>{guest.email}</h5>}
        </div>
        <PrimaryButton
          onClick={handleGoEditProfile}
          text={t("myProfile.editProfile")}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_M}
          className="MyProfileEditButton"
        />
      </div>
      <div className="MyProfileContent">
        <div className="MyProfileContentSection" onClick={handleLogOutGuest}>
          <LogOutIcon />
          <h6 className="Medium">{t("buttons.logOut")}</h6>
        </div>
      </div>
      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_A}
        title={t("modal.confirmModalTitleLogOut")}
        description={t("modal.confirmModalDescriptionLogOut")}
        mainElementRefConfirm={mainElementRefConfirmLogOutGuest}
        onCancel={(e) => handleOnCancelLogOutGuest(e)}
        onConfirm={(e) => handleOnConfirmLogOutGuest(e)}
        openSlide={openSlideConfirmLogOutGuest}
      />
    </div>
  );
};

export default MyProfile;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUserInfoByToken } from "redux/actions/userAction";

const useAuthCheck = () => {
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    dispatch(getUserInfoByToken()).then(() => setIsAuthenticated(true));
  }, []);

  return isAuthenticated;
};

export default useAuthCheck;

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import TimerWithColon from "components/admin/elements/timer-with-colon/TimerWithColon";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import { STORE_NAMES } from "utils/constants/redux";
import { createDOBucketName } from "utils/DO-Spaces";
import {
  updateOrderAssigneeAsync,
  updateOrderTableAsync,
} from "redux/actions/orderActions";
import { handleOnAsyncError, handleOnAsyncSuccess } from "utils/helpers";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import { setOrReplaceSelectedOrder } from "redux/slices/ordersStore";

import "./AdminOrderGeneralInfo.scss";

const AdminOrderGeneralInfo = ({ formData, setPendingData, pendingData }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const guestsCount = formData.guests.length;
  const { orders } = useSelector((state) => state[STORE_NAMES.orders]);
  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);
  const tableDropdownOptions =
    zones?.map((zone) => ({
      name: zone.name,
      subOptions: zone.tables.map((table) => {
        const isTableFull = orders?.some(
          (order) => order.table.id === table.id
        );
        const isTableSelected = table.id === formData?.table?.id;
        return {
          ...table,
          name:
            table.name +
            (isTableSelected
              ? ` (${t("table.current")})`
              : isTableFull
              ? ` (${t("table.full")})`
              : ""),
          isDisabled: isTableFull,
        };
      }),
    })) || [];
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );
  const assigneeDropdownOptions = useSelector(
    (state) => state[STORE_NAMES.business]?.users
  );

  const tableId = formData?.table?.id;
  const qrLink =
    zones
      ?.flatMap((zone) => zone.tables ?? [])
      .find((table) => table.id === tableId)
      ?.qrLinks.find((link) => link.type === "URL") ?? null;

  const handleOnAsyncErrorForOrder = (errorMessage) => {
    handleOnAsyncError(errorMessage || t(commonAsyncErrorMessage));
  };

  const handleOnUpdateOrderTable = async (order) => {
    const response = await dispatch(
      updateOrderTableAsync({ businessId, order, id: formData.id })
    );
    if (response.error) {
      handleOnAsyncErrorForOrder();
    } else {
      handleOnAsyncSuccess(t("toastMessages.success.updateOrder"), async () => {
        await dispatch(setOrReplaceSelectedOrder([response.payload]));
        setPendingData({
          ...pendingData,
          table: response.payload.table,
        });
      });
    }
  };
  const handleOnUpdateOrderAssignee = async (order) => {
    const response = await dispatch(
      updateOrderAssigneeAsync({ businessId, order, id: formData.id })
    );
    if (response.error) {
      handleOnAsyncErrorForOrder();
    } else {
      handleOnAsyncSuccess(t("toastMessages.success.updateOrder"), async () => {
        await dispatch(setOrReplaceSelectedOrder([response.payload]));
        setPendingData({
          ...pendingData,
          assignee: response.payload.assignee,
        });
      });
    }
  };

  return (
    <div className="AdminOrderGeneralInfo">
      <div className="AdminOrderGeneralInfoListsAndQR">
        <div className="AdminOrderGeneralInfoLists">
          <h4 className="Medium">{`${t("basket.order.order")} # ${
            formData.id
          }`}</h4>
          <h4 className="Medium">
            {t("dashboard.guest.guest")}: <span>{guestsCount}</span>
          </h4>
          <h4 className="Medium">
            {t("common.time.since")}:{" "}
            <span>
              <TimerWithColon
                startTime={formData.createDate}
                twoDigitsEnabled
              />
            </span>
          </h4>
        </div>
        {qrLink?.image && (
          <div className="AdminOrderGeneralInfoQR">
            <img src={createDOBucketName(qrLink?.image)} alt="QR" />
          </div>
        )}
      </div>
      <div className="AdminOrderGeneralInfoTableAndAssignee">
        <Dropdown
          onChange={(table) => handleOnUpdateOrderTable({ tableId: table.id })}
          value={formData.table}
          options={tableDropdownOptions}
          isGrouped
          placeholder={t("table.table")}
          className={"AdminOrderGeneralInfoDropdown"}
        />
        {assigneeDropdownOptions && (
          <Dropdown
            onChange={(assignee) =>
              handleOnUpdateOrderAssignee({ assigneeId: assignee.id })
            }
            value={formData.assignee}
            options={assigneeDropdownOptions}
            placeholder={t("waiter.waiter")}
            className={"AdminOrderGeneralInfoDropdown"}
          />
        )}
      </div>
    </div>
  );
};

AdminOrderGeneralInfo.propTypes = {
  /**
   * The form data for the order
   */
  formData: PropTypes.shape({
    id: PropTypes.number,
    guests: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    priceSummary: PropTypes.shape({
      promo: PropTypes.string,
    }),
    createDate: PropTypes.string.isRequired,
    table: PropTypes.object,
    assignee: PropTypes.object,
  }).isRequired,

  setPendingData: PropTypes.func,
  pendingData: PropTypes.object,
};

export default AdminOrderGeneralInfo;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getUsers } from "redux/actions/userAction";
import { STORE_NAMES } from "utils/constants/redux";
import Table from "components/admin/elements/table/Table";
import useAsync from "utils/hooks/useAsync";
import useCountdown from "utils/hooks/useCountdown";
import AddNewButton from "components/admin/buttons/add-new-button/AddNewButton";
import useOutsideClick from "utils/hooks/useOutsideClick";
import AddEmployeeModal from "./admin-user-modal/AddEmployeeModal";
import PhoneNumberFormatter from "components/elements/formatted-phone-number/FormattedPhoneNumber";
import AdminAddUserModalBody from "./admin-user-modal/admin-user-modal-body/AdminAddUserModalBody";
import AdminOtpVerifyModalBody from "./admin-user-modal/admin-user-modal-body/AdminOtpVerifyModalBody";
import AdminLoginMethodModalBody from "./admin-user-modal/admin-user-modal-body/AdminLoginMethodModalBody";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import AdminAddUserWithoutSignUpBody from "./admin-user-modal/admin-user-modal-body/AdminAddUserWithoutSignUpBody";
import AdminEditOrDeleteUserModalBody from "./admin-user-modal/admin-user-modal-body/AdminEditOrDeleteUserModalBody";
import AdminResetPasswordModalBody from "./admin-user-modal/admin-user-modal-body/AdminResetPasswordModalBody";
import { deleteUser } from "utils/api/services/user";
import {
  convertSnakeCaseToTitleCase,
  handleOnAsyncSuccess,
} from "utils/helpers";

import "./AdminUser.scss";

export const STEP_ENUMS = {
  LOGIN_METHOD: "LOGIN_METHOD",
  OTP_VERIFICATION: "OTP_VERIFICATION",
  ADD_USER_SIGN_UP: "ADD_USER_SIGN_UP",
  ADD_USER_WITHOUT_SIGN_UP: "ADD_USER_WITHOUT_SIGN_UP",
  EDIT_USER: "EDIT_USER",
  RESET_PASSWORD: "RESET_PASSWORD",
};

export const USER_MODAL_ENUMS = {
  phoneNumber: "phoneNumber",
  email: "email",
  role: "role",
  firstName: "firstName",
  lastName: "lastName",
  pinCode: "pinCode",
  criteriaModeAll: "all",
  manual: "manual",
  otpCode: "otpCode",
  otpToken: "otpToken",
  otpSendCount: "otpSendCount",
};

const initialFormData = {
  email: "",
  phoneNumber: "",
  role: {},
  firstName: "",
  lastName: "",
  pinCode: "",
  otpToken: "",
  otpSendCount: 0,
  userId: "",
};

const AdminUser = () => {
  const dispatch = useDispatch();
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business].business?.id
  );
  const { showFallbackError } = useAPIErrorStatusCodeHelper();
  const [counter, setCounter] = useCountdown(0);
  const { users } = useSelector((state) => state[STORE_NAMES.business]);
  const { user, userWithPin } = useSelector((state) => state[STORE_NAMES.user]);
  const [modalStep, setModalStep] = useState(STEP_ENUMS.LOGIN_METHOD);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUserExist, setIsUserExist] = useState(false);
  const [signInMethod, setSignInMethod] = useState(
    USER_MODAL_ENUMS.phoneNumber
  );
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    dispatch(getUsers({ businessId }));
  }, []);

  const handleOnAsyncSuccessForUser = (successMessage) => {
    handleOnAsyncSuccess(successMessage, () => {
      dispatch(getUsers({ businessId }));
      setOpenSlide(false);
    });
  };

  const handleOnAsyncErrorForUser = (error) => {
    showFallbackError(error.data);
  };

  const { execute: executeDeleteUser } = useAsync(deleteUser, {
    onError: (error) => handleOnAsyncErrorForUser(error.response),
    onSuccess: () => {
      handleOnAsyncSuccessForUser(t("toastMessages.success.deleteUser"));
    },
  });
  const [openSlide, setOpenSlide, mainElementRef, , , setOutsideClickAction] =
    useOutsideClick();

  const { t } = useTranslation();

  const handleClickBackButton = () => {
    setModalStep(STEP_ENUMS.LOGIN_METHOD);
    setSignInMethod(USER_MODAL_ENUMS.phoneNumber);
  };

  const handleOnAddUser = () => {
    setOpenSlide(!openSlide);
    setModalStep(STEP_ENUMS.LOGIN_METHOD);
  };

  const handleOnEditUser = (id) => {
    setOpenSlide(!openSlide);
    setModalStep(STEP_ENUMS.EDIT_USER);
    setSelectedUser(users.find((user) => user.id === id));
  };

  const handleOnDeleteUser = async (id) => {
    if (id === user.id || id === userWithPin?.id) {
      return toast.error(t("user.userDeleteError"));
    }
    await executeDeleteUser(businessId, id);
  };

  const renderBody = () => {
    if (modalStep === STEP_ENUMS.LOGIN_METHOD) {
      return (
        <AdminLoginMethodModalBody
          setModalStep={setModalStep}
          signInMethod={signInMethod}
          setSignInMethod={setSignInMethod}
          formData={formData}
          setFormData={setFormData}
          setOpenSlide={setOpenSlide}
          initialFormData={initialFormData}
          setOutsideClickAction={setOutsideClickAction}
          setCounter={setCounter}
          modalRef={mainElementRef}
        />
      );
    } else if (modalStep === STEP_ENUMS.OTP_VERIFICATION) {
      return (
        <AdminOtpVerifyModalBody
          counter={counter}
          formData={formData}
          handleClickBackButton={handleClickBackButton}
          setModalStep={setModalStep}
          setIsUserExist={setIsUserExist}
          setFormData={setFormData}
          setCounter={setCounter}
          signInMethod={signInMethod}
          setSignInMethod={setSignInMethod}
          setOpenSlide={setOpenSlide}
          initialFormData={initialFormData}
          setOutsideClickAction={setOutsideClickAction}
        />
      );
    } else if (modalStep === STEP_ENUMS.ADD_USER_SIGN_UP) {
      return (
        <AdminAddUserModalBody
          signInMethod={signInMethod}
          handleClickBackButton={handleClickBackButton}
          formData={formData}
          isUserExist={isUserExist}
          setSignInMethod={setSignInMethod}
          setOpenSlide={setOpenSlide}
          setOutsideClickAction={setOutsideClickAction}
          initialFormData={initialFormData}
          setFormData={setFormData}
        />
      );
    } else if (modalStep === STEP_ENUMS.ADD_USER_WITHOUT_SIGN_UP) {
      return (
        <AdminAddUserWithoutSignUpBody
          modalStep={modalStep}
          handleClickBackButton={handleClickBackButton}
          setSignInMethod={setSignInMethod}
          setFormData={setFormData}
          formData={formData}
          setOpenSlide={setOpenSlide}
          initialFormData={initialFormData}
          setOutsideClickAction={setOutsideClickAction}
          handleOnAsyncSuccess={handleOnAsyncSuccessForUser}
        />
      );
    } else if (modalStep === STEP_ENUMS.EDIT_USER) {
      return (
        <AdminEditOrDeleteUserModalBody
          selectedUser={selectedUser}
          setOpenSlide={setOpenSlide}
          setFormData={setFormData}
          handleOnDeleteUser={handleOnDeleteUser}
          setSignInMethod={setSignInMethod}
          initialFormData={initialFormData}
          setOutsideClickAction={setOutsideClickAction}
          setModalStep={setModalStep}
        />
      );
    } else if (modalStep === STEP_ENUMS.RESET_PASSWORD) {
      return (
        <AdminResetPasswordModalBody
          setOutsideClickAction={setOutsideClickAction}
          selectedUser={selectedUser}
          setOpenSlide={setOpenSlide}
          setModalStep={setModalStep}
        />
      );
    }
  };

  const tableColumns = [
    {
      key: "name",
      name: t("inputs.name"),
      isFilterable: false,
    },
    {
      key: "contact",
      name: t("inputs.contact"),
      isFilterable: false,
    },
    // {
    //   key: "email",
    //   name: t("inputs.email"),
    //   isFilterable: false,
    // },
    {
      key: "role",
      name: t("inputs.role"),
      isFilterable: false,
    },
    {
      key: "pinCode",
      name: t("inputs.pinCode"),
      isFilterable: false,
    },
  ];

  const tableItems = [...(users || [])]
    .sort((a, b) => new Date(b.createDate) - new Date(a.createDate))
    .map((user) => ({
      id: user.id,
      name: `${user.firstName} ${user.lastName}`,
      contact:
        (user.phoneNumber && (
          <PhoneNumberFormatter
            phoneNumber={user.phoneNumber}
            className="h7 tableColor Medium"
          />
        )) ||
        user.email,
      // email: user.email,
      role: user.roles
        .map((role) => convertSnakeCaseToTitleCase(role?.name))
        .join(", "),
      pinCode: user.pinCode.userPinCode,
    }));

  return (
    <div className="AdminUser">
      <h2 className="SemiBold AdminUserTitleText">
        {t("navbarRoutes.pageTitles.users")}
      </h2>
      <div className="AdminUserTitle">
        <div className="AdminUserTitleLeft">
          <h4 className="SemiBold">
            {`${t("user.employee")} (${tableItems.length})`}
          </h4>
        </div>
        <AddNewButton onClick={handleOnAddUser} label={t("buttons.addNew")} />
      </div>
      <Table
        columns={tableColumns}
        items={tableItems}
        hasEditColumnTitle
        onEdit={(id) => handleOnEditUser(id)}
        classname="AdminUserTable"
      />
      <AddEmployeeModal
        openSlide={openSlide}
        body={renderBody()}
        mainElementRef={mainElementRef}
      />
    </div>
  );
};

export default AdminUser;

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { STORE_NAMES } from "utils/constants/redux";
import { QUERY_PARAMS } from "utils/constants/routes";
import { resetReduxStoresForGuest } from "utils/general";
import {
  createGuestAsync,
  getGuestByTokenAsync,
} from "redux/actions/guestAction";
import { LOCAL_STORAGE_VARIABLES } from "utils/constants/global";
import useTransferOrderSourceToTarget from "./useTransferOrderSourceToTarget";

const useCreateOrGetGuest = () => {
  const [searchParams] = useSearchParams();
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const qrId = searchParams.get(QUERY_PARAMS.qrId);
  const dispatch = useDispatch();
  const [isStoreReset, setIsStoreReset] = useState(false);
  const { transferOrderSourceToTarget } = useTransferOrderSourceToTarget();

  useEffect(() => {
    resetReduxStoresForGuest(dispatch, qrId).finally(async () => {
      const guestAccessToken = localStorage.getItem(
        LOCAL_STORAGE_VARIABLES.accessTokenForGuest
      );
      const anonymousGuestId = guestId;
      if (guestAccessToken) {
        const response = await dispatch(getGuestByTokenAsync());
        if (anonymousGuestId !== response.payload.id) {
          await transferOrderSourceToTarget({
            sourceId: anonymousGuestId,
            targetId: response.payload.id,
          });
        }
      } else if (!guestId) {
        await dispatch(createGuestAsync());
      }
      await setIsStoreReset(true);
    });
  }, []);

  return { isStoreReset };
};
export default useCreateOrGetGuest;

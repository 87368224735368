import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css/effect-fade";
import "swiper/css";
import cx from "classnames";
import {
  updateBasket,
  updateFavorites,
  updateOrderItemByIndex,
} from "redux/slices/basketStore";
import {
  calculateMenuItemPrice,
  findItemById,
  calculateMenuItemPriceBySchedule,
} from "utils/general";
import MenuItemHeader from "components/elements/menu-item-header/MenuItemHeader";
import MenuItemInfo from "components/elements/menu-item-info/MenuItemInfo";
import CTAButton, {
  ENUMS as CTA_ENUMS,
} from "components/buttons/cta-button/CTAButton";
import ModificationsMenu from "components/elements/modifications-menu/ModificationsMenu";
import {
  findGuest,
  findMenuItemByIdAndPublished,
  getBasketModificationsWithDefaultValues,
} from "utils/helpers";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import useLanguage from "utils/hooks/useLanguage";
import { createDOBucketName } from "utils/DO-Spaces";
import AnimatedPlusMinusButton from "components/buttons/animated-plus-minus-button/AnimatedPlusMinusButton";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import WelcomeClient from "components/welcome-client/WelcomeClient";
import AddToBasketSound from "assets/audios/AddToBasketSound.mp3";

import "./MenuItemSelected.scss";

const MenuItemSelected = ({
  menuItemId,
  setSelectedMenuItemId,
  setSearchParams,
  menuItemSelectedRef,
}) => {
  const { displayDataByLanguage } = useLanguage();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { businessId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const basketIndex = queryParams.get("basketIndex");
  const isEditMode = queryParams.get("isEditMode");
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);

  const userId = useSelector((state) => state[STORE_NAMES.guest].id);
  const basketOrders = useSelector((state) => state[STORE_NAMES.basket].order);
  const guest = findGuest(userId, basketOrders);

  const favoriteItems = guest?.favoriteItems || [];

  const orderItems = guest?.orderItems || [];

  const menu = useSelector((state) => state[STORE_NAMES.menu].data);
  const isLoading = useSelector((state) => state[STORE_NAMES.menu].isLoading);
  const item =
    menu &&
    (basketIndex
      ? findMenuItemByIdAndPublished(
          menu.categories,
          orderItems[basketIndex].item,
          dispatch,
          guest.id
        )
      : findItemById(menuItemId, menu));

  const menuItem = {
    ...item,
    coverImageSrc: createDOBucketName(item.coverImageSrc),
    otherImagesSrc: item.otherImagesSrc?.map((otherImageSrc) =>
      createDOBucketName(otherImageSrc)
    ),
  };
  const allModifications = menu && findItemById(menuItemId, menu).modifications;

  const navigate = useNavigate();
  const [count, setCount] = useState(
    basketIndex ? orderItems[basketIndex].count : 1
  );
  const discountPrice = calculateMenuItemPriceBySchedule(menuItem);
  const menuItemPrice = menuItem.priceSell;

  const [selectedModifications, setSelectedModifications] = useState([]);
  const [finalPrice, setFinalPrice] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const [dynamicClass, setDynamicClass] = useState("");
  const audioRef = useRef(null);

  const [focusedModificationOption, setFocusedModificationOption] =
    useState(null);
  useEffect(() => {
    if (menu) {
      const defaultModifications = basketIndex
        ? menuItem.modifications
        : getBasketModificationsWithDefaultValues(menuItem.modifications);
      setSelectedModifications(defaultModifications);
    }
  }, [menu]);

  useEffect(() => {
    if (menu) {
      setFinalPrice(
        calculateMenuItemPrice({
          ...menuItem,
          priceSell: menuItemPrice + discountPrice,
          modifications: selectedModifications,
        })
      );
    }
  }, [selectedModifications, menu]);

  useEffect(() => {
    const header = document.querySelector(".MenuItemSelected");
    const headerMenuItem = document.querySelector(
      ".MenuItemHeaderContainerDefault"
    );
    const coverImage = document.querySelector(".MenuItemCoverImage");

    const headerHeight = headerMenuItem ? headerMenuItem.offsetHeight : 0;

    if (coverImage && headerHeight) {
      coverImage.style.marginTop = `-${headerHeight}px`;
      setDynamicClass(`MenuItemCoverImageWithMargin`);
    }

    const handleScroll = () => {
      if (header) {
        const scrollTop = header.scrollTop;
        setIsScrolled(scrollTop > headerHeight / 2);
      }
    };

    if (header) {
      header.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (header) {
        header.removeEventListener("scroll", handleScroll);
      }
    };
  }, [menuItemId]);

  useEffect(() => {
    document.body.classList.add("OverflowHidden");
    return () => document.body.classList.remove("OverflowHidden");
  }, []);

  if (isLoading) {
    return <WelcomeClient />;
  }

  if (!menuItem.isPublished) {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
    );
  }
  const handleOnFavorite = () => {
    dispatch(
      updateFavorites({
        userId,
        menuItemID: menuItem.id,
      })
    );
  };

  const handleOnCloseMenuItem = () => {
    setIsAnimatingOut(true);
    setTimeout(() => {
      setSearchParams((prev) => prev.delete(QUERY_PARAMS.selectedItem));
      setSelectedMenuItemId(null);
    }, 500);
  };
  const handleOnMinus = () => {
    count > 1 && setCount((prev) => --prev);
  };

  const handleOnPlus = () => {
    setCount((prev) => ++prev);
  };

  const handleAddToBasket = () => {
    audioRef.current = new Audio(AddToBasketSound);
    audioRef.current.currentTime = 0;
    audioRef.current.play().catch((error) => {
      console.error("Audio playback failed: ", error);
    });
    const filteredModifications = selectedModifications.filter(
      (modification) => {
        return modification.options.length !== 0;
      }
    );
    dispatch(
      updateBasket({
        menuItem: {
          id: menuItem.id,
          modifications: filteredModifications.map((modification) => {
            return {
              id: modification.id,
              options: modification.options.map((option) => {
                return {
                  id: option.id,
                  count: option.count ?? 1,
                };
              }),
            };
          }),
        },
        count: count,
        userId,
      })
    );
    setSearchParams((prev) => prev.delete(QUERY_PARAMS.selectedItem));
    handleOnCloseMenuItem();
    // setSelectedMenuItemId(null);
  };

  const handleSaveChanges = () => {
    dispatch(
      updateOrderItemByIndex({
        menuItem: {
          ...menuItem,
          modifications: selectedModifications,
        },
        count: count,
        userId,
        basketIndex: parseInt(basketIndex),
      })
    );
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.basket}`
    );
  };

  const menuItemName = displayDataByLanguage(menuItem.name);
  const headerClassName = isScrolled
    ? "MenuItemHeaderContainerSliderCatch"
    : undefined;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuItemSelectedRef.current &&
        !menuItemSelectedRef.current.contains(event.target)
      ) {
        handleOnCloseMenuItem();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={cx("MenuItemSelectedContainerOverlay", {
        slideDownOverlay: isAnimatingOut,
      })}
    >
      <div
        className={cx("MenuItemSelected", { hide: isAnimatingOut })}
        ref={menuItemSelectedRef}
      >
        <MenuItemHeader
          onFavorite={handleOnFavorite}
          itemId={menuItem.id}
          onClose={handleOnCloseMenuItem}
          favoriteItems={favoriteItems}
          type={"TYPE_B"}
          classNameDefault={"MenuItemHeaderContainerDefault"}
          classNameScroll={headerClassName}
        />

        {menuItem.otherImagesSrc?.length > 1 ? (
          <Swiper
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={true}
            effect={"fade"}
            modules={[EffectFade, Autoplay]}
            className="MenuItemSlider"
            style={{ marginTop: "-80px" }}
          >
            {menuItem.otherImagesSrc.map((imageSrc, index) => (
              <SwiperSlide key={index}>
                <img
                  className="MenuItemCarouselImage"
                  src={imageSrc}
                  alt={menuItemName}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : menuItem.coverImageSrc ? (
          <div>
            <ImageWithPlaceholder
              imageSource={menuItem.coverImageSrc}
              placeholder={IMAGE_ITEM_PLACEHOLDER}
              alt={String(menuItem.id)}
              className={`MenuItemCoverImage ${dynamicClass}`}
            />
          </div>
        ) : (
          <> </>
          // <div className="MenuItemCoverPlaceHolderEmptyArea"></div>
        )}

        <div className="MenuItemSelectedBottomContainer">
          <div className="MenuItemInfoContainer">
            <h2 className="SemiBold">{menuItemName}</h2>
            <MenuItemInfo
              onMinus={handleOnMinus}
              onPlus={handleOnPlus}
              menuItem={menuItem}
              count={count}
              discountPrice={discountPrice}
              menuItemPrice={menuItemPrice}
              disableMinusButtonAtOne
            />
          </div>
          {allModifications.length > 0 && (
            <div className="MenuItemModifications">
              {allModifications.map((modification) => (
                <ModificationsMenu
                  key={modification.id}
                  data={modification}
                  selectedOptions={selectedModifications.find(
                    (options) => options.id === modification.id
                  )}
                  setSelectedModifications={setSelectedModifications}
                  focusedModificationOption={focusedModificationOption}
                  setFocusedModificationOption={setFocusedModificationOption}
                />
              ))}
            </div>
          )}
          <div className="MenuItemFooter">
            <AnimatedPlusMinusButton
              onMinusClick={handleOnMinus}
              onPlusClick={handleOnPlus}
              hasBorder
              count={count}
              doAnimate
              disableMinusButtonAtOne
            />
            {isEditMode ? (
              <CTAButton
                className="SaveChanges"
                onClick={handleSaveChanges}
                name={t("buttons.saveChanges")}
                type={CTA_ENUMS.types.TYPE_N}
                price={count * finalPrice}
              />
            ) : (
              <CTAButton
                className="AddToBasketBtn"
                onClick={handleAddToBasket}
                name={t("buttons.addBasket")}
                type={CTA_ENUMS.types.TYPE_N}
                price={count * finalPrice}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

MenuItemSelected.propTypes = {
  menuItemId: PropTypes.string.isRequired,
  setSelectedMenuItemId: PropTypes.func,
  setSearchParams: PropTypes.func,
  menuItemSelectedRef: PropTypes.object,
};

export default MenuItemSelected;

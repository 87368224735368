import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useValidationSchema } from "utils/hooks/useValidationSchema";
import useCountdown from "utils/hooks/useCountdown";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, FormProvider } from "react-hook-form";
import { resetAllReduxStores } from "utils/general";
import Login from "components/admin/elements/login/Login";
import EmailOrPhone from "components/elements/email-or-phone/EmailOrPhone";
import { ROUTE_NAME } from "utils/constants/routes";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import { SIGN_IN_METHOD } from "pages/common/login/sign-in/SignIn";
import useAsync from "utils/hooks/useAsync";
import { createOtpRequestForUserReset } from "utils/api/services/otp";
import ConfirmOtpForForgotUserPassword from "./confirm-otp/ConfirmOtpForForgotUserPassword";
import SetNewPassword from "./new-password/SetNewPassword";

import "./ForgotPassword.scss";

export const ACTIVE_WINDOW = {
  SEND_OTP: "SEND_OTP",
  VERIFY_OTP: "VERIFY_OTP",
  SET_NEW_PASSWORD: "SET_NEW_PASSWORD",
};

const ForgotPassword = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();

  const [activeWindow, setActiveWindow] = useState(ACTIVE_WINDOW.SEND_OTP);
  const [counter, setCounter] = useCountdown(0);
  const [signInMethod, setSignInMethod] = useState(SIGN_IN_METHOD.phoneNumber);
  const [otpData, setOtpData] = useState({
    otpCode: "",
    otpToken: "",
    otpSendCount: 3,
    email: "",
    phoneNumber: "",
    userId: null,
  });

  const { execute: executeCreateOtpRequest, loading: isSignUpLoading } =
    useAsync(createOtpRequestForUserReset, {
      onError: (error) => {
        const errorData = handleAPIErrorMessage(error.response.data);
        if (errorData) {
          const { field, errorMessage } = errorData;
          setError(field, {
            type: "manual",
            message: errorMessage,
          });
        }
      },
      onSuccess: ({ data }) => {
        setOtpData((prev) => ({
          ...prev,
          otpToken: data.otpToken,
          otpSendCount: data.otpSendCount,
        }));
        setCounter(60);
        setActiveWindow(ACTIVE_WINDOW.VERIFY_OTP);
      },
    });

  const schemas = useValidationSchema(t);

  const dispatch = useDispatch();
  const { forgotPasswordForUserSchema } = schemas;
  const methods = useForm({
    resolver: zodResolver(
      forgotPasswordForUserSchema(signInMethod, activeWindow)
    ),
    criteriaMode: "all",
  });

  const { handleSubmit, setError, getValues } = methods;

  useEffect(() => {
    resetAllReduxStores(dispatch);
  }, [dispatch]);

  const handlePrev = () => {
    switch (activeWindow) {
      case ACTIVE_WINDOW.SEND_OTP:
        navigate(ROUTE_NAME.signIn);
        break;
      case ACTIVE_WINDOW.VERIFY_OTP:
        setActiveWindow(ACTIVE_WINDOW.SEND_OTP);
        setCounter(0);
        break;
      default:
        break;
    }
  };
  const handleOnSubmit = async () => {
    const methodValue = getValues();
    const otpData = {
      [signInMethod]:
        signInMethod === SIGN_IN_METHOD.phoneNumber
          ? methodValue[signInMethod].replace("+", "")
          : methodValue[signInMethod],
    };
    setOtpData((prev) => ({
      ...prev,
      ...otpData,
    }));
    executeCreateOtpRequest(otpData);
  };

  const activeTab = () => {
    if (activeWindow === ACTIVE_WINDOW.SEND_OTP) {
      return (
        <Login
          title={t("login.forgotPassword.title")}
          subTitle={t("login.forgotPassword.subTitle").replace(
            "{{method}}",
            t(`login.forgotPassword.${signInMethod}`)
          )}
          handlePrev={handlePrev}
          isPrevButtonVisible
          signInMethod={signInMethod}
        >
          <form
            className="ForgotPasswordForm"
            onSubmit={(e) => e.preventDefault()}
          >
            <FormProvider {...methods}>
              <EmailOrPhone
                signInMethod={signInMethod}
                setSignInMethod={setSignInMethod}
                className="ForgotPasswordFormInput"
              />
            </FormProvider>
            <PrimaryButton
              onClick={handleSubmit(handleOnSubmit)}
              text={t("login.forgotPassword.sendPassword")}
              type={PRIMARY_BUTTON_ENUMS.types.TYPE_A}
              className="ForgotPasswordFormButton"
              isLoading={isSignUpLoading}
            />
          </form>
        </Login>
      );
    } else if (activeWindow === ACTIVE_WINDOW.VERIFY_OTP) {
      return (
        <Login
          title={""}
          subTitle={""}
          handlePrev={handlePrev}
          isPrevButtonVisible
        >
          <form
            className="VerifyPasswordForm"
            onSubmit={(e) => e.preventDefault()}
          >
            <ConfirmOtpForForgotUserPassword
              handleNext={() => setActiveWindow(ACTIVE_WINDOW.SET_NEW_PASSWORD)}
              signInMethod={signInMethod}
              otpData={otpData}
              setOtpData={setOtpData}
              setCounter={setCounter}
              counter={counter}
            />
          </form>
        </Login>
      );
    } else if (activeWindow === ACTIVE_WINDOW.SET_NEW_PASSWORD) {
      return (
        <Login title={""} subTitle={""} handlePrev={handlePrev}>
          <h1 className="SemiBold LoginTitle">{t("auth.setNewPassword")}</h1>
          <h6 className="SemiBold">{t("auth.setNewPasswordDesc")}</h6>
          <FormProvider {...methods}>
            <SetNewPassword
              otpToken={otpData.otpToken}
              setSignInMethod={setSignInMethod}
              signInMethod={signInMethod}
            />
          </FormProvider>
        </Login>
      );
    }
  };

  return <div className="ForgotPassword">{activeTab()}</div>;
};

export default ForgotPassword;

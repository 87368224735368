import { AXIOS_USER } from "utils/api/axios";
import { API_PATH_PARAMS } from "utils/constants/api";

export const createCategory = async (businessId, menuId, category) =>
  AXIOS_USER.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.menus}/${menuId}/${API_PATH_PARAMS.menuCategories}`,
    category
  );
export const updateCategory = async (businessId, menuId, category, id) =>
  AXIOS_USER.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.menus}/${menuId}/${API_PATH_PARAMS.menuCategories}/${id}`,
    category
  );
export const deleteCategory = async (businessId, menuId, id) =>
  AXIOS_USER.delete(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.menus}/${menuId}/${API_PATH_PARAMS.menuCategories}/${id}`
  );

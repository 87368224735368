import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { PieChart as RechartsPieChartWithNeedle, Pie, Cell } from "recharts";

import "./PieChartWithNeedle.scss";

const RADIAN = Math.PI / 180;

const cx = 35;
const cy = 30;
const iR = 15;
const oR = 30;

const needle = (value, data, cx, cy, iR, oR, color) => {
  let total = 0;
  data.forEach((v) => {
    total += v.value;
  });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 3;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return (
    <>
      <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
      <path
        d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
        stroke="#none"
        fill={color}
      />
    </>
  );
};

const PieChartWithNeedle = ({ data, needleValue }) => {
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentValue((prevValue) =>
        prevValue !== needleValue
          ? prevValue < needleValue
            ? prevValue + 1
            : prevValue - 1
          : prevValue
      );
    }, 15);

    return () => clearInterval(interval);
  }, [needleValue]);

  return (
    <RechartsPieChartWithNeedle width={80} height={39}>
      <Pie
        dataKey="value"
        startAngle={180}
        endAngle={0}
        data={data}
        cx={cx}
        cy={cy}
        innerRadius={iR}
        outerRadius={oR}
        fill="#8884d8"
        stroke="none"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
      {needle(currentValue, data, cx, cy, iR, oR, "#1F1F1F")}
    </RechartsPieChartWithNeedle>
  );
};

PieChartWithNeedle.propTypes = {
  needleValue: PropTypes.number,
  data: PropTypes.array.isRequired,
};

export default PieChartWithNeedle;

import { createAsyncThunk } from "@reduxjs/toolkit";

import * as api from "utils/api/services/user";
import { STORE_NAMES } from "utils/constants/redux";

export const signin = createAsyncThunk(
  `${STORE_NAMES.user}/signin`,
  async ({ userData, method }, { rejectWithValue }) => {
    try {
      const response = await api.signin(userData, method);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const selectBusinessInSignin = createAsyncThunk(
  `${STORE_NAMES.user}/selectBusinessInSignin`,
  async ({ businessId }, { rejectWithValue }) => {
    try {
      const response = await api.selectBusinessInSignin(businessId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resendCode = createAsyncThunk(
  `${STORE_NAMES.user}/resend-code`,
  async ({ userData, method }, { rejectWithValue }) => {
    try {
      const response = await api.resendCode(userData, method);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyUser = createAsyncThunk(
  `${STORE_NAMES.user}/verify-user`,
  async ({ userData, method }, { rejectWithValue }) => {
    try {
      const response = await api.verifyUser(userData, method);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const signupOwnerAndCreateBusiness = createAsyncThunk(
  `${STORE_NAMES.user}/signupOwnerAndCreateBusiness`,
  async ({ userData, otpToken }, { rejectWithValue }) => {
    try {
      const response = await api.signupUserAndCreateBusiness(
        userData,
        otpToken
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createBusinessAndAssignToOwner = createAsyncThunk(
  `${STORE_NAMES.user}/createBusinessAndAssignToOwner`,
  async ({ userData, userId, otpToken }, { rejectWithValue }) => {
    try {
      const response = await api.CreateBusinessAssignToUser(
        userData,
        userId,
        otpToken
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  `${STORE_NAMES.user}/update-user`,
  async ({ businessId, userData, userId }, { rejectWithValue }) => {
    try {
      const response = await api.updateUser({
        businessId,
        userId,
        user: userData,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUsers = createAsyncThunk(
  `${STORE_NAMES.user}/getUsers`,
  async ({ businessId }, { rejectWithValue }) => {
    try {
      const response = await api.getUsers(businessId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserInfoByToken = createAsyncThunk(
  `${STORE_NAMES.user}/getUserInfoByToken`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getUserInfoByToken();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

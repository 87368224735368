export const DO_FOLDER_NAMES = {
  MERCHANT: "MERCHANT",
  INTERNAL: "INTERNAL",
  MENU: "MENU",
  BUSINESS: "BUSINESS",
  GUEST: "GUEST",
};

export const DO_FILE_TYPES = {
  MENU: "MENU_ITEM_IMAGE",
  BUSINESS_LOGO_IMAGE: "BUSINESS_LOGO_IMAGE",
  BUSINESS_BACKGROUND_IMAGE: "BUSINESS_BACKGROUND_IMAGE",
  BUSINESS_OTHER_IMAGES: "BUSINESS_OTHER_IMAGES",
  GUEST_PROFILE_PHOTO: "GUEST_PROFILE_PHOTO",
};

export const IMAGE_FILE = {
  defaults: {
    maxSizeMB: 1,
    maxWidthOrHeight: 1280,
  },
  menuItem: {
    maxSizeMB: 0.8,
    maxWidthOrHeight: 2000,
  },
  businessLogo: {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 600,
  },
  businessBackground: {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
  },
  guestAvatar: {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 600,
  },
};

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { createDOBucketName } from "utils/DO-Spaces";
import { ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import AddItem from "./add-item/AddItem";
import { ReactComponent as CheckIcon } from "assets/icons/check/Select.svg";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import useLanguage from "utils/hooks/useLanguage";
import DeletedIcon from "assets/icons/client-dashboard/trash.svg";
import DeniedIcon from "assets/icons/client-dashboard/close.svg";
import DeleteIcon from "assets/icons/trash/Trash.svg";
import useOutsideClick from "utils/hooks/useOutsideClick";
import DeleteMenuItemsModal from "components/admin/modal/delete-menu-items-modal/DeleteMenuItemsModal";
import { guestUpdateOrderAsync } from "redux/actions/orderActions";

import "./DashboardOrders.scss";

export const ENUMS = {
  name: "DashboardOrders",
};

const DashboardOrders = ({
  guests,
  setSelectedGuests,
  selectedGuests,
  selectedOrders,
  setSelectedOrders,
}) => {
  const orders = guests?.flatMap((guest) => guest.orderItems);
  const { displayDataByLanguage } = useLanguage();
  const dispatch = useDispatch();
  const [
    openSlideDeleteItem,
    setOpenSlideDeleteItem,
    mainElementRefDeleteItem,
  ] = useOutsideClick();
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const tableId = useSelector((state) => state[STORE_NAMES.qrScan].table.id);
  const ordersForGuest = useSelector(
    (state) => state[STORE_NAMES.orders].orders
  );
  const order = ordersForGuest?.find((order) => order.table.id === tableId);

  useEffect(() => {
    for (const guest of guests) {
      if (guest.orderItems.length > 0) {
        const filteredItemsByIsConfirmed = guest.orderItems.filter(
          (orderItem) => orderItem
        );
        const guestAllItemsIsSelected =
          filteredItemsByIsConfirmed.length > 0 &&
          filteredItemsByIsConfirmed.every((orderItem) =>
            selectedOrders.includes(orderItem.id)
          );
        if (
          guestAllItemsIsSelected &&
          !selectedGuests.includes(guest.person.id)
        ) {
          setSelectedGuests((prev) => [...prev, guest.person.id]);
        } else if (
          !guestAllItemsIsSelected &&
          selectedGuests.includes(guest.person.id)
        ) {
          setSelectedGuests((prev) =>
            prev.filter((user) => user !== guest.person.id)
          );
        }
      }
    }
  }, [selectedOrders]);

  const handleAddItemClick = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.menu}`
    );
  };

  const handleUnSelectItems = () => {
    setSelectedOrders([]);
    setSelectedGuests([]);
  };

  const handleSelectOrders = (order) => {
    setSelectedOrders((prev) => {
      if (selectedOrders.includes(order.id)) {
        return prev.filter((el) => el !== order.id);
      } else {
        return [...prev, order.id];
      }
    });
  };

  const sortedOrders = orders.sort((a, b) => {
    if (a.isConfirmed === null && b.isConfirmed !== null) {
      return -1;
    }
    if (a.isConfirmed !== null && b.isConfirmed === null) {
      return 1;
    }
    if (a.isConfirmed === b.isConfirmed) {
      return a.isArchived === b.isArchived ? 0 : a.isArchived ? 1 : -1;
    }
    return a.isConfirmed ? -1 : 1;
  });

  const handleDeleteMenuItem = () => {
    setOpenSlideDeleteItem(true);
  };

  const handleCancelModal = () => {
    setOpenSlideDeleteItem(false);
  };

  const selectedOrderObjects = orders.filter((order) =>
    selectedOrders.includes(order.id)
  );

  const handleConfirmModal = () => {
    const body = guests
      .map((guest) => {
        return {
          ...guest,
          orderItems: guest.orderItems
            .filter((orderItem) =>
              selectedOrderObjects
                .filter((orderItem) => orderItem.isConfirmed === null)
                .flatMap((orderItem) => orderItem.id)
                .includes(orderItem.id)
            )
            .map((orderItem) => ({
              ...orderItem,
              isArchived: true,
              isConfirmed: false,
            })),
        };
      })
      .filter((guest) => guest.orderItems.length > 0);

    const deletedOrderItemsId = body
      .flatMap((guest) => guest.orderItems)
      .flatMap((orderItem) => orderItem.id);

    const anyPendingItems = order.guests
      .flatMap(({ orderItems }) => orderItems)
      .some(
        (orderItem) =>
          orderItem.isConfirmed === null &&
          !deletedOrderItemsId.includes(orderItem.id)
      );

    const updatedOrder = {
      ...order,
      hasNewOrder: anyPendingItems,
    };
    dispatch(
      guestUpdateOrderAsync({
        businessId: business.id,
        order: { ...updatedOrder, guests: body },
        id: updatedOrder.id,
      })
    );

    setSelectedOrders([]);
    setOpenSlideDeleteItem(false);
  };

  const showDeleteButton = selectedOrderObjects.some(
    (item) => item.isConfirmed !== false
  );

  return (
    <div className="DashboardOrdersContainer">
      {orders.length > 0 && (
        <div className="DashboardOrdersHeaders">
          <h6 className="Medium">{t("dashboard.selectToPay")}</h6>

          {showDeleteButton && (
            <div className="DashboardOrdersActionButtons">
              <div
                className="DashboardOrdersDeleteIcon"
                onClick={handleDeleteMenuItem}
              >
                <img src={DeleteIcon} alt="delete" />
              </div>

              <button className="UnSelectBtn" onClick={handleUnSelectItems}>
                <h6 className="Medium">{t("dashboard.unSelect")}</h6>
              </button>
            </div>
          )}
        </div>
      )}

      <div className="DashboardOrders">
        <AddItem handleAddItemClick={handleAddItemClick} />
        {sortedOrders.map((order, index) => (
          <div
            key={`order_item_${index}`}
            className={cx("OrderItemCard", "OrderCard", {
              selected:
                selectedOrders.includes(order.id) &&
                order.isConfirmed !== false,
              denied: order.isConfirmed === false && order.isArchived === false,
              deleted: order.isConfirmed === false && order.isArchived === true,
            })}
            onClick={
              order.isConfirmed !== false
                ? () => handleSelectOrders(order)
                : undefined
            }
          >
            <ImageWithPlaceholder
              imageSource={createDOBucketName(order.item.coverImageSrc)}
              placeholder={IMAGE_ITEM_PLACEHOLDER}
              alt={order.item?.[0]?.name}
            />
            <span
              className={`ConfirmInfo ${
                order.isConfirmed === false && order.isArchived === false
                  ? "other"
                  : ""
              }`}
            >
              {t("dashboard.denied")}
            </span>
            {order.isConfirmed === false && order.isArchived === false && (
              <img src={DeniedIcon} alt="denied" className="DeniedIcon" />
            )}
            <span
              className={`ConfirmInfo ${
                order.isConfirmed === false && order.isArchived === true
                  ? "other"
                  : ""
              }`}
            >
              {t("dashboard.deleted")}
            </span>
            {order.isConfirmed === false && order.isArchived === true && (
              <img src={DeletedIcon} alt="deleted" className="DeniedIcon" />
            )}

            {order.isConfirmed !== false && (
              <div
                className={cx("OrderItemStatus", {
                  isWaiting: order.isConfirmed === null,
                  isConfirmed: order.isConfirmed === true,
                })}
              ></div>
            )}
            <div className="OrderItemName">
              <h5 className="h8">
                <span>{order.count}</span>x
              </h5>
              <h6 className="SemiBold h7">
                {displayDataByLanguage(order.item.name)}
              </h6>
            </div>
            {selectedOrders.length > 0 && order.isConfirmed !== false && (
              <div
                className={`OrderItemSelect ${
                  selectedOrders.includes(order.id) ? "isSelected" : ""
                }`}
              >
                {selectedOrders.includes(order.id) && <CheckIcon />}
              </div>
            )}
          </div>
        ))}
      </div>
      <DeleteMenuItemsModal
        openSlide={openSlideDeleteItem}
        orderItems={selectedOrderObjects}
        guestId={guestId}
        onCancel={handleCancelModal}
        onConfirm={handleConfirmModal}
        mainElementRef={mainElementRefDeleteItem}
      />
    </div>
  );
};

DashboardOrders.propTypes = {
  guests: PropTypes.array,
  setSelectedGuests: PropTypes.func,
  setSelectedOrders: PropTypes.func,
  selectedGuests: PropTypes.array,
  selectedOrders: PropTypes.array,
};

export default DashboardOrders;

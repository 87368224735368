import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import ArrowDownIcon from "assets/icons/arrows/BlackArrowDown.svg";
import AnimatedPlusMinusButton from "components/buttons/animated-plus-minus-button/AnimatedPlusMinusButton";
import DeleteButton from "components/buttons/delete-button/DeleteButton";
import {
  calculateMenuItemPriceBySchedule,
  calculateModificationsPrice,
} from "utils/general";
import {
  findMenuCategoryByItemId,
  findMenuItemByIdAndPublished,
} from "utils/helpers";
import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import useLanguage from "utils/hooks/useLanguage";
import { useDispatch } from "react-redux";
import { updateBasket } from "redux/slices/basketStore";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";

import "./BasketItem.scss";

export const ENUMS = {
  name: "BasketItem",
};

const BasketItem = ({
  menuItem: menuItemInBasket,
  basketIndex,
  menuCategories,
  onClick,
  onRemove,
  onAdd,
  onDecrease,
  count,
  guestId,
}) => {
  const dispatch = useDispatch();
  const menuItem = findMenuItemByIdAndPublished(
    menuCategories,
    menuItemInBasket,
    dispatch,
    guestId
  );
  const menuItemFinalPrice =
    menuItem.priceSell + calculateModificationsPrice(menuItem.modifications);

  if (!menuItem) {
    dispatch(
      updateBasket({
        menuItem: {
          ...menuItemInBasket,
        },
        count: null,
        userId: guestId,
      })
    );
    return null;
  }

  const menuCategoryOfItem = findMenuCategoryByItemId(
    menuCategories,
    menuItem.id
  );
  const discountPrice = calculateMenuItemPriceBySchedule(menuItem);

  const { displayDataByLanguage } = useLanguage();
  const hasSelectedModifications =
    menuItem.modifications.flatMap((modification) => modification.options)
      .length > 0;
  const [isProductDetailsShown, setIsProductDetailsShown] = useState(
    hasSelectedModifications
  );

  return (
    <div className={`${isProductDetailsShown ? "isOpened" : ""} BasketItem`}>
      <ImageWithPlaceholder
        imageSource={menuItem.coverImageSrc}
        placeholder={IMAGE_ITEM_PLACEHOLDER}
        alt="order"
        onClick={() => onClick(menuItem.id, basketIndex)}
      />
      <div>
        <div
          className={cx("DetailsWrapper", {
            showMore: isProductDetailsShown,
          })}
        >
          <div
            className="ProductDetail"
            onClick={() => onClick(menuItem.id, basketIndex)}
          >
            <h6 className="Medium ProductDetailTitle">
              {displayDataByLanguage(menuItem.name)}
            </h6>
            <h6 className="h8 ProductDetailOrderType">
              {displayDataByLanguage(menuCategoryOfItem.name)}
            </h6>
            <div className="PriceContainer">
              {discountPrice !== null && (
                <Price
                  type={ENUMS_PRICE.types.PRICE_LINE_THROUGH_XXS}
                  value={menuItemFinalPrice * count}
                />
              )}
              <Price
                type={ENUMS_PRICE.types.PRICE_XS}
                value={
                  discountPrice !== null
                    ? count * menuItemFinalPrice + discountPrice * count
                    : menuItemFinalPrice * count
                }
              />
            </div>
            <AnimatedPlusMinusButton
              count={count}
              onPlusClick={() => onAdd(menuItemInBasket)}
              onMinusClick={() => onDecrease(menuItemInBasket)}
              disableMinusButtonAtOne
              doAnimate
            />
          </div>

          <div className="ShowMoreDetails">
            <button
              onClick={() => setIsProductDetailsShown(!isProductDetailsShown)}
              className={cx("ShowMoreBtn", {
                hasNoSelectedModifications: !hasSelectedModifications,
              })}
              type="button"
            >
              <img
                src={ArrowDownIcon}
                alt="show-more-icon"
                className={cx({ rotateArrow: isProductDetailsShown })}
              />
            </button>
            <DeleteButton onClick={() => onRemove(menuItemInBasket)} />
          </div>
        </div>
        <ul
          className={cx("ProductDetailsList", {
            openList: isProductDetailsShown,
          })}
        >
          {menuItem.modifications &&
            menuItem.modifications.length > 0 &&
            menuItem.modifications.map(
              (modification, index) =>
                modification.options.length > 0 && (
                  <li key={index} >
                    <span className="ProductDetailOneList">
                      {displayDataByLanguage(modification.name)}:{" "}
                      {modification.options
                        .map((option) =>
                          option.count > 1
                            ? `${option.count} x ${displayDataByLanguage(
                                option.name
                              )}`
                            : displayDataByLanguage(option.name)
                        )
                        .join(", ")}
                    </span>
                  </li>
                )
            )}
        </ul>
      </div>
    </div>
  );
};

BasketItem.propTypes = {
  /**
   * The menu item object with its details.
   */
  menuItem: PropTypes.object.isRequired,
  /**
   * The index of the item in the basket.
   */
  basketIndex: PropTypes.number,
  /**
   * An array of menu categories objects.
   */
  menuCategories: PropTypes.array,
  /**
   * Callback function to handle a click event on the BasketItem.
   */
  onClick: PropTypes.func.isRequired,
  /**
   * Callback function to handle removing the item from the basket.
   */
  onRemove: PropTypes.func.isRequired,
  /**
   * Callback function to handle adding one item to the basket.
   */
  onAdd: PropTypes.func.isRequired,
  /**
   * Callback function to handle decreasing one item in the basket.
   */
  onDecrease: PropTypes.func.isRequired,
  /**
   * The count of the item.
   */
  count: PropTypes.number.isRequired,

  guestId: PropTypes.number.isRequired,
};

export default BasketItem;

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import Switch from "components/forms/switch/Switch";
import Dropdown from "components/admin/forms/dropdown/Dropdown";

import "./WorkingHours.scss";

export const ENUMS = {
  name: "WorkingHours",
};

const WorkingHours = ({ workingHours, onSwitch, handleOnChangeTime }) => {
  const workingHoursOptions = [];
  const { t } = useTranslation();

  for (let hours = 0; hours <= 23; hours++) {
    for (let minutes = 0; minutes < 60; minutes += 15) {
      const hourStr = hours.toString().padStart(2, "0");
      const minuteStr = minutes.toString().padStart(2, "0");
      const time = `${hourStr}:${minuteStr}`;
      workingHoursOptions.push({ id: time, name: time });
    }
  }
  const updatedWorkingHours =
    workingHours &&
    Object.keys(workingHours).reduce((updatedHours, day) => {
      const dayData = workingHours[day];
      updatedHours[day] = {
        isWorking: dayData.isWorking,
        from: { id: dayData.from, name: dayData.from },
        to: { id: dayData.to, name: dayData.to },
      };
      return updatedHours;
    }, {});

  return (
    <div className="WorkingHours">
      <div className="WorkingHoursHeader">
        <h6 className="h7 Medium">{t("common.days.days")}</h6>
        <h6 className="h7 Medium">{t("common.time.from")}</h6>
        <h6 className="h7 Medium">{t("common.time.to")}</h6>
      </div>
      <div className="WorkingHoursBody">
        {updatedWorkingHours &&
          Object.keys(updatedWorkingHours).map((day, index) => {
            const dayData = updatedWorkingHours[day];
            return (
              <div
                key={index}
                className={cx("WorkingHoursBodyDay", {
                  isWorking: dayData.isWorking,
                })}
              >
                <div className="WorkingHoursBodyDayIsWorking">
                  <Switch
                    onChange={() => onSwitch(day)}
                    isChecked={dayData.isWorking}
                  />
                  <h6 className="h7 Medium">{t(`common.days.${day}`)}</h6>
                </div>
                <div className="WorkingHoursBodyDayFrom">
                  <Dropdown
                    onChange={(value) => {
                      if (dayData.from.id !== value.id) {
                        handleOnChangeTime(day, value, "from");
                      }
                    }}
                    value={dayData.from}
                    options={workingHoursOptions}
                    className="WorkingHoursDropdown"
                    isBorderless
                  />
                </div>
                <div className="WorkingHoursBodyDayTo">
                  <Dropdown
                    onChange={(value) => {
                      if (dayData.to.id !== value.id) {
                        handleOnChangeTime(day, value, "to");
                      }
                    }}
                    value={dayData.to}
                    options={workingHoursOptions}
                    className="WorkingHoursDropdown"
                    isBorderless
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

WorkingHours.propTypes = {
  /**
   * An object representing working hours for each day of the week
   */
  workingHours: PropTypes.objectOf(
    PropTypes.shape({
      isWorking: PropTypes.bool.isRequired,
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ).isRequired,

  /**
   * Function to handle switching the working hours for a specific day
   */
  onSwitch: PropTypes.func,

  /**
   * Function to handle changing the "from" or "to" time for a specific day
   */
  handleOnChangeTime: PropTypes.func,
};

export default WorkingHours;

import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import Modal from "components/modal/Modal";
import CTAButton from "components/buttons/cta-button/CTAButton";
import Confirm from "components/admin/cards/confirm/Confirm";
import IconButton from "components/buttons/icon-button/IconButton";
import useAsync from "utils/hooks/useAsync";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { deleteTelegramIntegration } from "utils/api/services/integrations/telegram";
import { ReactComponent as QrNameIcon } from "assets/icons/qr/QrNameIcon.svg";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import { ReactComponent as IconArrowLeft } from "assets/icons/arrows/ArrowLeft.svg";
import { STORE_NAMES } from "utils/constants/redux";
import ILOYAL_LOGO from "assets/icons/logo/iLoyal/Logo-qr.png";
import QRCodeStyling from "qr-code-styling";

import "./TelegramIntegrationModal.scss";

export const telegramIntegrationSteps = {
  userSelectStep: "userSelectStep",
  userQrStep: "userQrStep",
};

const TelegramIntegrationModal = ({
  openSlide,
  setOpenSlide,
  mainElementRef,
}) => {
  const { t } = useTranslation();
  const { business } = useSelector((state) => state[STORE_NAMES.business]);
  const businessUsers = useSelector(
    (state) => state[STORE_NAMES.business]?.users
  );

  const [
    openSlideConfirmCloseModal,
    setOpenSlideConfirmCloseModal,
    mainElementRefConfirmCloseModal,
  ] = useOutsideClick();

  const [selectedUserData, setSelectedUserData] = useState(null);
  const [url, setUrl] = useState("");
  const [telegramModalStep, setTelegramModalStep] = useState(
    telegramIntegrationSteps.userSelectStep
  );
  const qrCodeRef = useRef(null);
  const isUserConnect = useMemo(() => {
    if (!selectedUserData || !businessUsers) return false;

    const foundUser = businessUsers.find(
      (user) => user.id === selectedUserData.id
    );

    return (
      foundUser && foundUser.telegramChatId !== selectedUserData.telegramChatId
    );
  }, [selectedUserData, businessUsers]);

  const qrCode = useMemo(
    () =>
      new QRCodeStyling({
        width: 142,
        height: 142,
        data: url,
        qrOptions: { typeNumber: 4, mode: "Byte", errorCorrectionLevel: "Q" },
        imageOptions: { hideBackgroundDots: true, imageSize: 0.6, margin: 0 },
        dotsOptions: { type: "dots", color: "#000000" },
        backgroundOptions: { color: "#ffffff" },
        image: ILOYAL_LOGO,
        cornersSquareOptions: { type: "extra-rounded", color: "#000000" },
        cornersDotOptions: { type: "dot", color: "#000000" },
      }),
    [url]
  );

  useEffect(() => {
    if (
      telegramModalStep === telegramIntegrationSteps.userQrStep &&
      qrCodeRef.current
    ) {
      qrCodeRef.current.innerHTML = "";
      qrCode.append(qrCodeRef.current);
    }
  }, [telegramModalStep, qrCode]);

  useEffect(() => {
    if (isUserConnect || !openSlide) {
      setTelegramModalStep(telegramIntegrationSteps.userSelectStep);
    }
  }, [isUserConnect, openSlide]);

  const {
    execute: executeDeleteTelegramIntegration,
    loading: isDeleteIntegrationLoading,
  } = useAsync(deleteTelegramIntegration, {
    onError: ({ response }) => toast.error(response.data.message),
  });

  const handleConnectButtonClick = (user) => {
    setSelectedUserData(user);
    if (user?.telegramChatId) {
      setOpenSlideConfirmCloseModal(true);
      setUrl("");
    } else {
      setUrl(
        `${process.env.REACT_APP_TELEGRAM_BOT_URL}${process.env.REACT_APP_TELEGRAM_BOT_NAME}?start=b${business?.id}-u${user?.id}`
      );
      setTelegramModalStep(telegramIntegrationSteps.userQrStep);
    }
  };

  const handleOnConfirmDisconnect = async () => {
    setOpenSlideConfirmCloseModal(false);
    await executeDeleteTelegramIntegration(business?.id, selectedUserData?.id);
  };

  const qrModalBody = (
    <div className="QrModalBody">
      <div className="QrCodeWrapper">
        <h6 className="Medium">{t("qr.qrScan")}</h6>
        <div className="QrCode">
          <div ref={qrCodeRef} />
        </div>
        <h6 className="QrCodeDescription Medium ">
          {t("qr.qrScanDescription")}
        </h6>
      </div>
    </div>
  );

  const telegramIntegrationModalHeader = (
    <div className="TelegramIntegrationModalHeader">
      {telegramModalStep === telegramIntegrationSteps.userQrStep && (
        <IconButton
          onClick={() =>
            setTelegramModalStep(telegramIntegrationSteps.userSelectStep)
          }
          svgComponent={<IconArrowLeft />}
          className="ModalHeaderBackIcon"
        />
      )}
      <h3 className="SemiBold">
        {telegramModalStep === telegramIntegrationSteps.userSelectStep
          ? t("navbarRoutes.pageTitles.telegramIntegration")
          : `${t("buttons.link")} ${selectedUserData?.name}`}
      </h3>
      <IconButton
        onClick={() => setOpenSlide(false)}
        svgComponent={<IconClose />}
        className="ModalHeaderCloseIcon"
      />
    </div>
  );

  const telegramIntegrationModalBody = (
    <div className="TelegramIntegrationModalBody">
      <div className="ModalCardWrapper">
        {businessUsers.map((user) => (
          <div className="ModalCardItem" key={user.id}>
            <div className="ModalCardItemContent">
              <div
                className={cx("ModalCardItemIsConnectIcon", {
                  isConnected: user?.telegramChatId,
                })}
              >
                <QrNameIcon />
              </div>
              <h6 className="Medium ModalCardItemTitle">{user.name}</h6>
            </div>
            <div className="ModalCardItemAction">
              <CTAButton
                type="TYPE_A"
                onClick={() => handleConnectButtonClick(user)}
                name={
                  user?.telegramChatId
                    ? t("common.disconnect")
                    : t("common.connect")
                }
                className={cx("LinkedButton Medium h7", {
                  isConnectedToLink: user?.telegramChatId,
                })}
                isLoading={
                  isDeleteIntegrationLoading && selectedUserData?.id === user.id
                }
              />
            </div>
          </div>
        ))}
      </div>
      <Confirm
        title={`${t("common.confirmDisconnect").replace(
          "{{name}}",
          `${selectedUserData?.name}`
        )}?`}
        mainElementRefConfirm={mainElementRefConfirmCloseModal}
        openSlide={openSlideConfirmCloseModal}
        onCancel={() => {
          setOpenSlideConfirmCloseModal(false);
        }}
        onConfirm={handleOnConfirmDisconnect}
      />
    </div>
  );

  return (
    openSlide && (
      <Modal
        header={telegramIntegrationModalHeader}
        body={
          telegramModalStep === telegramIntegrationSteps.userSelectStep
            ? telegramIntegrationModalBody
            : qrModalBody
        }
        mainElementRef={mainElementRef}
        className="TelegramIntegrationModal"
      />
    )
  );
};

TelegramIntegrationModal.propTypes = {
  openSlide: PropTypes.bool,
  setOpenSlide: PropTypes.func,
  mainElementRef: PropTypes.object,
};

export default TelegramIntegrationModal;

import React from "react";
import { useSelector } from "react-redux";

import IntegrationCard from "components/cards/integration-card/IntegrationCard";
import useOutsideClick from "utils/hooks/useOutsideClick";
import TelegramIntegrationModal from "./telegram-integration-modal/TelegramIntegrationModal";
import TELEGRAM_ICON from "assets/icons/integrations/telegram.svg";
import { STORE_NAMES } from "utils/constants/redux";

const TelegramIntegration = () => {
  const businessUsers = useSelector(
    (state) => state[STORE_NAMES.business]?.users
  );

  const isConnected = businessUsers?.filter((user) => user.telegramChatId);
  const [
    openSlideIntegrationModal,
    setOpenSlideIntegrationModal,
    mainElementRefIntegrationModal,
  ] = useOutsideClick();

  return (
    <div>
      <IntegrationCard
        icon={TELEGRAM_ICON}
        onClick={() => setOpenSlideIntegrationModal(true)}
        title={"Telegram"}
        className="TelegramIntegration"
        integrationStatus={`${isConnected.length}/${businessUsers.length}`}
      />

      <TelegramIntegrationModal
        openSlide={openSlideIntegrationModal}
        setOpenSlide={setOpenSlideIntegrationModal}
        mainElementRef={mainElementRefIntegrationModal}
      />
    </div>
  );
};

export default TelegramIntegration;

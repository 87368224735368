import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { LOCAL_STORAGE_VARIABLES } from "utils/constants/global";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import Spinner from "components/elements/spinner/Spinner";

const VerifyUser = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get(QUERY_PARAMS.token);
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );

  useEffect(() => {
    if (token) {
      localStorage.setItem(LOCAL_STORAGE_VARIABLES.accessTokenForGuest, token);
      navigate(
        businessId
          ? `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
          : `${ROUTE_NAME.client}${ROUTE_NAME.allBusinesses}`
      );
    }
  }, [token]);

  return <Spinner />;
};

export default VerifyUser;

import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import ICON_SEND from "assets/icons/chat/Send.svg";
import InputControl from "components/admin/forms/input-control/InputControl";
import { STORE_NAMES } from "utils/constants/redux";
import { formatTime } from "utils/helpers";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import NO_MESSAGES from "assets/icons/chat/NoMessages.svg";
import { createDOBucketName } from "utils/DO-Spaces";
import IMG_GUEST from "assets/images/placeholder/GuestPlaceholder.png";
import { ReactComponent as ChatScrollIcon } from "assets/icons/chat/arrowRead.svg";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import EmptyState from "components/admin/empty-state/EmptyState";

import "./Chat.scss";

const Chat = ({
  author,
  messages,
  onNewText,
  authorLastReadTime,
  updateUserLastReadTime,
  chatHeader,
  topicId,
}) => {
  const { t } = useTranslation();
  const [newMessage, setNewMessage] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const chatBodyRef = useRef(null);
  const business = useSelector((state) => state[STORE_NAMES.business]).business;
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const businessImage = createDOBucketName(business?.images?.logo);
  const roleEnums = useSelector(
    (state) => state[STORE_NAMES.app].enums
  )?.roleName;
  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (newMessage.trim() !== "") {
      onNewText(newMessage.trim());
      setNewMessage("");
    }
  };

  const checkOwnMessage = (authorId) => {
    return authorId === author.id;
  };

  const scrollToBottom = ({ smooth }) => {
    chatBodyRef?.current?.scrollTo({
      top: chatBodyRef.current.scrollHeight,
      behavior: smooth ? "smooth" : "auto",
    });
  };

  useEffect(() => {
    if (
      messages.length > 0 &&
      (!isScrolled || checkOwnMessage(messages[messages.length - 1].author.id))
    ) {
      scrollToBottom({ smooth: false });
      updateUserLastReadTime();
    }
  }, [messages]);

  const unReadMessages = messages.filter(
    (message) =>
      !checkOwnMessage(message.author.id) &&
      authorLastReadTime < message.dateTime
  );

  const onScrollHandler = (e) => {
    if (
      parseInt(e.target.scrollHeight - e.target.clientHeight) -
        parseInt(e.target.scrollTop) <
      50
    ) {
      setIsScrolled(false);
      updateUserLastReadTime();
    } else {
      setIsScrolled(true);
    }
  };

  const getGuestProfileInfo = (authorId) => {
    const order = orders.find((order) => order.id === topicId);

    const guestInOrder = order.guests.find(
      (guest) => guest.person.id === authorId
    );

    return {
      name: guestInOrder?.name || `${t("dashboard.guest.guest")} ${authorId}`,
      profilePicture: createDOBucketName(guestInOrder?.profilePic) || IMG_GUEST,
    };
  };

  return (
    <div className="Chat">
      {chatHeader}
      {messages.length > 0 ? (
        <div className="ChatBody" ref={chatBodyRef} onScroll={onScrollHandler}>
          {messages?.map((message, index) => {
            const isSameAuthor =
              index > 0 && message.author.id === messages[index - 1].author.id;
            const isOwnMessage = checkOwnMessage(message.author.id);
            return (
              <div
                key={index}
                className={cx("ChatBodyMessageContainer", {
                  isOwnMessage: isOwnMessage,
                  isSameAuthor: isSameAuthor,
                })}
              >
                <div className="ChatBodyMessage">
                  {!isOwnMessage && !isSameAuthor && (
                    <div className="ChatBodyMessageAuthor">
                      <ImageWithPlaceholder
                        imageSource={
                          message.author.role.name === roleEnums.guest
                            ? getGuestProfileInfo(message.author.id)
                                .profilePicture
                            : businessImage
                        }
                        placeholder={IMAGE_ITEM_PLACEHOLDER}
                        alt="avatar"
                        className="ChatHeaderAvatarImage"
                      />
                      <h4 className="ChatBodyMessageAuthorName SemiBold">
                        {message.author.role.name === roleEnums.guest
                          ? getGuestProfileInfo(message.author.id).name
                          : business.name}
                      </h4>
                    </div>
                  )}
                  <h4 className="h4 Medium">{message.text}</h4>
                  <h6 className="h8 ChatBodyMessageTime">
                    {formatTime(message.dateTime)}
                  </h6>
                </div>
              </div>
            );
          })}
          {isScrolled && (
            <div
              className="ChatScrollToBottom"
              onClick={() => scrollToBottom({ smooth: true })}
            >
              <ChatScrollIcon />
              <h6 className="ChatUnReadMessageCount Medium">
                {unReadMessages.length || undefined}
              </h6>
            </div>
          )}
        </div>
      ) : (
        <EmptyState
          isAdmin={false}
          icon={NO_MESSAGES}
          description={t("chat.noMessages")}
        />
      )}

      <div className="ChatFooter">
        <form onSubmit={handleOnSubmit} className="ChatFooterForm">
          <InputControl
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder={t("chat.typeHere")}
            value={newMessage}
            className="ChatFooterFormInput"
            noLabelFloating
            isBorderless
          />
          <button className="ChatFooterFormSubmitButton" type="button">
            <img onClick={handleOnSubmit} src={ICON_SEND} alt="send" />
          </button>
        </form>
      </div>
    </div>
  );
};

Chat.propTypes = {
  /**
   * An array of messages
   */
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        name: PropTypes.string,
        role: PropTypes.object,
      }).isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  /**
   * A function to handle the submission of new text
   */
  onNewText: PropTypes.func,
  /**
   * A boolean flag to determine if the Chat has a header
   */
  chatHeader: PropTypes.node,
  author: PropTypes.object,
  authorLastReadTime: PropTypes.string,
  updateUserLastReadTime: PropTypes.func,
  topicId: PropTypes.string,
};

export default Chat;

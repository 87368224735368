import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import SpinnerWheel from "components/wheel-of-fortune/spinner-wheel/SpinnerWheel";
import Checkbox from "components/admin/forms/checkbox/Checkbox";
import CloseButton, {
  ENUMS,
} from "components/buttons/close-button/CloseButton";
import Modal from "components/modal/Modal";
import { STORE_NAMES } from "utils/constants/redux";
import { QUERY_PARAMS } from "utils/constants/routes";
import { createDOBucketName } from "utils/DO-Spaces";
import useOutsideClick from "utils/hooks/useOutsideClick";
import AddCircle from "assets/images/dashboard-order-status/AddCircle.svg";
import Success from "assets/images/dashboard-order-status/Vector.svg";
import IMG_GUEST from "assets/images/placeholder/GuestPlaceholder.png";
import Setting from "assets/icons/settings/Setting.svg";
import Trash from "assets/icons/trash/Trash.svg";
import SpinEndSound from "assets/audios/SpinEnd.mp3";

import "./WheelOfFortune.scss";

const WheelOfFortune = () => {
  const { t } = useTranslation();
  const audioEndRef = useRef(new Audio(SpinEndSound));
  const useSpinnerRef = useRef(null);
  const [spinnerRunning, setSpinnerRunning] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [openSettingCloseModal, setOpenSettingCloseModal] = useOutsideClick();
  const [openEndModal, setOpenEndModal] = useOutsideClick();
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const [shuffleTrigger, setShuffleTrigger] = useState(false);
  const qrScanStore = useSelector((state) => state[STORE_NAMES.qrScan]);
  const tableId = qrScanStore.table.id;
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const order = orders?.find((order) => order?.table.id === tableId);

  const [guestList, setGuestList] = useState([]);
  const [newGuestName, setNewGuestName] = useState("");
  const [showGuestListEdit, setShowGuestListEdit] = useState(false);
  const [loser, setLoser] = useState(null);
  const [spinEnded, setSpinEnded] = useState(false);
  const [startName, setStartName] = useState(t("funZone.wheelOfFortune.start"));
  const [guestListEdited, setGuestListEdited] = useState(false);
  const [pendingGuests, setPendingGuests] = useState([]);
  useEffect(() => {
    if (!order?.guests) return;

    const editedGuests = order.guests.map((guest) => ({
      id: guest.id,
      personId: guest.person.id,
      name: guest.name || `${t("dashboard.guest.guest")} ${guest.person.id}`,
      image: createDOBucketName(guest.profilePic) || IMG_GUEST,
      checked: true,
    }));

    if (spinnerRunning) {
      setPendingGuests((prevPending) => [
        ...prevPending,
        ...editedGuests.filter(
          (newGuest) => !guestList.some((guest) => guest.id === newGuest.id)
        ),
      ]);
    } else {
      setGuestList((prevGuestList) => [
        ...prevGuestList,
        ...editedGuests.filter(
          (newGuest) => !prevGuestList.some((guest) => guest.id === newGuest.id)
        ),
      ]);
    }
  }, [order?.guests, t, shuffleTrigger]);

  useEffect(() => {
    if (!spinnerRunning && pendingGuests.length > 0 && !shuffleTrigger) {
      setGuestList((prevGuestList) => [
        ...prevGuestList,
        ...pendingGuests.filter(
          (pendingGuest) =>
            !prevGuestList.some((guest) => guest.id === pendingGuest.id)
        ),
      ]);
      setPendingGuests([]);
    }
  }, [spinnerRunning, pendingGuests]);

  const handleSettingOpen = () => {
    if (!spinnerRunning) {
      setOpenSettingCloseModal(true);
    }
  };
  const handleSettingClose = useCallback(() => {
    if (guestListEdited) {
      setGuestList((prevGuestList) =>
        prevGuestList.filter((guest) => !guest.isEdit)
      );
      setGuestListEdited(false);
    }
    setOpenSettingCloseModal(false);
    setShowGuestListEdit(false);
    setNewGuestName("");
  }, [
    guestListEdited,
    setOpenSettingCloseModal,
    setShowGuestListEdit,
    setNewGuestName,
  ]);

  useEffect(() => {
    if (guestList.some((guest) => guest.isEdit)) {
      setGuestListEdited(true);
    }
  }, [guestList]);

  const handleSettingSave = () => {
    setGuestList((prevGuestList) =>
      prevGuestList.map((guest) => ({
        ...guest,
        isEdit: false,
      }))
    );
    setOpenSettingCloseModal(false);
    setShowGuestListEdit(false);
    setNewGuestName("");
  };

  const handleCheckboxChange = (index) => {
    const updatedGuests = [...guestList];
    const selectedGuestsCount = updatedGuests.filter(
      (guest) => guest.checked
    ).length;
    if (selectedGuestsCount === 1 && updatedGuests[index].checked) {
      return;
    }
    updatedGuests[index].checked = !updatedGuests[index].checked;
    setGuestList(updatedGuests);
  };

  const handleNewGuestCreate = () => {
    if (newGuestName.trim() !== "") {
      setGuestList([
        ...guestList,
        {
          name: newGuestName,
          isNew: true,
          isEdit: true,
          checked: true,
        },
      ]);
      setShowGuestListEdit(false);
      setNewGuestName("");
    }
  };

  const handleAddNewUser = () => {
    setShowGuestListEdit(true);
  };

  const handleAddNewGuestCancel = () => {
    setShowGuestListEdit(false);
    setNewGuestName("");
  };

  const handleDeleteGuest = (index) => {
    const updatedGuests = guestList.filter((_, i) => i !== index);
    setGuestList(updatedGuests);
  };

  const handleCloseWheel = () => {
    searchParams.delete(QUERY_PARAMS.showWheelOfFortune);
    setSearchParams(searchParams);
    setSpinnerRunning(false);
    document.body.classList.remove("no-scroll");
  };

  const handleCongratulateModal = () => {
    setStartName(t("funZone.wheelOfFortune.start"));
    setShuffleTrigger(!shuffleTrigger);
    setOpenEndModal(false);
    setSpinEnded(false);
    // setLoser(null);
    if (audioEndRef?.current) audioEndRef.current.pause();
    audioEndRef.current.currentTime = 0;
  };

  useEffect(() => {
    const showWheelOfFortune = Boolean(
      searchParams.get(QUERY_PARAMS.showWheelOfFortune)
    );
    if (showWheelOfFortune) {
      useSpinnerRef.current.style.display = "block";
    } else {
      useSpinnerRef.current.style.display = "none";
    }
    setStartName(t("funZone.wheelOfFortune.start"));
    if (window.location.search.includes(QUERY_PARAMS.showWheelOfFortune))
      document.body.classList.add("no-scroll");
  }, [searchParams]);

  const SettingModalHeader = (
    <div className="WheelOfFortuneSettingsHeader">
      <p className="WheelOfFortuneSettingsHeaderTitle">
        {t("funZone.wheelOfFortune.editToPlay")}
      </p>
      <CloseButton onClick={handleSettingClose} type={ENUMS.types.TYPE_C} />
    </div>
  );

  const SettingModalBody = (
    <div className="WheelOfFortuneSettingsBody">
      <div className="WheelOfFortuneSettingsBodyGuestsList">
        {guestList.map((guest, index) => (
          <div
            key={index}
            className={cx("WheelOfFortuneSettingsBodyGuestsListItem", {
              isChecked: guest.checked,
            })}
            onClick={() => handleCheckboxChange(index)}
          >
            <div className="WheelOfFortuneSettingsBodyGuestsListTitle">
              <div className="WheelOfFortuneSettingsBodyGuestsListTitleLeft">
                {guest?.image ? (
                  <img
                    className="WheelOfFortuneSettingsBodyGuestsListTitleLeftImage"
                    alt={guest.name}
                    src={guest.image}
                  />
                ) : (
                  <img
                    src={IMG_GUEST}
                    alt="Img"
                    className="WheelOfFortuneSettingsBodyGuestsListTitleLeftImage"
                  />
                )}
                <p className="WheelOfFortuneSettingsBodyGuestsListTitleLeftText">
                  {guest.personId === guestId
                    ? t("dashboard.guest.me")
                    : guest.name}
                </p>
              </div>
              <div className="WheelOfFortuneSettingsBodyGuestsListTitleController">
                {guest.isNew && (
                  <div
                    className="WheelOfFortuneSettingsBodyGuestsListTitleControllerDelete"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteGuest(index);
                    }}
                  >
                    <img
                      className="WheelOfFortuneSettingsBodyGuestsListTitleControllerDeleteImage"
                      src={Trash}
                      alt="list item delete"
                    />
                  </div>
                )}
                <Checkbox isChecked={guest.checked} />
              </div>
            </div>
          </div>
        ))}
        {showGuestListEdit && (
          <div className={"WheelOfFortuneSettingsBodyAddNewType"}>
            <div className={"WheelOfFortuneSettingsBodyAddNewTypeForm"}>
              <input
                className="WheelOfFortuneSettingsBodyAddNewTypeFormInput"
                type="text"
                value={newGuestName}
                onChange={(e) => setNewGuestName(e.target.value)}
                placeholder={t("funZone.wheelOfFortune.typeHere")}
              />
              <div className="WheelOfFortuneSettingsBodyAddNewTypeFormActions">
                <CloseButton
                  onClick={handleAddNewGuestCancel}
                  className="WheelOfFortuneSettingsBodyAddNewTypeFormActionCancel"
                  type={ENUMS.types.TYPE_A}
                />
                <button
                  onClick={handleNewGuestCreate}
                  className="WheelOfFortuneSettingsBodyAddNewTypeFormActionAdd"
                >
                  <img
                    className="WheelOfFortuneSettingsBodyAddNewTypeFormActionAddImage"
                    src={Success}
                    alt="Add New Type"
                  />
                </button>
              </div>
            </div>
          </div>
        )}
        <div
          className="WheelOfFortuneSettingsBodyGuestsListNewType"
          onClick={handleAddNewUser}
        >
          <div className="WheelOfFortuneSettingsBodyGuestsListNewTypeAction">
            <img
              className="WheelOfFortuneSettingsBodyGuestsListNewTypeActionImage"
              src={AddCircle}
              alt="Add New Type Open"
            />
            <p className="WheelOfFortuneSettingsBodyGuestsListNewTypeActionText">
              {t("funZone.wheelOfFortune.addNew")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
  const SettingModalFooter = (
    <div className="WheelOfFortuneSettingsFooter" onClick={handleSettingSave}>
      <button className="WheelOfFortuneSettingsFooterSaveButton">
        {t("funZone.wheelOfFortune.saveAndContinue")}
      </button>
    </div>
  );

  const CongratulateModalHeader = (
    <div className="CongratulateHeader">
      <p className="CongratulateHeaderText">
        {t("funZone.wheelOfFortune.result")}
      </p>
      <CloseButton
        onClick={handleCongratulateModal}
        type={ENUMS.types.TYPE_C}
      />
    </div>
  );
  const CongratulateModalBody = (
    <div className="CongratulateBody">
      {loser?.isNew ? (
        <img
          src={IMG_GUEST}
          alt="congratulateImg"
          className="CongratulateBodyAvatar"
        />
      ) : (
        <img
          className="CongratulateBodyAvatar"
          src={loser?.image || IMG_GUEST}
          alt={`${loser?.name}`}
        />
      )}
      <div className="CongratulateBodyContent">
        <p className="Title">{loser?.name}</p>
        <p className="Description">
          {t("funZone.wheelOfFortune.youAreTheChosenOne")}
        </p>
      </div>
    </div>
  );
  return (
    <div ref={useSpinnerRef} className="WheelOfFortune">
      <div className="WheelOfFortuneHeader">
        <p className="WheelOfFortuneHeaderText">
          {t("funZone.wheelOfFortune.spinnerGame")}
        </p>
        <div className="WheelOfFortuneHeaderOperations">
          <img
            onClick={handleSettingOpen}
            className={`WheelOfFortuneHeaderOperationsSetting ${
              spinnerRunning &&
              "WheelOfFortuneHeaderOperationsSettingSpinnerActive"
            }`}
            src={Setting}
            alt="Settings"
          />
          <CloseButton onClick={handleCloseWheel} type={ENUMS.types.TYPE_C} />
        </div>
      </div>
      <Modal
        className={"WheelOfFortuneSelectParticipants"}
        openSlide={openSettingCloseModal}
        type="DARK"
        header={SettingModalHeader}
        body={SettingModalBody}
        footer={SettingModalFooter}
      />
      <div className="WheelOfFortuneSpinnerContainer">
        <SpinnerWheel
          guestList={guestList}
          shuffleTrigger={shuffleTrigger}
          setWinner={setLoser}
          setOpenEndModal={setOpenEndModal}
          spinEnded={spinEnded}
          setSpinEnded={setSpinEnded}
          startName={startName}
          setStartName={setStartName}
          // audioRef={audioRef}
          audioEndRef={audioEndRef}
          spinnerRunning={spinnerRunning}
          setSpinnerRunning={setSpinnerRunning}
        />
      </div>
      <Modal
        className="CongratulateModal"
        header={CongratulateModalHeader}
        body={CongratulateModalBody}
        type="DARK"
        openSlide={openEndModal}
      />
    </div>
  );
};

WheelOfFortune.propTypes = {
  SpinWheel: PropTypes.string,
  Setting: PropTypes.string,
  AddCircle: PropTypes.string,
};

export default WheelOfFortune;

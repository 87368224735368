import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { handleOnAsyncError, handleOnAsyncSuccess } from "utils/helpers";
import useAsync from "utils/hooks/useAsync";
import { STORE_NAMES } from "utils/constants/redux";
import useOutsideClick from "utils/hooks/useOutsideClick";
import {
  createTable,
  deleteTable,
  updateTable,
} from "utils/api/services/table";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import { getZonesAsync } from "redux/actions/zoneAction";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import { getAllQrAsync } from "redux/actions/qrAction";
import AdminTableModal from "pages/admin/admin-pages/admin-qr-table-map/admin-map/admin-table-modal/AdminTableModal";
import Table from "components/admin/cards/table/Table";
import EmptyState from "components/admin/empty-state/EmptyState";
import AddTableIcon from "assets/icons/qr/AddItem.svg";
import EmptyZoneIcon from "assets/icons/qr/Table.svg";
import AdminZone from "pages/admin/admin-pages/admin-qr-table-map/admin-zone/AdminZone";
import AddTagButton, {
  ENUMS as ENUMS_ADD_TABLE,
} from "components/admin/buttons/add-tag-button/AddTagButton";
import { createQRCode } from "utils/api/services/QR";

import "./AdminMap.scss";

const AdminMap = () => {
  const { t } = useTranslation();
  const [activeZone, setActiveZone] = useState(null);
  const [selectedTable, setSelectedTable] = useState(null);
  const [formData, setFormData] = useState(null);
  const [formDataInitial, setFormDataInitial] = useState(null);
  const [openSlide, setOpenSlide, mainElementRef, , , setOutsideClickAction] =
    useOutsideClick();
  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);
  const dispatch = useDispatch();

  const businessId = useSelector(
    (state) => state[STORE_NAMES.business].business?.id
  );

  const { execute: executeUpdateTable, loading: isLoadingUpdateTable } =
    useAsync(updateTable, {
      onError: () => handleOnAsyncErrorForZone(),
      onSuccess: () =>
        handleOnAsyncSuccessForTable(t("toastMessages.success.updateTable")),
    });

  const handleOnAsyncErrorForZone = (errorMessage) => {
    handleOnAsyncError(errorMessage || t(commonAsyncErrorMessage));
  };
  const handleOnAsyncSuccessForTable = (successMessage) => {
    handleOnAsyncSuccess(successMessage, () => {
      setOpenSlide(false);
      //TODO remove reFetch after web sockets
      dispatch(getZonesAsync({ businessId }));
    });
  };

  const handleOnAsyncSuccessForQr = (successMessage) => {
    handleOnAsyncSuccess(successMessage, () => {
      setOpenSlide(false);
      dispatch(getAllQrAsync(businessId));
    });
  };

  const { execute: executeDeleteTable } = useAsync(deleteTable, {
    onError: (data) => {
      handleOnAsyncErrorForZone(data.response.data.message);
    },
    onSuccess: () =>
      handleOnAsyncSuccessForTable(t("toastMessages.success.deleteTable")),
  });

  const { execute: executeCreateQRCode, loading: isLoadingCreateQr } = useAsync(
    createQRCode,
    {
      onError: () => handleOnAsyncErrorForZone(),
      onSuccess: () => {
        handleOnAsyncSuccessForQr(t("toastMessages.success.createQR"));
      },
    }
  );
  const { execute: executeCreateTable, loading: isLoadingCreateTable } =
    useAsync(createTable, {
      onError: handleOnAsyncErrorForZone,
      onSuccess: async ({
        data: { name: tableName, id: tableId, qrLinks },
      }) => {
        if (qrLinks?.length === 0) {
          const qrData = new FormData();
          qrData.append(
            "name",
            `${t("navbarRoutes.table")}-${tableName}-${tableId}`
          );
          qrData.append("tableId", tableId.toString());
          qrData.append(
            "qrData[url]",
            `${window.location.origin}${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}?${QUERY_PARAMS.qrId}=`
          );
          await executeCreateQRCode(businessId, qrData, "URL");
        }
        handleOnAsyncSuccessForTable(t("toastMessages.success.createTable"));
      },
    });

  const activeTables = zones?.find(
    (zone) => zone.id === activeZone?.id
  )?.tables;

  useEffect(() => {
    if (zones.length === 0) {
      setActiveZone(null);
    }
    if (!activeZone) {
      setActiveZone(zones?.[0]);
    }
  }, [zones]);
  const handleOnDeleteTable = async (id) => {
    await executeDeleteTable(businessId, id);
  };

  const handleOnSaveTable = async (data, id) => {
    const table = {
      name: data.name,
      zone: data.zone.id,
      assigneeId: data.assignee.id,
      qrLinks: data.QR.map((qr) => qr.id),
      minDeposit: data.minDeposit,
      maxSeat: parseInt(data.maxSeat),
    };
    if (id) {
      await executeUpdateTable(businessId, table, id);
    } else {
      await executeCreateTable(businessId, table);
    }
  };

  const qrCodes = zones?.flatMap((zone) =>
    zone.tables.flatMap((table) =>
      table.qrLinks.map((qrCode) => ({
        ...qrCode,
        name: `${qrCode.name} (${t("qr.connected")} ${table.name}, ${
          qrCode.type
        })`,
      }))
    )
  );

  const handleOnEditTable = (table) => {
    setOpenSlide(true);
    setSelectedTable(table);
  };

  const handleOnAddTable = () => {
    setOpenSlide(true);
    setSelectedTable(null);
  };

  return (
    <div className="AdminMapSection">
      <AdminZone activeZone={activeZone} setActiveZone={setActiveZone} />
      <div className="AdminMapSectionTables">
        {zones.length > 0 && (
          <div className="AdminMapAdd">
            <AddTagButton
              onClick={handleOnAddTable}
              type={ENUMS_ADD_TABLE.types.TYPE_D}
              text={t("table.addTable")}
            />
          </div>
        )}
        {zones.length === 0 && (
          <EmptyState
            icon={AddTableIcon}
            description={t("emptyTable.emptyTableDescription")}
            isAdmin={true}
          />
        )}
        {activeTables?.length === 0 && (
          <EmptyState
            className="AdminMapEmptyTable"
            icon={EmptyZoneIcon}
            description={t("emptyTable.emptyZoneDescription")}
            isAdmin={true}
          />
        )}

        {activeTables?.map((table) => (
          <Table key={table.id} onClick={handleOnEditTable} table={table} />
        ))}
      </div>

      <AdminTableModal
        mainElementRef={mainElementRef}
        setOpenSlide={setOpenSlide}
        formData={formData}
        setFormData={setFormData}
        formDataInitial={formDataInitial}
        setFormDataInitial={setFormDataInitial}
        selectedTable={selectedTable}
        activeZone={activeZone}
        connectedQRCodes={qrCodes}
        zones={zones}
        openSlide={openSlide}
        title={t("table.table")}
        onSave={handleOnSaveTable}
        onDelete={handleOnDeleteTable}
        setOutsideClickAction={setOutsideClickAction}
        isLoading={
          isLoadingCreateTable || isLoadingUpdateTable || isLoadingCreateQr
        }
      />
    </div>
  );
};

export default AdminMap;

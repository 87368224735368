import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAsync from "utils/hooks/useAsync";
import {
  getStatsOrderHistory,
  getStatsOrderSale,
} from "utils/api/services/dashboard";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import StatsCardChartWrapper from "components/admin/charts/chart-wrappers/stats-card-chart-wrapper/StatsCardChartWrapper";
import WeekdaysProgressChart from "components/admin/charts/weekdays-progress-chart/WeekdaysProgressChart";
import EmptyState from "components/admin/empty-state/EmptyState";
import EmptyChartDataIcon from "assets/icons/admin-dashboard/EmptyChartDataIcon.svg";
import Spinner from "components/elements/spinner/Spinner";
import { getStatsWeekdaysProgressData } from "utils/helper-functions/dashboardStatsHelper";

import "./AdminDashWeeklyStats.scss";

const AdminDashWeeklyStats = () => {
  const { setTitle, fromDateToDate } = useOutletContext();
  const { t } = useTranslation();

  useEffect(() => {
    setTitle(t("navbarRoutes.pageTitles.weeklyStatistics"));
  }, [setTitle, t]);

    const businessId = useSelector(
        (state) => state[STORE_NAMES.business]?.business?.id
    );

  const { result: fetchedRawSalesData } = useAsync(getStatsOrderSale, {
    immediate: true,
    params: [businessId, fromDateToDate.from, fromDateToDate.to],
  });

  // Sales Data

  const statsSalesWeekdaysProgressData =
    getStatsWeekdaysProgressData(fetchedRawSalesData);

  // Order  Data
  const {
    result: fetchedRawOrderHistoryData,
    loading: orderStatsHistoryLoading,
  } = useAsync(getStatsOrderHistory, {
    immediate: true,
    params: [businessId, fromDateToDate.from, fromDateToDate.to],
  });

  const statsWeekdaysProgressData = getStatsWeekdaysProgressData(
    fetchedRawOrderHistoryData
  );

  const controlOrderHistoryWeekDaysChartData = Object.values(
    statsWeekdaysProgressData
  ).every((day) => day.orders === 0 && day.orderItems === 0);

  return (
    <div className="AdminDashWeeklyStats">
      <div className="WrapperOfWeekDayStats">
        <StatsCardChartWrapper
          title={t("dashboard.weeklySaleStatistics")}
          subTitle={fromDateToDate}
          chartToRender={
            !orderStatsHistoryLoading ? (
              !controlOrderHistoryWeekDaysChartData ? (
                <WeekdaysProgressChart
                  data={statsSalesWeekdaysProgressData}
                  total={fetchedRawSalesData?.total}
                  countName={"sale"}
                />
              ) : (
                <EmptyState
                  icon={EmptyChartDataIcon}
                  isAdmin={true}
                  description={t("dashboard.noResultForThisPeriodMsg")}
                  descriptionClassName={
                    "AdminSalesDashboardEmptyStateDescription"
                  }
                />
              )
            ) : (
              <div>
                <Spinner />
              </div>
            )
          }
        />
        <StatsCardChartWrapper
          title={t("dashboard.weeklyOrderStatistics")}
          subTitle={fromDateToDate}
          className={"CustomStatsCardChartWrapper"}
          chartToRender={
            !orderStatsHistoryLoading ? (
              !controlOrderHistoryWeekDaysChartData ? (
                <WeekdaysProgressChart
                  data={statsWeekdaysProgressData}
                  total={fetchedRawOrderHistoryData?.orderCount}
                  countName={"orders"}
                />
              ) : (
                <EmptyState
                  icon={EmptyChartDataIcon}
                  isAdmin={true}
                  description={t("dashboard.noResultForThisPeriodMsg")}
                  descriptionClassName={
                    "AdminOrderHistoryEmptyStateDescription"
                  }
                />
              )
            ) : (
              <div>
                <Spinner />
              </div>
            )
          }
        />
      </div>
    </div>
  );
};

export default AdminDashWeeklyStats;

import { AXIOS_USER } from "utils/api/axios";
import { API_PATH_PARAMS } from "utils/constants/api";

export const getZones = async (businessId) =>
  AXIOS_USER.get(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.zones}`
  );
export const createZone = async (businessId, zone) =>
  AXIOS_USER.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.zones}`,
    zone
  );
export const updateZone = async (businessId, zone, id) =>
  AXIOS_USER.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.zones}/${id}`,
    zone
  );

export const deleteZone = async (businessId, id) =>
  AXIOS_USER.delete(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.zones}/${id}`
  );

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { PhoneInput } from "react-international-phone";

import "./FormattedPhoneNumber.scss";

const PhoneNumberFormatter = ({ phoneNumber, className }) => {
  return (
    <PhoneInput
      value={phoneNumber}
      hideDropdown
      className={cx("FormattedPhoneNumber", className)}
      inputProps={{
        autoComplete: "off",
        readOnly: true,
      }}
    />
  );
};

PhoneNumberFormatter.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  className: PropTypes.string,
};
export default PhoneNumberFormatter;

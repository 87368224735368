import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Modal from "components/modal/Modal";
import CLOCK_ICON from "assets/icons/chat-gpt/clock.svg";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";

import "./TranslateWarningModal.scss";

const TranslateWarningModal = ({
  warningModalOpenSlide,
  warningModalRef,
  warningModalSetOpenSlide,
}) => {
  const { t } = useTranslation();
  const onSuccessAiTranslateModalBody = (
    <div className="onSuccessAiTranslateModalBody">
      <div className="onSuccessAiTranslateModalBodyIcon">
        <img src={CLOCK_ICON} alt="Clock Icon" />
      </div>
      <h4 className="AITranslateModalBodyText Medium">
        {t("menu.translate.waitingTranslate")}
      </h4>
    </div>
  );

  const onSuccessAiTranslateModalFooter = (
    <PrimaryButton
      type="TYPE_D"
      onClick={() => {
        warningModalSetOpenSlide(false);
      }}
      text={t("buttons.close")}
      className="onSuccessAiTranslateModalFooter"
    />
  );

  return (
    <Modal
      mainElementRef={warningModalRef}
      openSlide={warningModalOpenSlide}
      body={onSuccessAiTranslateModalBody}
      footer={onSuccessAiTranslateModalFooter}
    />
  );
};

TranslateWarningModal.propTypes = {
  warningModalOpenSlide: PropTypes.bool,
  warningModalRef: PropTypes.object,
  warningModalSetOpenSlide: PropTypes.func,
};

export default TranslateWarningModal;

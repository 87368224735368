import { AXIOS_USER, AXIOS_USER_NO_AUTH } from "utils/api/axios";
import {
  API_PATH_PARAMS,
  API_PATH_HELPERS,
  QUERY_PARAMS,
} from "utils/constants/api";

export const signin = (user, method) =>
  AXIOS_USER_NO_AUTH.post(
    `/${API_PATH_PARAMS.auth}/${API_PATH_PARAMS.login}?${API_PATH_HELPERS.method}=${method}`,
    user
  );

export const selectBusinessInSignin = (businessId) =>
  AXIOS_USER.get(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.login}`
  );

export const verifyUser = (user, method) =>
  AXIOS_USER_NO_AUTH.post(
    `/${API_PATH_PARAMS.auth}/${API_PATH_PARAMS.verifyUser}?${API_PATH_HELPERS.method}=${method}`,
    user
  );

export const signupUserAndCreateBusiness = (user, otpToken) =>
  AXIOS_USER_NO_AUTH.post(
    `/${API_PATH_PARAMS.auth}/${API_PATH_PARAMS.users}/${API_PATH_PARAMS.signUp}?${QUERY_PARAMS.otpToken}=${otpToken}`,
    user
  );

export const CreateBusinessAssignToUser = (user, userId, otpToken) =>
  AXIOS_USER_NO_AUTH.post(
    `/${API_PATH_PARAMS.auth}/${API_PATH_PARAMS.users}/${userId}/${API_PATH_PARAMS.signUp}?${QUERY_PARAMS.otpToken}=${otpToken}`,
    user
  );

export const resendCode = (user, method) =>
  AXIOS_USER_NO_AUTH.post(
    `/${API_PATH_PARAMS.auth}/${API_PATH_PARAMS.resendCode}?${API_PATH_HELPERS.method}=${method}`,
    user
  );

export const getUsers = async (businessId) =>
  AXIOS_USER.get(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.users}`
  );

export const verifyUserOtpCode = async ({ otpCode, otpToken }) => {
  return AXIOS_USER_NO_AUTH.post(
    `/${API_PATH_PARAMS.business}/${API_PATH_PARAMS.verifyOtp}?${QUERY_PARAMS.code}=${otpCode}&${QUERY_PARAMS.otpToken}=${otpToken}`
  );
};

export const createUser = async ({ businessId, user, otpToken }) =>
  AXIOS_USER.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.users}?${QUERY_PARAMS.otpToken}=${otpToken}`,
    user
  );

export const createAnonymousUser = async ({ businessId, user }) => {
  return AXIOS_USER.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.users}/${API_PATH_PARAMS.anonymous}`,
    user
  );
};

export const assignUserToBusiness = async ({
  businessId,
  userId,
  otpToken,
  userData,
}) => {
  return AXIOS_USER.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.users}/${userId}?${QUERY_PARAMS.otpToken}=${otpToken}`,
    userData
  );
};

export const updateUser = async ({ businessId, userId, user }) =>
  AXIOS_USER.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.users}/${userId}`,
    user
  );

export const resetUserPassword = async ({ businessId, userId, user }) =>
  AXIOS_USER.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.users}/${userId}/${API_PATH_PARAMS.resetPassword}`,
    user
  );

export const deleteUser = async (businessId, id) =>
  AXIOS_USER.delete(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.users}/${id}`
  );

export const getUserInfoByToken = async () =>
  AXIOS_USER.get(`/${API_PATH_PARAMS.auth}/${API_PATH_PARAMS.info}`);

export const forgotUserPassword = async ({ otpToken, body }) =>
  AXIOS_USER_NO_AUTH.post(
    `/${API_PATH_PARAMS.auth}/${API_PATH_PARAMS.forgotPassword}?${QUERY_PARAMS.otpToken}=${otpToken}`,
    body
  );

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useSelector } from "react-redux";

import { STORE_NAMES } from "utils/constants/redux";

import "./Price.scss";

export const ENUMS = {
  name: "Price",
  types: {
    PRICE_XXS: "PRICE_XXS",
    PRICE_LINE_THROUGH_XXS: "PRICE_LINE_THROUGH_XXS",
    PRICE_XS: "PRICE_XS",
    PRICE_S: "PRICE_S",
    PRICE_S_REGULAR: "PRICE_S_REGULAR",
    PRICE_M_BOLD: "PRICE_M",
    PRICE_M_SEMI_BOLD: "PRICE_M_SEMI_BOLD",
    PRICE_L: "PRICE_L",
    PRICE_XL: "PRICE_XL",
    PRICE_XXL: "PRICE_XXL",
    PRICE_XXXL_BOLD: "PRICE_XXXL_BOLD",
    PRICE_LINE_THROUGH: "PRICE_LINE_THROUGH",
    PRICE_MODIFICATION: "PRICE_MODIFICATION",
  },
};

const Price = ({ value, type }) => {
  const menuCurrency = useSelector(
    (state) => state[STORE_NAMES.menu].data?.currency
  );
  const currencySymbol = menuCurrency?.symbol;
  const fixedValue = value === "" ? 0 : value.toFixed(2);

  return (
    <div className="Price">
      {(type === ENUMS.types.PRICE_XS ||
        type === ENUMS.types.PRICE_LINE_THROUGH) && (
        <h6
          className={cx("SemiBold", {
            lineThrough: type === ENUMS.types.PRICE_LINE_THROUGH,
          })}
        >
          {currencySymbol}
          {fixedValue}
        </h6>
      )}
      {type === ENUMS.types.PRICE_LINE_THROUGH_XXS && (
        <div
          className={cx("h8 Medium", {
            lineThrough: type === ENUMS.types.PRICE_LINE_THROUGH_XXS,
          })}
        >
          {currencySymbol}
          {fixedValue}
        </div>
      )}
      {type === ENUMS.types.PRICE_XXS && (
        <h6 className="h8 Medium">
          {currencySymbol}
          {fixedValue}
        </h6>
      )}
      {type === ENUMS.types.PRICE_S && (
        <h6 className="SemiBold">
          {currencySymbol}
          {fixedValue}
        </h6>
      )}
      {type === ENUMS.types.PRICE_S_REGULAR && (
        <h6>
          {currencySymbol}
          {fixedValue}
        </h6>
      )}
      {type === ENUMS.types.PRICE_M_BOLD && (
        <h5 className="Bold">
          {currencySymbol}
          {fixedValue}
        </h5>
      )}
      {type === ENUMS.types.PRICE_M_SEMI_BOLD && (
        <h5 className="SemiBold">
          {currencySymbol}
          {fixedValue}
        </h5>
      )}
      {type === ENUMS.types.PRICE_L && (
        <h4 className="SemiBold">
          {currencySymbol}
          {fixedValue}
        </h4>
      )}
      {type === ENUMS.types.PRICE_XL && (
        <h3 className="SemiBold">
          {currencySymbol}
          {fixedValue}
        </h3>
      )}
      {type === ENUMS.types.PRICE_XXL && (
        <h2 className="SemiBold">
          {currencySymbol}
          {fixedValue}
        </h2>
      )}{" "}
      {type === ENUMS.types.PRICE_XXXL_BOLD && (
        <h1 className="Bold">
          {currencySymbol}
          {fixedValue}
        </h1>
      )}
      {type === ENUMS.types.PRICE_MODIFICATION && (
        <h4 className="ModificationPrice">
          {value !== 0 && (value > 0 ? "+" : "-")}&nbsp;
          {currencySymbol}
          {Math.abs(value).toFixed(2)}
        </h4>
      )}
    </div>
  );
};

Price.propTypes = {
  /**
   * The value of the price
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]).isRequired,

  /**
   * The type of the component
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),
};

export default Price;

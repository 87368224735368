import React from "react";
import { useTranslation } from "react-i18next";

import AllBusinessesPoker from "pages/client/all-businesses/all-businesses-poker/AllBusinessesPoker";
import AllBusinessesGrid from "pages/client/all-businesses/all-businesses-grid/AllBusinessesGrid";
import EmptyState from "components/admin/empty-state/EmptyState";
import SearchIcon from "assets/icons/other/Search.svg";

import "./AllBusinessesCategories.scss";
import PropTypes from "prop-types";

const AllBusinessesCategories = ({
  setOpenSearch,
  setSearchBusinesses,
  setSeeAll,
  seeAll,
  businesses,
}) => {
  const { t } = useTranslation();
  const handleSeeAll = () => {
    setSeeAll((prev) => !prev);
    setOpenSearch(false);
    setSearchBusinesses("");
  };
  return (
    <React.Fragment>
      {businesses?.length > 0 ? (
        !seeAll ? (
          <div className="AllBusinessesCategories">
            <div className="AllBusinessesCategory">
              <div className="AllBusinessesCategoryTitleWrapper">
                <h3 className="Bold AllBusinessesCategoryTitle">
                  {t("businesses.mostPopular")}
                </h3>
                <h5
                  className="Medium AllBusinessesCategorySeeAll"
                  onClick={handleSeeAll}
                >
                  {seeAll ? t("businesses.seeLess") : t("businesses.seeAll")}
                </h5>
              </div>
              <AllBusinessesPoker allBusiness={businesses} />
            </div>
            <div className="AllBusinessesCategory">
              <div className="AllBusinessesCategoryTitleWrapper">
                <h3 className="Bold AllBusinessesCategoryTitle">
                  {t("businesses.coffeeShop")}
                </h3>
                <h5 className="Medium AllBusinessesCategorySeeAll">
                  {t("businesses.seeAll")}
                </h5>
              </div>
              <AllBusinessesPoker allBusiness={businesses} />
            </div>
            <div className="AllBusinessesCategory">
              <div className="AllBusinessesCategoryTitleWrapper">
                <h3 className="Bold AllBusinessesCategoryTitle">
                  {t("businesses.coffeeShop")}
                </h3>
                <h5 className="Medium AllBusinessesCategorySeeAll">
                  {t("businesses.seeAll")}
                </h5>
              </div>
              <AllBusinessesPoker allBusiness={businesses} />
            </div>
            <div className="AllBusinessesCategory">
              <div className="AllBusinessesCategoryTitleWrapper">
                <h3 className="Bold AllBusinessesCategoryTitle">
                  {t("businesses.coffeeShop")}
                </h3>
                <h5 className="Medium AllBusinessesCategorySeeAll">
                  {t("businesses.seeAll")}
                </h5>
              </div>
              <AllBusinessesPoker allBusiness={businesses} />
            </div>
          </div>
        ) : (
          <AllBusinessesGrid allBusinesses={businesses} />
        )
      ) : (
        <EmptyState
          description={t("emptyStates.noSearchResults")}
          icon={SearchIcon}
          isAdmin
          className="AllBusinessesIsEmpty"
        />
      )}
    </React.Fragment>
  );
};
AllBusinessesCategories.propTypes = {
  businesses: PropTypes.array,
  seeAll: PropTypes.bool,
  setSeeAll: PropTypes.func,
  setSearchBusinesses: PropTypes.func,
  setOpenSearch: PropTypes.func,
};
export default AllBusinessesCategories;

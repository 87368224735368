import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import GuestModal, {
  MODAL_POSITIONS,
  POSITION_TYPES,
} from "../guest/guest-modal/GuestModal";
import { ROUTE_NAME } from "utils/constants/routes";

import "./GuestAuthModal.scss";

const GuestAuthModal = ({ openSlide, onCancel, setOpenSlide }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSignUp = () => {
    setOpenSlide(false);
    navigate(`${ROUTE_NAME.client}${ROUTE_NAME.auth}${ROUTE_NAME.signUp}`);
  };
  const handleSignIn = () => {
    setOpenSlide(false);
    navigate(`${ROUTE_NAME.client}${ROUTE_NAME.auth}${ROUTE_NAME.signIn}`);
  };

  const GuestAuthModalHeader = (
    <div className="GuestAuthModalHeader">
      <h1 className="GuestAuthModalHeaderTitle Bold">
        {t("auth.createAnAccount")}
      </h1>
      <div className="GuestAuthModalCloseButtonWrapper" onClick={onCancel}>
        <IconClose />
      </div>
    </div>
  );

  const GuestAuthModalBody = (
    <div className="GuestAuthModalBody">
      <h4 className="Regular GuestAuthModalBodyTitle">
        {t("auth.createGuestAccountDesc")}
      </h4>
    </div>
  );

  const GuestAuthModalFooter = (
    <div className="GuestAuthModalFooter">
      <PrimaryButton
        onClick={handleSignUp}
        type={PRIMARY_BUTTON_ENUMS.types.TYPE_P}
        text={t("login.signUp.title")}
      />
      <div className="GuestAuthModalFooterSignIn" onClick={handleSignIn}>
        <h6 className="Medium GuestAuthModalFooterTitle">
          {t("login.alreadyHaveAnAccount")}
        </h6>
        <h5 className="Bold GuestAuthModalFooterLogin">
          {t("login.signIn.title")}
        </h5>
      </div>
    </div>
  );

  return (
    <div className="GuestAuthModal">
      <GuestModal
        className={"InfoModalBackground"}
        containerClassname={"ContainerClassname"}
        modalClassname={"ModalClassname"}
        header={GuestAuthModalHeader}
        body={GuestAuthModalBody}
        footer={GuestAuthModalFooter}
        openSlide={openSlide}
        slideDirection={POSITION_TYPES.DOWN}
        modalPosition={MODAL_POSITIONS.BOTTOM}
      />
    </div>
  );
};

GuestAuthModal.propTypes = {
  openSlide: PropTypes.bool.isRequired,
  setOpenSlide: PropTypes.func.isRequired,

  /**
   * Function called when the cancel action is performed
   */ onCancel: PropTypes.func.isRequired,
};

export default GuestAuthModal;

import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import useAsync from "utils/hooks/useAsync";
import { getAllBusinesses } from "utils/api/services/business";
import AllBusinessesHeader from "pages/client/all-businesses/all-businesses-header/AllBusinessesHeader";
import AllBusinessesSpecialOffer from "pages/client/all-businesses/all-businesses-special-offer/AllBusinessesSpecialOffer";
import AllBusinessesFooter from "pages/client/all-businesses/all-businesses-footer/AllBusinessesFooter";
import Search, { ENUMS as SEARCH_ENUMS } from "components/forms/search/Search";
import AllBusinessesCategoryTags from "pages/client/all-businesses/all-businesses-category-tags/AllBusinessesCategoryTags";
import AllBusinessesCategories from "pages/client/all-businesses/all-businesses-categories/AllBusinessesCategories";
import { ReactComponent as Seaside } from "assets/icons/all-business/seaside.svg";
import { ReactComponent as CoffeeShop } from "assets/icons/all-business/CoffeeShop.svg";
import { ReactComponent as Chicken } from "assets/icons/all-business/Chicken.svg";
import { advanceSearch } from "utils/algorithms";
import { resetReduxStoresForAllBusiness } from "utils/general";

import "./AllBusinesses.scss";

const BUSINESSES_CATEGORIES_TAGS = [
  {
    id: 1,
    translationKey: "seaside",
    name: [
      {
        value: "Seaside",
        languageCode: "EN",
      },
      {
        value: "Побережье",
        languageCode: "RU",
      },
      {
        value: "Dəniz kənarı",
        languageCode: "AZ",
      },
      {
        value: "Sahil",
        languageCode: "TR",
      },
      {
        value: "Küste",
        languageCode: "DE",
      },
    ],
    icon: <Seaside />,
  },
  {
    id: 2,
    translationKey: "coffeeShop",
    name: [
      {
        value: "Coffee Shops",
        languageCode: "EN",
      },
      {
        value: "Кофейни",
        languageCode: "RU",
      },
      {
        value: "Kofe dükanları",
        languageCode: "AZ",
      },
      {
        value: "Kahve Dükkanları",
        languageCode: "TR",
      },
      {
        value: "Cafés",
        languageCode: "DE",
      },
    ],
    icon: <CoffeeShop />,
  },
  {
    id: 3,
    translationKey: "nationalCuisine",
    name: [
      {
        value: "National Cuisine",
        languageCode: "EN",
      },
      {
        value: "Национальная кухня",
        languageCode: "RU",
      },
      {
        value: "Milli Mətbəx",
        languageCode: "AZ",
      },
      {
        value: "Ulusal Mutfak",
        languageCode: "TR",
      },
      {
        value: "Nationale Küche",
        languageCode: "DE",
      },
    ],
    icon: <Chicken />,
  },
  {
    id: 4,
    translationKey: "nationalCuisineExtended",
    name: [
      {
        value: "National Cuisineeeeeeee",
        languageCode: "EN",
      },
      {
        value: "Национальная кухняяяяяяяяяя",
        languageCode: "RU",
      },
      {
        value: "Milli Mətbəxxxxxxxxxxx",
        languageCode: "AZ",
      },
      {
        value: "Ulusal Mutfakkkkkkkkkkk",
        languageCode: "TR",
      },
      {
        value: "Nationale Kücheeeeeeeee",
        languageCode: "DE",
      },
    ],
    icon: <Chicken />,
  },
];
const AllBusinesses = () => {
  const { t } = useTranslation();
  const { result: allBusinesses } = useAsync(getAllBusinesses, {
    immediate: true,
  });
  const [openSearch, setOpenSearch] = useState(false);
  const [searchBusinesses, setSearchBusinesses] = useState("");
  const [seeAll, setSeeAll] = useState(false);
  const dispatch = useDispatch();

  const handleSearchBusinesses = (value) => {
    setSearchBusinesses(value);
  };

  const allBusinessesToDisplay = useMemo(() => {
    if (allBusinesses) {
      const filteredBusinesses = allBusinesses.filter(
        (business) => !business.isArchived && business.showInBusinessCatalogue
      );
      if (!searchBusinesses) {
        return filteredBusinesses;
      } else {
        return filteredBusinesses.filter((business) =>
          advanceSearch({
            string: business.name,
            searchString: searchBusinesses,
          })
        );
      }
    }
  }, [allBusinesses, searchBusinesses]);

  useEffect(() => {
    resetReduxStoresForAllBusiness(dispatch);
  }, []);

  return (
    <div className="AllBusinesses">
      <AllBusinessesHeader
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
        businesses={allBusinessesToDisplay}
        seeAll={seeAll}
        setSeeAll={setSeeAll}
        searchBusinesses={searchBusinesses}
        setSearchBusinesses={setSearchBusinesses}
        handleSearchBusinesses={handleSearchBusinesses}
      />
      {!seeAll && <AllBusinessesSpecialOffer seeAll={seeAll} />}
      <div className="AllBusinessesBody">
        <AllBusinessesCategoryTags
          tags={BUSINESSES_CATEGORIES_TAGS}
          seeAll={seeAll}
        />
        {seeAll && (
          <div className="SearchBusinessesCategory">
            <Search
              placeholder={t("businesses.search")}
              onChange={(value) => {
                handleSearchBusinesses(value);
              }}
              value={searchBusinesses}
              type={SEARCH_ENUMS.types.TYPE_D}
              classname="BusinessesSearchInput"
            />
          </div>
        )}
        <AllBusinessesCategories
          setSearchBusinesses={setSearchBusinesses}
          setOpenSearch={setOpenSearch}
          setSeeAll={setSeeAll}
          seeAll={seeAll}
          businesses={allBusinessesToDisplay}
        />
      </div>
      <AllBusinessesFooter />
      {/*<div className="AllBusinessesBottomBarContainer">*/}
      {/*  <AllBusinessesBottomBar />*/}
      {/*</div>*/}
    </div>
  );
};

export default AllBusinesses;

import useAsync from "./useAsync";
import { transferOrderToAuthenticatedGuest } from "../api/services/order";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "../constants/redux";

const useTransferOrderSourceToTarget = () => {
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );
  const orderId = useSelector((state) => state[STORE_NAMES.orders].orders)[0]
    ?.id;
  const qrScanStore = useSelector((state) => state[STORE_NAMES.qrScan]);
  const tableId = qrScanStore.table.id;

  const { execute: executeTransferOrderToAuthenticatedGuest } = useAsync(
    transferOrderToAuthenticatedGuest
  );

  const transferOrderSourceToTarget = async ({ sourceId, targetId }) => {
    if (orderId && businessId && tableId) {
      const orderData = {
        tableId,
        sourceId,
        targetId,
      };
      await executeTransferOrderToAuthenticatedGuest(
        businessId,
        orderId,
        orderData
      );
    }
  };

  return {
    transferOrderSourceToTarget,
  };
};

export default useTransferOrderSourceToTarget;

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "../constants/redux";

const useTranslationInProgress = () => {
  const { data: menu } = useSelector((state) => state[STORE_NAMES.menu]);
  const translationStatus = useSelector(
    (state) => state[STORE_NAMES.app].enums.menuTranslationStatus
  );
  const lastOpenAITranslationJob = menu?.openAITranslationJobs?.reduce(
    (max, job) => {
      return parseInt(job.id) > parseInt(max.id) ? job : max;
    },
    menu.openAITranslationJobs[0]
  );

  const openAITranslationJobs = lastOpenAITranslationJob ?? null;

  const [translationInProgress, setTranslationInProgress] = useState(false);

  useEffect(() => {
    if (openAITranslationJobs?.status === translationStatus.inprogress) {
      const translationStartTime = new Date(openAITranslationJobs.startTime);

      const currentTime = new Date();

      const timeDifference = currentTime - translationStartTime;

      if (timeDifference < 3600000) {
        return setTranslationInProgress(true);
      }
    }
    setTranslationInProgress(false);
  }, [openAITranslationJobs]);

  return { translationInProgress };
};

export default useTranslationInProgress;

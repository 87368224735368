import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SelectUserBusiness from "components/admin/modal/select-user-business/SelectUserBusiness";
import ILoyalLogo from "assets/icons/logo/iLoyal/logo.svg";

import "./GuestBlock.scss";

const GuestBlock = ({ business }) => {
  const { t } = useTranslation();

  return (
    <div className="GuestBlock">
      <div className="GuestBlockHeader">
        <img src={ILoyalLogo} alt="logo" />
      </div>
      <div className="GuestBlockBoxContainer">
        <div className="GuestBlockBox">
          <SelectUserBusiness
            userBusinesses={[business]}
            selectedBusiness={business}
            setSelectedBusiness={() => null}
            isAdmin={false}
          />
        </div>
        <div className="GuestBlockText">
          <h2>{t("business.guestBusinessBlockTitle")}</h2>
          <p>{t("business.guestBusinessBlockSubTitle")}</p>
        </div>
      </div>
    </div>
  );
};

GuestBlock.propTypes = {
  business: PropTypes.object,
};

export default GuestBlock;

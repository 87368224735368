import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import "./SwitchOptions.scss";

const SwitchOptions = ({
  className,
  options,
  selectedMethod,
  setSelectedMethod,
}) => {
  const { t } = useTranslation();

  const onChangeHandler = (event) => {
    const { value } = event.target;
    setSelectedMethod(value);
  };

  const activeIndex = options.findIndex(
    (option) => option.name === selectedMethod
  );

  return (
    <div className={cx("FilterSwitch", className)}>
      <div className="FilterSwitchWrapper">
        {options.map((option) => {
          const { name, value, label } = option;
          return (
            <React.Fragment key={name}>
              <input
                id={name}
                name={name}
                type="radio"
                value={value}
                checked={selectedMethod === name}
                onChange={onChangeHandler}
              />
              <label className="option" htmlFor={name}>
                {t(label)}
              </label>
            </React.Fragment>
          );
        })}
        <div
          className="background"
          style={{
            width: `${100 / options.length}%`,
            left: `${(activeIndex * 100) / options.length}%`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default SwitchOptions;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import isEqual from "lodash/isEqual";

import IconButton from "components/buttons/icon-button/IconButton";
import Modal from "components/modal/Modal";
import MenuItemInfo from "components/elements/menu-item-info/MenuItemInfo";
import {
  calculateMenuItemPrice,
  findItemById,
  calculateMenuItemPriceBySchedule,
} from "utils/general";
import { STORE_NAMES } from "utils/constants/redux";
import ModificationsMenu from "components/elements/modifications-menu/ModificationsMenu";
import { getBasketModificationsWithDefaultValues } from "utils/helpers";
import CTAButton, {
  ENUMS as CTA_BUTTON_ENUMS,
} from "components/buttons/cta-button/CTAButton";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import useLanguage from "utils/hooks/useLanguage";
import AnimatedPlusMinusButton from "components/buttons/animated-plus-minus-button/AnimatedPlusMinusButton";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useFormOutsideClickHandler from "utils/hooks/useFormOutsideClickHandler";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";

import "./AdminOrderItemModal.scss";

export const ENUMS = {
  name: "AddMenuItem",
};

const AdminOrderItemModal = ({
  mainElementRef,
  orderItem,
  setOpenSlide,
  openSlide,
  onAddToOrder,
  onSave,
  isEnableToAddOrder,
  setOutsideClickAction,
}) => {
  const { item, count } = orderItem;
  const { t } = useTranslation();
  const discountPrice = calculateMenuItemPriceBySchedule(item);
  const menuItemPrice = item.priceSell;
  const [selectedModifications, setSelectedModifications] = useState([]);
  const [selectedCount, setSelectedCount] = useState(1);
  const menu = useSelector((state) => state[STORE_NAMES.menu].data);
  const { displayDataByLanguage } = useLanguage();
  const allModifications = findItemById(item.id, menu).modifications;

  const [
    openSlideConfirmCloseModal,
    setOpenSlideConfirmCloseModal,
    mainElementRefConfirmCloseModal,
  ] = useOutsideClick();

  useFormOutsideClickHandler({
    formData: { item, count },
    formDataInitial: {
      item: {
        ...item,
        modifications: selectedModifications.filter(
          (modification) => modification.options.length > 0
        ),
      },
      count: selectedCount,
    },
    setOpenSlide,
    setOpenSlideConfirmCloseModal,
    setOutsideClickAction,
  });

  const price =
    calculateMenuItemPrice({
      ...item,
      priceSell: menuItemPrice + discountPrice,
      modifications: selectedModifications,
    }) * selectedCount;

  const [focusedModificationOption, setFocusedModificationOption] =
    useState(null);

  useEffect(() => {
    if (isEnableToAddOrder) {
      setSelectedCount(1);
    } else {
      setSelectedCount(count);
    }
  }, [isEnableToAddOrder, openSlide]);

  useEffect(() => {
    const defaultModifications = isEnableToAddOrder
      ? getBasketModificationsWithDefaultValues(item.modifications)
      : item.modifications;
    setSelectedModifications(defaultModifications);
  }, [item, openSlide]);
  const handleOnSave = () => {
    const hasChanges = !isEqual(
      { item, count },
      {
        item: {
          ...item,
          modifications: selectedModifications.filter(
            (modification) => modification.options.length > 0
          ),
        },
        count: selectedCount,
      }
    );
    if (hasChanges) {
      onSave({
        item: { ...item, modifications: selectedModifications },
        count: selectedCount,
        orderItemId: orderItem.id,
      });
    }
    setOpenSlide(false);
  };

  const handleOnMinus = () => {
    setSelectedCount((count) => count - 1);
  };

  const handleOnPlus = () => {
    setSelectedCount((count) => count + 1);
  };

  const handleOnAddToOrder = () => {
    setOpenSlide(false);
    onAddToOrder({
      item: { ...item, modifications: selectedModifications },
      count: selectedCount,
    });
  };

  const handleOnModalClose = () => {
    const hasChanges = !isEqual(
      { item, count },
      {
        item: {
          ...item,
          modifications: selectedModifications.filter(
            (modification) => modification.options.length > 0
          ),
        },
        count: selectedCount,
      }
    );
    if (hasChanges) {
      return setOpenSlideConfirmCloseModal(true);
    }
    setOpenSlide(false);
  };

  const handleOnCancelCloseModal = () => {
    setOpenSlideConfirmCloseModal(false);
  };

  const handleOnConfirmCloseModal = () => {
    setOpenSlide(false);
    setOpenSlideConfirmCloseModal(false);
  };

  const AdminOrderItemModalHeader = (
    <div className="AdminOrderItemModalHeader">
      <h3 className="AdminOrderItemModalHeaderTitle SemiBold">
        {displayDataByLanguage(item?.name)}
      </h3>
      <IconButton onClick={handleOnModalClose} svgComponent={<IconClose />} />
    </div>
  );

  const AdminOrderModalBody = (
    <div className="AdminOrderModalBody">
      <MenuItemInfo
        onMinus={handleOnMinus}
        onPlus={handleOnPlus}
        menuItem={item}
        count={selectedCount}
        discountPrice={discountPrice}
        menuItemPrice={menuItemPrice}
        disableMinusButtonAtOne
      />
      {allModifications.length > 0 && (
        <div className="AdminOrderModalBodyModifications">
          {allModifications.map((modification) => (
            <ModificationsMenu
              key={modification.id}
              data={modification}
              selectedOptions={selectedModifications.find(
                (options) => options.id === modification.id
              )}
              setSelectedModifications={setSelectedModifications}
              focusedModificationOption={focusedModificationOption}
              setFocusedModificationOption={setFocusedModificationOption}
            />
          ))}
        </div>
      )}
      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_C}
        title={t("modal.warningModalTitleUnsavedChanges")}
        mainElementRefConfirm={mainElementRefConfirmCloseModal}
        onCancel={(e) => handleOnCancelCloseModal(e)}
        onConfirm={(e) => handleOnConfirmCloseModal(e)}
        openSlide={openSlideConfirmCloseModal}
        description={t("modal.warningModalDescription")}
      />
    </div>
  );

  const AdminOrderModalFooter = (
    <div className="AdminMenuItemFooter">
      <AnimatedPlusMinusButton
        onMinusClick={handleOnMinus}
        onPlusClick={handleOnPlus}
        hasBorder
        count={selectedCount}
        doAnimate
        disableMinusButtonAtOne
      />
      {isEnableToAddOrder ? (
        <CTAButton
          onClick={handleOnAddToOrder}
          name={t("buttons.addToOrder")}
          type={CTA_BUTTON_ENUMS.types.TYPE_N}
          price={price}
        />
      ) : (
        <PrimaryButton onClick={handleOnSave} text={t("buttons.save")} />
      )}
    </div>
  );

  return (
    <Modal
      header={AdminOrderItemModalHeader}
      body={AdminOrderModalBody}
      footer={AdminOrderModalFooter}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    />
  );
};

AdminOrderItemModal.propTypes = {
  /**
   * A reference to the main element of the modal
   */
  mainElementRef: PropTypes.object,

  /**
   * The order item data to display and edit
   */
  orderItem: PropTypes.shape({
    id: PropTypes.number,
    item: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.array,
      rate: PropTypes.object,
      modifications: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
        })
      ),
      priceSell: PropTypes.number,
    }),
    count: PropTypes.number,
  }),

  /**
   * Function to set the open state of the slide
   */
  setOpenSlide: PropTypes.func,

  /**
   * Boolean indicating whether the slide is open
   */
  openSlide: PropTypes.bool,

  /**
   * Function to add the menu item to the basket
   */
  onAddToOrder: PropTypes.func,

  /**
   * Function to save changes
   */
  onSave: PropTypes.func,

  /**
   * Boolean indicating whether it's enabled to add the item to the basket
   */
  isEnableToAddOrder: PropTypes.bool.isRequired,

  setOutsideClickAction: PropTypes.func,
};

export default AdminOrderItemModal;

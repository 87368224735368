import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { setLanguage } from "redux/slices/userStore";
import { useDispatch, useSelector } from "react-redux";
import IMAGE_BACKGROUND from "assets/images/other/SignInBackground.png";
import BackButton, {
  ENUMS as BACK_BUTTON_ENUMS,
} from "components/buttons/back-button/BackButton";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import { STORE_NAMES } from "utils/constants/redux";
import { getBrandMainLogo } from "utils/branding-helper";

import "./Login.scss";

export const ENUMS = {
  name: "Login",
};

const MainLogo = getBrandMainLogo();

const Login = ({
  title,
  subTitle,
  phoneNumber,
  link,
  linkHref,
  hasSSO,
  children,
  isPrevButtonVisible,
  handlePrev,
}) => {
  const dispatch = useDispatch();
  const allLanguages = useSelector((state) => state[STORE_NAMES.app].languages);
  const selectedLanguage = useSelector(
    (state) => state[STORE_NAMES.user].userLanguage
  );
  // const { t } = useTranslation();
  // const handleOnLoginWithFacebook = (response) => {
  //   console.log("login with Facebook result", response);
  // };
  //
  // const handleOnLoginWithGoogle = useGoogleLogin({
  //   onSuccess: (response) => console.log("login with Google result", response),
  // });
  const handleOnLanguageClick = async (language) => {
    if (
      !selectedLanguage ||
      (selectedLanguage && selectedLanguage.id !== language.id)
    ) {
      dispatch(setLanguage(language));
    }
  };

  const languageOptions = allLanguages.map((language) => {
    return {
      ...language,
      name: language.code,
      imgSrc: language.imgSrc,
    };
  });

  return (
    <div className="Login">
      <div className="LoginBackgroundImg">
        <img src={IMAGE_BACKGROUND} alt="login background" />
      </div>
      <div className="LoginContent">
        <div className="LoginTopContainer">
          {isPrevButtonVisible && (
            <BackButton
              type={BACK_BUTTON_ENUMS.types.TYPE_C}
              onClick={handlePrev}
            />
          )}
          <MainLogo />
          <div className="LoginLanguage">
            {allLanguages?.length > 0 && (
              <Dropdown
                value={selectedLanguage ? selectedLanguage : allLanguages[0]}
                dropdownWithIcon
                hideDescription={true}
                options={languageOptions}
                onChange={(language) => {
                  if (language) handleOnLanguageClick(language);
                }}
                showCodeColumnInTitle
                className="LoginLanguageDropDown"
              />
            )}
          </div>
        </div>
        <h1 className="SemiBold LoginTitle">{title}</h1>
        <h6 className="Medium LoginSubTitle">
          {subTitle}{" "}
          {phoneNumber && <span className="Bold">{phoneNumber}</span>}
          {link && (
            <Link to={linkHref} className="LoginSubTitleLink SemiBold">
              {link}
            </Link>
          )}
        </h6>
        <div>{children}</div>
        {hasSSO && (
          <div className="LoginSSO">
            {/*<div className="LoginSSOOr">*/}
            {/*  <h6 className="Medium">{t("login.or")}</h6>*/}
            {/*</div>*/}
            {/*<div className="LoginSSOGoogleAndFacebook">*/}
            {/*  <button*/}
            {/*    onClick={handleOnLoginWithGoogle}*/}
            {/*    className="LoginSSOButton"*/}
            {/*  >*/}
            {/*    <img src={ICON_GOOGLE} alt="google" />*/}
            {/*    <h6 className="SemiBold">{`${title} ${t("login.google")}`}</h6>*/}
            {/*  </button>*/}
            {/*  <FacebookLogin*/}
            {/*    appId={process.env.REACT_APP_FACEBOOK_AUTH_APP_ID}*/}
            {/*    fields="name,email,picture"*/}
            {/*    scope="public_profile,user_friends,user_actions.books"*/}
            {/*    callback={handleOnLoginWithFacebook}*/}
            {/*    render={(renderProps) => (*/}
            {/*      <button*/}
            {/*        onClick={renderProps.onClick}*/}
            {/*        className="LoginSSOButton"*/}
            {/*      >*/}
            {/*        <img src={ICON_FACEBOOK} alt="facebook" />*/}
            {/*        <h6 className="SemiBold">{`${title} ${t(*/}
            {/*          "login.facebook"*/}
            {/*        )}`}</h6>*/}
            {/*      </button>*/}
            {/*    )}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        )}
      </div>
    </div>
  );
};

Login.propTypes = {
  /**
   * The title for the login component
   */
  title: PropTypes.string.isRequired,

  /**
   * The subtitle for the login component
   */
  subTitle: PropTypes.string,

  /**
   * The link text for the Link component
   */
  link: PropTypes.string,

  /**
   * The URL or path for the Link component
   */
  linkHref: PropTypes.string,

  /**
   * Flag to determine if Single Sign-On (SSO) is enabled
   */
  hasSSO: PropTypes.bool,

  /**
   * The children components or elements
   */
  children: PropTypes.node,

  /**
   * Flag to determine if the previous button is visible
   */
  isPrevButtonVisible: PropTypes.bool,

  /**
   * Callback function called when the previous button is clicked
   */
  handlePrev: PropTypes.func,

  /**
   * The phone number
   */
  phoneNumber: PropTypes.string,
};

export default Login;

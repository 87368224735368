import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";

import useAsync from "utils/hooks/useAsync";
import IconButton from "components/buttons/icon-button/IconButton";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useFormOutsideClickHandler from "utils/hooks/useFormOutsideClickHandler";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import { resetUserPassword } from "utils/api/services/user";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { handleOnAsyncSuccess } from "utils/helpers";
import { ReactComponent as IconArrowLeft } from "assets/icons/arrows/ArrowLeft.svg";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import { STORE_NAMES } from "utils/constants/redux";
import { STEP_ENUMS, USER_MODAL_ENUMS } from "../../AdminUser";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";

const initialFormData = {
  oldPassword: "",
  newPassword: "",
};

const AdminResetPasswordModalBody = ({
  selectedUser,
  setOpenSlide,
  setModalStep,
  setOutsideClickAction,
}) => {
  const { t } = useTranslation();
  const schemas = useValidationSchema(t);
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business].business?.id
  );
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();

  const methods = useForm({
    resolver: zodResolver(schemas.resetPasswordSchema),
    criteriaMode: USER_MODAL_ENUMS.criteriaModeAll,
  });

  const {
    handleSubmit,
    register,
    getValues,
    watch,
    setError,
    formState: { errors },
  } = methods;

  const handleOnAsyncSuccessForUser = (successMessage) => {
    handleOnAsyncSuccess(successMessage, () => {
      setOpenSlide(false);
    });
  };

  const [
    openSlideConfirmCloseModal,
    setOpenSlideConfirmCloseModal,
    mainElementRefConfirmCloseModal,
  ] = useOutsideClick();

  useFormOutsideClickHandler({
    formData: { ...initialFormData, ...watch() },
    formDataInitial: initialFormData,
    setOpenSlide,
    setOpenSlideConfirmCloseModal,
    setOutsideClickAction,
  });

  const {
    execute: executeResetUserPassword,
    loading: isLoadingResetUserPassword,
  } = useAsync(resetUserPassword, {
    onError: (error) => {
      const errorData = handleAPIErrorMessage(error.response.data);
      if (errorData) {
        const { field, errorMessage } = errorData;
        setError(field, {
          type: "manual",
          message: errorMessage,
        });
      }
    },
    onSuccess: () => {
      handleOnAsyncSuccessForUser(t("toastMessages.success.resetPassword"));
    },
  });

  const handleOnCloseModal = () => {
    if (getValues("oldPassword") !== "" || getValues("newPassword") !== "") {
      setOpenSlideConfirmCloseModal(true);
    } else {
      setOpenSlide(false);
    }
  };

  const handleOnConfirmCloseModal = () => {
    setOpenSlideConfirmCloseModal(false);
    setOpenSlide(false);
  };

  const handleOnCancelCloseModal = () => {
    setOpenSlideConfirmCloseModal(false);
  };

  const handleClickBackButton = () => {
    setModalStep(STEP_ENUMS.EDIT_USER);
  };

  const handleClickSubmitResetPassword = () => {
    executeResetUserPassword({
      businessId,
      userId: selectedUser.id,
      user: {
        oldPassword: getValues("oldPassword"),
        newPassword: getValues("newPassword"),
      },
    });
  };

  return (
    <div>
      <div>
        <div className="AdminUserModalTitle">
          <div className="AdminUserModalTitleLeft">
            <IconButton
              onClick={handleClickBackButton}
              svgComponent={<IconArrowLeft />}
              className="ModalHeaderBackIcon"
            />
          </div>
          <h3 className="SemiBold">{t("user.editEmployee")}</h3>
          <div className="AdminUserModalTitleRight">
            <IconButton
              onClick={handleOnCloseModal}
              svgComponent={<IconClose />}
            />
          </div>
        </div>
        <div className="AdminUserModalBody">
          <div className="AdminAddUserModal AdminResetPassModalForm">
            <InputControl
              name="Old password"
              placeholder={t("inputs.oldPassword")}
              required
              defaultValue={initialFormData.oldPassword}
              type="password"
              {...register("oldPassword")}
              hasError={errors.oldPassword}
              labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
              error={
                <ErrorMessage
                  errors={errors}
                  name="oldPassword"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
            />
            <InputControl
              name="New password"
              required
              defaultValue={initialFormData.newPassword}
              type="password"
              placeholder={t("inputs.newPassword")}
              {...register("newPassword")}
              hasError={errors.newPassword}
              labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
              error={
                <ErrorMessage
                  errors={errors}
                  name="newPassword"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
            />
          </div>
        </div>
        <div className="AdminUserModalFooter">
          <PrimaryButton
            onClick={handleSubmit(handleClickSubmitResetPassword)}
            text={t("buttons.save")}
            isLoading={isLoadingResetUserPassword}
          />
        </div>
      </div>
      <Confirm
        title={t("modal.warningModalTitleUnsavedChanges")}
        type={ENUMS_CONFIRM.types.TYPE_C}
        mainElementRefConfirm={mainElementRefConfirmCloseModal}
        openSlide={openSlideConfirmCloseModal}
        onCancel={handleOnCancelCloseModal}
        onConfirm={handleOnConfirmCloseModal}
        description={t("modal.warningModalDescription")}
      />
    </div>
  );
};

AdminResetPasswordModalBody.propTypes = {
  setOpenSlide: PropTypes.func,
  selectedUser: PropTypes.object,
  setModalStep: PropTypes.func,
  setOutsideClickAction: PropTypes.func,
};

export default AdminResetPasswordModalBody;

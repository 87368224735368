import { AXIOS_USER, AXIOS_GUEST_NO_AUTH } from "utils/api/axios";
import { API_PATH_PARAMS, QUERY_PARAMS } from "utils/constants/api";

export const updateReservationGuest = async (
  businessId,
  reservationId,
  reservationData
) =>
  AXIOS_GUEST_NO_AUTH.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.reservations}/${reservationId}`,
    reservationData
  );

export const deleteReservation = async (businessId, id) =>
  AXIOS_USER.delete(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.reservations}/${id}`
  );

export const getAllReservations = async ({ businessId, startDate }) =>
  AXIOS_USER.get(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.reservations}?${QUERY_PARAMS.startDate}=${startDate}`
  );
export const getAllPendingReservations = async (businessId) =>
  AXIOS_USER.get(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.reservations}/${API_PATH_PARAMS.pending}`
  );

export const createReservationAdmin = async (businessId, reservation) =>
  AXIOS_USER.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.reservations}`,
    reservation
  );
export const createReservationGuest = async (businessId, reservation) =>
  AXIOS_GUEST_NO_AUTH.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.reservations}`,
    reservation
  );

export const updateReservationAdmin = async (businessId, reservations) =>
  AXIOS_USER.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.reservations}`,
    reservations
  );

export const getGuestReservations = async (guestId) =>
  AXIOS_USER.get(
    `/${API_PATH_PARAMS.users}/${API_PATH_PARAMS.guests}/${guestId}/${API_PATH_PARAMS.reservations}`
  );

export const mergeMenus = (existingMenu, partialMenuUpdate) => {
  const updatedMenu = { ...existingMenu };
  if (partialMenuUpdate.categories) {
    updatedMenu.categories = mergeCategories(
      existingMenu.categories,
      partialMenuUpdate.categories
    );
  }
  if (partialMenuUpdate.language) {
    updatedMenu.language = partialMenuUpdate.language;
  }
  if (partialMenuUpdate.currency) {
    updatedMenu.currency = partialMenuUpdate.currency;
  }
  if (partialMenuUpdate.defaultMenuView) {
    updatedMenu.defaultMenuView = partialMenuUpdate.defaultMenuView;
  }
  if (partialMenuUpdate.primaryColor) {
    updatedMenu.primaryColor = partialMenuUpdate.primaryColor;
  }
  if (partialMenuUpdate.borderRadius) {
    updatedMenu.borderRadius = partialMenuUpdate.borderRadius;
  }
  if (partialMenuUpdate.availableLanguages) {
    updatedMenu.availableLanguages = partialMenuUpdate.availableLanguages;
  }
  if (partialMenuUpdate.openAITranslationJobs) {
    updatedMenu.openAITranslationJobs = partialMenuUpdate.openAITranslationJobs;
  }

  return updatedMenu;
};

const mergeCategories = (allCategories, updatedCategories) => {
  const resultCategories = [...allCategories];
  updatedCategories.forEach((updatedCategory) => {
    const existingIndex = resultCategories.findIndex(
      (item) => item.id === updatedCategory.id
    );
    if (existingIndex < 0) {
      // add new category
      resultCategories.push(updatedCategory);
    } else {
      // update existing category
      const existingCategory = resultCategories[existingIndex];

      // update category data
      const resultCategory = {
        ...updatedCategory,
        menuItems: existingCategory.menuItems,
      };

      // update category menu-items data
      if (updatedCategory.menuItems) {
        resultCategory.menuItems = mergeMenuItems(
          resultCategory.menuItems,
          updatedCategory.menuItems
        );
      }

      resultCategories[existingIndex] = resultCategory;
    }
  });
  return resultCategories;
};

const mergeMenuItems = (allMenuItems, updatedMenuItems) => {
  const resultMenuItems = [...allMenuItems];
  updatedMenuItems.forEach((updatedMenuItem) => {
    delete updatedMenuItem.category;
    const existingItemIndex = allMenuItems.findIndex(
      (item) => item.id === updatedMenuItem.id
    );
    if (existingItemIndex < 0) {
      // new menu-item
      resultMenuItems.push(updatedMenuItem);
    } else {
      resultMenuItems[existingItemIndex] = {
        ...resultMenuItems[existingItemIndex],
        ...updatedMenuItem,
      };
    }
  });
  return resultMenuItems;
};

import React from "react";

const FunZoneProfile = () => {
  return (
    <div>
      <h3>Profile</h3>
    </div>
  );
};

export default FunZoneProfile;

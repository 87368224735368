import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import DropdownArrowDown from "assets/icons/arrows/DropdownArrowDown.svg";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { createDOBucketName } from "utils/DO-Spaces";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import RadioButton from "components/buttons/radio-button/RadioButton";
import { DROPDOWN_TYPES } from "pages/admin/admin-pages/admin-menu/admin-menu-controller/admin-ai-translate-modal/AdminAiTranslateModal";
import useLanguage from "utils/hooks/useLanguage";

import "./TranslateAiDropdown.scss";

const TranslateAiDropdown = ({
  categories,
  items,
  title,
  name,
  excludeCategories,
  excludeItems,
  setExcludeCategoriesHandler,
  setExcludeItemsHandler,
  watchedLanguageName,
}) => {
  const cardContentRef = useRef(null);
  const { displayDataByLanguage } = useLanguage();
  const { t } = useTranslation();

  const filterByLanguage = (nameArray, watchedLanguageName) => {
    return nameArray.some((name) => name.languageCode === watchedLanguageName);
  };

  const isItemSelected = (id, excludeArray) => {
    return excludeArray?.includes(id);
  };

  const toggleItemSelection = (
    id,
    nameArray,
    excludeArray,
    setExcludeHandler,
    watchedLanguageName
  ) => {
    if (!filterByLanguage(nameArray, watchedLanguageName)) {
      return;
    }

    if (isItemSelected(id, excludeArray)) {
      setExcludeHandler(excludeArray?.filter((itemId) => itemId !== id));
    } else {
      setExcludeHandler([...excludeArray, id]);
    }
  };

  const handleSelectAllButtonInMenuItemsDropdown = (category) => {
    const categoryItems = category.menuItems
      .filter((item) => !item.isArchived)
      .filter((menuItem) =>
        filterByLanguage(menuItem.name, watchedLanguageName)
      )
      .map((item) => item.id);

    if (categoryItems.every((itemId) => isItemSelected(itemId, excludeItems))) {
      setExcludeItemsHandler(
        excludeItems?.filter((itemId) => !categoryItems.includes(itemId))
      );
    } else {
      setExcludeItemsHandler([...excludeItems, ...categoryItems]);
    }
  };

  const toggleAllSelection = (type) => {
    if (type === DROPDOWN_TYPES.category) {
      const validCategories = categories.filter((category) =>
        filterByLanguage(category.name, watchedLanguageName)
      );

      const allCategoriesSelected = validCategories.every((category) =>
        excludeCategories.includes(category.id)
      );

      if (allCategoriesSelected) {
        setExcludeCategoriesHandler(
          excludeCategories.filter((id) =>
            validCategories.every((category) => category.id !== id)
          )
        );
      } else {
        setExcludeCategoriesHandler(
          validCategories.map((category) => category.id)
        );
      }
    } else if (type === DROPDOWN_TYPES.items) {
      const validItems = items.filter((item) =>
        filterByLanguage(item.name, watchedLanguageName)
      );

      const allItemsSelected = validItems.every((item) =>
        excludeItems.includes(item.id)
      );

      if (allItemsSelected) {
        setExcludeItemsHandler(
          excludeItems.filter(
            (id) => !validItems.some((item) => item.id === id)
          )
        );
      } else {
        setExcludeItemsHandler(validItems.map((item) => item.id));
      }
    }
  };

  const renderCategories = () =>
    categories.map((category) => (
      <div
        className={cx("SelectedCardBodyItem", {
          onError: !filterByLanguage(category.name, watchedLanguageName),
        })}
        key={category.id}
        onClick={() =>
          toggleItemSelection(
            category.id,
            category.name,
            excludeCategories,
            setExcludeCategoriesHandler,
            watchedLanguageName
          )
        }
      >
        <div className="SelectedCardBodyItemWrapper">
          <RadioButton
            checked={isItemSelected(category.id, excludeCategories)}
          />
          <h6 className="SemiBold SelectedCardBodyItemTitle">
            {displayDataByLanguage(category.name)}
          </h6>
        </div>
        {!filterByLanguage(category.name, watchedLanguageName) && (
          <div className="SelectedCardBodyItemErrorMessage">
            <p>{t("menu.translate.categoryErrorMessage")}</p>
          </div>
        )}
      </div>
    ));

  const renderItems = () =>
    categories.map(
      (category) =>
        category.menuItems.length > 0 && (
          <div key={category.id} className="SelectedCardBodyAllList">
            <div className="SelectedCardBodyItem allItemSelect">
              <div className="SelectedCardBodyItemWrapper">
                <h6 className="SemiBold">
                  {displayDataByLanguage(category.name)}
                </h6>
                <button
                  className="weekDaysBtnTitle Medium SelectedCardBodyItemButton"
                  onClick={() =>
                    handleSelectAllButtonInMenuItemsDropdown(category)
                  }
                  type="button"
                >
                  {category.menuItems.some((menuItem) =>
                    isItemSelected(menuItem.id, excludeItems)
                  )
                    ? t("dashboard.unSelect")
                    : t("buttons.selectAll")}
                </button>
              </div>
            </div>
            <div className="SelectedCardBodyList">
              {category.menuItems
                .filter((menuItem) => !menuItem.isArchived)
                .sort((a, b) => a.placeInTheList - b.placeInTheList)
                .map((menuItem) => (
                  <div
                    className={cx("SelectedCardBodyListItem", {
                      onError: !filterByLanguage(
                        menuItem.name,
                        watchedLanguageName
                      ),
                    })}
                    key={menuItem.id}
                    onClick={() =>
                      toggleItemSelection(
                        menuItem.id,
                        menuItem.name,
                        excludeItems,
                        setExcludeItemsHandler,
                        watchedLanguageName
                      )
                    }
                  >
                    <div className="SelectedCardBodyListItemWrapper">
                      <RadioButton
                        checked={isItemSelected(menuItem.id, excludeItems)}
                      />
                      <ImageWithPlaceholder
                        imageSource={createDOBucketName(menuItem.coverImageSrc)}
                        placeholder={IMAGE_ITEM_PLACEHOLDER}
                        alt={displayDataByLanguage(menuItem.name)}
                        className="SelectedCardBodyListItemImage"
                      />
                      <h6 className="SemiBold SelectedCardBodyListItemTitle">
                        {displayDataByLanguage(menuItem.name)}
                      </h6>
                    </div>
                    {!filterByLanguage(menuItem.name, watchedLanguageName) && (
                      <div className="SelectedCardBodyListItemErrorMessage">
                        <p>{t("menu.translate.itemErrorMessage")}</p>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )
    );

  return (
    <div className="SelectedCardContainer" ref={cardContentRef}>
      <div className="SelectedCardHeader">
        <div
          onClick={() => toggleAllSelection(name)}
          className="RadioButtonWrapper"
        >
          <RadioButton
            checked={
              name === DROPDOWN_TYPES.category
                ? excludeCategories?.length ===
                  categories.filter((category) =>
                    category.name.some(
                      (name) => name.languageCode === watchedLanguageName
                    )
                  ).length
                : excludeItems?.length ===
                  items.filter((item) =>
                    item.name.some(
                      (name) => name.languageCode === watchedLanguageName
                    )
                  ).length
            }
          />
        </div>
        <div
          className="TitleDropdownWrapper"
          onClick={() => {
            cardContentRef.current.classList.toggle("isOpen");
          }}
        >
          <h6 className="SemiBold SelectedCardHeaderTitle">{title}</h6>
          <img src={DropdownArrowDown} alt="" />
        </div>
      </div>
      <div className="SelectedCardBody">
        {name === "category" ? renderCategories() : renderItems()}
      </div>
    </div>
  );
};

TranslateAiDropdown.propTypes = {
  categories: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  excludeCategories: PropTypes.array,
  excludeItems: PropTypes.array,
  setExcludeCategoriesHandler: PropTypes.func,
  setExcludeItemsHandler: PropTypes.func,
  watchedLanguageName: PropTypes.string,
  isItemSelectedError: PropTypes.bool,
  setIsItemSelectedError: PropTypes.func,
};

export default TranslateAiDropdown;

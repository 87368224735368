import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Tag, { ENUMS as ENUMS_TAG } from "components/elements/tag/Tag";
import TagWithIcon, {
  ENUMS as ENUMS_ICONTAG,
} from "components/elements/tag-with-icon/TagWithIcon";
import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import MenuViewPokerSlider from "components/homepage-views/menu-view-poker/menu-view-poker-slider/MenuViewPokerSlider";
import {
  calculateItemPriceWithDefaultModificationPrice,
  calculateMenuItemPriceBySchedule,
} from "utils/general";
import useLanguage from "utils/hooks/useLanguage";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import useTags from "utils/hooks/useTags";
import useTagsWIcon from "utils/hooks/useTagsWithIcon";

import "./MenuViewPoker.scss";

export const ENUMS = {
  name: "MenuViewPoker",
};

const MenuViewPoker = ({
  menuItems,
  onAdd,
  onFavorite,
  onClick,
  favoriteItems = [],
  orderItems = [],
}) => {
  const { displayDataByLanguage } = useLanguage();
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [hasAnimation, setHasAnimation] = useState(false);
  const sliderItems = menuItems.map((item) => ({
    imgSrc: item.coverImageSrc || IMAGE_ITEM_PLACEHOLDER,
    discountRate: item.rate && !item.rate.isFixed && item.rate,
  }));

  const currentItem = menuItems[activeCardIndex];
  const nextItemId = menuItems[activeCardIndex + 1]?.id;
  const prevItemId = menuItems[activeCardIndex - 1]?.id;

  const { allTags } = useTags(currentItem, true);
  const { allTagsWithIcon } = useTagsWIcon(
    currentItem,
    displayDataByLanguage,
    true
  );

  const handleSliderChange = (index) => {
    setHasAnimation(true);
    setTimeout(() => {
      setActiveCardIndex(index);
      setHasAnimation(false);
    }, 200);
  };

  const discountPrice = calculateMenuItemPriceBySchedule(currentItem);
  const menuItemPrice =
    calculateItemPriceWithDefaultModificationPrice(currentItem);
  return (
    <div className="MenuViewPoker" onClick={() => onClick(currentItem.id)}>
      <MenuViewPokerSlider
        menuItems={sliderItems}
        setActiveCardIndex={handleSliderChange}
        favoriteItems={favoriteItems}
        orderItems={orderItems}
        onFavorite={onFavorite}
        onAdd={onAdd}
        currentItem={currentItem}
        nextItemId={nextItemId}
        prevItemId={prevItemId}
        activeCardIndex={activeCardIndex}
      />
      <div className="MenuViewPokerBottom">
        <div
          className={cx("MenuViewPokerBottomContent", {
            hasAnimation: hasAnimation,
          })}
        >
          <div>
            <div className="MenuViewPokerNameAndPrice">
              <div className="MenuViewPokerPriceAndTags">
                <div className="MenuViewPokerPrice">
                  <Price
                    type={ENUMS_PRICE.types.PRICE_XXXL_BOLD}
                    value={
                      discountPrice !== null
                        ? menuItemPrice + discountPrice
                        : menuItemPrice
                    }
                  />
                  {discountPrice !== null && (
                    <Price
                      type={ENUMS_PRICE.types.PRICE_LINE_THROUGH}
                      value={menuItemPrice}
                    />
                  )}
                </div>
                <div className="MenuViewPokerTags">
                  <Tag items={allTags} type={ENUMS_TAG.types.TYPE_A} />
                </div>
              </div>
              <h4 className="MenuViewPokerName Medium">
                {displayDataByLanguage(currentItem.name)}
              </h4>
            </div>
            <div className="MenuViewPokerTagsWithIcon">
              <TagWithIcon
                items={allTagsWithIcon}
                type={ENUMS_ICONTAG.types.TYPE_A}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MenuViewPoker.propTypes = {
  /**
   * Menu items array
   */
  menuItems: PropTypes.array.isRequired,

  /**
   * Add action
   */
  onAdd: PropTypes.func.isRequired,

  /**
   * Favorite action
   */
  onFavorite: PropTypes.func.isRequired,

  /**
   * A function to handle a click event on an item.
   */
  onClick: PropTypes.func.isRequired,

  /**
   * An array of favorite items.
   */
  favoriteItems: PropTypes.array,

  /**
   * An array of order items.
   */
  orderItems: PropTypes.array,
};

export default MenuViewPoker;

import React from "react";
import PropTypes from "prop-types";
import { Group, Rect, Text, Ellipse } from "react-konva";
import { SHAPE_ENUMS } from "utils/constants/data/base";

const TableShape = ({
  mapItem,
  setSelectedId,
  mapItems,
  setMapItems,
  ratio,
  setOpenSlideEditTableMap,
  setSelectedTable,
  hasUnsavedTableChanges,
}) => {
  const { id, width, height, x, y, rotation, name, shape } = mapItem;

  const handleItemClick = (id) => {
    setSelectedId((prev) => (id === prev ? null : id));
  };
  const handleDoubleClick = () => {
    setSelectedTable(mapItem);
    setOpenSlideEditTableMap(true);
  };

  const handleDragStart = (e) => {
    const id = e.target.id();
    setSelectedId(null);
    setMapItems((prev) =>
      prev.map((item) => ({
        ...item,
        isDragging: item.id === id,
      }))
    );
  };

  const handleDragMove = (e) => {
    const shapeTarget = e.target;
    const stage = shapeTarget.getStage();
    const stageWidth = stage.width();
    const stageHeight = stage.height();

    let newX, newY;

    if (shape === SHAPE_ENUMS["CIRCLE"]) {
      const radiusX = shapeTarget.width() / 2;
      const radiusY = shapeTarget.height() / 2;

      newX = Math.max(radiusX, Math.min(shapeTarget.x(), stageWidth - radiusX));
      newY = Math.max(
        radiusY,
        Math.min(shapeTarget.y(), stageHeight - radiusY)
      );
    } else {
      newX = Math.max(
        0,
        Math.min(shapeTarget.x(), stageWidth - shapeTarget.width())
      );
      newY = Math.max(
        0,
        Math.min(shapeTarget.y(), stageHeight - shapeTarget.height())
      );
    }

    shapeTarget.position({ x: newX, y: newY });
  };

  const handleDragEnd = (e) => {
    const id = e.target.id();
    const node = e.target;
    setMapItems((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            x:
              shape === SHAPE_ENUMS["CIRCLE"]
                ? (node.x() / ratio - item.width / 2).toFixed(2)
                : (node.x() / ratio).toFixed(2),
            y:
              shape === SHAPE_ENUMS["CIRCLE"]
                ? (node.y() / ratio - item.height / 2).toFixed(2)
                : (node.y() / ratio).toFixed(2),
            isDragging: false,
          };
        }
        return item;
      })
    );
  };

  const handleTransformEnd = (e) => {
    const id = e.target.id();
    const node = e.target;

    const updatedMapItems = mapItems.map((item) => {
      if (item.id === id) {
        if (item.shape === SHAPE_ENUMS["CIRCLE"]) {
          const newWidth = (node.width() * node.scaleX()) / ratio;
          const newHeight = (node.height() * node.scaleY()) / ratio;
          return {
            ...item,
            x: (node.x() / ratio - newWidth / 2).toFixed(2),
            y: (node.y() / ratio - newHeight / 2).toFixed(2),
            width: newWidth.toFixed(2),
            height: newHeight.toFixed(2),
            rotation: node.rotation(),
          };
        } else {
          return {
            ...item,
            x: (node.x() / ratio).toFixed(2),
            y: (node.y() / ratio).toFixed(2),
            width: ((node.width() / ratio) * node.scaleX()).toFixed(2),
            height: ((node.height() / ratio) * node.scaleY()).toFixed(2),
            rotation: node.rotation(),
          };
        }
      }
      return item;
    });

    node.scaleX(1);
    node.scaleY(1);
    setMapItems(updatedMapItems);
  };

  return (
    <Group
      id={id}
      x={shape === SHAPE_ENUMS["CIRCLE"] ? x + width / 2 : x}
      y={shape === SHAPE_ENUMS["CIRCLE"] ? y + height / 2 : y}
      width={width}
      height={height}
      rotation={rotation}
      draggable
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
      onClick={() => {
        if (!hasUnsavedTableChanges) {
          handleItemClick(id);
        }
      }}
      onTransformEnd={handleTransformEnd}
      onDblClick={() => {
        if (!hasUnsavedTableChanges) {
          handleDoubleClick(id);
        }
      }}
    >
      <>
        {shape === SHAPE_ENUMS["RECTANGLE"] && (
          <Rect
            width={width}
            height={height}
            fill="#EFEDED"
            cornerRadius={14}
            shadowColor="black"
            // shadowBlur={10}
            shadowOpacity={0.6}
          />
        )}
        {shape === SHAPE_ENUMS["CIRCLE"] && (
          <Ellipse fill="#EFEDED" radiusX={width / 2} radiusY={height / 2} />
        )}
        <Text
          text={name}
          fontSize={16}
          fontStyle="bold"
          fill="black"
          align="center"
          verticalAlign="middle"
          x={shape === SHAPE_ENUMS["RECTANGLE"] ? 0 : -width / 2}
          y={shape === SHAPE_ENUMS["RECTANGLE"] ? height / 2 - 8 : -8}
          width={width}
          height={16}
        />
      </>
    </Group>
  );
};

TableShape.propTypes = {
  mapItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    rotation: PropTypes.number,
    name: PropTypes.string.isRequired,
    shape: PropTypes.number,
  }).isRequired,
  setSelectedId: PropTypes.func.isRequired,
  mapItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      rotation: PropTypes.number,
      isDragging: PropTypes.bool,
      shape: PropTypes.number,
    })
  ).isRequired,
  setMapItems: PropTypes.func.isRequired,
  ratio: PropTypes.number.isRequired,
  hasUnsavedTableChanges: PropTypes.bool,
  setSelectedTable: PropTypes.func,
  setOpenSlideEditTableMap: PropTypes.func,
};

export default TableShape;

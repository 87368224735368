import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SelectUserBusiness from "components/admin/modal/select-user-business/SelectUserBusiness";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import ILoyalLogo from "assets/icons/logo/iLoyal/logo.svg";

import "./AdminBlock.scss";

const AdminBlock = ({ business }) => {
  const { t } = useTranslation();

  return (
    <div className="AdminBlock">
      <div className="AdminBlockHeader">
        <img src={ILoyalLogo} alt="logo" />
      </div>
      <div className="AdminBlockBoxContainer">
        <div className="AdminBlockBox">
          <SelectUserBusiness
            userBusinesses={[business]}
            title={t("businesses.information")}
            selectedBusiness={business}
            setSelectedBusiness={() => null}
          />
          <PrimaryButton
            onClick={() => null}
            text={t("buttons.contactUs")}
            className="ContactUsButton"
          />
        </div>
      </div>
    </div>
  );
};

AdminBlock.propTypes = {
  business: PropTypes.object,
};

export default AdminBlock;

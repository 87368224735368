import { STORE_NAMES } from "utils/constants/redux";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "utils/api/services/guest";

export const createGuestAsync = createAsyncThunk(
  `${STORE_NAMES.guest}/createGuest`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.createAnonymousGuest();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const registerGuestAsync = createAsyncThunk(
  `${STORE_NAMES.guest}/registerGuest`,
  async ({ guestId, otpToken, guestBody }, { rejectWithValue }) => {
    try {
      const response = await api.registerGuest({
        guestId,
        otpToken,
        guestBody,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loginGuestAsync = createAsyncThunk(
  `${STORE_NAMES.guest}/loginGuest`,
  async ({ guestBody }, { rejectWithValue }) => {
    try {
      const response = await api.loginGuest({
        guestBody,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateGuestInfoAsync = createAsyncThunk(
  `${STORE_NAMES.guest}/updateGuestInfo`,
  async ({ guestBody, guestId }, { rejectWithValue }) => {
    try {
      const response = await api.updateGuestInfo({ guestBody, guestId });
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getGuestByTokenAsync = createAsyncThunk(
  `${STORE_NAMES.guest}/getGuestByToken`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getGuestByToken();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

import React from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import { zodResolver } from "@hookform/resolvers/zod";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, FormProvider, useForm } from "react-hook-form";

import useAsync from "utils/hooks/useAsync";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import IconButton from "components/buttons/icon-button/IconButton";
import EmailOrPhone from "components/elements/email-or-phone/EmailOrPhone";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useFormOutsideClickHandler from "utils/hooks/useFormOutsideClickHandler";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { assignUserToBusiness, createUser } from "utils/api/services/user";
import {
  convertSnakeCaseToTitleCase,
  handleOnAsyncSuccess,
} from "utils/helpers";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import { ReactComponent as IconArrowLeft } from "assets/icons/arrows/ArrowLeft.svg";
import { getUsers } from "redux/actions/userAction";
import { STORE_NAMES } from "utils/constants/redux";
import { USER_MODAL_ENUMS } from "../../AdminUser";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";

import "./AdminUserModalBody.scss";

const AdminAddUserModalBody = ({
  signInMethod,
  handleClickBackButton,
  formData,
  isUserExist,
  setSignInMethod,
  setOpenSlide,
  setOutsideClickAction,
  initialFormData,
  setFormData,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const schemas = useValidationSchema(t);
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business].business?.id
  );
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();

  const handleOnAsyncSuccessForUser = (successMessage) => {
    handleOnAsyncSuccess(successMessage, () => {
      dispatch(getUsers({ businessId }));
    });
  };

  const [
    openSlideConfirmCloseModal,
    setOpenSlideConfirmCloseModal,
    mainElementRefConfirmCloseModal,
  ] = useOutsideClick();

  const methods = useForm({
    resolver: zodResolver(schemas.userModalSchema(!isUserExist)),
    criteriaMode: USER_MODAL_ENUMS.criteriaModeAll,
    defaultValues: {
      phoneNumber: formData.phoneNumber,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
    },
  });

  const {
    watch,
    handleSubmit,
    control,
    register,
    getValues,
    setError,
    formState: { errors },
  } = methods;

  watch();

  const handleOnCloseModal = () => {
    const editedFormData = { ...formData, ...getValues() };
    if (!isEqual(editedFormData, initialFormData)) {
      return setOpenSlideConfirmCloseModal(true);
    }
    setSignInMethod(USER_MODAL_ENUMS.phoneNumber);
    setFormData(initialFormData);
    setOpenSlide(false);
  };

  useFormOutsideClickHandler({
    formData: { ...formData, ...watch() },
    formDataInitial: initialFormData,
    setOpenSlide,
    setOpenSlideConfirmCloseModal,
    setOutsideClickAction,
  });

  const handleOnCancelCloseModal = () => {
    setOpenSlideConfirmCloseModal(false);
  };

  const handleOnConfirmCloseModal = () => {
    setFormData(initialFormData);
    setOpenSlideConfirmCloseModal(false);
    setOpenSlide(false);
  };

  const {
    execute: executeAssignUserToBusiness,
    loading: isLoadingAssignUserToBusiness,
  } = useAsync(assignUserToBusiness, {
    onError: (error) => {
      const errorData = handleAPIErrorMessage(error.response.data);
      if (errorData) {
        const { field, errorMessage } = errorData;
        setError(field, {
          type: "manual",
          message: errorMessage,
        });
      }
    },
    onSuccess: () => {
      handleOnAsyncSuccessForUser(t("toastMessages.success.updateUser"));
      setOpenSlide(false);
      setSignInMethod(USER_MODAL_ENUMS.phoneNumber);
      setFormData(initialFormData);
    },
  });

  const { execute: executeCreateUser, loading: isLoadingCreateUser } = useAsync(
    createUser,
    {
      onError: (error) => {
        const errorData = handleAPIErrorMessage(error.response.data);
        if (errorData) {
          const { field, errorMessage } = errorData;
          setError(field, {
            type: "manual",
            message: errorMessage,
          });
        }
      },
      onSuccess: () => {
        handleOnAsyncSuccessForUser(t("toastMessages.success.createUser"));
        setOpenSlide(false);
        setSignInMethod(USER_MODAL_ENUMS.phoneNumber);
        setFormData(initialFormData);
      },
    }
  );

  const roleEnums = Object.values(
    useSelector((state) => state[STORE_NAMES.app].roles)
  );

  const roles = roleEnums
    .map((role) => {
      return {
        id: role.id,
        name: convertSnakeCaseToTitleCase(role.name),
      };
    })
    .filter((role) => role !== null);

  const getRoleName = (selectedRole) =>
    roleEnums.find((role) => role?.id === selectedRole?.id)?.name;

  const handleClickSubmitAddUser = () => {
    if (isUserExist) {
      const userData = {
        role: getRoleName(getValues().role),
        pinCode: getValues().pinCode,
      };
      executeAssignUserToBusiness({
        businessId,
        userId: formData.userId,
        otpToken: formData.otpToken,
        userData,
      });
    } else {
      const userData = {
        firstName: getValues().firstName,
        lastName: getValues().lastName,
        role: getRoleName(getValues().role),
        pinCode: getValues().pinCode,
        password: getValues().password,
      };
      let hasError = false;

      Object.keys(userData).forEach((key) => {
        if (key === "pinCode") return;
        if (!userData[key]) {
          setError(key, {
            type: "required",
            message: t("errorMessages.required"),
          });
          hasError = true;
        }
      });

      if (!hasError) {
        executeCreateUser({
          businessId,
          user: userData,
          otpToken: formData.otpToken,
        });
      }
    }
  };

  return (
    <div>
      <div>
        <div className="AdminUserModalTitle">
          <div className="AdminUserModalTitleLeft">
            <IconButton
              onClick={handleClickBackButton}
              svgComponent={<IconArrowLeft />}
              className="ModalHeaderBackIcon"
            />
          </div>
          <h3 className="SemiBold">{t("user.addEmployee")}</h3>
          <div className="AdminUserModalTitleRight">
            <IconButton
              onClick={handleOnCloseModal}
              svgComponent={<IconClose />}
            />
          </div>
        </div>
        <div className="AdminUserModalBody">
          <div className="AdminAddUserModal">
            <FormProvider {...methods}>
              <EmailOrPhone
                readOnly={true}
                hideSignInMethod={true}
                signInMethod={signInMethod}
                className="AdminAddUserModalBodyEmailOrPhone"
              />
            </FormProvider>
            <form className="AdminUserModalBodyForm">
              <InputControl
                name="firstName"
                required
                type="text"
                placeholder={t("inputs.firstName")}
                {...register("firstName")}
                hasError={errors.firstName}
                labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
                readOnly={!!formData?.firstName}
                error={
                  <ErrorMessage
                    errors={errors}
                    name="firstName"
                    render={({ message }) => (
                      <p className="h7 error-message">{message}</p>
                    )}
                  />
                }
              />
              <InputControl
                name="lastName"
                placeholder={t("inputs.lastName")}
                required
                type="text"
                {...register("lastName")}
                hasError={errors.lastName}
                labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
                readOnly={!!formData?.lastName}
                error={
                  <ErrorMessage
                    errors={errors}
                    name="lastName"
                    render={({ message }) => (
                      <p className="h7 error-message">{message}</p>
                    )}
                  />
                }
              />
              <Controller
                name="role"
                control={control}
                defaultValue={{}}
                render={({ field: { value, onChange } }) => (
                  <Dropdown
                    onChange={(value) => {
                      onChange(value);
                    }}
                    required
                    isOptionRequired
                    placeholder={t("inputs.role")}
                    name="role"
                    value={value}
                    options={roles}
                    func={{
                      ...register("role"),
                    }}
                    error={
                      <ErrorMessage
                        errors={errors}
                        name="role"
                        render={({ message }) => (
                          <p className="h7 error-message">{message}</p>
                        )}
                      />
                    }
                    hasError={errors.role}
                  />
                )}
              />
              <InputControl
                name="pinCode"
                type="text"
                placeholder={t("inputs.pinCode")}
                required
                {...register("pinCode")}
                labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
                hasError={errors.pinCode}
                error={
                  <ErrorMessage
                    errors={errors}
                    name="pinCode"
                    render={({ message }) => (
                      <p className="h7 error-message">{message}</p>
                    )}
                  />
                }
              />
              {!isUserExist && (
                <InputControl
                  name="password"
                  type="password"
                  required
                  placeholder={t("inputs.password")}
                  {...register("password")}
                  labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
                  error={
                    <ErrorMessage
                      errors={errors}
                      name="password"
                      render={({ message }) => (
                        <p className="h7 error-message">{message}</p>
                      )}
                    />
                  }
                  hasError={errors.password}
                />
              )}
            </form>
          </div>
        </div>
        <div className="AdminUserModalFooter">
          <PrimaryButton
            onClick={handleSubmit(handleClickSubmitAddUser)}
            text={t("buttons.save")}
            isLoading={isLoadingCreateUser || isLoadingAssignUserToBusiness}
          />
        </div>
      </div>
      <Confirm
        title={t("modal.warningModalTitleUnsavedChanges")}
        type={ENUMS_CONFIRM.types.TYPE_C}
        mainElementRefConfirm={mainElementRefConfirmCloseModal}
        openSlide={openSlideConfirmCloseModal}
        onCancel={handleOnCancelCloseModal}
        onConfirm={handleOnConfirmCloseModal}
        description={t("modal.warningModalDescription")}
      />
    </div>
  );
};

AdminAddUserModalBody.propTypes = {
  signInMethod: PropTypes.string,
  handleClickBackButton: PropTypes.func,
  formData: PropTypes.object,
  isUserExist: PropTypes.bool,
  setSignInMethod: PropTypes.func,
  setOpenSlide: PropTypes.func,
  setOutsideClickAction: PropTypes.func,
  initialFormData: PropTypes.object,
  setFormData: PropTypes.func,
  mainElementRefConfirmCloseModal: PropTypes.object,
  openSlideConfirmCloseModal: PropTypes.bool,
};

export default AdminAddUserModalBody;

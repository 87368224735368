import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { ReactComponent as IconUserAvatar } from "assets/icons/user-avatar/UserAvatar.svg";
import { createDOBucketName } from "utils/DO-Spaces";

import "./GuestProfileWithIcon.scss";

export const ENUMS = {
  name: "GuestProfileWithIcon",
};

const GuestProfileWithIcon = ({
  image,
  hasImage,
  loading,
  handleOnClick,
  className,
  actionButton,
}) => {
  return (
    <div onClick={handleOnClick}>
      <div className={cx("GuestProfileWithIconContainer", className)}>
        {hasImage ? (
          <img
            src={createDOBucketName(image)}
            alt="avatar"
            className={cx("GuestProfileWithIconProfilePicture", {
              isLoading: loading,
            })}
          />
        ) : (
          <IconUserAvatar className="GuestProfileWithIconAvatar" />
        )}
      </div>
      <div className="GuestProfileWithIconActionButtonWrapper">
        {actionButton}
      </div>
    </div>
  );
};

GuestProfileWithIcon.propTypes = {
  /**
   * The image of the guest.
   */
  image: PropTypes.string,

  /**
   * A boolean to determine if the guest has an image.
   */
  hasImage: PropTypes.bool,
  /**
   * A boolean to determine if the image is loading.
   */
  loading: PropTypes.bool,
  /**
   * A callback function to handle click events on Guest Profile.
   */
  handleOnClick: PropTypes.func,

  /**
   * Custom className for additional styling
   */
  className: PropTypes.string,

  /**
   * Custom action button
   */
  actionButton: PropTypes.node,
};

export default GuestProfileWithIcon;

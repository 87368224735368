import React from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import { useSelector } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";

import useAsync from "utils/hooks/useAsync";
import IconButton from "components/buttons/icon-button/IconButton";
import EmailOrPhone from "components/elements/email-or-phone/EmailOrPhone";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import useFormOutsideClickHandler from "utils/hooks/useFormOutsideClickHandler";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { createOtpRequestForAssignUserToBusiness } from "utils/api/services/otp";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import { STEP_ENUMS, USER_MODAL_ENUMS } from "../../AdminUser";
import { STORE_NAMES } from "utils/constants/redux";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";

import "./AdminUserModalBody.scss";

const AdminLoginMethodModalBody = ({
  signInMethod,
  setSignInMethod,
  setModalStep,
  setFormData,
  formData,
  setOpenSlide,
  initialFormData,
  setOutsideClickAction,
  setCounter,
}) => {
  const { t } = useTranslation();
  const schemas = useValidationSchema(t);
  const methods = useForm({
    resolver: zodResolver(schemas.emailOrPhoneSchema(signInMethod)),
    criteriaMode: USER_MODAL_ENUMS.criteriaModeAll,
    defaultValues: {
      phoneNumber: formData.phoneNumber,
      email: formData.email,
    },
  });
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business].business?.id
  );
  const { getValues, watch, handleSubmit, setError } = methods;

  const [
    openSlideConfirmCloseModal,
    setOpenSlideConfirmCloseModal,
    mainElementRefConfirmCloseModal,
  ] = useOutsideClick();

  const { execute: executeCreateOtpCode, loading: isLoadingCreateOtpCode } =
    useAsync(createOtpRequestForAssignUserToBusiness, {
      onError: (error) => {
        const errorData = handleAPIErrorMessage(error.response.data);
        if (errorData) {
          const { field, errorMessage } = errorData;
          setError(field, {
            type: "manual",
            message: errorMessage,
          });
        }
      },
      onSuccess: ({ data }) => {
        setFormData((prev) => ({
          ...prev,
          otpToken: data.otpToken,
          otpSendCount: data.otpSendCount,
        }));
        setCounter(60);
        setModalStep(STEP_ENUMS.OTP_VERIFICATION);
      },
    });

  const handleOnCloseModal = () => {
    const editedFormData = { ...formData, ...getValues() };
    if (!isEqual(editedFormData, initialFormData)) {
      return setOpenSlideConfirmCloseModal(true);
    }
    setSignInMethod(USER_MODAL_ENUMS.phoneNumber);
    setFormData(initialFormData);
    setOpenSlide(false);
  };

  useFormOutsideClickHandler({
    formData: { ...formData, ...watch() },
    formDataInitial: initialFormData,
    setOpenSlide,
    setOpenSlideConfirmCloseModal,
    setOutsideClickAction,
  });

  const handleOnCancelCloseModal = () => {
    setOpenSlideConfirmCloseModal(false);
  };

  const handleOnConfirmCloseModal = () => {
    setFormData(initialFormData);
    setOpenSlideConfirmCloseModal(false);
    setOpenSlide(false);
  };

  const handleClickSubmitLoginMethod = () => {
    if (signInMethod === USER_MODAL_ENUMS.phoneNumber) {
      handlePhoneNumberStep(getValues());
    } else if (signInMethod === USER_MODAL_ENUMS.email) {
      handleEmailStep(getValues());
    }
  };

  const handlePhoneNumberStep = (formDataValues) => {
    if (!formDataValues.phoneNumber) return;
    formDataValues.phoneNumber = formDataValues.phoneNumber.replace(/\+/g, "");
    setFormData({ ...formData, phoneNumber: formDataValues.phoneNumber });
    executeCreateOtpCode({
      otpBody: {
        phoneNumber: formDataValues.phoneNumber,
      },
      businessId,
    });
  };
  const handleEmailStep = (formDataValues) => {
    if (!formDataValues.email) return;
    formDataValues.email = formDataValues.email.trim();
    setFormData({ ...formData, email: formDataValues.email });
    executeCreateOtpCode({
      otpBody: { email: formDataValues.email },
      businessId,
    });
  };

  return (
    <div>
      <div>
        <div className="AdminUserModalTitle">
          <div className="AdminUserModalTitleLeft"></div>
          <h3 className="SemiBold">{t("user.addEmployee")}</h3>
          <div className="AdminUserModalTitleRight">
            <IconButton
              onClick={handleOnCloseModal}
              svgComponent={<IconClose />}
            />
          </div>
        </div>
        <div className="AdminUserModalBody">
          <div className="AdminUserModalSignInDetails">
            <FormProvider {...methods}>
              <EmailOrPhone
                signInMethod={signInMethod}
                setSignInMethod={setSignInMethod}
              />
            </FormProvider>
            <div className="UserModalWithoutSignUp">
              <h6>
                {t("login.or")} {t("auth.withoutSignUp")}{" "}
                <span
                  className="SemiBold h4"
                  onClick={() => {
                    setModalStep(STEP_ENUMS.ADD_USER_WITHOUT_SIGN_UP);
                  }}
                >
                  {t("buttons.continue")}
                </span>
              </h6>
            </div>
          </div>
        </div>
        <div className="AdminUserModalFooter">
          <PrimaryButton
            onClick={handleSubmit(handleClickSubmitLoginMethod)}
            text={t("buttons.continue")}
            isLoading={isLoadingCreateOtpCode}
          />
        </div>
      </div>
      <Confirm
        title={t("modal.warningModalTitleUnsavedChanges")}
        type={ENUMS_CONFIRM.types.TYPE_C}
        mainElementRefConfirm={mainElementRefConfirmCloseModal}
        openSlide={openSlideConfirmCloseModal}
        onCancel={handleOnCancelCloseModal}
        onConfirm={handleOnConfirmCloseModal}
        description={t("modal.warningModalDescription")}
      />
    </div>
  );
};

AdminLoginMethodModalBody.propTypes = {
  signInMethod: PropTypes.string,
  setSignInMethod: PropTypes.func,
  setModalStep: PropTypes.func,
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  setCounter: PropTypes.func,
  setOpenSlide: PropTypes.func,
  initialFormData: PropTypes.object,
  setOutsideClickAction: PropTypes.func,
};

export default AdminLoginMethodModalBody;

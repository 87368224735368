export const INITIAL_SIZE_CONTAINER = {
  width: 0,
  height: 0,
};
export const INITIAL_SIZE_ZONE = { width: 10, height: 5 };

export const generateShapeForMap = (tables) => {
  return tables.map((table, index) => {
    const { id, name, maxSeat, shape } = table;
    return {
      id: id.toString(),
      name,
      maxSeat,
      x: 0 + index * 0.2,
      y: 0 + index * 0.2,
      shape,
      rotation: 0,
      isDragging: false,
      width: 2,
      height: 2,
    };
  });
};

export const convertPixelToMeter = ({ item, ratio }) => {
  const { x, y, width, height } = item;
  return {
    ...item,
    x: x / ratio,
    y: y / ratio,
    width: width / ratio,
    height: height / ratio,
  };
};
export const convertMeterToPixel = ({ item, ratio }) => {
  const { x, y, width, height } = item;
  return {
    ...item,
    x: x * ratio,
    y: y * ratio,
    width: width * ratio,
    height: height * ratio,
  };
};

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useDebounce from "utils/hooks/useDebounce";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import useAsync from "utils/hooks/useAsync";
import { findMenuItemById } from "utils/helpers";
import { createDOBucketName } from "utils/DO-Spaces";
import { STORE_NAMES } from "utils/constants/redux";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import AdminDashTable from "components/admin/tables/admin-dash-table/AdminDashTable";
import { getStatsMenuStatisticsTableData } from "utils/api/services/dashboard";
import useLanguage from "utils/hooks/useLanguage";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import { ReactComponent as SortArrowDefault } from "assets/icons/admin-dashboard/sortArrowDefault.svg";

import "./AdminDashAllMenus.scss";

const TABLE_KEYS = {
  id: "id",
  name: "name",
  category: "category",
  orderCount: "orderCount",
  price: "price",
  cost: "cost",
  revenue: "revenue",
  totalSale: "totalSale",
};

const getMenuStatisticsTableData = (
  fetchedRawTableData,
  categories,
  activePage,
  currencySymbol
) => {
  return (
    fetchedRawTableData?.data?.map((item, itemIndex) => {
      const selectedCategoryData = categories?.find(
        (category) => category.id === item.categoryId
      );

      const selectedMenuItemData = findMenuItemById(categories, item.itemId);

      const menuItem = {
        key: item.itemId,
        id: item.itemId,
        menuItemName: selectedMenuItemData?.name || "",
        menuItemPicture:
          createDOBucketName(selectedMenuItemData?.coverImageSrc) ||
          IMAGE_ITEM_PLACEHOLDER,
        categoryName: selectedCategoryData?.name || "",
        orderCount: item.orderCount || 0,
        menuItemPrice: item.price || 0,
        cost: item.cost || 0,
        revenue: item.revenue || 0,
        totalSale: parseFloat(item.totalSale).toFixed(2) || 0,
      };

      return {
        [TABLE_KEYS.id]: `${menuItem.id}-${itemIndex}`,
        [TABLE_KEYS.name]: (
          <div className="TableItemInfo" title={`${menuItem?.menuItemName}`}>
            <h6 className="Medium TableItemIndex">
              {activePage > 1
                ? 1 + itemIndex + activePage * 10 - 10
                : 1 + itemIndex}
            </h6>
            <div className="TableItemImage">
              <ImageWithPlaceholder
                imageSource={menuItem?.menuItemPicture}
                placeholder={IMAGE_ITEM_PLACEHOLDER}
                alt="menu item picture"
              />
            </div>
            <h6 className="Medium MenuItemName">{menuItem?.menuItemName}</h6>
          </div>
        ),
        [TABLE_KEYS.category]: menuItem.categoryName,
        [TABLE_KEYS.orderCount]: `${menuItem.orderCount}`,
        [TABLE_KEYS.price]: `${menuItem.menuItemPrice}${currencySymbol}`,
        [TABLE_KEYS.cost]: `${menuItem.cost}${currencySymbol}`,
        [TABLE_KEYS.revenue]: `${
          menuItem.revenue === 0 ? "-" : `${menuItem.revenue}${currencySymbol}`
        }`,
        [TABLE_KEYS.totalSale]: `${menuItem.totalSale}${currencySymbol}`,
      };
    }) || []
  );
};

const AdminDashAllMenus = () => {
  const { setTitle, fromDateToDate } = useOutletContext();
  const { t } = useTranslation();
  const { displayDataByLanguage } = useLanguage();
  const [categoryTable, setCategoryTable] = useState("");
  const [tableData, setTableData] = useState({
    data: [],
    disableNext: false,
    pageNumber: 1,
  });
  const [searchValue, setSearchValue] = useState("");
  const [searchValue2, setSearchValue2] = useState("");
  const [sortType, setSortType] = useState("");
  const [clickedColumnIndex, setClickedColumnIndex] = useState(null);
  const [, setColumnClickCount] = useState(0);
  const [activePage, setActivePage] = useState(1);

  const businessId = useSelector(
      (state) => state[STORE_NAMES.business]?.business?.id
  );
  const menuCurrency = useSelector(
    (state) => state[STORE_NAMES.menu].data?.currency
  );

  const allCategories = useSelector(
    (state) => state[STORE_NAMES.menu].data?.categories
  );

  useEffect(() => {
    setTitle(t("navbarRoutes.pageTitles.allItems"));
  }, [t]);

  const handleOnCategoryTableDropdownChange = (option) => {
    setCategoryTable(option);
    setActivePage(1);
  };

  const debouncedRequest = useDebounce(() => {
    setSearchValue2(searchValue);
  }, 1000);

  const handleSearchChange = (value) => {
    setSearchValue(value);
    debouncedRequest();
    setActivePage(1);
  };

  const DASH_HISTORY_TABLE_CONFIGS = [
    {
      key: TABLE_KEYS.name,
      name: t("dashboard.table.menuStatisticsTable.menuItem"),
      isFilterable: false,
      hasCustomRow: true,
    },
    {
      key: TABLE_KEYS.category,
      name: t("dashboard.table.menuStatisticsTable.category"),
      isFilterable: false,
    },
    {
      key: TABLE_KEYS.orderCount,
      name: (
        <div>
          {t("dashboard.table.menuStatisticsTable.order")} <br />
          {t("dashboard.table.menuStatisticsTable.count")}
        </div>
      ),
      isFilterable: true,
    },
    {
      key: TABLE_KEYS.price,
      name: (
        <div>
          {t("dashboard.table.menuStatisticsTable.price")} <br />
          {t("dashboard.table.menuStatisticsTable.item")}
        </div>
      ),
      isFilterable: true,
    },
    {
      key: TABLE_KEYS.cost,
      name: t("dashboard.table.menuStatisticsTable.cost"),
      isFilterable: true,
    },
    {
      key: TABLE_KEYS.revenue,
      name: t("dashboard.table.menuStatisticsTable.revenue"),
      isFilterable: true,
    },
    {
      key: TABLE_KEYS.totalSale,
      name: t("dashboard.table.menuStatisticsTable.total"),
      isFilterable: true,
    },
  ];
  const tableColumnKey = DASH_HISTORY_TABLE_CONFIGS[clickedColumnIndex]?.key;

  const { loading, result: fetchedMenuStatsTableData } = useAsync(
    getStatsMenuStatisticsTableData,
    {
      immediate: true,
      params: [
        businessId,
        fromDateToDate.from,
        fromDateToDate.to,
        500,
        activePage,
        tableColumnKey,
        sortType,
        searchValue2,
        categoryTable?.id,
      ],
    }
  );

  const updatedAllCategories = allCategories?.map((category) => {
    const updatedCategoryName = displayDataByLanguage(category?.name);
    const updatedMenuItems = category?.menuItems?.map((menuItem) => ({
      ...menuItem,
      name: displayDataByLanguage(menuItem?.name),
    }));

    return {
      ...category,
      name: updatedCategoryName,
      menuItems: updatedMenuItems,
    };
  });
  updatedAllCategories?.unshift({ name: t("dashboard.all"), id: undefined });

  useEffect(() => {
    if (
      fetchedMenuStatsTableData?.data?.length === 0 &&
      !categoryTable?.id &&
      activePage > 1
    ) {
      setTableData({ ...tableData, disableNext: true });
      return;
    }
    const statsMenuStatisticsTableData = getMenuStatisticsTableData(
      fetchedMenuStatsTableData,
      updatedAllCategories,
      activePage,
      menuCurrency?.symbol
    );
    setTableData({
      disableNext: fetchedMenuStatsTableData?.data?.length < 10,
      data: statsMenuStatisticsTableData,
      pageNumber: fetchedMenuStatsTableData?.pageNumber,
    });
  }, [fetchedMenuStatsTableData]);

  useEffect(() => {
    setActivePage(1);
    setSearchValue("");
    setSearchValue2("");
    setSortType("");
    setCategoryTable("");
  }, [fromDateToDate]);

  return (
    <div className="AdminDashAllMenus">
      <div className="Form">
        {/*// TODO: turn on search input, when backend live*/}
        {/*<Search*/}
        {/*  type={"TYPE_B"}*/}
        {/*  classname={"CustomSearch"}*/}
        {/*  onChange={(value) => handleSearchChange(value)}*/}
        {/*  value={searchValue}*/}
        {/*  placeholder={`${t("dashboard.placeholder.itemName")}...`}*/}
        {/*/>*/}

        <Dropdown
          className={"CustomDropDown"}
          value={categoryTable || updatedAllCategories?.[0] || ""}
          onChange={(category) => handleOnCategoryTableDropdownChange(category)}
          placeholder={t("dashboard.placeholder.categories")}
          options={updatedAllCategories}
        />
      </div>

      <AdminDashTable
        tableColumns={DASH_HISTORY_TABLE_CONFIGS}
        tableItems={tableData.data}
        disableNextButton={tableData.disableNext}
        hasEditColumnTitle={false}
        hasEditColumn={false}
        hasEditRow={false}
        showClassName={true}
        sortType={sortType}
        setSortType={setSortType}
        columnClick={clickedColumnIndex}
        setColumnClick={setClickedColumnIndex}
        showPagination={false}
        activePage={tableData.pageNumber}
        setActivePage={setActivePage}
        showLoadMore
        customFilterIcon={<SortArrowDefault />}
        isDataLoading={loading}
        setColumnClickCount={setColumnClickCount}
        tableClassName={"AdminDashMenuStatFormCustomTable"}
      />
    </div>
  );
};

export default AdminDashAllMenus;

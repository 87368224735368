export const GUEST_SIGN_UP_STEP_TYPES = {
  mainForm: {
    type: "main_form",
    title: "auth.signUp",
    key: "mainForm",
  },
  verifyOtp: {
    type: "verify_otp",
    title: "auth.signUp",
    key: "verifyOtp",
  },
  detailForm: {
    type: "detail_form",
    title: "auth.signUp",
    key: "detailForm",
  },
};
export const GUEST_FORGET_PASSWORD_STEP_TYPES = {
  mainForm: {
    type: "main_form",
    title: "auth.signUp",
    key: "mainForm",
  },
  verifyOtp: {
    type: "verify_otp",
    title: "auth.signUp",
    key: "verifyOtp",
  },
  newPassword: {
    type: "new_password",
    title: "auth.signUp",
    key: "newPassword",
  },
};

import  { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./TypingEffect.scss";

const TypingEffect =
  ({
    texts,
    typingSpeed,
    deletingSpeed,
    pauseDuration,
    deleteEnabled,
    textContainer,
  }) => {
    const [displayText, setDisplayText] = useState("");
    const [textIndex, setTextIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    useEffect(() => {
      setDisplayText("");
      setTextIndex(0);
      setCharIndex(0);
      setIsDeleting(false);
    }, [texts]);
    useEffect(() => {
      let timeout;

      if (isDeleting) {
        if (charIndex > 0) {
          timeout = setTimeout(() => {
            setDisplayText((prev) => prev.slice(0, charIndex - 1));
            setCharIndex((prev) => prev - 1);
          }, deletingSpeed);
        } else {
          setIsDeleting(false);
          setTextIndex((prev) => (prev + 1) % texts.length);
          timeout = setTimeout(() => {
            setCharIndex(0);
          }, pauseDuration);
        }
      } else {
        if (charIndex < texts[textIndex].length) {
          timeout = setTimeout(() => {
            setDisplayText((prev) => prev + texts[textIndex][charIndex]);
            setCharIndex((prev) => prev + 1);
          }, typingSpeed);
        } else {
          if (deleteEnabled) {
            timeout = setTimeout(() => {
              setIsDeleting(true);
            }, pauseDuration);
          }
        }
      }

      return () => {
        clearTimeout(timeout);
      };
    }, [
      texts,
      charIndex,
      textIndex,
      isDeleting,
      typingSpeed,
      deletingSpeed,
      pauseDuration,
    ]);

    return textContainer(displayText);
  }


TypingEffect.propTypes = {
  texts: PropTypes.arrayOf(PropTypes.string).isRequired,
  typingSpeed: PropTypes.number,
  deletingSpeed: PropTypes.number,
  pauseDuration: PropTypes.number,
  textContainer: PropTypes.func.isRequired,
  deleteEnabled: PropTypes.bool,
};

TypingEffect.defaultProps = {
  typingSpeed: 25,
  deletingSpeed: 10,
  pauseDuration: 1000,
  deleteEnabled: true,
};

export default memo(TypingEffect);

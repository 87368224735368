const TRANSLATION = {
  welcome: "hoş geldiniz (Turkish)",
  common: {
    settings: "Ayarlar",
    about: "Hakkında",
    home: "Ana Sayfa",
    logo: "Logo",
    shape: "Şekil",
    selected: "Seçildi",
    create: "Oluştur",
    notFound: "Bulunamadı",
    applied: "Uygulandı",
    yourLikes: "Favorileriniz",
    details: "Ayrıntılar",
    published: "Yayınlandı",
    unPublished: "Yayınlanmadı",
    general: "Genel",
    address: "Adres",
    wifi: "Wi-Fi",
    copied: "Kopyalandı",
    contact: "İletişim",
    pages: "Sayfalar",
    kcal: "kcal",
    currency: "Para Birimi",
    connect: "Bağlan",
    connection: "Bağlantı",
    disconnect: "Bağlantıyı kes",
    confirmDisconnect:
      "{{name}}'in bağlantısını kesmek istediğinizden emin misiniz?",
    qr: "QR",
    telegram: "Telegram",
    enable: "Etkin",
    disable: "Devre dışı",
    zoneAndTable: "Bölge & Masa",
    connectToWifi: "Wi-Fi'ye Bağlandı",
    disconnectFromWifi: "Wi-Fi'den Bağlantı Kesildi",
    name: "İsim",
    active: "Aktif",
    inactive: "Pasif",
    date: {
      date: "Tarih",
      time: "Zaman",
      createdAt: "Oluşturulma Tarihi",
      dateCreated: "Oluşturulma Tarihi",
      dateSelection: "Tarih Seçimi",
      schedule: "Planlı Dönemlerde Faaliyette",
    },
    time: {
      hour: "saat",
      minute: "dakika",
      second: "saniye",
      from: "Başlangıç",
      to: "Bitiş",
      since: "Sipariş süresi",
      min: "dak",
      shortVersion: {
        hour: "sa",
        minute: "dk",
        second: "sn",
      },
    },
    days: {
      days: "Günler",
      monday: "Pazartesi",
      tuesday: "Salı",
      wednesday: "Çarşamba",
      thursday: "Perşembe",
      friday: "Cuma",
      saturday: "Cumartesi",
      sunday: "Pazar",
      everyday: "Her Gün",
      everyWeekday: "Her İş Günü",
      weekend: "Hafta Sonu",
    },
  },
  navbarRoutes: {
    statistics: "İstatistikler",
    salesStatistics: "Satış İstatistikleri",
    orderStatistics: "Sipariş İstatistikleri",
    allOrders: "Tüm Siparişler",
    menuStatistics: "Menü İstatistikleri",
    allItems: "Tüm Ürünler",
    staff: "Personel",
    weeklyStatistics: "Haftalık İstatistikler",
    menu: "Menü",
    business: "İşletme",
    users: "Kullanıcılar",
    qrAndTable: "QR & Masa",
    table: "Masa",
    qr: "QR",
    reservation: "Rezervasyon",
    map: "Harita",
    orders: "Siparişler",
    departments: "Departmanlar",
    superAdmin: "Süper Yönetici",
    inventory: "Envanter",
    funZone: "Eğlence Bölgesi",
    home: "Ana Sayfa",
    guests: "Misafirler",
    pageTitles: {
      superAdmin: "SÜPER YÖNETİCİ",
      infra: "İNFRA",
      guests: "MİSAFİRLER",
      weeklyStatistics: "HAFTALIK İSTATİSTİKLER",
      salesStatistics: "SATIŞ İSTATİSTİKLERİ",
      orderStatistics: "SİPARİŞ İSTATİSTİKLERİ",
      menuStatistics: "MENÜ İSTATİSTİKLERİ",
      allOrders: "TÜM SİPARİŞLER",
      allItems: "TÜM ÜRÜNLER",
      category: "KATEGORİ",
      settings: "AYARLAR",
      business: "İŞLETME",
      users: "KULLANICILAR",
      zones: "BÖLGELER",
      tables: "MASALAR",
      qrList: "QR LİSTESİ",
      integrations: "Entegrasyonlar",
      telegramIntegration: "Telegram - entegrasyon",
    },
  },
  emptyTable: {
    emptyTableDescription: "Henüz hiçbir bölgeniz yok.",
    emptyZoneDescription: "Bu bölgede masa yok.",
    emptyQR: "Henüz QR kodunuz yok.",
    emptyZoneTitle: "Haritada masa yok.",
  },
  language: {
    language: "Dil",
    english: "İngilizce",
    russian: "Rusça",
    azerbaijani: "Azerice",
    german: "Almanca",
  },
  buttons: {
    addItem: "Ürün Ekle",
    addQR: "QR Ekle",
    back: "Geri",
    createQR: "QR Oluştur",
    createZone: "Bölge Oluştur",
    createOrder: "Sipariş Oluştur",
    edit: "Düzenle",
    delete: "Sil",
    confirm: "Onayla",
    finish: "Bitir",
    discard: "Vazgeç",
    change: "Değiştir",
    deny: "Reddet",
    save: "Kaydet",
    close: "Kapat",
    saveChanges: "Değişiklikleri Kaydet",
    all: "Tümü",
    selectAll: "Hepsini Seç",
    seeAll: "Tümünü Gör",
    seeLess: "Daha Az Gör",
    add: "Ekle",
    addNew: "Yeni Ekle",
    reset: "Temizle",
    resend: "Tekrar Gönder",
    clearFilters: "Filtreleri Temizle",
    pay: "Öde",
    clear: "Hepsini Temizle",
    apply: "Uygula",
    defaultLanguage: "Varsayılan Dil",
    defaultCurrency: "Varsayılan Para Birimi",
    availableLanguages: "Mevcut Diller",
    currency: "Varsayılan Para Birimi",
    link: "Bağlantı",
    links: "Bağlantılar",
    unlink: "Bağlantıyı Kaldır",
    addBasket: "Sepete Ekle",
    addToOrder: "Siparişe Ekle",
    clickToAdd: "Eklemek için tıklayın",
    clickToAddImage: "Resim eklemek için tıklayın",
    clickToUpload: "Yüklemek için tıklayın",
    logOut: "Çıkış Yap",
    login: "Giriş Yap",
    lockScreen: "Kilitle",
    goToDashboard: "Kontrol Paneline Git",
    rememberMe: "Beni Hatırla",
    forgotPassword: "Şifrenizi mi Unuttunuz?",
    payWith: "İle Öde",
    seeMenu: "Menüyü Gör",
    addOrder: "Yeni Sipariş",
    readMore: "Devamını Oku",
    serve: "Servis Et",
    confirmOrder: "Siparişi Onayla",
    backToMenu: "Menüye Dön",
    send: "Gönder",
    tryAgain: "Tekrar Dene",
    update: "Güncelle",
    continue: "Devam Et",
    verify: "Doğrula",
    applyDiscount: "İndirimi Uygula",
    print: "Yazdır",
    closeOrder: "Siparişi Bitir",
    printBill: "Hesabı Yazdır",
    fullTables: "Dolu Masalar",
    editForModal: "{{title}} düzenle",
    addForModal: "{{title}} ekle",
    successfullySent: "Başarıyla Gönderildi",
    sent: "Gönderildi",
    reserve: "Rezerve etmek",
    skip: "Atla",
    source: "Kaynak",
    target: "Hedef",
    editZone: "Bölgeyi Düzenle",
    contactUs: "Bize ulaşın",
  },
  inputs: {
    name: "Ad",
    username: "Kullanıcı Adı",
    description: "Açıklama",
    price: "Fiyat",
    discount: "İndirim",
    role: "Rol",
    phoneNumber: "Telefon Numarası",
    pinCode: "PIN Kodu",
    pinCodeConfirm: "PIN Kodunu Onayla",
    firstName: "Ad",
    lastName: "Soyad",
    email: "Email",
    password: "Şifre",
    confirmPassword: "Şifreyi Onayla",
    newPassword: "Yeni Şifre",
    oldPassword: "Eski Şifre",
    businessName: "İşletme Adı",
    city: "Şehir",
    zipCode: "Posta Kodu",
    street: "Sokak",
    map: "Google haritalar bağlantısı",
    serviceFee: "Servis Ücreti",
    search: "Ara",
    enterPromoCode: "Promosyon kodunu girin",
    link: "Bağlantı Ekle",
    instagram: "Instagram Bağlantısı",
    phone: "Telefon Ekle",
    contactPhone: "Telefon Numarası",
    whatsapp: "Whatsapp Numarası",
    mail: "Email Ekle",
    contactMail: "Email",
    tableName: "Masa adı",
    usernameExample: "@örnek",
    chooseCurrent: "Mevcut Olanı Seç",
    seats: "Koltuklar",
    minDeposit: "Min. Depozito",
    qrName: "QR Adı",
    wifiName: "Wi-Fi Adı",
    sell: "Satış Fiyatı",
    cost: "Maliyet",
    calories: "Kaloriler",
    time: "Zaman",
    amount: "Miktar",
    unit: "Birim",
    addComment: "Yorum Ekle",
    warningAmount: "Uyarı Miktarı",
    number: "Sayı",
    notFixed: "Sabit Değil",
    fixed: "Sabit",
    maxNumber: "Maksimum Sayı",
    deposit: "Depozito",
    yourName: "Adınız",
    contact: "İletişim",
    reserveeName: "Rezervasyon Yapanın Adı",
    numberOfGuest: "Misafir Sayısı",
    date: "Tarih",
    duration: "Süre",
    comment: "Yorum...",
    commentText: "Yorum",
    fullName: "Tam Ad",
    dateOfBirth: "Doğum Tarihi",
  },
  image: {
    images: "Resimler",
    selectCover: "kapak resmi olarak ayarlamak için resme tıklayın",
    background: "Arka Plan Resmi",
    other: "Diğer Fotoğraflar",
    addPhoto: "Fotoğraf Ekle",
    addImage: "Resim Ekle",
    formats: "SVG, PNG, JPEG",
  },
  modification: {
    modificationName: "Değişiklik Adı",
    modification: "Değişiklik",
    modifications: "Değişiklikler",
    noModifications: "Henüz değişiklik eklenmedi.",
    noModificationOption: "Henüz seçenek eklenmedi.",
    addModification: "Değişiklik ekle",
    editModification: "Değişikliği düzenle",
    selection: {
      selection: "Seçim",
      types: "Seçim Türü",
      singleSelection: "Tekli",
      singleSelectionDescription: "yalnızca bir seçenek seçilebilir",
      multiSelection: "Çoklu",
      multiSelectionDescription: "birden fazla seçenek seçilebilir",
      option: {
        options: "Seçenekler",
        onlyOnce: "Yalnızca bir kez",
        manyTimes: "Birçok kez",
        default: "Varsayılan seçim",
        addLanguage: "Dil ekle",
      },
    },
  },
  user: {
    staff: "Personel",
    listOfEmployees: "{{count}} çalışan",
    employee: "Çalışan",
    addEmployee: "Çalışan Ekle",
    editEmployee: "Çalışanı Düzenle",
    sendAllDetails: "Yeni bir şifre oluştur ve kullanıcıya gönder",
    userDeleteError: "Oturum açtığınız hesabı silemezsiniz",
    generatePassword: "Yeni bir şifre oluşturun ve kullanıcıya gönderin.",
    changeYourPassword: "Şifrenizi değiştirin",
  },
  table: {
    connected: "QR ile Bağlı",
    tablesLowercase: "masalar",
    tables: "Masalar",
    table: "Masa",
    addTable: "Masa Ekle",
    editTable: "Masayı Düzenle",
    selectTable: "Masayı Seç",
    busy: "masalar dolu",
    available: "masalar müsait",
    full: "dolu",
    noTablesVisible: "Şu anda haritada görünür masa yok.",
    map: "Harita",
    current: "mevcut",
  },
  zone: {
    zone: "Bölge",
    tables: "Masalar",
    zones: "Bölgeler",
    name: "Bölge Adı",
    allZones: "Tüm bölgeler",
  },
  map: {
    removeFromMap: "Haritadan kaldır",
    shapes: {
      rectangle: "Dikdörtgen",
      circle: "Daire",
    },
    createTable: "Masa Oluştur",
    addToMap: "Haritaya Ekle",
    windowAndDoor: "Pencere ve Kapı",
    designElements: "Tasarım Öğeleri",
    decoration: "Dekorasyon",
    reset: "Sıfırla",
    width: "Genişlik",
    height: "Yükseklik",
  },

  qr: {
    qr: "QR",
    qrCode: "qr kodu",
    name: "QR Adı",
    list: "QR Listesi",
    id: "Qr ID",
    type: "Qr Kod Türü",
    status: "Durum",
    unlinked: "Bağlantısız",
    linked: "Bağlantılı",
    qrIsNotAdded: "QR eklenmedi",
    connected: "Bağlı",
    notConnected: "Bağlı değil",
    scanToConnect: "veya bağlanmak için tarayın",
    QRLinkCopied: "QR bağlantısı kopyalandı",
    qrScan: "Bu QR'yi tarat",
    qrScanDescription:
      "* Kullanıcının telefonundan taratın ve Telegram uygulamasının yüklü olduğundan emin olun",
    types: {
      qrType: "QR Türü",
      wifi: "Wi-Fi",
      menu: "Menü",
    },
    downloadAllQr: "Tüm QR'leri indir",
  },
  modal: {
    yesIConfirmMyOrderAndTakeResponsibilityToPay:
      "Evet, siparişimi onaylıyorum ve ödeme sorumluluğunu kabul ediyorum.",
    areYouSureYouWantToPlaceThisOrder:
      "Bu siparişi vermek istediğinizden emin misiniz?",
    deleteModalTitle: "Silmek istediğinizden emin misiniz?",
    deleteModalDescription: "Bu işlem geri alınamaz",
    warningModalTitleFinishOrder:
      "Bu siparişi bitirmek istediğinizden emin misiniz?",
    confirmModalTitleLogOut: "Oturumu kapatmak istediğinize emin misiniz?",
    confirmModalDescriptionLogOut:
      "İstediğiniz zaman tekrar giriş yapabilirsiniz",
    warningModalTitleChangeZone:
      "Bu bölgeyi değiştirmek istediğinizden emin misiniz?",
    deleteMenuItemTitle: "Bu öğeleri silmek istediğinize emin misiniz?",
    warningModalTitleLogout: "Çıkış yapmak istediğinizden emin misiniz?",
    warningModalTitleNewOrder:
      "Yeni bir sipariş oluşturacaksınız {{zone}}/{{table}}. Emin misiniz?",
    warningModalDescriptionNewOrder:
      "Yeni bir sipariş oluşturmak için lütfen evet düğmesine tıklayın",
    warningModalTitleUnsavedChanges:
      "Kaydetmeden çıkmak istediğinizden emin misiniz?",
    warningModalDescription: "Düzenlemeleriniz kaydedilmeyecek",
    yes: "Evet",
    no: "Hayır",
    close: "Kapat",
    apply: "Uygula",
    id: "id",
    cannotBeRemoved: "Onaylanmış öğeler kaldırılamaz",
  },
  menu: {
    preview: "Önizleme Menüsü",
    translate: {
      ai: "Sihirli çeviri",
      translating: "Çevriliyor...",
      aiAutoTranslate: "AI Otomatik Çeviri",
      languageSelection: "Dil seçimi",
      successfulTranslate: "Başarıyla çevrildi",
      waitingTranslate:
        "AI, menünüzü çevirmek için birkaç dakika sürecek. Bu süre zarfında menü öğeleri düzenlenemez.",
      magicTranslate: "Çevir",
      itemErrorMessage:
        "Bu öğe kaynak dilde mevcut değil. Çevirmek için lütfen öğeyi kaynak dile ekleyin.",
      categoryErrorMessage:
        "Bu kategori kaynak dilde mevcut değil. Çevirmek için lütfen kategoriyi kaynak dile ekleyin.",
    },
    tag: {
      tag: "Etiket",
      tags: "Etiketler",
      name: "Etiket Adı",
    },
    category: {
      category: "Kategori",
      categories: "Kategoriler",
      add: "Kategori Ekle",
      all: "Tüm Kategoriler",
      name: "Kategori Adı",
      mealCategories: "Yemek Kategorileri",
      emptyGuestMenuTitle: "Menü Boş",
      emptyGuestMenuDescription:
        "Görünüşe göre işletme henüz menüye bir şey eklememiş",
      emptyAdminCategoryDescription: "Henüz hiçbir kategoriniz yok.",
    },
    item: {
      items: "Ürün",
      item: "Ürün",
      all: "Tüm Ürünler",
      itemLowerCase: "ürün",
      itemsLowerCase: "ürünler",
      selected: "Seçili ürünler",
      deleteItem: "Ürünü Sil",
      duplicateItem: "Ürünü Çoğalt",
      duplicate: "Çoğalt",
      scheduleDiscount: "İndirim Planla",
      emptyAdminMenuItemDescription: "Henüz hiçbir ürününüz yok.",
      itemDeleteError:
        "Bu ürünü silemezsiniz çünkü aktif bir siparişte mevcut.",
    },
    view: {
      selection: "Varsayılan Menü Görünümü",
      scroll: "Kaydırma",
      list: "Liste",
      threeD: "3D",
      poker: "Poker",
      grid: "Izgara",
    },
    filter: {
      filters: "Filtreler",
      priceRange: "Fiyat Aralığı",
      noResult: "Sonuç Bulunamadı",
      noResultDesc: "Aktif filtreler tüm menü ürünlerini gizliyor.",
    },
    settings: {
      color: "Renk",
      radius: "Kenar Yarıçapı",
      roundness: "Yuvarlaklık",
      currency: "Para Birimi",
      colors: {
        primary: "Birincil Renkler",
        red: "Kırmızı",
        gold: "Altın",
        purple: "Mor",
        blue: "Mavi",
      },
      curves: {
        elementCurves: "Eleman Kavisleri",
        none: "Yok",
        small: "Küçük",
        medium: "Orta",
        large: "Büyük",
      },
      changeProperties: "Özellikleri Değiştir",
      details: "Menü Detayları",
    },
  },
  business: {
    businesses: "İşletmeler",
    list: "İşletmeler listesi",
    staffList: "{{count}} çalışan",
    selectTitle: "İşletmeyi seçin",
    selectSubTitle: "Lütfen girmek istediğiniz işletmeyi seçin ve devam edin",
    contact: "İletişim",
    contactWith: "İletişim kur",
    workingHours: "Çalışma Saatleri",
    needToKnow: "Bilmeniz Gerekenler",
    addNew: "İşletme Ekle",
    edit: "İşletmeyi Düzenle",
    plan: "Plan",
    selectPlan: "Planı seçin",
    creationDate: "Oluşturulma Tarihi",
    status: "Durum",
    verified: "Doğrulandı",
    unverified: "Doğrulanmadı",
    businessStatus: "İşletme durumu",
    expirationDate: "Son Kullanma Tarihi",
    country: "Ülke",
    printers: "Yazıcılar",
    printer: "Yazıcı",
    guestBusinessBlockTitle: "Bu işletme aktif değil.",
    guestBusinessBlockSubTitle: "Üzgünüz, bu işletme artık mevcut değil.",
    settings: {
      goLive: "Canlı Yayına Geç",
      chat: "Sohbet",
      realTimeOrder: "Gerçek Zamanlı",
      menuItemCalorie: "Kalori",
      menuItemTime: "Süre",
      menuItemAmount: "Miktar",
      reservation: "Rezervasyon",
      reservationMap: "Rezervasyonda haritayı göster",
      chatSound: "Sohbet",
      orderSound: "Sipariş",
      askForBillSound: "Hesap İsteme",
      callWaiterSound: "Garson Çağırma",
      isAskForBillEnabled: "Hesap İsteme",
      isCallWaiterEnabled: "Garson Çağırma",
      menu: "Menü",
      sound: "Ses",
      general: "Genel",
      order: "Sipariş",
      action: "Eylemler",
      reservationVRLink: "Sanal bağlantı ekle",
      reservationVREnabled: "Sanal görünüm",
      reservationMapEnabled: "Harita görünümü",
    },
  },
  orderItem: {
    denied: "Reddedildi",
    deleted: "Silindi",
    empty: "Boş",
  },
  basket: {
    basket: "Sepet",
    favorites: "Favoriler",
    basketAndFavorites: "Sepet & Favoriler",
    addMessage: "Restorana mesaj ekleyin",
    messagePlaceholder: "Özel istek, alerji, diyet kısıtlamaları?",
    itemAdded: "{{count}} ürün sepete eklendi",
    emptyFavorite: "Henüz favorileriniz yok",
    order: {
      info: "Sipariş Bilgisi",
      subtotal: "Ara Toplam",
      total: "Toplam",
      tax: "Vergi",
      promoCode: "Promosyon kodu",
      totalCost: "Toplam maliyet",
      orders: "Siparişler",
      myOrders: "Siparişim",
      ordersLowerCase: "siparişler",
      orderLowerCase: "sipariş",
      order: "Sipariş",
      bill: "Fatura",
      noOrder: "Mevcut sipariş yok",
      deletedOrderTitle: "Siparişiniz silindi",
      deletedOrderDescription:
        "Üzgünüz, siparişiniz yönetici/garson tarafından silindi",
      notifications: "Bildirimler",
      updatesToSave: "Kaydedilecek güncellemeler",
      newOrder: "Yeni Sipariş",
      deleteOrder: "Siparişi Sil",
      finishOrder: "Siparişi Bitir",
      done: "Tamamlandı",
      preparing: "Hazırlanıyor",
      numberOfGuests: "Misafir sayısı",
      fullNessOfTable: "Masanın doluluğu",
      acceptAll: "Hepsini Kabul Et",
    },
    emptyBasketTitle: "Sepetiniz Boş",
    emptyBasketDescription:
      "Görünüşe göre sepetinize henüz bir şey eklemediniz",
  },
  orders: {
    orders: "Siparişler",
    order: "Sipariş",
    guests: "Misafirler",
    guest: "Misafir",
  },
  guests: {
    guest: "Misafir",
    guests: "Misafirler",
    listOfGuests: "{{count}} misafir",
    allBusinesses: "Tüm İşletmeler",
  },

  funZone: {
    wheelOfFortune: {
      spinnerGame: "Spinner Oyunu",
      who: "Kim?",
      whoWillPayTheBill: "Kim hesabı ödeyecek?",
      whoWillGoToTheMarket: "Kim markete gidecek?",
      whoWillBuyCigarettes: "Kim sigara alacak?",
      whoWillHaveAnotherShot: "Kim bir tane daha içecek?",
      whoWillGetDessert: "Kim tatlı alacak?",
      whoWillChooseTheMusic: "Kim müziği seçecek?",
      whoWillSingKaraoke: "Kim karaoke söyleyecek?",
      start: "Başla",
      spinning: "Dönüyor...",
      editToPlay: "Oynamak için düzenle",
      addNew: "Yeni seçim ekle",
      saveAndContinue: "Kaydet ve devam et",
      typeHere: "Buraya yazın",
      result: "Sonuç",
      youAreTheChosenOne: "Sen seçilmiş kişisin",
    },
  },

  dashboard: {
    dashboard: "Gösterge Paneli",
    sales: "Satışlar",
    salesAnalytics: "Toplam Satışlar / Saat",
    weekStatistics: "Haftalık İstatistikler",
    statistics: "İstatistikler",
    totalSales: "Toplam Satışlar",
    paymentMethodAnalysis: "Ödeme Yöntemi Analizi",
    byNumberOfOrders: "Sipariş sayısına göre",
    byTheAmountOfSales: "Satış miktarına göre",
    byTheNumberOfRatings: "Puan sayısına göre",
    bestselling: "En Çok Satanlar",
    worstSelling: "En Az Satanlar",
    selectToPay: "Seçmek için tıklayın",
    processing: "İşlemde",
    billReady: "Fatura hazır olacak",
    unSelect: "Seçimi Kaldır",
    denied: "Reddedildi",
    deleted: "Silindi",
    showMore: "Daha Fazla Göster",
    showLess: "Daha Az Göster",
    bestSellingCategory: "En çok satan kategori",
    topPerformer: "En İyi Performans Gösteren",
    lowPerformer: "En Düşük Performans Gösteren",
    noResultForThisPeriodMsg: "Bu dönem için sonuç yok",
    all: "Tümü",
    yourTable: "Masanız",
    totalGuests: "Toplam Misafir",
    totalItems: "Toplam Öğe",
    weeklySaleStatistics: "Haftalık Satış İstatistikleri",
    weeklyOrderStatistics: "Haftalık Sipariş İstatistikleri",
    legends: {
      sale: "Satış",
      revenue: "Gelir",
      orders: "Sipariş sayısı",
      orderItems: "Ürün sayısı",
      totalSale: "Toplam Satış",
    },
    guest: {
      guests: "Misafir",
      guest: "Misafir",
      me: "Ben",
      review: {
        meal: "Yemek",
        service: "Hizmet",
        review: "Değerlendirme",
        sent: "Gönderildi",
        feedBack: "Geri Bildirim",
      },
    },
    staffStatistics: {
      sales: "Satışlar",
      orders: "Siparişler",
      rating: "Puan",
    },
    orderStatistics: {
      totalSale: "Toplam Satış",
      revenue: "Gelir",
      averageBill: "Ortalama Fatura",
      orderCount: "Sipariş Sayısı",
      guestsServed: "Hizmet Verilen Misafirler",
      averageTime: "Ortalama Süre",
      from: "dünden",
      orders: "Siparişler",
      sale: "Satış",
      in: "İçeri",
      out: "Dışarı",
    },
    table: {
      adminOrderHistoryTable: {
        staff: "Personel",
        dates: "Tarihler",
        item: "Kalem",
        subTotal: "Ara Toplam",
        discount: "İndirim",
        total: "Toplam",
        zone: "Bölge",
        table: "Masa",
        service: "Hizmet",
        fee: "Ücret",
        orderDetails: "Sipariş Detayları",
        receipt: "Fiş",
      },
      menuStatisticsTable: {
        menuItem: "Menü Kalemi",
        category: "Kategori",
        cost: "Maliyet",
        revenue: "Gelir",
        total: "Toplam",
        order: "Sipariş",
        count: "Sayım",
        price: "Fiyat",
        item: "Kalem",
      },
    },
    pagination: {
      prev: "Önceki",
      next: "Sonraki",
    },
    prevNext: {
      previous: "Önceki",
      next: "Sonraki",
    },
    placeholder: {
      zone: "Bölge",
      orderId: "Sipariş ID",
      staff: "Personel",
      itemName: "Kalem Adı",
      categories: "Kategoriler",
    },
  },
  login: {
    or: "veya",
    facebook: "Facebook ile",
    google: "Google ile",
    pin: "PIN",
    alreadyHaveAnAccount: "Zaten bir hesabınız var mı?",
    signIn: {
      title: "Giriş Yap",
      subTitle: "Hesabınız yok mu?",
      details: "Giriş detayları",
      method: "Giriş yöntemi",
    },
    signUp: {
      title: "Kayıt Ol",
      subTitleForUserExist:
        "Zaten bir hesabınız var. Lütfen mevcut şifrenizi girin.",
      subTitleForUserNotExist:
        "Lütfen şuraya gönderilen şifreyi girin: {{emailOrPhone}}",
      alreadyHaveAccount: "Zaten bir hesabınız var mı?",
      passwordSentTo: "Şifre gönderildi",
      setPinSubTitle: "Kolay giriş için bir PIN kodu belirleyin",
      agree: "Devam ederek, şunları kabul ediyorsunuz:",
      termsAndPrivacy: "Şartlar ve Gizlilik Politikası",
      enterPassword: "Şifrenizi girin",
      setPinSubtitle: "Kolay giriş için bir PIN kodu belirleyin",
    },
    forgotPassword: {
      title: "Şifremi Unuttum?",
      subTitle: "{{method}} girin ve şifrenizi tekrar göndereceğiz.",
      sendPassword: "Şifre Gönder",
      phoneNumber: "Telefon numarası",
      email: "E-posta",
    },
    resetPassword: {
      title: "Şifreyi Değiştir",
      subTitle: "Tekrar hoş geldiniz, sizi özledik!",
    },
    resendPassword: {
      title: "Şifreyi Yeniden Gönder",
      subTitleForUserExist: "Mevcut şifrenizi mi unuttunuz?",
      subTitleForUserNotExist: "Şifreyi almadınız mı?",
      buttonTextForUserExist: "Yeni şifre gönder",
      buttonTextForUserNotExist: "Tekrar gönder",
    },
  },
  payment: {
    payment: "Ödeme",
    method: "Ödeme Yöntemi",
    pageTitle: "Nasıl ödemek istersiniz?",
    pageSubtitle: "Ödeme yapmak istediğiniz yöntemi seçin",
    cash: "Nakit",
    POSTerminal: "POS Terminal",
    ready: "Bir dakika içinde hazır!",
    confirmPOSTerminal:
      "Onaylanmış istek. POS Terminal garson tarafından getirilecek",
    confirmCash: "Onaylanmış istek. Faturanızı garson tarafından getirilecek",
    totalCostFor: "Toplam maliyet",
    printsAndBill: "Baskılar ve fatura",
    paymentConfirmedTitle:
      "Siparişiniz tamamlandı. Bizi tercih ettiğiniz için teşekkürler.",
    paymentConfirmedDescription:
      "Sizi ağırlamak bizim için zevkti! Bir dahaki sefere görüşmek üzere 👋 İyi günler 😍",
  },
  waiter: {
    waiter: "Garson",
    waiterLowercase: "garson",
    call: "Garsonu Çağır",
    bill: "Hesap İste",
    quickActions: "Hızlı İşlemler",
    actions: "İşlemler",
  },
  mascot: {
    dashboard: {
      support: "Destek ve Kısayollar",
      eatIn: "Yerde Yemek",
      eatInHall: "Salonda Yemek",
      reserveTable: "Masa Rezervasyonu",
      letUs: "Bırakın sizin için ...",
      learnMore: "Daha fazla öğren",
      fixProblemText: "Uygulama ile ilgili bir sorun mu yaşıyorsunuz?",
      fixProblemButton: "Düzeltmek için Buraya tıklayın",
    },
  },
  errorMessages: {
    selectTableToCreateOrder: "Sipariş oluşturmak için bir masa seçiniz",
    selectWaiterToCreateOrder: "Sipariş oluşturmak için bir garson seçiniz",
    lastLanguage: "Son dili kaldıramazsınız",
    orderAssignee: "Bir görevli seçmelisiniz",
    required: {
      email: "*Email gerekli",
      password: "*Şifre gerekli",
      confirmPassword: "*Şifre doğrulaması gerekli",
      name: "*İsim gerekli",
      customMessage: "*{{name}} gerekli",
      plan: "*Plan gerekli",
      phoneNumber: "*Telefon numarası gerekli",
    },
    image: "Resim yükleme başarısız",
    addUnit: "Birim ekle",
    confirmOrders:
      "Tamamlanmamış işleriniz var. Devam etmeden önce lütfen kaydedin",
    input: {
      maxCharacter: "*Lütfen {{max}} karakterden fazla girmeyin",
      minCharacter: "*Lütfen en az {{min}} karakter girin",
      maxNumber: "*Lütfen {{max}} sayısından daha küçük bir sayı girin",
      minNumber: "*Lütfen 0'dan büyük bir sayı girin",
      invalid: "*Geçersiz {{name}}",
      required: "*{{name}} gerekli",
      generalRequired: "*Bu alan gerekli",
      qrAndTable: {
        maxSeat: "Koltuk sayısı bir tam sayı olmalıdır",
      },
      menu: {
        fixedDiscount: "*İndirim satış fiyatından fazla olamaz",
        unFixedDiscount: "*İndirim %100'den fazla olamaz",
        requiredUnit: "*Verilen miktar için bir birim seçiniz",
      },
      menuSettings: {
        requiredDefaultLanguage: "*Lütfen bir varsayılan dil seçiniz",
        requiredAvailableLanguages: "*Lütfen en az bir dil seçiniz",
      },
      pinCode: {
        maxDigits: "*Pin kodu 6 haneden az olmalıdır",
        alreadyExists: "*Bu pin kodu zaten mevcut",
        notNumeric: "*Pin kodu yalnızca rakamlardan oluşmalıdır",
        skipOrSetNow: "*Şimdi bir pin kodu belirleyin veya bu adımı atlayın",
        notMatch: "*Pin kodları eşleşmiyor",
        incorrect: "*Pin kodu yanlış",
        TryAgainLater: "Limiti aştınız, lütfen daha sonra tekrar deneyin.",
      },
      email: {
        alreadyExists: "*Bu email zaten mevcut",
        incorrect: "*Lütfen geçerli bir email adresi girin",
        notExists: "*Bu email adresi mevcut değil",
        TryAgainLater: "*Limiti aştınız, lütfen daha sonra tekrar deneyin.",
      },
      password: {
        incorrect: "*Şifre yanlış",
        notNumeric: "*Şifre yalnızca rakamlardan oluşmalıdır",
        incorrectLength: "*Şifre 6 haneli olmalıdır",
      },
      phoneNumber: {
        incorrectLength: "*Telefon numarası 12 haneli olmalıdır",
        notNumeric: "*Telefon numarası yalnızca rakamlardan oluşmalıdır",
        alreadyExists: "*Bu telefon numarası zaten mevcut",
        incorrect: "*Lütfen geçerli bir telefon numarası girin",
        notExists: "*Bu telefon numarası mevcut değil",
        TryAgainLater: "*Limiti aştınız, lütfen daha sonra tekrar deneyin.",
      },
      reservation: {
        maxGuestCount: "Misafir sayısı bir tam sayı olmalıdır",
      },

      business: {
        serviceFeeMax: "*Hizmet ücreti %100'den az olmalıdır",
        serviceFeeMin: "*Hizmet ücreti %0'dan fazla olmalıdır",
        url: "*Lütfen geçerli bir URL girin",
      },
      contact: {
        instagram: "*Lütfen geçerli bir Instagram adresi girin",
      },
      otpCode: {
        OtpIsWrong: "*Otp yanlış",
        OtpIsNotVerified: "Otp doğrulanmadı",
        sendOtp: "Kod gönderildi:",
      },
      oldPassword: {
        OldPasswordIsWrong: "*Mevcut şifre yanlış",
      },
    },
  },
  toastMessages: {
    success: {
      login: "Giriş başarılı!",
      updateBusinessProfile: "İşletme profili başarıyla güncellendi",
      updateTag: "Etiket başarıyla güncellendi",
      createTag: "Etiket başarıyla oluşturuldu",
      deleteTag: "Etiket başarıyla silindi",
      createReservation: "Rezervasyon başarıyla oluşturuldu",
      deleteReservation: "Rezervasyon başarıyla silindi",
      updateReservation: "Rezervasyon başarıyla güncellendi",
      updateCategory: "Kategori başarıyla güncellendi",
      createCategory: "Kategori başarıyla oluşturuldu",
      deleteCategory: "Kategori başarıyla silindi",
      updateMenuItem: "Menü ürünü başarıyla güncellendi",
      updateMenu: "Menü başarıyla güncellendi",
      createMenuItem: "Menü ürünü başarıyla oluşturuldu",
      deleteMenuItem: "Menü ürünü başarıyla silindi",
      duplicateMenuItem: "Menü ürünü başarıyla çoğaltıldı",
      updateZone: "Bölge başarıyla güncellendi",
      createZone: "Bölge başarıyla oluşturuldu",
      deleteZone: "Bölge başarıyla silindi",
      updateTable: "Masa başarıyla güncellendi",
      updateMenuSettings: "Menü ayarları başarıyla güncellendi",
      createTable: "Masa başarıyla oluşturuldu",
      deleteTable: "Masa başarıyla silindi",
      updateUser: "Kullanıcı başarıyla güncellendi",
      createUser: "Kullanıcı başarıyla oluşturuldu",
      deleteUser: "Kullanıcı başarıyla silindi",
      updateQR: "QR kodu başarıyla güncellendi",
      createQR: "QR kodu başarıyla oluşturuldu",
      deleteQR: "QR kodu başarıyla silindi",
      updateOrder: "Sipariş başarıyla güncellendi",
      createOrder: "Sipariş başarıyla oluşturuldu",
      deleteOrder: "Sipariş başarıyla silindi",
      finishedOrder: "Sipariş başarıyla tamamlandı",
      updateInventoryCategory: "Envanter kategorisi başarıyla güncellendi",
      createInventoryCategory: "Envanter kategorisi başarıyla oluşturuldu",
      deleteInventoryCategory: "Envanter kategorisi başarıyla silindi",
      updateInventoryItem: "Envanter ürün başarıyla güncellendi",
      createInventoryItem: "Envanter ürün başarıyla oluşturuldu",
      deleteInventoryItem: "Envanter ürün başarıyla silindi",
      updateBusiness: "İşletme başarıyla güncellendi",
      createBusiness: "İşletme başarıyla oluşturuldu",
      deleteBusiness: "İşletme başarıyla silindi",
      updateLanguage: "Dil başarıyla değiştirildi",
      resetPassword: "Şifre başarıyla değiştirildi",
    },
    error: {
      common: "Bir şeyler yanlış gitti! Lütfen tekrar deneyin",
      zones: "Bu bölgeyi silemezsiniz çünkü bir masa içeriyor",
      unselectedTable: "Bir masa seçmelisiniz",
      unselectedZone: "Bir bölge seçmelisiniz",
      deleteCategory: "Bu kategoriyi silemezsiniz çünkü içinde bir öğe var.",
    },
  },
  reservation: {
    reserve: "Rezervasyon Yap",
    reservationTime: "Rezervasyon Zamanı",
    reservationInfo: "Rezervasyon Bilgisi",
    contactInfo: "İletişim Bilgileri",
    enterName: "Adınızı Girin",
    enterPhoneNumber: "Telefon Numarası",
    bookingList: "Rezervasyon Listesi",
    booked: "Rezerve Edildi",
    pending: "Bekliyor",
    createReservation: "Rezervasyon Oluştur",
    editReservation: "Rezervasyonu Düzenle",
    waiting: "Bekliyor",
    today: "Bugün",
    guestNumber: "Misafir Sayısı",
    waitingTime: "İstek Zamanı",
    startDate: "Başlangıç Tarihi",
    ReservationDuration: "Rezervasyon Süresi",
    comment: "Yorum",
    numberOfGuest: "Misafir Sayısı",
    startTime: "Başlangıç Saati",
    duration: "Süre",
    confirmed: "Onaylandı",
    denied: "Reddedildi",
    delete: "Sil",
    update: "Güncelle",
    searchRestaurant: "Restoran Ara",
    tableNotFound: "Seçilmedi",
    showMore: "Daha Fazla Göster",
    showLess: "Daha Az Göster",
    reservations: "Rezervasyonlar",
    open: "Açık",
    seeMenu: "Menüyü Gör",
    reservation: "Rezervasyon",
    contact: "İletişim",
    place: "Yer",
    reserveeName: "Rezerve Edenin Adı",
    date: "Tarih",
    time: "Zaman",
    reservationStatus: "Rezervasyon Durumu",
    reservationDurationHelpText:
      "Not: Süreyi seçmezseniz, otomatik olarak 1 saat olarak ayarlanacaktır.",
    justNow: "Şu Anda",
    oneDay: "1 Gün",
    day: "gün",
    hour: "sa",
    minute: "dk",
    selectedTable: "Seçilen Masa",
    confirmation: "Onayla ve devam et",
    chooseRestaurant: "Restoranı Seç",
    setDate: "Ne zaman ziyaret ediyorsunuz?",
    chooseTable: "Tercih ettiğiniz bir masa var mı?",
    addContact: "Kim ziyaret ediyor?",
    vrLinkCopied: "VR bağlantısı kopyalandı",
    statuses: {
      PENDING: "Bekliyor",
      CONFIRMED: "Onaylandı",
      DENIED: "İptal Edildi",
      DELETED: "Silindi",
    },
    vr: {
      browserDoesNotSupport: "Tarayıcınız video etiketini desteklemiyor.",
      vrTourReservation: "VR Turu Rezervasyonu",
      explore: "Keşfedin ve yerinizi seçin!",
      vrView: "VR görüntü",
    },
    map: {
      chooseTable: "Masa Seç",
      mapReservation: "Rezervasyonu Haritala",
      chooseLocation: "Masa konumunuzu seçin!",
      tables: "Masalar",
    },
    sentSuccessfully: "İstek başarıyla gönderildi",
    successMessage:
      "Güncellemeler numaranıza gönderilecek ve rezervasyon sayfasından durumunuzu kontrol edebilirsiniz",
  },
  socials: {
    instagram: "Instagram",
    email: "Email",
    whatsapp: "Whatsapp",
  },
  months: {
    january: "Ocak",
    february: "Şubat",
    march: "Mart",
    april: "Nisan",
    may: "Mayıs",
    june: "Haziran",
    july: "Temmuz",
    august: "Ağustos",
    september: "Eylül",
    october: "Ekim",
    november: "Kasım",
    december: "Aralık",
  },
  weekdays: {
    monday: "Pazartesi",
    tuesday: "Salı",
    wednesday: "Çarşamba",
    thursday: "Perşembe",
    friday: "Cuma",
    saturday: "Cumartesi",
    sunday: "Pazar",
  },
  periods: {
    hourly: "Saatlik",
    daily: "Günlük",
    weekly: "Haftalık",
    monthly: "Aylık",
  },
  chat: {
    typeHere: "Buraya yazın...",
    chat: "Sohbet",
    allMessages: "Tüm Mesajlar",
    unreadMessages: "Okunmamış Mesajlar",
    selectTopic: "Lütfen konu seçiniz.",
    noMessages: "Mesaj yok.",
  },
  kitchen: {
    stageNew: "Yeni",
    stageProgress: "Hazırlanıyor",
    stageDone: "Tamamlandı",
  },
  inventory: {
    productList: "Ürün Listesi",
    product: "ürün",
    stock: "Stok",
    warningAmountInfo:
      "Neredeyse bitmek üzere bildirim yapılacak minimum miktarı seçin",
  },
  emptyStates: {
    noPrinters: "Henüz yazıcı eklenmedi",
    noOption: "Seçenek bulunmamaktadır",
    noOrder: "Henüz sipariş bulunmamaktadır",
    noItem: "Henüz ürün bulunmamaktadır",
    noLanguage: "Eklenecek dil bulunmamaktadır",
    noTableData: "Bu sayfada veri bulunmamaktadır",
    noOrderedCategory: "Bu kategoride sıralanan ürün bulunmamaktadır",
    noChat: "Sipariş olmadığı için herhangi bir sohbetiniz yok",
    noUnread: "Okunmamış mesaj yok",
    noOrders: "Sipariş Yok",
    noGuests: "Misafir Yok",
    noSearchResults: "Arama Sonucu Yok",
    noPendingReservations: "Bekleyen rezervasyon yok.",
    noReservationDescription: "Henüz hiçbir rezervasyonunuz yok",
    noReservations: "Rezervasyon Yok",
    notOpenForReservations: "Bu restoran rezervasyonlara açık değil.",
    noBusiness: "İş Yok",
    noBusinessDescription: "Maalesef, tüm restoran rezervasyonları kapalı.",
  },
  info: {
    itemAmount:
      "Sayı, kilogram (kg), gram (g), litre (l) veya mililitre (ml) gibi birimlerle belirtilen ürünün miktarını gösterir.",
    itemModificationOptionMaxNumberInfo:
      "Değiştirme seçeneği maksimum limiti, bu seçeneklerden uygulanabilecek maksimum sayıyı belirtir.",
    general: "Genel bilgi",
    detail: "Detaylı bilgi",
  },
  stepper: {
    signupMethod: "Kayıt yöntemi",
    otpVerification: "OTP Doğrulama",
    userDetail: "Kullanıcı Bilgileri",
    passwordVerification: "Şifre Doğrulama",
    setPinCode: "PIN Kodu",
  },
  general: {
    companyName: "iLoyal",
    termsAndConditions: "Kullanım Şartları",
    privacyPolicy: "Gizlilik Politikası",
    allRightReserved: "Tüm hakları saklıdır.",
    copyRight: "© 2024 {{value}}.",
    joinUs: "Bize katılın {{emoji}}",
    madeWith: "{{emoji}} ile yapıldı",
    madeBy: "<strong>iLoyal</strong> tarafından yapılmıştır",
    scanQR: "Yeni sipariş için QR kodunu tarayın",
    name: "Arkadaşlarınıza siz olduğunuzu gösterin!",
    profilePicture: " Profil fotoğrafınız",
  },
  optionTypes: {
    None: "Hiç",
  },
  businesses: {
    allBusinesses: "Tüm İşletmeler",
    specialOffers: "Özel Teklifler",
    mostPopular: "En Popüler",
    coffeeShop: "Kafe",
    nationalCuisine: "Milli Mutfak",
    nationalCuisineExtended: "Milli Mutfakkkkkkkkk",
    seaside: "Sahil",
    search: "Ara...",
    seeAll: "Hepsini Gör",
    seeLess: "Daha Az Göster",
  },
  policy: {
    TermsOfService: "Hizmet Şartları (Lisans Sözleşmesi)",

    termsDataInfo:
      "Devam ederek, <strong>Şartlar ve Gizlilik Politikası'nı</strong> kabul etmiş olursunuz.",

    joinUsTermsData: {
      introduction: {
        title: "1. Giriş",
        content: {
          point1:
            "Hizmetlerimizi kullanarak, bu Hizmet Şartları ve Gizlilik Politikası ile hemfikir olduğunuzu kabul edersiniz. Lütfen bunları dikkatlice okuyun.",
        },
      },
      userConsent: {
        title: "2. Kullanıcı Onayı",
        content: {
          point1:
            "Hizmetlerimize erişerek veya kullanarak, bilgilerinizi bu Hizmet Şartları ve Gizlilik Politikasında açıklanan şekilde toplama, kullanma ve paylaşma işlemlerine onay vermiş olursunuz. Bu şartlarla anlaşmıyorsanız, lütfen hizmetlerimizi kullanmayın.",
        },
      },
      dataCollection: {
        title: "3. Veri Toplama",
        content: {
          point1:
            "Hesap oluşturduğunuzda, alışveriş yaptığınızda veya destek için bizimle iletişime geçtiğinizde doğrudan bize sağladığınız bilgileri toplarız. Ayrıca, IP adresiniz, tarayıcı türünüz ve kullanım verileriniz gibi bilgileri otomatik olarak da toplayabiliriz.",
        },
      },
      dataUse: {
        title: "4. Veri Kullanımı",
        content: {
          point1:
            "Verileriniz, hizmetlerimizi sağlamak, sürdürmek ve geliştirmek için kullanılır. Bu, işlemleri işleme, iletişim gönderme ve deneyiminizi kişiselleştirmeyi içerir.",
        },
      },
      dataProtection: {
        title: "5. Veri Koruma",
        content: {
          point1:
            "Verilerinizi korumaya kararlıyız. Bilgilerinizi yetkisiz erişim, değişiklik, ifşa veya yok edilmeden korumak için çeşitli güvenlik önlemleri uygularız.",
        },
      },
      dataSharing: {
        title: "6. Veri Paylaşımı",
        content: {
          point1:
            "Kişisel verilerinizi satmıyoruz. Web sitemizi işletmek, işimizi yürütmek veya size hizmet sunmak için bize yardımcı olan güvenilir üçüncü şahıslarla bilgi paylaşabiliriz, ancak bu taraflar bu bilgileri gizli tutmayı kabul etmelidir.",
        },
      },
      userRights: {
        title: "7. Kullanıcı Hakları",
        content: {
          point1:
            "Kişisel bilgilerinize erişme, düzeltme veya silme hakkına sahipsiniz. Verilerinizin belirli kullanımlarından ve ifşalarından da vazgeçebilirsiniz.",
        },
      },
      general: {
        title: "",
        content: {
          point1:
            "Hizmetlerimizi kullanarak, bu şartları okuduğunuzu ve anladığınızı ve onlara uymayı kabul ettiğinizi kabul edersiniz.",
        },
      },
    },

    signUpTermsData: {
      agreement: {
        title: "1. Sözleşme Şartları",
        content: {
          point1:
            "1.1 Lisans veren kişi, bu sözleşmenin şartlarına göre yazılım lisansını sağlamalıdır ve müşteri bu lisans için ödeme yapmalıdır.",
          point2:
            "1.2 Aylık lisans (abonelik) ücreti, iLoyal tarafından müşterinin ihtiyaçlarına göre sağlanan özelliklerin çeşitliliğine bağlı olarak belirlenir. Lisans (abonelik) ücretindeki değişiklikler yalnızca Müşteri ile iLoyal arasında karşılıklı anlaşma ile yapılabilir. Aylık lisans (abonelik) süresi, sözleşmenin imzalandığı tarihten itibaren başlamış sayılır.",
        },
      },
      license: {
        title: "2. Lisans Şartları",
        content: {
          point1:
            "2.1 Lisans veren kişi, basit lisans şartları altında aşağıdaki hakları verir:",
          point2:
            "2.1.1 Yazılımın kullanımını sağlamak ve amacına uygun şekilde çalışmasını belirlemek.",
          point3: "2.2 Lisans için aşağıdakiler YASAKTIR:",
          point4: "2.2.1 Kullanıcı tarafından yazılım kodunun çıkarılması.",
          point5:
            "2.2.2 Kullanıcı tarafından yazılımın bir veya daha fazla kopyasının üretilmesi.",
          point6: "2.2.3 Yazılımın ve kopyalarının yeniden satılması.",
          point7: "2.2.4 Yazılımın kamuya açık hale getirilmesi.",
        },
      },
      payment: {
        title: "3. Lisans Ödemesi",
        content: {
          point1:
            "3.1 Müşteri, bu sözleşmede belirtilen lisans(lar) için faturada belirtilen tutarı ödemeyi kabul eder.",
          point2:
            "3.2 Lisansların fiyatları, lisans veren kişinin resmi web sitesinde listelenmiştir.",
          point3:
            "3.3 Taraflar arasındaki anlaşmanın feshi durumunda, lisans ücreti müzakere edilemez ve geri ödenemez.",
          point4:
            "3.4 Lisans veren kişi tarafından düzenlenen faturaya göre lisans ücreti ödendikten sonra, geçerli Lisans Sözleşmesi'nin şartlarını kabul etmiş sayılırsınız ve bu sözleşme siz ve lisans veren kişi arasında imzalanmış sayılır.",
          point5:
            "3.5 Lisans veren kişi bu sözleşmeyi tek taraflı olarak değiştirme hakkını saklı tutar.",
          point6:
            "3.6 İlgili tutarlar lisans veren kişinin banka hesabına yatırıldığında, müşterinin tüm ödeme yükümlülükleri yerine getirilmiş sayılır.",
        },
      },
      dataProtection: {
        title: "4. Veri Koruma",
        content: {
          point1:
            "4.1 Tüm müşteri bilgileri lisans veren kişi tarafından korunur.",
          point2:
            "4.2 Bulut hesaplarımızda saklanan müşteri verileri üçüncü şahıslarla paylaşılmaz.",
        },
      },
    },
  },
  auth: {
    createAnAccount: "Hesap Oluştur",
    verifyOtp: "OTP'yi Doğrula",
    profile: "Profil",
    signUp: "Kaydol",
    otpSentTo: "OTP gönderildi",
    otpCode: "OTP Kodu",
    letKnow: "Sizi Tanıyalım",
    gender: "Cinsiyet",
    saveAndFinish: "Kaydet ve Bitir",
    genders: {
      male: "Erkek",
      female: "Kadın",
      other: "Diğer",
    },
    loginYourAccount: "Hesabınıza Giriş Yapın",
    forgotPassword: "Şifrenizi mi unuttunuz?",
    forgotPasswordTitle: "Şifrenizi unuttunuz",
    forgotPasswordDesc:
      "{{method}} girin, size OTP doğrulama kodu göndereceğiz",
    setNewPassword: "Yeni şifre belirle",
    setNewPasswordDesc: "Yeni şifrenizi girin",
    signUpWith: "veya ile kaydolun",
    withoutSignUp: "Üye olmadan",
    phoneNumber: "telefon numarası",
    email: "e-posta",

    loginAccount: "Hesabınıza giriş yapın",
    resendingIsAvailable: "Tekrar gönderim mevcut",
    resendingCodeWillBeAvailableIn: "Kod tekrar gönderilebilir olacak ",
    info: "Bilgi",
    clickButtonToLogin: "Giriş yapmak için bu butona tıklayın",
    youAlreadyHaveAccount: "Zaten kayıtlı bir hesabınız var: ",
    createGuestAccountDesc:
      "iLoyal ile kaydolduğunuzda, indirimlerden ve ek özelliklerden yararlanma şansına sahip olursunuz!",
  },
  myProfile: {
    title: "Profilim",
    editProfile: "Profili Düzenle",
    resetPassword: {
      resetPassword: "Şifreyi Sıfırla",
      setNewPassword: "Yeni şifre belirle",
      enterCurrentPassword:
        "yeni bir şifre belirlemek için lütfen önce mevcut şifrenizi girin.",
      currentPassword: "Mevcut Şifre *",
      newPassword: "Yeni Şifre *",
    },
  },
};
export default TRANSLATION;

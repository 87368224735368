import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import { ROUTE_NAME } from "utils/constants/routes";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import GuestModal, {
  MODAL_POSITIONS,
  POSITION_TYPES,
} from "components/guest/guest-modal/GuestModal";
import FormattedPhoneNumber from "components/elements/formatted-phone-number/FormattedPhoneNumber";

import "./GuestAlreadyHaveAccount.scss";

const GuestAlreadyHaveAccountModal = ({
  openSlide,
  onCancel,
  setOpenSlide,
  userIdentifier,
  selectedMethod,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSignIn = () => {
    setOpenSlide(false);
    navigate(`${ROUTE_NAME.client}${ROUTE_NAME.auth}${ROUTE_NAME.signIn}`);
  };

  const GuestAlreadyHaveAccountModalHeader = (
    <div className="GuestAlreadyHaveAccountModalHeader">
      <h1 className="GuestAlreadyHaveAccountModalHeaderTitle Bold">
        {t("auth.info")}
      </h1>
      <div
        className="GuestAlreadyHaveAccountModalCloseButtonWrapper"
        onClick={onCancel}
      >
        <IconClose />
      </div>
    </div>
  );
  const GuestAlreadyHaveAccountModalBody = (
    <div className="GuestAlreadyHaveAccountModalBody">
      <h4 className="GuestAlreadyHaveAccountModalBodyTitle">
        {t("auth.youAlreadyHaveAccount")}
      </h4>
      {selectedMethod === "phoneNumber" ? (
        <FormattedPhoneNumber
          phoneNumber={userIdentifier}
          className="textWhite SemiBold h4"
        />
      ) : (
        <h4 className="GuestAlreadyHaveAccountModalBodyTitle">
          {userIdentifier}
        </h4>
      )}
    </div>
  );

  const GuestAlreadyHaveAccountModalFooter = (
    <div className="GuestAlreadyHaveAccountModalFooter">
      <h4>{t("auth.clickButtonToLogin")}</h4>
      <PrimaryButton
        onClick={handleSignIn}
        type={PRIMARY_BUTTON_ENUMS.types.TYPE_P}
        text={t("buttons.login")}
      />
    </div>
  );

  return (
    <div className="GuestAlreadyHaveAccountModal">
      <GuestModal
        className={"InfoModalBackground"}
        containerClassname={"ContainerClassname"}
        modalClassname={"ModalClassname"}
        header={GuestAlreadyHaveAccountModalHeader}
        body={GuestAlreadyHaveAccountModalBody}
        footer={GuestAlreadyHaveAccountModalFooter}
        openSlide={openSlide}
        slideDirection={POSITION_TYPES.DOWN}
        modalPosition={MODAL_POSITIONS.BOTTOM}
      />
    </div>
  );
};

GuestAlreadyHaveAccountModal.propTypes = {
  openSlide: PropTypes.bool.isRequired,
  setOpenSlide: PropTypes.func.isRequired,

  /**
   * Function called when the cancel action is performed
   */ onCancel: PropTypes.func.isRequired,
  userIdentifier: PropTypes.string.isRequired,
  selectedMethod: PropTypes.oneOf(["email", "phoneNumber"]).isRequired,
};

export default GuestAlreadyHaveAccountModal;

import React, { useEffect } from "react";
import EmailOrPhone from "components/elements/email-or-phone/EmailOrPhone";
import PropTypes from "prop-types";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import { useTranslation } from "react-i18next";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { useKeyPress } from "utils/hooks/useKeyPress";
import { EVENT_CODES } from "utils/constants/events";
import PolicyModal from "components/policy-modal/PolicyModal";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { createOtpRequestForUserSignup } from "utils/api/services/otp";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import useAsync from "utils/hooks/useAsync";
import { SIGN_IN_METHOD } from "../../sign-in/SignIn";

import "./SignUpMethod.scss";

const SignUpMethod = ({
  setOtpData,
  setCounter,
  handleNext,
  setSignInMethod,
  signInMethod,
}) => {
  const { t } = useTranslation();
  const schemas = useValidationSchema(t);
  const emailOrPhoneSchema = schemas.emailOrPhoneSchema(signInMethod);
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();

  const methods = useForm({
    resolver: zodResolver(emailOrPhoneSchema),
  });

  const { getValues, handleSubmit, setError } = methods;

  const [openSlidePolicy, setOpenSlidePolicy, mainElementRefPolicy] =
    useOutsideClick();

  const handleOnModalClose = () => {
    setOpenSlidePolicy(false);
  };
  const enterPressed = useKeyPress(EVENT_CODES.enter);

  useEffect(() => {
    if (enterPressed) {
      handleSubmit(handleSubmitMethodAndSendVerificationCode)();
    }
  }, [enterPressed]);

  const { execute: executeCreateOtpRequest, loading: isSignUpLoading } =
    useAsync(createOtpRequestForUserSignup, {
      onError: (error) => {
        const errorData = handleAPIErrorMessage(error.response.data);
        if (errorData) {
          const { field, errorMessage } = errorData;
          setError(field, {
            type: "manual",
            message: errorMessage,
          });
        }
      },
      onSuccess: ({ data }) => {
        setOtpData((prev) => ({
          ...prev,
          otpToken: data.otpToken,
          otpSendCount: data.otpSendCount,
        }));
        setCounter(60);
        handleNext();
      },
    });

  const handleSubmitMethodAndSendVerificationCode = () => {
    const methodValue = getValues();
    const otpData = {
      [signInMethod]:
        signInMethod === SIGN_IN_METHOD.phoneNumber
          ? methodValue[signInMethod].replace("+", "")
          : methodValue[signInMethod],
    };
    setOtpData((prev) => ({
      ...prev,
      ...otpData,
    }));
    executeCreateOtpRequest(otpData);
  };

  return (
    <div className="SignUpMethod">
      <form onSubmit={(e) => e.preventDefault()}>
        <FormProvider {...methods}>
          <EmailOrPhone
            signInMethod={signInMethod}
            setSignInMethod={setSignInMethod}
          />
        </FormProvider>
        <p className="SignUpFormTermsAndConditionsText">
          {t("login.signUp.agree")}{" "}
          <span onClick={() => setOpenSlidePolicy(true)}>
            {t("login.signUp.termsAndPrivacy")}
          </span>
        </p>
        <PrimaryButton
          onClick={handleSubmit(handleSubmitMethodAndSendVerificationCode)}
          text={t("buttons.continue")}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_A}
          isLoading={isSignUpLoading}
        />
      </form>
      <PolicyModal
        open={openSlidePolicy}
        onClose={handleOnModalClose}
        mainElementRefPolicy={mainElementRefPolicy}
        policyModalTranslateFileKey={"signUpTermsData"}
      />
    </div>
  );
};

SignUpMethod.propTypes = {
  setOtpData: PropTypes.func,
  setCounter: PropTypes.func,
  handleNext: PropTypes.func,
  setSignInMethod: PropTypes.func,
  signInMethod: PropTypes.string,
};

export default SignUpMethod;

import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";

import "./AllBusinessesSpecialOffer.scss";

const AllBusinessesSpecialOffer = () => {
  const { t } = useTranslation();

  return (
    <div className="AllBusinessesSpecialOffers">
      <h3 className="Bold">{t("businesses.specialOffers")}</h3>
      <Swiper
        slidesPerView={"auto"}
        centeredSlides={true}
        spaceBetween={16}
        grabCursor={true}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        className="mySwiper"
        modules={[Autoplay]}
      >
        <SwiperSlide>
          <div className="AllBusinessesSpecialOffersMenuImage">
            <img
              src="https://images.unsplash.com/photo-1727178392526-297cc3577778?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="SpecialMenuImage"
              className="SpecialMenuImage"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="AllBusinessesSpecialOffersMenuImage">
            <img
              src="https://images.unsplash.com/photo-1727178392429-b87c025839a2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              className="SpecialMenuImage"
              alt="SpecialMenuImage"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="AllBusinessesSpecialOffersMenuImage">
            <img
              src="https://plus.unsplash.com/premium_photo-1710108922061-2e1bd4440780?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              className="SpecialMenuImage"
              alt="SpecialMenuImage"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="AllBusinessesSpecialOffersMenuImage">
            <img
              src="https://images.unsplash.com/photo-1726908904370-502c4c172d6e?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              className="SpecialMenuImage"
              alt="SpecialMenuImage"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="AllBusinessesSpecialOffersMenuImage">
            <img
              src="https://plus.unsplash.com/premium_photo-1663852297508-56e6ac75be2c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              className="SpecialMenuImage"
              alt="SpecialMenuImage"
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default AllBusinessesSpecialOffer;

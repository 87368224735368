import React, { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import "./AllBusinessesCategoryTag.scss";
import { useTranslation } from "react-i18next";

const AllBusinessesCategoryTags = ({ tags, seeAll }) => {
  const [checkedTags, setCheckedTags] = useState([]);

  const { t } = useTranslation();

  const handleTagClick = (index) => {
    setCheckedTags((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const getTranslatedTagName = (tagKey) => {
    return t(`businesses.${tagKey}`);
  };

  return (
    <div className={cx("AllBusinessesCategoryTags", { seeAll: seeAll })}>
      {tags &&
        tags.map((tag, index) => (
          <div
            key={index}
            onClick={() => handleTagClick(index)}
            className={cx("AllBusinessesCategoryTag", {
              AllBusinessesCategoryCheckedTag: checkedTags.includes(index),
            })}
          >
            {tag.icon}
            <p className="h7">{getTranslatedTagName(tag.translationKey)}</p>
          </div>
        ))}
    </div>
  );
};

AllBusinessesCategoryTags.propTypes = {
  tags: PropTypes.array,
  seeAll: PropTypes.bool,
};

export default AllBusinessesCategoryTags;

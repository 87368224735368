import { useEffect, useRef } from "react";

const useTimeout = ({ callback, delay, clear }) => {
  const mainCallback = useRef();
  const clearCallback = useRef();

  useEffect(() => {
    mainCallback.current = callback;
    clearCallback.current = clear || (() => {});
  }, [callback, clear]);

  useEffect(() => {
    if (delay !== null) {
      const id = setTimeout(() => {
        mainCallback.current();
      }, delay);

      return () => {
        clearTimeout(id);
        clearCallback.current();
      };
    }
  }, [delay]);
};

export default useTimeout;

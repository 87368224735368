import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/modal/Modal";
import IconButton from "components/buttons/icon-button/IconButton";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import RadioSelection from "components/admin/forms/radio-selection/RadioSelection";
import Rectangle from "assets/icons/map/Rectangle.svg";
import Circle from "assets/icons/map/Circle.svg";
import { SHAPE_ENUMS } from "utils/constants/data/base";
import DeleteButton from "components/buttons/delete-button/DeleteButton";

import "./MapItemEditModal.scss";

const INITIAL_FORM_DATA = {
  shape: SHAPE_ENUMS.RECTANGLE,
};

const MapItemEditModal = ({
  selectedTable,
  mainElementRef,
  setOpenSlide,
  openSlide,
  onSave,
  isLoading,
  onDeleteMapItem,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);

  useEffect(() => {
    if (selectedTable) {
      setFormData({
        ...INITIAL_FORM_DATA,
        shape: selectedTable?.shape,
      });
    }
  }, [selectedTable]);

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: parseInt(value),
      };
    });
  };

  const handleOnSubmit = () => {
    onSave([
      {
        id: parseInt(selectedTable.id),
        shape: formData.shape,
      },
    ]);
    setOpenSlide(false);
  };

  const MapItemEditModalHeader = (
    <div className="MapItemEditModalTitle">
      <h3 className="SemiBold">{t("common.shape")}</h3>
      <div className="MapItemEditModalTitleActions">
        <DeleteButton onClick={onDeleteMapItem} />
        <IconButton
          onClick={() => {
            setOpenSlide(false);
          }}
          svgComponent={<IconClose />}
        />
      </div>
    </div>
  );

  const MapItemEditModalBody = (
    <div className="MapItemEditModalBodyTables">
      <div className="ShapeSelectionOptions">
        <RadioSelection
          value={SHAPE_ENUMS.RECTANGLE}
          label={t("map.shapes.rectangle")}
          name="shape"
          imgSrc={Rectangle}
          isChecked={formData.shape === SHAPE_ENUMS.RECTANGLE}
          onChange={handleInputChange}
        />
        <RadioSelection
          value={SHAPE_ENUMS.CIRCLE}
          label={t("map.shapes.circle")}
          name="shape"
          imgSrc={Circle}
          isChecked={formData.shape === SHAPE_ENUMS.CIRCLE}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
  const MapItemEditModalFooter = (
    <div className="MapItemEditModalFooter">
      <PrimaryButton
        onClick={handleOnSubmit}
        text={t("buttons.save")}
        isLoading={isLoading}
      />
    </div>
  );
  return (
    <Modal
      header={MapItemEditModalHeader}
      body={MapItemEditModalBody}
      footer={MapItemEditModalFooter}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    />
  );
};

export default MapItemEditModal;

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { useSelector } from "react-redux";

import "./GuestProfiles.scss";
import {
  calculateAllOrdersDiscountPrice,
  calculateAllOrdersPrice,
  calculateAllOrdersTotalPrice,
  calculateServiceFee,
} from "utils/general";
import { STORE_NAMES } from "utils/constants/redux";
import { createDOBucketName } from "utils/DO-Spaces";
import IMG_GUEST from "assets/images/placeholder/GuestPlaceholder.png";
import { generateGuestIdSuffix } from "utils/helpers";

export const ENUMS = {
  name: "GuestProfiles",
};

const GuestProfiles = ({ guests, onClick, selectedGuests, guestId }) => {
  const { t } = useTranslation();
  const {
    serviceFee,
    images,
    name: businessName,
  } = useSelector((state) => state[STORE_NAMES.business].business);
  const businessImage = createDOBucketName(images.logo);
  const generatedGuestId = generateGuestIdSuffix(guestId);

  let allParticipants = [
    ...guests.filter((guest) => guest.person.id === generatedGuestId),
    ...guests.filter((guest) => guest.person.id !== generatedGuestId),
  ];
  const handleGuestClick = (generatedGuestId) => {
    onClick(generatedGuestId);
  };
  const menuCurrency = useSelector(
    (state) => state[STORE_NAMES.menu].data?.currency
  );
  const currencySymbol = menuCurrency?.symbol;

  const calculateForEachPerson = (person) => {
    const subTotal = calculateAllOrdersPrice([person]);
    const discountPrice = calculateAllOrdersDiscountPrice([person]);
    const serviceFeeTotal = calculateServiceFee(
      subTotal,
      discountPrice,
      serviceFee
    );
    return calculateAllOrdersTotalPrice(
      subTotal,
      0,
      undefined,
      serviceFeeTotal,
      discountPrice
    ).toFixed(2);
  };

  return (
    <div className="GuestProfiles">
      {allParticipants.map((participant, index) => {
        const {
          name: guestName,
          id,
          image: guestImage,
          isGuest,
          originalId,
        } = participant.person;

        const isActive = selectedGuests.find(
          (selectedGuest) => participant.person.id === selectedGuest.person.id
        );

        return (
          <div
            key={`${id}-${index}`}
            className={cx("GuestProfile", {
              isActive: isActive,
            })}
            onClick={() => handleGuestClick(id)}
          >
            <img
              alt={isGuest ? guestName : businessName}
              src={isGuest ? guestImage : businessImage || IMG_GUEST}
            />
            <div className="GuestProfileIdentity">
              <div className="GuestProfileName">
                <h6 className="Medium">
                  {id === generatedGuestId
                    ? t("dashboard.guest.me")
                    : isGuest
                    ? guestName
                    : businessName}
                </h6>
                <h6 className="h7 GuestPersonId">
                  {!participant.name && originalId}
                </h6>
              </div>

              <h6 className="Bold GuestTotalPrice">
                {currencySymbol}
                {calculateForEachPerson(participant)}
              </h6>
            </div>
          </div>
        );
      })}
    </div>
  );
};

GuestProfiles.propTypes = {
  /**
   * An array of guest objects.
   */
  guests: PropTypes.array.isRequired,
  /**
   * A callback function to handle click events on guest profiles.
   */
  onClick: PropTypes.func.isRequired,
  /**
   * An array of selected guest objects.
   */
  selectedGuests: PropTypes.array.isRequired,

  /**
   * The id of the guest
   */
  guestId: PropTypes.string,
};

export default GuestProfiles;

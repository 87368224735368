import { useDispatch, useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { resetGuestStore } from "redux/slices/guestStore";
import { createGuestAsync } from "redux/actions/guestAction";
import { resetBasketStore } from "redux/slices/basketStore";
import { LOCAL_STORAGE_VARIABLES } from "utils/constants/global";

const useLogoutGuest = () => {
  const dispatch = useDispatch();
  const guestHasSignedUp = useSelector(
    (state) => state[STORE_NAMES.guest]?.hasSignedUp
  );
  const logoutGuest = async () => {
    localStorage.removeItem(LOCAL_STORAGE_VARIABLES.accessTokenForGuest);
    if (guestHasSignedUp) {
      await dispatch(resetBasketStore());
      await dispatch(resetGuestStore());
      return dispatch(createGuestAsync());
    }
  };

  return { logoutGuest };
};

export default useLogoutGuest;

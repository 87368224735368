import { AXIOS_GUEST_NO_AUTH, AXIOS_USER_NO_AUTH } from "utils/api/axios";
import { API_PATH_PARAMS } from "utils/constants/api";

export const createOtpRequest = async (body) => {
  return AXIOS_GUEST_NO_AUTH.post(`/${API_PATH_PARAMS.otp}`, body);
};

export const createOtpRequestForUserSignup = async (body) => {
  return AXIOS_USER_NO_AUTH.post(
    `/${API_PATH_PARAMS.auth}/${API_PATH_PARAMS.sendOtp}`,
    body
  );
};

export const createOtpRequestForGuestReset = async (body) => {
  return AXIOS_GUEST_NO_AUTH.post(
    `/${API_PATH_PARAMS.users}/${API_PATH_PARAMS.guests}/${API_PATH_PARAMS.sendOtp}`,
    body
  );
};

export const createOtpRequestForUserReset = async (body) => {
  return AXIOS_GUEST_NO_AUTH.post(
    `/${API_PATH_PARAMS.auth}/${API_PATH_PARAMS.forgotPassword}/${API_PATH_PARAMS.sendOtp}`,
    body
  );
};
export const createOtpRequestForAssignUserToBusiness = async ({
  businessId,
  otpBody,
}) => {
  return AXIOS_USER_NO_AUTH.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.users}/${API_PATH_PARAMS.sendOtp}`,
    otpBody
  );
};

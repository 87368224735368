import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import ICON_CHECK from "assets/icons/form/Check.svg";

import "./ModificationRadioButton.scss";

export const ENUMS = {
  name: "RadioButton",
};

const ModificationRadioButton = ({ isCountable, count }) => {
  return (
    <h4
      className={cx("RadioButtonContainer Bold", {
        isCountable: isCountable && count > 0,
        isUncountable: !isCountable && count > 0,
      })}
    >
      <div className="RadioButton">
        {isCountable && count > 0 && count}
        {!isCountable && count > 0 && <img src={ICON_CHECK} alt="check" />}
      </div>
    </h4>
  );
};

ModificationRadioButton.propTypes = {
  /**
   * Indicates if the item is countable.
   */

  isCountable: PropTypes.bool,

  /**
   * the count of the item
   */

  count: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
};

export default ModificationRadioButton;

import { AXIOS_USER } from "utils/api/axios";
import { API_PATH_PARAMS } from "utils/constants/api";

export const getAdminMetadata = async (businessId) =>
  AXIOS_USER.get(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.metadata}/${API_PATH_PARAMS.admin}`
  );

export const getGeneralMetadata = async () =>
  AXIOS_USER.get(
    `/${API_PATH_PARAMS.business}/${API_PATH_PARAMS.metadata}/${API_PATH_PARAMS.general}`
  );

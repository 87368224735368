import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import useLanguage from "utils/hooks/useLanguage";
import useAsync from "utils/hooks/useAsync";
import { createDOBucketName } from "utils/DO-Spaces";
import { STORE_NAMES } from "utils/constants/redux";
import AdminDashMenuByOrder from "pages/admin/admin-pages/admin-dashboard/admin-dash-menu-stats/admin-dash-menu-by-order/AdminDashMenuByOrder";
import StatsCardChartWithPeriodWrapper from "components/admin/charts/chart-wrappers/stat-chart-with-periods-wrapper/StatsCardChartWithPeriodWrapper";
import VerticalBarChart from "components/admin/charts/vertical-bar-chart/VerticalBarChart";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import EmptyState from "components/admin/empty-state/EmptyState";
import Spinner from "components/elements/spinner/Spinner";
import { getStatsMenuStatistics } from "utils/api/services/dashboard";
import {
  fixCharDataWidth,
  getCategoryNameById,
} from "utils/helper-functions/dashboardStatsHelper";
import { findMenuItemById } from "utils/helpers";
import EmptyChartDataIcon from "assets/icons/admin-dashboard/EmptyChartDataIcon.svg";

import "./AdminDashMenuStats.scss";

const CHART_DATA_KEYS = {
  xAxisDataKey: "categoryName",
  ordersDataKey: "totalSale",
};

const CHART_DATA_COLORS = {
  ordersFillColor: "#FFDD00",
  ordersStrokeColor: "#000000",
};

const getMenuStatisticsChartData = (bestSellingCategories, categories) => {
  const data = bestSellingCategories?.map((item) => ({
    [CHART_DATA_KEYS.xAxisDataKey]: getCategoryNameById(
      categories,
      item?.categoryId
    ),
    [CHART_DATA_KEYS.ordersDataKey]: item?.totalSale,
  }));

  return {
    meta: {
      type: "monotone",
      xAxisDataKey: CHART_DATA_KEYS.xAxisDataKey,
      data: [
        {
          dataKey: CHART_DATA_KEYS.ordersDataKey,
          fillColor: CHART_DATA_COLORS.ordersFillColor,
          strokeColor: CHART_DATA_COLORS.ordersStrokeColor,
        },
      ],
    },
    data,
  };
};

const transformBestOrWorstSellingData = (
  allCategories,
  allSellingData,
  currencySymbol
) => {
  return allSellingData?.map((sellingData) => {
    const menuItem = findMenuItemById(allCategories, sellingData.itemId);
    return {
      primaryData: sellingData.orderCount,
      secondaryData: `${sellingData.totalSales}${currencySymbol}`,
      ternaryData: `${sellingData.revenue}${currencySymbol}`,
      imageSrc: createDOBucketName(menuItem.coverImageSrc),
      itemName: menuItem.name,
    };
  });
};

const getBestAndWorstSellingData = (
  t,
  allFetchedBestWorstData,
  allCategories,
  selectedCategoryId = null,
  currencySymbol
) => {
  let selectedFetchedBestWorstData;
  if (selectedCategoryId) {
    selectedFetchedBestWorstData = allFetchedBestWorstData?.find(
      (rawData) => rawData.categoryId === selectedCategoryId
    );
  } else {
    selectedFetchedBestWorstData = allFetchedBestWorstData?.find(
      (rawData) => !rawData.categoryId
    );
  }

  const leftColumn = transformBestOrWorstSellingData(
    allCategories,
    selectedFetchedBestWorstData?.bestSelling,
    currencySymbol
  );

  const rightColumn = transformBestOrWorstSellingData(
    allCategories,
    selectedFetchedBestWorstData?.worstSelling,
    currencySymbol
  );

  return {
    leftColumn: leftColumn,
    rightColumn: rightColumn,
  };
};

const AdminDashMenuStats = () => {
  const { setTitle, fromDateToDate } = useOutletContext();
  const [categoryOrder, setCategoryOrder] = useState("");

  const { t } = useTranslation();
  const { displayDataByLanguage } = useLanguage();

  useEffect(() => {
    setTitle(t("navbarRoutes.pageTitles.menuStatistics"));
  }, [t]);

  useEffect(() => {
    setCategoryOrder("");
  }, [fromDateToDate]);

  const businessId = useSelector(
      (state) => state[STORE_NAMES.business]?.business?.id
  );
  const currency = useSelector(
    (state) => state[STORE_NAMES.menu].data?.currency
  );
  const allCategories = useSelector(
    (state) => state[STORE_NAMES.menu].data?.categories
  );

  const updatedAllCategories = allCategories?.map((category) => {
    const updatedCategoryName = displayDataByLanguage(category?.name);
    const updatedMenuItems = category?.menuItems?.map((menuItem) => ({
      ...menuItem,
      name: displayDataByLanguage(menuItem?.name),
    }));

    return {
      ...category,
      name: updatedCategoryName,
      menuItems: updatedMenuItems,
    };
  });
  updatedAllCategories?.unshift({ name: t("dashboard.all"), id: undefined });

  const handleOnCategoryOrderDropdownChange = (option) => {
    setCategoryOrder(option);
  };

  const { result: fetchedMenuStatsData, loading: menuStatsDataLoading } =
    useAsync(getStatsMenuStatistics, {
      immediate: true,
      params: [businessId, fromDateToDate.from, fromDateToDate.to],
    });

  const statsMenuStatisticsChartData = getMenuStatisticsChartData(
    fetchedMenuStatsData?.bestSellingCategories,
    updatedAllCategories,
    currency?.symbol
  );

  const statsMenuBestAndWorstSellingData = getBestAndWorstSellingData(
    t,
    fetchedMenuStatsData?.menuItemsStatistics,
    updatedAllCategories,
    categoryOrder?.id,
    currency?.symbol
  );

  const chartDataWidth = fixCharDataWidth(
    statsMenuStatisticsChartData?.data?.length,
    10,
    15
  );

  return (
    <div className="AdminDashMenuStats">
      {menuStatsDataLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <>
          {statsMenuBestAndWorstSellingData && (
            <AdminDashMenuByOrder
              className="AdminDashMenuStatsMenuByOrder"
              title={t("dashboard.byNumberOfOrders")}
              subTitle={fromDateToDate}
              leftColumn={
                statsMenuBestAndWorstSellingData.leftColumn
                  ? statsMenuBestAndWorstSellingData.leftColumn
                  : []
              }
              rightColumn={
                statsMenuBestAndWorstSellingData.rightColumn
                  ? statsMenuBestAndWorstSellingData.rightColumn
                  : []
              }
              leftColumnName={t("dashboard.bestselling")}
              rightColumnName={t("dashboard.worstSelling")}
              columnItemPrimaryName={t("dashboard.orderStatistics.orders")}
              columnItemSecondaryName={t("dashboard.orderStatistics.sale")}
              columnItemTernaryName={t("dashboard.orderStatistics.revenue")}
              element={
                <Dropdown
                  className={"AdminDashMenuByOrderDropDown"}
                  value={categoryOrder || updatedAllCategories?.[0] || ""}
                  onChange={(category) => {
                    handleOnCategoryOrderDropdownChange(category);
                  }}
                  placeholder={t("dashboard.placeholder.categories")}
                  options={updatedAllCategories}
                />
              }
            />
          )}

          <StatsCardChartWithPeriodWrapper
            title={t("dashboard.bestSellingCategory")}
            chartToRender={
              statsMenuStatisticsChartData ? (
                statsMenuStatisticsChartData.data?.length > 0 ? (
                  <VerticalBarChart
                    chartData={statsMenuStatisticsChartData}
                    chartWidth={chartDataWidth}
                    showCustomTick={true}
                    showYaxisCurrencySymbol={true}
                  />
                ) : (
                  <EmptyState
                    icon={EmptyChartDataIcon}
                    isAdmin={true}
                    description={t("dashboard.noResultForThisPeriodMsg")}
                    descriptionClassName={
                      "AdminDashMenuStatsEmptyStateDescription"
                    }
                  />
                )
              ) : (
                <div>
                  <Spinner />
                </div>
              )
            }
          />
        </>
      )}
    </div>
  );
};

export default AdminDashMenuStats;

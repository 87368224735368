import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useAsync from "utils/hooks/useAsync";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import AdminDashTable from "components/admin/tables/admin-dash-table/AdminDashTable";
import { formatDashBoardDate } from "utils/helpers";
import { QUERY_PARAMS } from "utils/constants/api";
import { STORE_NAMES } from "utils/constants/redux";
import useDebounce from "utils/hooks/useDebounce";
import {
  deleteOrderHistoryTable,
  getStatsOrderHistoryTable,
} from "utils/api/services/dashboard";
import { getUsers } from "utils/api/services/user";
import Search, { ENUMS as SEARCH_ENUMS } from "components/forms/search/Search";
import { ReactComponent as SortArrowDefault } from "assets/icons/admin-dashboard/sortArrowDefault.svg";
import DeleteButton from "components/buttons/delete-button/DeleteButton";

import "./AdminDashAllOrders.scss";

const TABLE_KEYS = {
  name: "name",
  order_date: "orderDate",
  zone_and_table: "ZoneAndTable",
  item_count: "itemCount",
  sub_total: "subTotal",
  service_fee: "serviceFee",
  discount: "discount",
  total: "total",
  action: "action",
};

const getOrderHistoryTableData = (
  fetchedRawTableData,
  currencySymbol,
  onDelete
) => {
  return (
    fetchedRawTableData?.data?.map((staff) => {
      const startDate = formatDashBoardDate(staff.orderInitDate);
      const finishedDate = formatDashBoardDate(staff.orderFinishDate);
      const staffName = staff?.staff.split(" ");
      return {
        id: staff.id,
        [TABLE_KEYS.name]: (
          <div
            className={"AdminOrderHistoryTableCustomRowItem"}
            title={`${staffName[0]}\n${staffName[1]}`}
          >
            {staffName[0]} <br />
            {staffName[1]}
          </div>
        ),
        [TABLE_KEYS.order_date]: (
          <div
            className={"AdminOrderHistoryTableCustomRowItem"}
            title={`${startDate}\n${finishedDate}`}
          >
            {startDate} <br />
            {finishedDate}
          </div>
        ),
        [TABLE_KEYS.zone_and_table]: (
          <div
            className={"AdminOrderHistoryTableCustomRowItem"}
            title={`${staff.zone}\n${staff.table}`}
          >
            {staff.zone} <br />
            {staff.table}
          </div>
        ),
        [TABLE_KEYS.item_count]: `${staff.itemCount}`,
        [TABLE_KEYS.sub_total]: `${currencySymbol}${staff.subTotal}`,
        [TABLE_KEYS.service_fee]: `${
          staff.serviceFee === 0 ? "-" : `${currencySymbol}${staff.serviceFee}`
        }`,
        [TABLE_KEYS.discount]: `${
          staff.discount === 0 ? "-" : `${staff.discount}${currencySymbol}`
        }`,
        [TABLE_KEYS.total]: `${staff.total}${currencySymbol}`,
        [TABLE_KEYS.action]: (
          <div className="AdminOrderHistoryTableDeleteButton">
            <DeleteButton onClick={() => onDelete(staff.id)} />
          </div>
        ),
      };
    }) || []
  );
};

const getColumnIndexKey = (tableColumns, index) => {
  return tableColumns[index]?.key;
};

const AdminDashAllOrders = () => {
  const { t } = useTranslation();
  const [staff, setStaff] = useState(QUERY_PARAMS.staff);
  const [zone, setZone] = useState(QUERY_PARAMS.zone);
  const [searchValueOrderHistory, setSearchValueOrderHistory] = useState("");
  const [searchValueOrderHistory2, setSearchValueOrderHistory2] = useState("");
  const [activePageOrderHistory, setActivePageOrderHistory] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortTypeOrderHistory, setSortTypeOrderHistory] = useState("");
  const [columnClickOrderHistory, setColumnClickOrderHistory] = useState(null);
  const [, setColumnClickCountOrderHistory] = useState(0);
  const zones = useSelector((state) => state[STORE_NAMES.zones].zones);
  const currency = useSelector(
    (state) => state[STORE_NAMES.menu].data.currency
  );
  const { setTitle, fromDateToDate } = useOutletContext();
  const [isOrderHistoryDeleteLoading, setIsOrderHistoryDeleteLoading] =
    useState(false);

  useEffect(() => {
    setTitle(t("navbarRoutes.pageTitles.allOrders"));
  }, [t]);

  useEffect(() => {
    setActivePageOrderHistory(1);
    setSearchValueOrderHistory("");
    setSearchValueOrderHistory2("");
    setStaff(QUERY_PARAMS.staff);
    setZone(QUERY_PARAMS.zone);
    setSortTypeOrderHistory("");
  }, [fromDateToDate]);

  const debouncedRequest = useDebounce(() => {
    setSearchValueOrderHistory2(searchValueOrderHistory);
  }, 1000);
  const handleSearchChange = (value) => {
    setSearchValueOrderHistory(value);
    debouncedRequest();
    setActivePageOrderHistory(1);
  };

  const handleDropdownChange = (option) => {
    setZone(option);
    setActivePageOrderHistory(1);
  };

  const handleOnDropdownChange = (option) => {
    setStaff(option);
    setActivePageOrderHistory(1);
  };

  const businessId = useSelector(
      (state) => state[STORE_NAMES.business]?.business?.id
  );

  const tableColumnsOrderHistory = [
    {
      key: TABLE_KEYS.name,
      name: t("dashboard.table.adminOrderHistoryTable.staff"),
      isFilterable: false,
      hasCustomRow: true,
    },
    {
      key: TABLE_KEYS.order_date,
      hasCustomRow: true,
      hasCustomColumn: true,
      name: t("dashboard.table.adminOrderHistoryTable.dates"),
      isFilterable: true,
      columnClassName: "TableDateColumnClass",
    },
    {
      key: TABLE_KEYS.zone_and_table,
      hasCustomColumn: true,
      hasCustomRow: true,
      name: (
        <div className={"AdminOrderHistoryTableCustomRowItem"}>
          {t("dashboard.table.adminOrderHistoryTable.zone")} <br />
          {t("dashboard.table.adminOrderHistoryTable.table")}
        </div>
      ),
      isFilterable: false,
    },
    {
      key: TABLE_KEYS.item_count,
      name: t("dashboard.table.adminOrderHistoryTable.item"),
      isFilterable: true,
    },
    {
      key: TABLE_KEYS.sub_total,
      name: t("dashboard.table.adminOrderHistoryTable.subTotal"),
      isFilterable: true,
    },
    {
      key: TABLE_KEYS.service_fee,
      hasCustomColumn: true,
      name: (
        <div className={"AdminOrderHistoryTableCustomRowItem"}>
          {t("dashboard.table.adminOrderHistoryTable.service")} <br />
          {t("dashboard.table.adminOrderHistoryTable.fee")}
        </div>
      ),
      isFilterable: true,
    },
    {
      key: TABLE_KEYS.discount,
      name: t("dashboard.table.adminOrderHistoryTable.discount"),
      isFilterable: true,
    },
    {
      key: TABLE_KEYS.total,
      name: t("dashboard.table.adminOrderHistoryTable.total"),
      isFilterable: true,
    },
    {
      key: TABLE_KEYS.action,
      name: "",
      isFilterable: false,
    },
  ];

  const tableColumnKey = getColumnIndexKey(
    tableColumnsOrderHistory,
    columnClickOrderHistory
  );

  const {
    loading,
    result: fetchedRawOrderHistoryTableData,
    execute: executeFetchedRawOrderHistoryTableData,
  } = useAsync(getStatsOrderHistoryTable, {
    immediate: true,
    params: [
      businessId,
      fromDateToDate.from,
      fromDateToDate.to,
      10,
      activePageOrderHistory,
      tableColumnKey,
      sortTypeOrderHistory,
      zone?.id,
      staff?.id,
      searchValueOrderHistory2,
    ],
  });

  const { execute: executeDeleteOrderHistory } = useAsync(
    deleteOrderHistoryTable,
    {
      onSuccess: () =>
        toast.success(t("toastMessages.success.deleteOrderHistory")),
      onError: ({ response }) => toast.error(response.data.message),
    }
  );

  useEffect(() => {
    if (fetchedRawOrderHistoryTableData) {
      setTotalPages(fetchedRawOrderHistoryTableData.totalPages);
      setActivePageOrderHistory(fetchedRawOrderHistoryTableData.pageNumber); // Update the current page based on API response
    }
  }, [fetchedRawOrderHistoryTableData]);
  // Handle page change
  const handlePageChange = (pageNumber) => {
    setActivePageOrderHistory(pageNumber); // Update the current page
  };

  const { result: usersData } = useAsync(getUsers, {
    immediate: true,
    params: [businessId],
  });

  const handleDeleteHistory = async (id) => {
    setIsOrderHistoryDeleteLoading(true);
    await executeDeleteOrderHistory({ businessId, deletedOrderHistory: [id] });
    await executeFetchedRawOrderHistoryTableData(
      businessId,
      fromDateToDate.from,
      fromDateToDate.to,
      10,
      activePageOrderHistory,
      tableColumnKey,
      sortTypeOrderHistory,
      zone?.id,
      staff?.id,
      searchValueOrderHistory2
    );
    setIsOrderHistoryDeleteLoading(false);
  };

  const assigneeDropdownOptions = usersData?.data;

  const statsOrderHistoryTableDataItems = getOrderHistoryTableData(
    fetchedRawOrderHistoryTableData,
    currency?.symbol,
    handleDeleteHistory
  );

  return (
    <div className="AdminDashAllOrders">
      <div className="AdminDashHistoryForm">
        <div className="Form">
          <Search
            type={SEARCH_ENUMS.types.TYPE_B}
            classname={"CustomSearch"}
            onChange={(value) => {
              handleSearchChange(value);
            }}
            value={searchValueOrderHistory}
            placeholder={t("dashboard.placeholder.orderId")}
          />

          <Dropdown
            className={"CustomDropDown"}
            onChange={(assignee) => handleOnDropdownChange(assignee)}
            placeholder={t("dashboard.placeholder.staff")}
            value={staff}
            options={assigneeDropdownOptions}
          />

          <Dropdown
            className={"CustomDropDown"}
            onChange={(zone) => {
              handleDropdownChange(zone);
            }}
            name="zone"
            placeholder={t("dashboard.placeholder.zone")}
            value={zone}
            options={zones}
          />
        </div>
      </div>

      <AdminDashTable
        tableColumns={tableColumnsOrderHistory}
        tableItems={statsOrderHistoryTableDataItems}
        hasEditColumnTitle={false}
        hasEditRow={false}
        hasEditColumn={false}
        totalPages={totalPages}
        setActivePage={handlePageChange}
        activePage={activePageOrderHistory}
        tableClassName={"AdminOrderHistoryCustomTable"}
        sortType={sortTypeOrderHistory}
        setSortType={setSortTypeOrderHistory}
        columnClick={columnClickOrderHistory}
        setColumnClick={setColumnClickOrderHistory}
        customFilterIcon={<SortArrowDefault />}
        isDataLoading={loading || isOrderHistoryDeleteLoading}
        setColumnClickCount={setColumnClickCountOrderHistory}
        classNameForEmptyDataComponent={"AdminOrderHistoryEmptyTableData"}
        currentPage={fetchedRawOrderHistoryTableData?.pageNumber}
      />
    </div>
  );
};

export default AdminDashAllOrders;

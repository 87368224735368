import React from "react";
import { ReactComponent as LogoLight } from "assets/icons/logo/iLoyal/logo-light.svg";
import "./errorBoundary.scss";
import { resetAllReduxStores } from "../../../utils/general";
import { useDispatch } from "react-redux";

const ErrorBoundaryFallback = ({ error, resetErrorBoundary }) => {
  const dispatch = useDispatch();

  return (
    <div className="error-container">
      <LogoLight className="iloyal-logo" />
      <div className="error-card">
        <h2 className="error-title">Oops! Something went wrong.</h2>
        <p className="error-message">{error.message}</p>
        <button
          className="retry-button"
          onClick={async () => {
            await resetAllReduxStores(dispatch);
            resetErrorBoundary();
          }}
        >
          Restart
        </button>
      </div>
    </div>
  );
};

export default ErrorBoundaryFallback;

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Modal from "components/modal/Modal";
import ModificationRadioButton from "components/buttons/modification-radio-button/ModificationRadioButton";
import { STORE_NAMES } from "utils/constants/redux";
import { setLanguage } from "redux/slices/userStore";

import "./BusinessesLanguage.scss";

const BusinessesLanguage = ({ openSlide, onClose, mainElementRef }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedLanguage = useSelector(
    (state) => state[STORE_NAMES.user].userLanguage
  );

  const availableLanguages = useSelector(
    (state) => state[STORE_NAMES.app].languages
  );
  const currentLanguage =
    selectedLanguage || availableLanguages?.find((lang) => lang.code === "EN");

  const handleSelect = (language) => {
    dispatch(setLanguage(language));
    onClose();
  };

  const LanguagesModalBody = (
    <ul className="BusinessesLanguageModalBody">
      {availableLanguages?.map((language) => (
        <li
          className="BusinessesLanguageModalOption"
          onClick={() => handleSelect(language)}
          key={language.id}
        >
          <div className="BusinessesLanguageDetails">
            <img alt="language" src={language.imgSrc} />
            <div className="BusinessesLanguageModalOptionNameContainer">
              <h5 className="SemiBold BusinessesLanguageModalOptionName">
                {t(language.name)}
              </h5>
              <p className="h7">{language.description}</p>
            </div>
          </div>
          <ModificationRadioButton
            count={currentLanguage?.id === language.id}
          />
        </li>
      ))}
    </ul>
  );

  return (
    <Modal
      body={LanguagesModalBody}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    />
  );
};

BusinessesLanguage.propTypes = {
  openSlide: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mainElementRef: PropTypes.object,
};

export default BusinessesLanguage;

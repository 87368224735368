import React from "react";
import { createDOBucketName } from "utils/DO-Spaces";
import PropTypes from "prop-types";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";

import "./SelectUserBusiness.scss";

const SelectUserBusiness = ({
  userBusinesses,
  setSelectedBusiness,
  selectedBusiness = {},
  title,
  subtitle,
  isAdmin = true,
}) => {
  const handleSelectUserBusiness = async (selectedBusiness) => {
    setSelectedBusiness(selectedBusiness);
  };
  return (
    <div className="SelectBusinessModalBody">
      {title && <h3 className="SemiBold">{title}</h3>}
      {subtitle && <p>{subtitle}</p>}
      <ul className="UserBusinessLists">
        {userBusinesses.length > 0 &&
          userBusinesses
            ?.sort((a, b) => {
              return a.isArchived - b.isArchived;
            })
            .map((business) => (
              <li
                key={business.id}
                onClick={() => handleSelectUserBusiness(business)}
                className={[
                  selectedBusiness.id === business.id
                    ? business.isArchived
                      ? "DeletedActiveBusiness"
                      : "ActiveBusiness"
                    : "",
                  business.isArchived ? "DeletedBusiness" : "",
                ]
                  .filter(Boolean)
                  .join(" ")}
              >
                <ImageWithPlaceholder
                  imageSource={createDOBucketName(business.images.logo)}
                  placeholder={IMAGE_ITEM_PLACEHOLDER}
                  alt="business-logo"
                />
                <h3 className="SemiBold">{business.name}</h3>
                {business.isArchived && (
                  <div className="InactiveBusiness">
                    <p>inactive</p>
                    {isAdmin && (
                      <>
                        <p className="TextDash">-</p>
                        <span>Contact us</span>
                      </>
                    )}
                  </div>
                )}
              </li>
            ))}
      </ul>
    </div>
  );
};

SelectUserBusiness.propTypes = {
  userBusinesses: PropTypes.array,
  setSelectedBusiness: PropTypes.func,
  selectedBusiness: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isAdmin: PropTypes.bool,
};

export default SelectUserBusiness;

import { createAsyncThunk } from "@reduxjs/toolkit";

import { STORE_NAMES } from "utils/constants/redux";
import {
  createZone,
  deleteZone,
  getZones,
  updateZone,
} from "utils/api/services/zone";

export const getZonesAsync = createAsyncThunk(
  `${STORE_NAMES.zones}/getZones`,
  async ({ businessId }) => {
    const response = await getZones(businessId);
    const chatData = [
      {
        tableId: 1,
        messages: [],
      },
      {
        tableId: 3,
        messages: [],
      },
    ];

    return response.data.map((zone) => {
      const tablesWithChatData = zone.tables.map((table) => {
        const matchingChatData = chatData.find(
          (chat) => chat.tableId === table.id
        );

        if (matchingChatData) {
          return { ...table, messages: matchingChatData.messages };
        }

        return table;
      });

      return { ...zone, tables: tablesWithChatData };
    });
  }
);

export const updateZoneAsync = createAsyncThunk(
  `${STORE_NAMES.zones}/updateZone`,
  async ({ businessId, zone, id }) => {
    const response = await updateZone(businessId, zone, id);
    return response.data;
  }
);

export const createZoneAsync = createAsyncThunk(
  `${STORE_NAMES.zones}/createZone`,
  async ({ businessId, zone }) => {
    const response = await createZone(businessId, zone);
    return response.data;
  }
);

export const deleteZoneAsync = createAsyncThunk(
  `${STORE_NAMES.zones}/deleteZone`,
  async ({ businessId, id }) => {
    const response = await deleteZone(businessId, id);
    return response.data;
  }
);

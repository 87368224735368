import { AXIOS_USER } from "utils/api/axios";
import { API_PATH_PARAMS } from "utils/constants/api";

export const getTables = async (businessId) =>
  AXIOS_USER.get(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.tables}`
  );

export const createTable = async (businessId, table) =>
  AXIOS_USER.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.tables}`,
    table
  );

export const updateTable = async (businessId, table, id) =>
  AXIOS_USER.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.tables}/${id}`,
    table
  );

export const deleteTable = async (businessId, id) =>
  AXIOS_USER.delete(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.tables}/${id}`
  );

export const updateTableMapView = async (businessId, tables) =>
  AXIOS_USER.put(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.tables}/${API_PATH_PARAMS.map}`,
    tables
  );

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PhoneInput } from "react-international-phone";

import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { createDOBucketName } from "utils/DO-Spaces";
import { STORE_NAMES } from "utils/constants/redux";
import { ReactComponent as PendingIcon } from "assets/icons/reserve/pending.svg";
import { ReactComponent as ConfirmedIcon } from "assets/icons/reserve/confirmed.svg";
import { ReactComponent as DeniedIcon } from "assets/icons/reserve/denied.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar/calendar.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock/ClockCircle.svg";
import { ReactComponent as GuestCountIcon } from "assets/icons/reserve/People.svg";
import { ReactComponent as TableIcon } from "assets/icons/reserve/element-plus.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrows/ArrowDown.svg";
import { ReactComponent as MenuIcon } from "assets/icons/reserve/menu.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/reserve/phone.svg";
import { ReactComponent as LocationIcon } from "assets/icons/reserve/location.svg";
import { ReactComponent as GuestIcon } from "assets/icons/reserve/GuestName.svg";
import { ReactComponent as CallIcon } from "assets/icons/reserve/GuestPhone.svg";
import { ReactComponent as CommentIcon } from "assets/icons/reserve/message.svg";
import {
  formatReservationDate,
  formatReservationTimeRange,
} from "utils/helpers";
import { ROUTE_NAME } from "utils/constants/routes";
import { useNavigate } from "react-router-dom";
import ThreeDots from "components/admin/elements/three-dots/ThreeDots";
import Confirm from "components/admin/cards/confirm/Confirm";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useAsync from "utils/hooks/useAsync";
import { deleteReservation } from "utils/api/services/reservation";
import Spinner from "components/elements/spinner/Spinner";
import { ENUMS } from "components/elements/email-or-phone/EmailOrPhone";
import FormattedPhoneNumber from "components/elements/formatted-phone-number/FormattedPhoneNumber";

import "./ReservationInfo.scss";

const ReservationInfo = ({ reservation, setGuestReservations }) => {
  const { t } = useTranslation();
  const [showReservationDetail, setShowReservationDetail] = useState(false);
  const [reservationDetailsHeight, setReservationDetailsHeight] = useState(0);
  const OrderDetailRef = useRef(null);
  const {
    execute: executeDeleteReservation,
    loading: isDeleteReservationLoading,
  } = useAsync(deleteReservation, {
    onSuccess: (res) => {
      setGuestReservations((prev) => {
        return prev.filter((reservation) => reservation.id !== res.data.id);
      });
    },
  });
  const [openSlideConfirm, setOpenSlideConfirm, mainElementRefConfirm] =
    useOutsideClick();

  useEffect(() => {
    if (OrderDetailRef.current) {
      const height = OrderDetailRef.current.clientHeight;
      setReservationDetailsHeight(height);
    }
  }, []);

  const { enums } = useSelector((state) => state[STORE_NAMES.app]);
  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);
  const navigate = useNavigate();
  const { business } = useSelector((state) => state[STORE_NAMES.business]);

  const threeDotsOptions = [
    {
      name: t("reservation.delete"),
      action: () => setOpenSlideConfirm(true),
    },
  ];

  const handleGoToMenu = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.menu}`
    );
  };

  const handleOnConfirm = (e) => {
    e.stopPropagation();
    executeDeleteReservation(reservation.business.id, reservation.id);
    setOpenSlideConfirm(false);
  };

  const handleOnCancel = (e) => {
    e.stopPropagation();
    setOpenSlideConfirm(false);
  };

  const { startTime, endTime } = formatReservationTimeRange(
    reservation.startDateTime,
    reservation.endDateTime
  );

  const reservationDetail = (
    <div className="ReservationInfoMoreDetail" ref={OrderDetailRef}>
      <div className="ReservationInfoDetailGuestContact">
        <div>
          <GuestIcon className="ReservationInfoDetailCalendarIcon" />
          <span>{reservation.guestName}</span>
        </div>
        <div>
          <CallIcon className="ReservationInfoDetailCalendarIcon" />
          <PhoneInput
            value={reservation?.guestPhone || "-"}
            name="guestPhone"
            placeholder={t("inputs.phoneNumber")}
            defaultCountry={ENUMS.defaultCountry}
            forceDialCode
            hideDropdown
            className="phone-input"
            inputProps={{
              autoComplete: "off",
              readOnly: true,
            }}
          />
        </div>
      </div>
      {reservation.guestComment && (
        <div className="ReservationInfoDetailGuestComment">
          <CommentIcon />
          <span>{reservation.guestComment}</span>
        </div>
      )}
      <div onClick={handleGoToMenu} style={{ cursor: "pointer" }}>
        <div className="IconWrapper">
          <MenuIcon />
        </div>
        <span>{t("reservation.seeMenu")}</span>
      </div>
      {reservation.business.contact.phone.value && (
        <div
          onClick={() =>
            window.open(
              `tel:${reservation.business.contact.phone.value}`,
              "_self"
            )
          }
        >
          <div className="IconWrapper">
            <PhoneIcon />
          </div>
          <span>
            <FormattedPhoneNumber
              phoneNumber={reservation.business.contact.phone.value}
              className="textWhite h4 Medium"
            />
          </span>
        </div>
      )}
      {reservation.business.address.city && (
        <div
          onClick={() =>
            business.address.googleLink &&
            window.open(business.address.googleLink, "_self")
          }
        >
          <div className="IconWrapper">
            <LocationIcon />
          </div>
          <span>
            {" "}
            {`${reservation.business.address.city || ""} ${
              reservation.business.address.city
                ? t("inputs.city").toLowerCase()
                : ""
            } ${
              reservation.business.address.city &&
              reservation.business.address.street
                ? ","
                : ""
            } ${reservation.business.address.street || ""}`}
          </span>
        </div>
      )}
    </div>
  );

  const ReservationStatus = (status) => {
    switch (status) {
      case enums.reservationStatus.pending:
        return (
          <div className="ReservationInfoStatusWrapper">
            <span className="ReservationInfoStatusPending">
              {t("reservation.pending")}
            </span>
            <PendingIcon />
          </div>
        );
      case enums.reservationStatus.confirmed:
        return (
          <div className="ReservationInfoStatusWrapper">
            <span className="ReservationInfoStatusConfirmed">
              {t("reservation.confirmed")}
            </span>
            <ConfirmedIcon />
          </div>
        );
      case enums.reservationStatus.denied:
        return (
          <div className="ReservationInfoStatusWrapper">
            <span className="ReservationInfoStatusDenied">
              {t("reservation.denied")}
            </span>
            <DeniedIcon />
          </div>
        );
      default:
        return null;
    }
  };

  const staticBoxHeight = 265;

  return (
    <div
      className="ReservationInfo"
      style={{
        height: showReservationDetail
          ? `calc(${staticBoxHeight}px + ${reservationDetailsHeight}px)`
          : `${staticBoxHeight}px`,
      }}
    >
      {isDeleteReservationLoading && (
        <div className="ReservationInfoMoreDetailLoading">
          <Spinner />
        </div>
      )}
      <div className="ReservationInfoHeader">
        <div className="ReservationInfoBusiness">
          <div className="ReservationInfoBusinessImage">
            <ImageWithPlaceholder
              imageSource={createDOBucketName(reservation.business.images.logo)}
              placeholder={IMAGE_ITEM_PLACEHOLDER}
              alt="logo"
            />
          </div>
          <h3>{reservation.business.name}</h3>
        </div>
        <div>
          <ThreeDots
            options={threeDotsOptions}
            className="ReservationInfoHeaderThreeDots"
          />
        </div>
      </div>
      <div className="ReservationInfoStatus">
        {ReservationStatus(reservation.status)}
      </div>
      <div className="ReservationInfoDetail">
        <div className="ReservationInfoDetailDate">
          <div>
            <CalendarIcon className="ReservationInfoDetailCalendarIcon" />
            <span>{formatReservationDate(reservation.startDateTime)}</span>
          </div>
          <div>
            <GuestCountIcon className="ReservationInfoDetailCalendarIcon" />
            <span>
              {t("guests.guest")} ({reservation.guestsCount})
            </span>
          </div>
        </div>
        <div className="ReservationInfoDetailGuest">
          <div>
            <ClockIcon className="ReservationInfoDetailClockIcon" />
            <span>
              {`${startTime} - ${endTime.includes(":") ? endTime : t(endTime)}`}
            </span>
          </div>
          <div>
            <TableIcon
              style={{ marginLeft: "2" }}
              className="ReservationInfoDetailCalendarIcon"
            />
            {reservation?.table?.name ? (
              <div className="ReservationTableAndZoneName">
                <h6>{reservation?.table?.name}</h6>
                <h5>{"("}</h5>
                <h6>{reservation.table.zoneName}</h6>
                <h5>{")"}</h5>
              </div>
            ) : (
              <span>{t("reservation.tableNotFound")}</span>
            )}
          </div>
        </div>
      </div>

      <div
        className="ReservationsDetailsButton"
        onClick={() => {
          setShowReservationDetail(!showReservationDetail);
        }}
      >
        <p>
          {showReservationDetail
            ? t("reservation.showLess")
            : t("reservation.showMore")}
        </p>
        <ArrowIcon
          className={showReservationDetail ? "RotateArrowIcon" : undefined}
        />
      </div>
      {reservationDetail}
      <Confirm
        title={t("modal.deleteModalTitle")}
        description={t("modal.deleteModalDescription")}
        mainElementRefConfirm={mainElementRefConfirm}
        openSlide={openSlideConfirm}
        onCancel={(e) => handleOnCancel(e)}
        onConfirm={(e) => handleOnConfirm(e)}
      />
    </div>
  );
};

ReservationInfo.propTypes = {
  reservation: PropTypes.object,
  setGuestReservations: PropTypes.func,
  setShowAddReservationForm: PropTypes.func,
};

export default ReservationInfo;

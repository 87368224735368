import React, { useEffect, useState } from "react";
import cx from "classnames";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";

import useAsync from "utils/hooks/useAsync";
import OtpInput from "components/forms/input/input-otp/OtpInput";
import IconButton from "components/buttons/icon-button/IconButton";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useFormOutsideClickHandler from "utils/hooks/useFormOutsideClickHandler";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import { verifyUserOtpCode } from "utils/api/services/user";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { createOtpRequestForAssignUserToBusiness } from "utils/api/services/otp";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import { ReactComponent as IconArrowLeft } from "assets/icons/arrows/ArrowLeft.svg";
import { STORE_NAMES } from "utils/constants/redux";
import { STEP_ENUMS, USER_MODAL_ENUMS } from "../../AdminUser";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";

import "./AdminUserModalBody.scss";

const AdminOtpVerifyModalBody = ({
  counter,
  formData,
  handleClickBackButton,
  setModalStep,
  setIsUserExist,
  setFormData,
  setCounter,
  signInMethod,
  setSignInMethod,
  setOpenSlide,
  initialFormData,
  setOutsideClickAction,
}) => {
  const { t } = useTranslation();
  const { guestSignUpOtp } = useValidationSchema(t);
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();
  const [otpCode, setOtpCode] = useState(null);
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business].business?.id
  );
  const otpSendingAvailable = counter === 0;
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    trigger,
    getValues,
    setError,
  } = useForm({
    resolver: zodResolver(guestSignUpOtp),
    criteriaMode: "all",
  });

  const [
    openSlideConfirmCloseModal,
    setOpenSlideConfirmCloseModal,
    mainElementRefConfirmCloseModal,
  ] = useOutsideClick();

  const handleOnCloseModal = () => {
    const editedFormData = { ...formData, ...getValues() };
    if (!isEqual(editedFormData, initialFormData)) {
      return setOpenSlideConfirmCloseModal(true);
    }
    setSignInMethod(USER_MODAL_ENUMS.phoneNumber);
    setFormData(initialFormData);
    setOpenSlide(false);
  };

  useFormOutsideClickHandler({
    formData,
    formDataInitial: initialFormData,
    setOpenSlide,
    setOpenSlideConfirmCloseModal,
    setOutsideClickAction,
  });

  const resendButtonClass = cx("ForgetPasswordVerifyOtpResendButton", {
    isDisabled: !otpSendingAvailable || getValues().otpSendCount === 0,
  });

  useEffect(() => {
    if (otpCode && otpCode.length === 6) {
      handleSubmit(handleClickSubmitOtpVerification)();
    }
  }, [otpCode]);

  const resendMessage =
    getValues().otpSendCount === 0
      ? t("errorMessages.input.pinCode.TryAgainLater")
      : otpSendingAvailable
      ? t("auth.resendingIsAvailable")
      : `${t("auth.resendingCodeWillBeAvailableIn")} ${Math.floor(
          counter / 60
        )}:${(counter % 60).toString().padStart(2, "0")}`;

  const handleOtpChange = async (value) => {
    setOtpCode(value);
    setValue(USER_MODAL_ENUMS.otpCode, value);
    if (errors.otpCode) {
      await trigger(USER_MODAL_ENUMS.otpCode);
    }
  };

  const handleClickSubmitOtpVerification = () => {
    executeVerifyUserOtpCode({
      otpToken: formData.otpToken,
      otpCode: getValues().otpCode,
    });
  };

  const {
    execute: executeVerifyUserOtpCode,
    loading: isLoadingVerifyUserOtpCode,
  } = useAsync(verifyUserOtpCode, {
    onError: (error) => {
      const errorData = handleAPIErrorMessage(error.response.data);
      if (errorData) {
        const { field, errorMessage } = errorData;
        setError(field, {
          type: "manual",
          message: errorMessage,
        });
      }
    },
    onSuccess: ({ data }) => {
      setModalStep(STEP_ENUMS.ADD_USER_SIGN_UP);
      setFormData((prev) => ({
        ...prev,
        otpToken: data.otpToken,
        otpSendCount: data.otpSendCount,
        firstName: data?.user?.firstName,
        lastName: data?.user?.lastName,
        userId: data?.user?.id,
      }));
      setIsUserExist(!!data?.user);
    },
  });

  const { execute: executeCreateOtpCode } = useAsync(
    createOtpRequestForAssignUserToBusiness,
    {
      onError: (error) => {
        const errorData = handleAPIErrorMessage(error.response.data);
        if (errorData) {
          const { field, errorMessage } = errorData;
          setError(field, {
            type: "manual",
            message: errorMessage,
          });
        }
      },
      onSuccess: ({ data }) => {
        setFormData((prev) => ({
          ...prev,
          otpToken: data.otpToken,
          otpSendCount: data.otpSendCount,
        }));
        setCounter(60);
        setModalStep(STEP_ENUMS.OTP_VERIFICATION);
      },
    }
  );

  const handleOnCancelCloseModal = () => {
    setOpenSlideConfirmCloseModal(false);
  };

  const handleOnConfirmCloseModal = () => {
    setFormData(initialFormData);
    setOpenSlideConfirmCloseModal(false);
    setOpenSlide(false);
  };

  const handleResendCode = () => {
    let otpBody;
    if (signInMethod === USER_MODAL_ENUMS.email) {
      otpBody = { email: formData.email };
    } else if (signInMethod === USER_MODAL_ENUMS.phoneNumber) {
      otpBody = { phoneNumber: formData.phoneNumber.replace(/\+/g, "") };
    }
    executeCreateOtpCode({ businessId, otpBody });
  };

  return (
    <div>
      <div>
        <div className="AdminUserModalTitle">
          <div className="AdminUserModalTitleLeft">
            <IconButton
              onClick={handleClickBackButton}
              svgComponent={<IconArrowLeft />}
              className="ModalHeaderBackIcon"
            />
          </div>
          <h3 className="SemiBold">{t("user.addEmployee")}</h3>
          <div className="AdminUserModalTitleRight">
            <IconButton
              onClick={handleOnCloseModal}
              svgComponent={<IconClose />}
            />
          </div>
        </div>
        <div className="AdminUserModalBody">
          <div className="AdminUserModalOtpVerify">
            <h5 className="AdminUserModalOtpVerifyTitle SemiBold">
              {t("auth.verifyOtp")}
            </h5>
            <h5 className="AdminUserModalOtpVerifySubTitle Medium">
              {t("errorMessages.input.otpCode.sendOtp")}{" "}
              <span className="SemiBold">
                {formData.phoneNumber
                  ? `+${formData.phoneNumber}`
                  : formData.email}{" "}
              </span>
            </h5>
            <OtpInput
              name={"otpCode"}
              value={getValues().otpCode}
              hasError={errors.otpCode}
              onOtpChange={handleOtpChange}
              func={{
                ...register("otpCode", {}),
              }}
              error={
                <ErrorMessage
                  errors={errors}
                  name="otpCode"
                  render={({ message }) => (
                    <p className="h7 error-message">{t(message)}</p>
                  )}
                />
              }
            />

            <div className="AdminUserModalOtpVerifyResend">
              <h6 className="SemiBold h6">{resendMessage}</h6>
              <button
                className={resendButtonClass}
                disabled={
                  !otpSendingAvailable || getValues().otpSendCount === 0
                }
                onClick={handleResendCode}
              >
                {t("buttons.resend")}
              </button>
            </div>
          </div>
        </div>
        <div className="AdminUserModalFooter">
          <PrimaryButton
            onClick={handleSubmit(handleClickSubmitOtpVerification)}
            text={t("buttons.continue")}
            isLoading={isLoadingVerifyUserOtpCode}
          />
        </div>
      </div>
      <Confirm
        title={t("modal.warningModalTitleUnsavedChanges")}
        type={ENUMS_CONFIRM.types.TYPE_C}
        mainElementRefConfirm={mainElementRefConfirmCloseModal}
        openSlide={openSlideConfirmCloseModal}
        onCancel={handleOnCancelCloseModal}
        onConfirm={handleOnConfirmCloseModal}
        description={t("modal.warningModalDescription")}
      />
    </div>
  );
};

AdminOtpVerifyModalBody.propTypes = {
  counter: PropTypes.number,
  formData: PropTypes.object,
  handleClickBackButton: PropTypes.func,
  setModalStep: PropTypes.func,
  setIsUserExist: PropTypes.func,
  setFormData: PropTypes.func,
  setCounter: PropTypes.func,
  signInMethod: PropTypes.string,
  setSignInMethod: PropTypes.func,
  setOpenSlide: PropTypes.func,
  initialFormData: PropTypes.object,
  setOutsideClickAction: PropTypes.func,
};

export default AdminOtpVerifyModalBody;

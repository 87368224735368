import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getBrandMainLogo } from "utils/branding-helper";
import { ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import BackButton, {
  ENUMS as BACK_BUTTON_ENUMS,
} from "components/buttons/back-button/BackButton";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import OrSignUpWith from "pages/client/auth/sign-up/or-sign-up-with/OrSignUpWith";
import useLogoutGuest from "utils/hooks/useGuestLogout";

import "./Auth.scss";

const MainLogo = getBrandMainLogo();

const Auth = () => {
  const { logoutGuest } = useLogoutGuest();

  useEffect(() => {
    logoutGuest();
  }, []);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { business } = useSelector((state) => state[STORE_NAMES.business]);
  const handleGoMenu = () => {
    navigate(
      business
        ? `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business.id}${ROUTE_NAME.menu}`
        : `${ROUTE_NAME.client}${ROUTE_NAME.allBusinesses}`
    );
  };
  const handleSignUp = () => {
    navigate(`${ROUTE_NAME.client}${ROUTE_NAME.auth}${ROUTE_NAME.signUp}`);
  };
  const handleSignIn = () => {
    navigate(`${ROUTE_NAME.client}${ROUTE_NAME.auth}${ROUTE_NAME.signIn}`);
  };

  const GuestAuthHeader = (
    <div className="GuestAuthHeader">
      <BackButton
        onClick={handleGoMenu}
        type={BACK_BUTTON_ENUMS.types.TYPE_D}
      />
      <div className="GuestAuthLogo">
        <MainLogo />
      </div>
    </div>
  );

  const GuestAuthContent = (
    <div className="GuestAuthContent">
      <PrimaryButton
        onClick={handleSignUp}
        type={PRIMARY_BUTTON_ENUMS.types.TYPE_P}
        text={t("login.signUp.title")}
      />
      <PrimaryButton
        onClick={handleSignIn}
        type={PRIMARY_BUTTON_ENUMS.types.TYPE_S}
        text={t("buttons.login")}
      />
      <OrSignUpWith />
    </div>
  );

  return (
    <div className="GuestAuth">
      {GuestAuthHeader}
      {GuestAuthContent}
    </div>
  );
};

export default Auth;

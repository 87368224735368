export const RESERVATION_STEP_TYPES = {
  businessSelection: {
    type: "business_selection",
    title: "reservation.chooseRestaurant",
    key: "businessSelection",
  },
  tableSelectionType: {
    type: "table_selection_type",
    title: "reservation.chooseTable",
    key: "tableSelectionType",
  },
  chooseDate: {
    type: "reservation_form",
    title: "reservation.setDate",
    key: "chooseDate",
  },
  addContact: {
    type: "reservation_contact",
    title: "reservation.addContact",
    key: "addContact",
  },
  reservationFinalDetail: {
    type: "reservation_final_detail",
    title: "reservation.confirmation",
    key: "reservationFinalDetail",
  },
};

export const generateDurationOptions = (t) => {
  return Array.from({ length: 96 }, (_, i) => {
    const minutes = (i + 1) * 15;
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    const hourText =
      hours > 0 ? `${hours} ${t("common.time.shortVersion.hour")}` : "";
    const minuteText =
      mins > 0 ? `${mins} ${t("common.time.shortVersion.minute")}` : "";

    const name = hourText
      ? `${hourText}${minuteText ? ` ${minuteText}` : ""}`
      : `${mins.toString().padStart(2, "0")} ${t(
          "common.time.shortVersion.minute"
        )}`;

    return { id: i, name };
  });
};

export const TIME_OPTIONS = Array.from({ length: 96 }, (_, i) => {
  const minutes = i * 15;
  let hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  if (hours === 24) hours = 0; // If hours is 24, set to 0

  return {
    id: i,
    name: `${hours > 9 ? hours : "0" + hours}:${mins > 9 ? mins : "0" + mins}`,
    hours,
    minutes: mins,
  };
});

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import PropTypes from "prop-types";
import { STORE_NAMES } from "utils/constants/redux";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import { useTranslation } from "react-i18next";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import { useKeyPress } from "utils/hooks/useKeyPress";
import { EVENT_CODES } from "utils/constants/events";

import "./SignUpForm.scss";

const SignUpForm = ({ handleSignupFormSubmit, isUserExist, formData }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const { plans } = useSelector((state) => state[STORE_NAMES.app]);
  const isLoadingSignUp = useSelector((state) => state[STORE_NAMES.user])
    .thunkAPIStates?.signup;

  const enterPressed = useKeyPress(EVENT_CODES.enter);

  useEffect(() => {
    if (enterPressed) {
      handleSubmit(handleSignupFormSubmit)();
    }
  }, [enterPressed]);

  return (
    <div className="SignUpForm">
      <h5 className="SemiBold">{t("stepper.userDetail")}</h5>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="SignUpFormInputGroups">
          <InputControl
            type="text"
            placeholder={t("inputs.firstName")}
            required
            name="firstName"
            func={{ ...register("firstName") }}
            hasError={errors.firstName}
            labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
            defaultValue={formData.firstName}
            readOnly={isUserExist}
            error={
              <ErrorMessage
                errors={errors}
                name="firstName"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
          />
          <InputControl
            type="text"
            placeholder={t("inputs.lastName")}
            defaultValue={formData.lastName}
            readOnly={isUserExist}
            required
            name="lastName"
            labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
            func={{ ...register("lastName") }}
            hasError={errors.lastName}
            error={
              <ErrorMessage
                errors={errors}
                name="lastName"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
          />
        </div>
        <div className="SignUpFormInputGroups">
          <InputControl
            type="text"
            placeholder={t("inputs.businessName")}
            required
            name="businessName"
            labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
            func={{ ...register("businessName") }}
            hasError={errors.businessName}
            error={
              <ErrorMessage
                errors={errors}
                name="businessName"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
          />
          <Controller
            name="plan"
            control={control}
            defaultValue={{}}
            render={({ field: { value, onChange } }) => (
              <Dropdown
                options={plans || []}
                onChange={onChange}
                placeholder={t("business.plan")}
                value={value}
                isOptionRequired
                isDisabled={plans.length === 0}
                name="plan"
                required
                error={
                  <ErrorMessage
                    errors={errors}
                    name="plan"
                    render={({ message }) => (
                      <p className="h7 error-message">{message}</p>
                    )}
                  />
                }
                hasError={errors.plan}
              />
            )}
          />
        </div>
        {!isUserExist && (
          <div className="SignUpFormInputGroups">
            <InputControl
              type="password"
              name="password"
              labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
              placeholder="Password"
              required
              className="VerifyPasswordFormInput"
              {...register("password")}
              hasError={errors.password}
              error={
                <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
            />
          </div>
        )}
        <PrimaryButton
          onClick={handleSubmit(handleSignupFormSubmit)}
          text={t("buttons.continue")}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_A}
          isLoading={isLoadingSignUp}
          isDisabled={isLoadingSignUp}
          className="SignupFormSubmitButton"
        />
      </form>
    </div>
  );
};

SignUpForm.propTypes = {
  handleSignupFormSubmit: PropTypes.func,
  isUserExist: PropTypes.bool,
  formData: PropTypes.object,
};

export default SignUpForm;

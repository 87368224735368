import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { STORE_NAMES } from "utils/constants/redux";
import { ReactComponent as TablePreview } from "assets/icons/other/TablePreview.svg";
import { formatTime } from "utils/helpers";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import AdminChat from "pages/admin/admin-pages/admin-order/admin-chat/AdminChat";
import Search, { ENUMS as SEARCH_ENUMS } from "components/forms/search/Search";
import { ReactComponent as OrderIcon } from "assets/icons/admin-dashboard/Order.svg";
import CHAT_ICON from "assets/images/chat/chat-icon.svg";
import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";
import { QUERY_PARAMS } from "utils/constants/routes";
import EmptyState from "components/admin/empty-state/EmptyState";
import PleaseSelectChatIcon from "assets/icons/chat/chat-v2.svg";
import SearchIcon from "assets/icons/chat/search.svg";
import { advanceSearch } from "utils/algorithms";

import "./AdminDetailedChat.scss";

const ChatLineItem = ({
  topicName,
  lastMessage,
  unreadMessageCount,
  onClickHandler,
  isSelected,
}) => {
  return (
    <div
      className={cx("ChatLineItem", { isSelected: isSelected })}
      onClick={onClickHandler}
    >
      <div className="ChatLineItemContent">
        <div className="ChatLineAvatar">
          <TablePreview />
        </div>
        <div className="ChatLineItemPreview">
          <h5 className="SemiBold">{topicName}</h5>
          {lastMessage && (
            <h6>
              {lastMessage.author.name}: {lastMessage.text}
            </h6>
          )}
        </div>
        <div className="ChatLineItemDetails">
          <span
            className={cx("ChatLineItemDetailsDate", {
              isUnread: unreadMessageCount > 0,
            })}
          >
            {lastMessage && <h6>{formatTime(lastMessage?.dateTime)}</h6>}
          </span>
          {unreadMessageCount > 0 && (
            <span className="ChatLineItemDetailsUnreadMessageCount">
              <h6 className="h7 SemiBold">{unreadMessageCount}</h6>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const FILTER_BY_READ_ENUMS = [
  {
    value: "all",
    label: "chat.allMessages",
  },
  {
    value: "unread",
    label: "chat.unreadMessages",
  },
];
const AdminDetailedChat = ({ setSearchParams, searchParams }) => {
  const { t } = useTranslation();
  const initialZone = { name: t("zone.allZones"), id: null };
  const topics = useSelector((state) => state[STORE_NAMES.chat].topics);
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const zones = useSelector((state) => state[STORE_NAMES.zones].zones);
  const roleEnums = useSelector(
    (state) => state[STORE_NAMES.app].enums
  )?.roleName;
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const author = useSelector((state) => state[STORE_NAMES.user].user);
  const [filterByRead, setFilterByRead] = useState(FILTER_BY_READ_ENUMS[0]);
  const selectedTopicId =
    Number(searchParams.get(QUERY_PARAMS.selectedChat)) || null;
  const [selectedZone, setSelectedZone] = useState(initialZone);
  const [searchValue, setSearchValue] = useState("");

  const chatLineClickHandler = (topicId) => {
    if (topicId === selectedTopicId) {
      searchParams.delete(QUERY_PARAMS.selectedChat);
      setSearchParams(searchParams);
      return;
    }
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(QUERY_PARAMS.selectedChat, topicId);
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    if (!orders.length || !selectedTopic) {
      searchParams.delete(QUERY_PARAMS.selectedChat);
      setSearchParams(searchParams);
    }
  }, [orders.length]);

  const handleZoneChange = (zone) => {
    setSelectedZone(zone);
    setFilterByRead(FILTER_BY_READ_ENUMS[0]);
  };

  const findOrderTableIdByOrderId = (orderId) => {
    return orders.find((order) => order.id === orderId)?.table?.id;
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const findTableAndZoneByTableId = (tableId) => {
    for (let zone of zones) {
      const foundTable = zone.tables?.find((table) => table.id === tableId);
      if (foundTable) {
        return {
          zone: { id: zone.id, name: zone.name },
          table: { id: foundTable.id, name: foundTable.name },
        };
      }
    }
  };

  const detectUnReadMessages = (topic) => {
    const authorLastReadTime = topic.usersLastReadTime.find(
      (item) => item.userId === author.id
    )?.dateTime;
    return topic.messages.filter(
      (message) =>
        message.author.id !== author.id &&
        (authorLastReadTime ? authorLastReadTime < message.dateTime : true)
    );
  };

  const getTopicGuestCount = (orderId) => {
    const currentOrder = orders.find((order) => order.id === orderId);
    return currentOrder.guests?.length || 0;
  };

  // Function to get guest profile info by authorId and topicId
  const getGuestProfileInfo = (authorId, topicId) => {
    const order = orders.find((order) => order.id === topicId);

    const guestInOrder = order.guests.find(
      (guest) => guest.person.id === authorId
    );

    return {
      name: guestInOrder?.name || `${t("dashboard.guest.guest")} ${authorId}`,
    };
  };

  const enrichTopic = (topic) => {
    const tableId = findOrderTableIdByOrderId(topic.orderId);
    if (tableId) {
      const { table, zone } = findTableAndZoneByTableId(tableId);
      const guestCount = getTopicGuestCount(topic.orderId);
      const unreadMessageCount = detectUnReadMessages(topic).length;
      return { ...topic, table, zone, unreadMessageCount, guestCount };
    }
  };

  // Function to filter topics by selected zone
  const filterByZone = (topic, selectedZone) => {
    if (selectedZone?.id) {
      return topic.zone.id === selectedZone.id;
    }
    return true;
  };

  // Function to filter topics by read/unread status
  const filterByReadStatus = (topic, filterByRead) => {
    if (filterByRead.value === "all") {
      return true;
    } else if (filterByRead.value === "unread") {
      return topic.unreadMessageCount > 0;
    }
    return true;
  };

  // Function to filter topics by search value
  const filterBySearchValue = (topic, searchValue) => {
    if (searchValue) {
      const searchValueLower = searchValue.trim().toLowerCase();

      const topicZoneNameMatches = advanceSearch({
        string: topic.zone.name,
        searchString: searchValueLower,
      });

      const topicTableNameMatches = advanceSearch({
        string: topic.table.name,
        searchString: searchValueLower,
      });

      const messageContainsSearchValue = topic.messages.some((message) =>
        advanceSearch({
          string: message.text,
          searchString: searchValueLower,
        })
      );

      return (
        topicZoneNameMatches ||
        topicTableNameMatches ||
        messageContainsSearchValue
      );
    }

    return true;
  };

  // Function to sort topics by the latest message date
  const sortByLatestMessageDate = (a, b) => {
    const dateA = new Date(a.messages[a.messages.length - 1]?.dateTime);
    const dateB = new Date(b.messages[b.messages.length - 1]?.dateTime);
    return dateB - dateA;
  };
  // Main processing of topics

  const potentialTopics = orders
    .filter((order) => {
      const allCurrentTopicId = topics.flatMap((topic) => topic.id);
      return !allCurrentTopicId.includes(order.id);
    })
    .map((order) => {
      return {
        id: order.id,
        businessId: business.id,
        orderId: order.id,
        messages: [],
        usersLastReadTime: [],
      };
    });

  const selectedTopic = [...topics, ...potentialTopics]
    .map(enrichTopic)
    .filter(Boolean)
    .find((topic) => topic.id === selectedTopicId);

  const updatedTopics = [...topics, ...potentialTopics]
    .map(enrichTopic)
    .filter((topic) => filterByZone(topic, selectedZone))
    .filter((topic) => filterByReadStatus(topic, filterByRead))
    .filter((topic) => filterBySearchValue(topic, searchValue))
    .filter(Boolean)
    .sort(sortByLatestMessageDate);

  const closeChatHandler = () => {
    searchParams.delete(QUERY_PARAMS.showDetailedChat);
    searchParams.delete(QUERY_PARAMS.selectedChat);
    setSearchParams(searchParams);
  };

  const editOrderHandler = () => {
    setSearchParams({
      ...searchParams,
      [QUERY_PARAMS.selectedOrder]: selectedTopic.orderId,
      [QUERY_PARAMS.from]: searchParams,
    });
  };

  if (!orders.length) {
    return (
      <EmptyState
        description={t("emptyStates.noChat")}
        icon={CHAT_ICON}
        isAdmin
        className="AdminMenuControllerEmptyState"
        closeButtonHandler={closeChatHandler}
      />
    );
  }

  const ChatHeader = (
    <div className="AdminDetailedChatHeader">
      <div className="AdminDetailedChatHeaderOrderInfo">
        <div className="AdminDetailedChatHeaderOrderInfoPreview">
          <TablePreview />
        </div>
        <div className="AdminDetailedChatHeaderOrderInfoDetail">
          <div className="AdminDetailedChatHeaderOrderInfoDetailTopicName SemiBold">
            <h4>{selectedTopic?.table.name}</h4>
            <h3>{"("}</h3>
            <h4>{selectedTopic?.zone.name}</h4>
            <h3>{")"}</h3>
          </div>
          <h4 className="Medium">
            {t(
              `dashboard.guest.${
                selectedTopic?.guestCount > 1 ? "guests" : "guest"
              }`
            )}
            : {selectedTopic?.guestCount}
          </h4>
        </div>
      </div>
      <div className="AdminDetailedChatHeaderOrderActions">
        <button
          className="AdminDetailedChatHeaderOrderInfoOrderBtn"
          onClick={editOrderHandler}
          type="button"
        >
          <OrderIcon />
        </button>
        <CloseButton
          onClick={closeChatHandler}
          type={ENUMS_CLOSE_BUTTON.types.TYPE_E}
          className={"AdminChatCloseBtn"}
        />
      </div>
    </div>
  );

  return (
    <div className="AdminDetailedChat">
      <div className="AdminChatSidebar">
        <div className="AdminChatSidebarTools">
          <div className="AdminChatSidebarToolsSearch">
            <Dropdown
              onChange={handleZoneChange}
              placeholder={t("zone.zone")}
              options={[initialZone, ...zones]}
              value={selectedZone}
              name="zone"
            />
            <Search
              onChange={(value) => {
                handleSearchChange(value);
              }}
              value={searchValue}
              placeholder={t("inputs.search")}
              type={SEARCH_ENUMS.types.TYPE_B}
            />
          </div>
          <div className="AdminChatSidebarToolsFilter">
            <div className="AdminChatSidebarToolsFilterOptions">
              {FILTER_BY_READ_ENUMS.map((option, index) => {
                const isSelected = filterByRead.value === option.value;
                const unreadChatCount =
                  updatedTopics.filter((topic) => topic.unreadMessageCount)
                    .length || null;
                return (
                  <div
                    key={index}
                    className={cx("AdminChatSidebarToolsFilterOption", {
                      isSelected,
                    })}
                    onClick={() => setFilterByRead(option)}
                  >
                    <h6 className="Medium">
                      {t(`${option.label}`)}
                      {option.value === "unread" && unreadChatCount > 0 && (
                        <h6 className="AdminChatSidebarToolsFilterOptionUnreadMessageCount h7 SemiBold">
                          {unreadChatCount}
                        </h6>
                      )}
                    </h6>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="ChatLineItems">
          {!updatedTopics.length && (
            <EmptyState
              description={
                filterByRead.value === FILTER_BY_READ_ENUMS[0].value
                  ? t("emptyStates.noSearchResults")
                  : t("emptyStates.noUnread")
              }
              icon={
                filterByRead.value === FILTER_BY_READ_ENUMS[0].value
                  ? SearchIcon
                  : CHAT_ICON
              }
              isAdmin
              className="AdminMenuControllerEmptyState"
            />
          )}
          {updatedTopics.map((topic) => {
            const lastMessage = topic.messages[topic.messages.length - 1];
            const authorName = lastMessage
              ? lastMessage.author.role.name === roleEnums.guest
                ? getGuestProfileInfo(lastMessage.author.id, topic.orderId)
                    ?.name
                : lastMessage.author.name
              : null;

            return (
              <ChatLineItem
                key={topic.id}
                onClickHandler={() => chatLineClickHandler(topic.id)}
                topicName={`${topic.table.name} (${topic.zone.name})`}
                lastMessage={
                  lastMessage && {
                    ...lastMessage,
                    author: { ...lastMessage.author, name: authorName },
                  }
                }
                isSelected={selectedTopic?.id === topic.id}
                unreadMessageCount={topic.unreadMessageCount}
                topicId={topic.orderId}
              />
            );
          })}
        </div>
      </div>
      {selectedTopic ? (
        <AdminChat selectedTopic={selectedTopic} chatHeader={ChatHeader} />
      ) : (
        <div className="AdminDetailedChatNoSelectedTopic">
          <div className="AdminDetailedChatHeader">
            <CloseButton
              onClick={closeChatHandler}
              type={ENUMS_CLOSE_BUTTON.types.TYPE_E}
              className={"AdminChatCloseBtn"}
            />
          </div>
          <EmptyState
            icon={PleaseSelectChatIcon}
            description={t("chat.selectTopic")}
            isAdmin
          />
        </div>
      )}
    </div>
  );
};

ChatLineItem.propTypes = {
  topicName: PropTypes.string,
  lastMessage: PropTypes.object,
  unreadMessageCount: PropTypes.number,
  onClickHandler: PropTypes.func,
  isSelected: PropTypes.bool,
};

AdminDetailedChat.propTypes = {
  searchParams: PropTypes.object,
  setSearchParams: PropTypes.func,
};
export default AdminDetailedChat;

import React from "react";

import "./FunZoneHomePage.scss";

const FunZoneHomePage = () => {
  return (
    <div className="funZoneHomePage">
      <div>
        <h1>Fun Zone</h1>
      </div>
      <h2 className="title">HomePage</h2>
    </div>
  );
};

export default FunZoneHomePage;

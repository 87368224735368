import React from "react";
import ReactPaginate from "react-paginate";
import { ReactComponent as AdminArrowLeft } from "assets/icons/arrows/ArrowLeftLong.svg";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./Pagination.scss";

const Pagination = ({ pageCount, pageRange, setActivePage, currentPage }) => {
  const { t } = useTranslation();
  const handlePageClick = (event) => {
    setActivePage(event.selected + 1);
  };

  return (
    <ReactPaginate
      previousLabel={
        currentPage !== 1 && (
          <>
            <AdminArrowLeft className="ArrowIcon ArrowLeftIcon" />
            {t("dashboard.pagination.prev")}
          </>
        )
      }
      nextLabel={
        pageCount > 1 &&
        pageCount !== currentPage && (
          <>
            {t("dashboard.pagination.next")}{" "}
            <AdminArrowLeft className="ArrowIcon ArrowRightIcon" />
          </>
        )
      }
      breakLabel={"..."}
      pageCount={pageCount}
      marginPagesDisplayed={1}
      pageRangeDisplayed={pageRange}
      onPageChange={handlePageClick}
      containerClassName={"CustomPagination"}
      pageClassName={"page-item"}
      pageLinkClassName={"page-link"}
      previousClassName={"page-item previousPageLink"}
      previousLinkClassName={"page-link"}
      nextClassName={"page-item"}
      nextLinkClassName={"page-link nextPageLink"}
      breakClassName={"page-item"}
      breakLinkClassName={"page-link"}
      activeClassName={"ActivePage"}
      forcePage={currentPage - 1} // Adjust to 0-based index
    />
  );
};

Pagination.propTypes = {
  setActivePage: PropTypes.func.isRequired,
  pageCount: PropTypes.number,
  pageRange: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default Pagination;

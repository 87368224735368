import React, { useEffect } from "react";

import SignUpStepper from "pages/client/auth/sign-up/sign-up-stepper/SignUpStepper";
import useLogoutGuest from "utils/hooks/useGuestLogout";

import "./SignUp.scss";

const SignUp = () => {
  const { logoutGuest } = useLogoutGuest();
  useEffect(() => {
    logoutGuest();
  }, []);

  return (
    <div className="GuestAuthSignUp">
      <SignUpStepper />
    </div>
  );
};

export default SignUp;

import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import cx from "classnames";

import { STORE_NAMES } from "utils/constants/redux";
import useOutsideClick from "utils/hooks/useOutsideClick";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import CalendarPicker from "components/elements/calendar-picker/CalendarPicker";
import AdminReservationSchedule from "pages/admin/admin-pages/admin-reservation/admin-reservation-schedule/AdminReservationSchedule";
import AddNewButton, {
  ENUMS as ADD_NEW_BUTTON_ENUMS,
} from "components/admin/buttons/add-new-button/AddNewButton";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar/calendar.svg";
import AdminReservationModal from "pages/admin/admin-pages/admin-reservation/admin-reservation-modal/AdminReservationModal";
import {
  getAllPendingReservationsAsync,
  getAllReservationsAsync,
} from "redux/actions/reservationAction";
import ReservationList from "components/reservation-list/ReservationList";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import useAsync from "utils/hooks/useAsync";
import { updateReservationAdmin } from "utils/api/services/reservation";
import {
  formatDateToShortFormat,
  handleOnAsyncError,
  handleOnAsyncSuccess,
} from "utils/helpers";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import { EDITOR_TYPE } from "pages/admin/data/constants/general";
import UndoButton from "components/admin/buttons/undo-button/UndoButton";
import { QUERY_PARAMS } from "utils/constants/routes";
import Spinner from "components/elements/spinner/Spinner";

import "./AdminReservation.scss";

const AdminReservation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const businessId = business?.id;
  const { reservations, pending, isLoading } = useSelector(
    (state) => state[STORE_NAMES.reservation]
  );
  const [editedReservations, setEditedReservations] = useState([]);
  const [selectedZones, setSelectedZones] = useState(zones);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedReservation, setSelectedReservations] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (editedReservations.length > 0) {
      setSearchParams({
        ...searchParams,
        [QUERY_PARAMS.unsavedChanges]: true,
      });
    } else {
      searchParams.delete(QUERY_PARAMS.unsavedChanges);
      setSearchParams(searchParams);
    }
  }, [editedReservations]);

  useEffect(() => {
    dispatch(
      getAllReservationsAsync({
        businessId,
        startDate: selectedDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
      })
    );
  }, [selectedDate]);

  useEffect(() => {
    dispatch(getAllPendingReservationsAsync({ businessId }));
  }, []);

  const [openSlideCalendar, setOpenSlideCalendar, mainElementRefCalendar] =
    useOutsideClick();

  const [
    openSlideReservation,
    setOpenSlideReservation,
    mainElementRefReservation,
    ,
    ,
    setOutsideClickAction,
  ] = useOutsideClick();

  const handleZoneChange = (zone) => {
    const zoneIsSelected = selectedZones.find((z) => z.id === zone.id);
    if (zoneIsSelected && selectedZones.length !== 1) {
      const updatedZones = selectedZones.filter((z) => z.id !== zone.id);
      setSelectedZones(updatedZones);
    } else if (!zoneIsSelected) {
      setSelectedZones((prev) => [...prev, zone]);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setOpenSlideCalendar(false);
  };

  const isToday =
    selectedDate.getFullYear() === new Date().getFullYear() &&
    selectedDate.getMonth() === new Date().getMonth() &&
    selectedDate.getDate() === new Date().getDate();

  const handleOnAsyncErrorForReservation = (errorMessage) => {
    handleOnAsyncError(errorMessage || t(commonAsyncErrorMessage));
  };

  const handleOnAsyncSuccessForReservation = (successMessage) => {
    handleOnAsyncSuccess(successMessage);
  };

  const { execute: executeUpdateReservation } = useAsync(
    updateReservationAdmin,
    {
      onError: () => handleOnAsyncErrorForReservation(),
      onSuccess: () => {
        setEditedReservations([]);
        // dispatch(
        //   getAllReservationsAsync({
        //     businessId,
        //     startDate: selectedDate.toISOString().split("T")[0],
        //   })
        // );
        // dispatch(getAllPendingReservationsAsync({ businessId }));
        handleOnAsyncSuccessForReservation(
          t("toastMessages.success.updateReservation")
        );
      },
    }
  );

  useEffect(() => {
    if (!openSlideReservation) {
      setSelectedReservations(null);
    }
  }, [openSlideReservation]);
  const onSaveHandler = () => {
    const requestBody = editedReservations.map((reservation) => {
      return {
        id: reservation.id,
        tableId: reservation.tableId,
        status: reservation.status,
        guestName: reservation.guestName,
        guestPhone: reservation.guestPhone,
        guestComment: reservation.guestComment,
        guestsCount: reservation.guestsCount,
        startDateTime: reservation.startDateTime,
        endDateTime: reservation.endDateTime,
      };
    });
    executeUpdateReservation(businessId, requestBody);
  };

  const onUndoHandler = () => {
    setEditedReservations([]);
  };
  const confirmedReservations = useMemo(() => {
    return reservations
      .map((reservation) => {
        const editedReservation = editedReservations.find(
          (res) => res.id === reservation.id
        );
        return editedReservation || reservation;
      })
      .filter((reservation) => reservation.status === "CONFIRMED");
  }, [reservations, editedReservations]);

  const isDisabled = editedReservations.length > 0;

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="AdminReservation">
      <div className="AdminReservationHeader">
        <h2 className="SemiBold">{t("navbarRoutes.reservation")}</h2>
        <div className="AdminReservationHeaderOperations">
          {editedReservations.length > 0 && (
            <div className="AdminReservationEditOperations">
              <UndoButton onClick={onUndoHandler} />
              <PrimaryButton onClick={onSaveHandler} text={t("buttons.save")} />
            </div>
          )}
          <AddNewButton
            onClick={() => setOpenSlideReservation(true)}
            label={t("buttons.add")}
            type={ADD_NEW_BUTTON_ENUMS.types.TYPE_A}
            isDisabled={isDisabled}
          />
        </div>
      </div>
      <div
        className={cx("AdminReservationOptions", {
          editMode: isDisabled,
        })}
      >
        <div
          className={cx("AdminReservationDatePicker", {
            isDisabled: isDisabled,
          })}
          onClick={() => setOpenSlideCalendar(true)}
        >
          {isToday ? (
            <h6 className="Medium">{t("reservation.today")}</h6>
          ) : (
            <h6 className="Medium">{formatDateToShortFormat(selectedDate)}</h6>
          )}
          <div className="AdminReservationDatePickerIcon">
            <CalendarIcon />
          </div>
        </div>
        <Dropdown
          onChange={handleZoneChange}
          name="zones"
          value={selectedZones}
          options={zones}
          isMultiple
          isDisabled={isDisabled}
          placeholder={t("zone.zones")}
        />
      </div>
      <div className="AdminReservationScheduleAndList">
        <AdminReservationSchedule
          reservations={confirmedReservations}
          selectedDate={selectedDate}
          selectedZones={selectedZones}
          isToday={isToday}
          editedReservations={editedReservations}
          setEditedReservations={setEditedReservations}
          setSelectedReservations={setSelectedReservations}
          setOpenSlide={setOpenSlideReservation}
        />
        <ReservationList
          title={t("reservation.pending")}
          reservations={pending}
          editedReservations={editedReservations}
          setSelectedReservations={setSelectedReservations}
          setOpenSlide={setOpenSlideReservation}
          updateReservation={executeUpdateReservation}
        />
      </div>
      <CalendarPicker
        value={selectedDate}
        refCalendar={mainElementRefCalendar}
        setOpenCalendar={setOpenSlideCalendar}
        openCalendar={openSlideCalendar}
        handleDateChange={(date) => handleDateChange(date)}
      />
      <AdminReservationModal
        mainElementRef={mainElementRefReservation}
        openSlide={openSlideReservation}
        setOpenSlide={setOpenSlideReservation}
        title={t("reservation.reservation")}
        data={selectedReservation}
        formType={selectedReservation ? EDITOR_TYPE.EDIT : EDITOR_TYPE.CREATE}
        selectedDate={selectedDate}
        setOutsideClickAction={setOutsideClickAction}
      />
    </div>
  );
};

export default AdminReservation;

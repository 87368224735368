import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Switch, { ENUMS as ENUMS_SWITCH } from "components/forms/switch/Switch";

import "./CallButton.scss";

export const ENUMS = {
  name: "CallButton",
};
const CallButton = ({ isChecked, onChange, title, isDisabled, className }) => {
  return (
    <div
      className={cx("CallButton", className, {
        isDisabled,
      })}
      onClick={isDisabled ? undefined : onChange}
    >
      <h6 className="SemiBold">{title}</h6>

      <Switch
        isChecked={isChecked}
        type={ENUMS_SWITCH.types.TYPE_B}
        isDisabled={isDisabled}
      />
    </div>
  );
};

CallButton.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};
export default CallButton;

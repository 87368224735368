import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useOutsideClick from "utils/hooks/useOutsideClick";
import Table from "components/admin/elements/table/Table";
import Pagination from "components/pagination/Pagination";
import EmptyTable from "assets/icons/admin-dashboard/EmptyTable.svg";

import "./AdminDashTable.scss";

const AdminDashTable = ({
  tableColumns,
  tableItems,
  disableNextButton = false,
  hasEditColumnTitle,
  hasEditColumn,
  hasEditRow,
  tableClassName,
  showClassName = false,
  totalPages,
  activePage,
  setActivePage,
  sortType,
  setSortType,
  columnClick,
  setColumnClick,
  showPagination = true,
  showLoadMore = false,
  customFilterIcon,
  isDataLoading,
  setColumnClickCount,
  classNameForEmptyDataComponent,
  currentPage,
}) => {
  const [openSlide, setOpenSlide] = useOutsideClick();
  const { t } = useTranslation();
  const handleOnEditUser = (id) => {
    setOpenSlide(!openSlide);
  };

  return (
    <div className="AdminDashTable">
      <div
        className={`AdminDashHistoryTable ${showClassName && "EditedTable"}`}
      >
        <Table
          columns={tableColumns}
          items={tableItems}
          hasEditColumnTitle={hasEditColumnTitle}
          onEdit={(id) => handleOnEditUser(id)}
          dashboardClassname={"AdminDashBoardCustomTable"}
          classname={tableClassName}
          tableControlsClassName={"tableControlsClassName"}
          hasEditColumn={hasEditColumn}
          hasEditRow={hasEditRow}
          sortType={sortType}
          setSortType={setSortType}
          setColumnClick={setColumnClick}
          customFilterIcon={customFilterIcon}
          columnClick={columnClick}
          setColumnClickCount={setColumnClickCount}
          isDataLoading={isDataLoading}
        />

        {tableItems?.length === 0 && !isDataLoading && (
          <div
            className={`AdminDashHistoryFormAndTableEmpty ${classNameForEmptyDataComponent}`}
          >
            <img src={EmptyTable} alt="Empty Table" />
            <h4 className="Medium AdminDashHistoryFormAndTableEmptyInfo">
              {t("emptyStates.noTableData")}
            </h4>
          </div>
        )}

        {showPagination && totalPages > 1 && !isDataLoading && (
          <div className="AdminDashHistoryTablePagination">
            <Pagination
              pageCount={totalPages}
              pageRange={5}
              setActivePage={setActivePage}
              currentPage={currentPage}
            />
          </div>
        )}
        {/*{showLoadMore && !isDataLoading && tableItems?.length > 10 && (*/}
        {/*  <div className="AdminDashHistoryTableLoadMoreLoadLess">*/}
        {/*    <div className="AdminDashHistoryTableLoadLess">*/}
        {/*      {activePage > 2 && (*/}
        {/*        <Button*/}
        {/*          className={*/}
        {/*            "AdminDashHistoryTableButton AdminDashHistoryTableDoubleLeftButton"*/}
        {/*          }*/}
        {/*          onClick={() => setActivePage(1)}*/}
        {/*          IconLeft={<ArrowLeft />}*/}
        {/*          IconRight={<ArrowRight />}*/}
        {/*          disable={isDataLoading}*/}
        {/*        />*/}
        {/*      )}*/}

        {/*      {activePage > 1 && (*/}
        {/*        <Button*/}
        {/*          text={t("dashboard.prevNext.previous")}*/}
        {/*          className={*/}
        {/*            "AdminDashHistoryTableButton AdminDashHistoryTablePreviousButton"*/}
        {/*          }*/}
        {/*          onClick={() => setActivePage((prev) => prev - 1)}*/}
        {/*          IconLeft={<ArrowLeft />}*/}
        {/*          disable={isDataLoading}*/}
        {/*        />*/}
        {/*      )}*/}
        {/*    </div>*/}

        {/*    {tableItems?.length === 0 || disableNextButton ? (*/}
        {/*      ""*/}
        {/*    ) : (*/}
        {/*      <Button*/}
        {/*        text={t("dashboard.prevNext.next")}*/}
        {/*        className="AdminDashHistoryTableButton AdminDashHistoryTableNextButton"*/}
        {/*        onClick={() => setActivePage((prev) => prev + 1)}*/}
        {/*        IconRight={<ArrowRight />}*/}
        {/*        disable={isDataLoading}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </div>
  );
};

AdminDashTable.propTypes = {
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      isFilterable: PropTypes.bool,
    })
  ).isRequired,

  tableItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      date: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      ZoneAndTable: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      item: PropTypes.string,
      subTotal: PropTypes.string,
      serviceFee: PropTypes.string,
      discount: PropTypes.string,
      total: PropTypes.string,
    })
  ).isRequired,

  hasEditColumn: PropTypes.bool,
  hasEditRow: PropTypes.bool,
  tableClassName: PropTypes.string,
  hasEditColumnTitle: PropTypes.bool,
  setActivePage: PropTypes.func,
  sortType: PropTypes.string,
  setSortType: PropTypes.func,
  columnClick: PropTypes.number,
  setColumnClick: PropTypes.func,
  totalPages: PropTypes.number,
  showClassName: PropTypes.bool,
  showPagination: PropTypes.bool,
  showLoadMore: PropTypes.bool,
  activePage: PropTypes.number,
  customFilterIcon: PropTypes.element,
  setColumnClickCount: PropTypes.func,
  disableNextButton: PropTypes.bool,
  isDataLoading: PropTypes.bool,
  classNameForEmptyDataComponent: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
};

export default AdminDashTable;
